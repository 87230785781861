import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import * as reportingItemActions from "../../../actions/reportingItem";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import CloseIcon from '@material-ui/icons/Close';
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import ComponentLoading from "../../../components/ComponentLoading";
import ReportingItemQuickCommentForm from '../../../components/forms/reportingItem/quickComment/form';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import UrlBuilderUtil from "../../../utils/UrlBuilderUtil";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import * as constants from '../../../constants/constants';
import QuickCommentIcon from "@material-ui/icons/ChatBubbleOutline";
import ReportingItemSummaryIntro from "../../../components/reportingItem/ReportingItemSummaryIntro";
import { Dialog, Typography, IconButton, DialogTitle, DialogContent, Hidden, Tooltip, Button, Grid } from "@material-ui/core";
import ArrayUtil from "../../../utils/ArrayUtil";
import DateUtil from "../../../utils/DateUtil";
import ReportingItemTimelinePeriodSection from "../../../components/reportingItem/ReportingItemTimelinePeriodSection";
import GoogleTagManagerUtil from "../../../utils/GoogleTagManagerUtil";
import PrintIcon from "@material-ui/icons/Print";
import RoomIcon from '@material-ui/icons/Room';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import CommentaryIcon from '@material-ui/icons/AssignmentOutlined';
import ReportingItemsUtil from "../../../utils/ReportingItemsUtil";

class ReportingItemTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: null,
            dataLoading: true,
            timelineLoading: true,
            lastDateLoaded: null,
            timelineData: [],
            showQCModalForm: false
        }
    }

    componentDidMount() {
        Promise.all([
            this.mountItem(),
            this.mountTimelineItems()
        ]).then(() =>
            this.setState({
                dataLoading: false
            })
        );
    }

    mountTimelineItems = () => {
        const { match, actions } = this.props;
        let { lastDateLoaded, timelineData } = this.state;
        const _this = this;
        var reportingItemId = objectPath.get(match, "params.reportingItemId");


        if (reportingItemId) {
            this.setState({
                timelineLoading: false
            });

            return actions.getReportingItemTimeline(reportingItemId, lastDateLoaded).then(response => {
                let newTimelineData = objectPath.get(response, "data.data");
                let newLastDateLoaded = objectPath.get(newTimelineData, "nextDate", null);

                if(newLastDateLoaded) {
                    timelineData.push(newTimelineData);
                    _this.setState({
                        lastDateLoaded: newLastDateLoaded,
                        timelineData: timelineData,
                        timelineLoading: false
                    });
                }
            });

        } else {
            return new Promise((resolve) => {

                _this.setState({
                    lastDateLoaded: null,
                    timelineData: [],
                    timelineLoading: false
                }, resolve);
            });
        }
    }

    mountItem() {
        const { match, history, actions } = this.props;
        const _this = this;
        var reportingItemId = objectPath.get(match, "params.reportingItemId");

        if (reportingItemId) {

            return actions.getReportingItemSummary(reportingItemId).then(response => {
                let reportingItemSummary = objectPath.get(response, "data.data");
                if (reportingItemSummary) {
                    return _this.setState({
                        item: reportingItemSummary,
                    })
                } else {
                    return history.push("/404");
                }
            });
        } else {
            return new Promise((resolve) => {
                _this.setState({
                    item: null,
                }, resolve);
            });
        }
    }

    handleQuickCommentOnRI = (values) => {
        const { actions, enqueueSnackbar } = this.props;
        const { item } = this.state;
        let successMessage = item ? 'Quick comment added to "' + objectPath.get(item, 'name', null) + '"' : 'Quick comment added to Reporting item';

        return actions.postReportingItemQuickCommentary(objectPath.get(item, 'id', null), values).then(response => {
            GoogleTagManagerUtil.trackQuickComment(item);
            if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
                SnackBarUtil.showSuccessMessage(response, enqueueSnackbar, successMessage);
                this.setState({
                    lastDateLoaded: null,
                    showQCModalForm: false,
                    timelineData: []
                }, () => {
                    this.mountTimelineItems();
                });

            }
        });
    }

    getDialogInnerPart = () => {
        const { item } = this.state;
        return item ? <div><DialogTitle
            className="formDialogTitle"
            onClose={this.closeQCModalForm}
            disableTypography={true}
        ><Typography variant="h5">
            <strong>{item.name}</strong>
            <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Close dialog">
                <IconButton size="small" onClick={this.closeQCModalForm}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </Typography>

        </DialogTitle>
            <DialogContent className={"dialogFormContent"}>
                <ReportingItemQuickCommentForm
                    expandable={true}
                    reportingItemId={item.id}
                    onCancel={this.closeQCModalForm}
                    proceedSubmit={this.handleQuickCommentOnRI}
                />
        </DialogContent></div> : null
    }

    getRelatedProjectId = () => {
        const { item } = this.state;
        return objectPath.get(item, 'projectId', null);
    }

    getItemName = () => {
        const { item } = this.state;
        return item ? item.name : ''
    }

    closeQCModalForm = () => {
        this.setState({
            showQCModalForm: false
        });
    }

    openMap = (url) => (e) => {
        e.preventDefault();
        window.open(url);
    }

    render() {
        const { classes, history } = this.props;
        var projectId = this.getRelatedProjectId();

        const { item, dataLoading, timelineLoading, timelineData, showQCModalForm, lastDateLoaded } = this.state;
        const editable = objectPath.get(item,'editable', false);

        const mapUrl = objectPath.get(item, 'projectMapUrl', '');
        const showPinpointMapIcon = mapUrl !== '';
        const externalDocuments = objectPath.get(item, 'externalDocuments', []);

        return (
            <div>
                <PageHeading
                    mutedHeading={projectId ? '#' + projectId : null}
                    heading={dataLoading ? "loading ..." : this.getItemName()}
                    externalDocuments={externalDocuments}
                    actions={
                        <div>
                            { showPinpointMapIcon ? 
                                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="View map">
                                    <IconButton color="secondary" className="outlinedIconButton" onClick={this.openMap(mapUrl)}>
                                        <RoomIcon />
                                    </IconButton>
                                </Tooltip>
                                : null 
                            }

                            { (typeof window.print !== "undefined") ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Print"><IconButton color="secondary" className="outlinedIconButton" onClick={() => window.print()}><PrintIcon /></IconButton></Tooltip> : null }
                            
                            { editable ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Add Quick comment"><IconButton className={!item.commentaryDue && item.quickCommentDue ? "outlinedIconButton due" : "outlinedIconButton"} color={item.quickCommentDue ? "default" : "secondary"} onClick={() => this.setState({ showQCModalForm: true })}><QuickCommentIcon /></IconButton></Tooltip> : null }
                            
                            { editable ? 
                                ReportingItemsUtil.isProgressReportDue(item) ?
                                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Progress report"><IconButton className="outlinedIconButton due" color="default" onClick={() => history.push(UrlBuilderUtil.getProgressReport(item.projectId)) }><AssignmentIcon /></IconButton></Tooltip>  
                                     : (item.commentaryDue ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Commentary"><IconButton className="outlinedIconButton due" color="default" onClick={() => history.push(UrlBuilderUtil.getReportingItemCommentary(item.id))}><CommentaryIcon /></IconButton></Tooltip>  : null ) 
                                    : null 
                                    }
                            <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getDashboard()} />
                        </div>
                    } />

                {dataLoading ? <ComponentLoading /> :
                    <div>
                        
                        <ReportingItemSummaryIntro item={item} />
                        
                        <PageHeading heading={"ACTIVITY TIMELINE"} />

                        { ArrayUtil.isNonEmptyArray(timelineData) ? 
                            timelineData.map((x,index) => <ReportingItemTimelinePeriodSection key={index} data={x} projectId={projectId} />)
                            : !timelineLoading ? <div className={classes.textCenter}><Typography variant="body2" className="muted">No results</Typography></div> : null}

                        <Grid container>
                        <Grid item className="gridItem timelineLoadMoreWrap" xs={12}>{timelineLoading ? <ComponentLoading /> : <Button 
                        size="large" 
                        color="primary" 
                        fullWidth={true} 
                        variant="outlined" 
                        onClick={() => this.mountTimelineItems()}>
                            {lastDateLoaded ? "Load updates before "+DateUtil.basicDate(lastDateLoaded) : "Load more" }
                        </Button> }</Grid>
                        </Grid>

                        <Hidden smDown><Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            onClose={this.closeQCModalForm}
                            open={showQCModalForm}
                            className={classes.formDialogWrap}
                        >
                            {this.getDialogInnerPart()}
                        </Dialog></Hidden>
                        <Hidden mdUp><Dialog
                            fullScreen
                            fullWidth={true}
                            onClose={this.closeQCModalForm}
                            open={showQCModalForm}
                            className={classes.formDialogWrap}
                        >
                            {this.getDialogInnerPart()}
                        </Dialog></Hidden>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
({
    actions: bindActionCreators(
        {
            ...reportingItemActions,
        },
        dispatch
    )
});

export default withRouter(withSnackbar(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withStyles(styles, { withTheme: true })(ReportingItemTimeline)
    )
));