
import ValidationUtil from '../../../utils/ValidationUtil';
import objectPath from 'object-path';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "name",
    "dueDays",
    "reminderDays1",
    "reminderDays2",
    "meetingSchedules"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  errors = ValidationUtil.checkStringMaxLength(['name'],values,errors,50);

  errors = ValidationUtil.checkIntegerMinMax([
    "dueDays",
    "reminderDays1",
    "reminderDays2",
  ],values,errors,1,20);


  //greater than
  let hasFieldError = objectPath.get(errors,'reminderDays1',null);
  if(hasFieldError === null){
    let reminderDays1 = parseInt(objectPath.get(values, 'reminderDays1', 0));
    let reminderDays2 = parseInt(objectPath.get(values, 'reminderDays2', 0)); 
     if(reminderDays2 >= reminderDays1){
      objectPath.set(errors,'reminderDays1', "Reminder 1 needs to be greater than Reminder 2.");
     } 
  }


  return errors;
};

export default validate;
