import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function FilterIcon(props) {
    return <SvgIcon {...props} viewBox="0 0 51.5 52.8">
<path d="M48.8,0.5H2.7C1.9,0.5,1.1,1,0.7,1.7C0.4,2.4,0.4,3.3,0.9,4l16.9,23.8c0,0,0,0,0,0c0.6,0.8,0.9,1.8,0.9,2.9
	v19.4c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,1,0.6,1.5,0.6c0.3,0,0.6-0.1,0.9-0.2l9.5-3.6c0.9-0.3,1.4-1.1,1.4-2V30.7c0-1,0.3-2,0.9-2.9
	c0,0,0,0,0,0L50.6,4c0.5-0.7,0.5-1.6,0.2-2.3C50.4,1,49.6,0.5,48.8,0.5z M31.5,26.3c-0.9,1.3-1.5,2.8-1.5,4.4v15.4l-8.7,3.3V30.7
	c0-1.6-0.5-3.1-1.5-4.4L3.5,3.1H48L31.5,26.3z"/>
      </SvgIcon>
  }