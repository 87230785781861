import React from 'react'
import AppCard from '../../components/AppCard';
import { Typography, Button } from '@material-ui/core';

const ServerError = ({ location }) => (
  <div>
    <AppCard type="error">
          <Typography variant="h1">Internal Server Error</Typography>
          <br />
          <Typography variant="body1">
          Sorry, something went wrong. Please try again later.
          </Typography>
          <br />
          <br />
          <Button href="/" variant="contained" color="default">Home page</Button>
    </AppCard>
    
  </div>
)

export default ServerError
