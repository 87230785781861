import FilterUtil from "../utils/FilterUtil"

export default {
    userInfo: {
        name: "",
        adgroups: [],
        role: "user"
    },
    drawerOpen: false,
    users: [],
    keycloakUsers: [],
    positions: [],
    strategicPlans: {},
    dashboardMergedData: {
        items : [],
        filter : FilterUtil.getDefaultFilterValues(),
        queryPage: 0
      }
};
