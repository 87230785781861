import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, Grid, Hidden } from '@material-ui/core';
import FormatterUtil from '../../utils/FormatterUtil';
import DateUtil from '../../utils/DateUtil';
import AssessmentUtil from '../../utils/AssessmentUtil'
import classnames from 'classnames'

function FinancialOverviewCard(props) {

  const { classes, item  } = props;

  const financialOverview = item && item.financialOverview;

  

  return financialOverview ? (
    
        <Grid container className={classes.financialOverviewCard}>
            <Grid item xs={12}>
                { item.reportDate ? <Typography variant="h6">
                <Hidden smDown>{ "As at " }</Hidden>
                { DateUtil.basicDateWithoutYear(item.reportDate) }
                </Typography> : <Typography variant="h6"> </Typography> }
            </Grid>


            <Hidden xsDown><Grid item sm={6}>
                <Typography className="cardCaption">Annual Budget (revised)</Typography>
            </Grid></Hidden>
            <Grid item xs={12} sm={6}>
                <Typography variant="body2">{ FormatterUtil.formatNZDollar(financialOverview.budget) }</Typography>
            </Grid>

            <Hidden xsDown><Grid item sm={6}>
                <Typography className="cardCaption">Actual YTD</Typography>
            </Grid></Hidden>
            <Grid item xs={12} sm={6}>
                <Typography variant="body2">{ FormatterUtil.formatNZDollar(financialOverview.acwp) }</Typography>
            </Grid>

            <Hidden xsDown><Grid item sm={6}>
                <Typography className="cardCaption">Budget YTD (revised)</Typography>
            </Grid></Hidden>
            <Grid item xs={12} sm={6}>
                <Typography variant="body2">{ FormatterUtil.formatNZDollar(financialOverview.bcws) }</Typography>
            </Grid>

            <Hidden xsDown><Grid item sm={6}>
                <Typography className="cardCaption">YTD Variance</Typography>
            </Grid></Hidden>
            <Grid item xs={12} sm={6}>
                <Typography variant="body2">{ FormatterUtil.formatNZDollar(financialOverview.bcwp) }</Typography>
            </Grid>

            <Hidden xsDown><Grid item sm={6}>
                <Typography className="cardCaption">System Assessment</Typography>
            </Grid></Hidden>
            <Grid item xs={12} sm={6}>
                
                <Typography variant="body2">
                {AssessmentUtil.getIndicatorText(financialOverview.costAssessment)} &nbsp;

                <span className={classnames("indicator",AssessmentUtil.getIndicatorClassname(classes, financialOverview.costAssessment))}></span>
                </Typography>
                
            </Grid>

        </Grid>
  ) : null;
}

FinancialOverviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FinancialOverviewCard);