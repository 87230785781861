import React, { Component } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Hidden
} from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import AddImageIcon from "@material-ui/icons/PhotoOutlined";
import CameraIcon from "@material-ui/icons/CameraAltOutlined";

import FormatterUtil from "../../utils/FormatterUtil";
import ImageList from "../ImageList";

export default class ImageInput extends Component {

  filesChanged = (e) => {
    const {
      input,
      multiple
    } = this.props;
    
    if(multiple){
      //TODO handle multiple files
    } else {
      if(e.target && e.target.files && e.target.files[0]){
        var fileObject = e.target.files[0];
        FormatterUtil.getBase64(fileObject).then(data => {
          input.onChange({
            name: fileObject.name,
            base64String: data,
            size: fileObject.size,
          });
        });
      }
      
      
    }
    
  }

  removeFileFromList = (index) => {
    const {
      input,
      multiple
    } = this.props;
    
    var newValue;

    if(multiple){
      newValue = [];
      //TODO handle multiple files
    } else { 
      newValue = null;

    }
    input.onChange(newValue);
    
  }

  selectedFiles = (value) => {
    const {
      multiple,
      disabled
    } = this.props;
    var filesArray = multiple ? Array.from(value) : value ? [value] : null;
    return  <ImageList files={filesArray} disabled={disabled} removeFileFromList={this.removeFileFromList} />
  }

  render() {
    //const { input: { value } } = this.props;
    const {
      input,
      name,
      meta: { touched, error },
      multiple,
      disabled
    } = this.props;

    var showError = touched && error ? true : false;

    

    return (
      <FormControl error={showError} className={showError ? "error imageInputWrap" : "imageInputWrap"}>
        {this.selectedFiles(input.value)}
        {!disabled && (multiple || (!multiple && !input.value)) ? <Button variant="text"><FormControlLabel
          control={
            <div>
            <input
              name={name}
              type="file"
              accept="image/*"
              onChange={this.filesChanged}
              multiple={multiple}
            />
            <Hidden smUp><CameraIcon /></Hidden><Hidden xsDown><AddImageIcon fontSize="small" /> Add image </Hidden>
            </div>
          }
        /></Button> : null }

        {InputHelperText({ touched, error })}
      </FormControl>
    );
  }
}
