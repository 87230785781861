import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import classnames from 'classnames';
import objectPath from 'object-path';
import * as formNames from "../../../constants/forms";
import * as constants from "../../../constants/constants";

import {
  TextInput,
  SelectWithSuggestions,
  RadioGroupInput,
} from "../../inputs/";


import validate from "./validate";
//import asyncValidate from "./asyncValidate";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "@material-ui/core/Fab";
import TickIcon from "@material-ui/icons/Check";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, IconButton, Typography, Divider, Tooltip, Button, InputAdornment } from "@material-ui/core";
import ProjectPreviewCard from "../../project/ProjectPreviewCard";
import warnAboutUnsavedForm from "../../../hoc/withWarnAboutUnsavedChanges";

const ReportingItemForm = props => {
  const {
    handleSubmit,
    //pristine,
    submitting,
    classes,
    proceedSubmit,
    activityOptions,
    positionOptions,
    searchForProject,
    onLinkProject,
    initialValues,
    project,
  } = props;
  
  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><TickIcon /></Fab>;

  const onEnterPressed = function (e){ // call for project 
    if (e.key === 'Enter') {
      e.preventDefault();//do not submit form
      searchForProject();
    }
    
  }

  let projectId = props.projectId ? props.projectId : objectPath.get(initialValues, 'projectId',null);
  let disableProjectFields = (projectId !== null) ? true : false;


  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classes.customForm}>
           <div className={classes.searchCorpWrap}>
          <Grid container>
            <Grid item className="gridItem btnWrap" xs={2} sm={3} md={3} lg={1}>
            <Tooltip title="Search project by ID">
            <IconButton size="small" onClick={searchForProject}>
                <SearchIcon />
              </IconButton>
              </Tooltip>
            </Grid>
            <Grid item className="gridItem" xs={10} sm={9} md={9} lg={11}>
            <Field name="searchProjectId" 
                  component="input" 
                  type="text" 
                  onKeyPress={onEnterPressed}
                  placeholder="Link to a CORP project" />
            <Field name="projectId"
            component="input"
            type="hidden" />
            </Grid>
          </Grid>
          </div>
          <div className="maxWidthPart">
          <Grid container>
            <Grid item className="gridItem" xs={12}>
              <ProjectPreviewCard project={project} actionButtons={
              <div>
                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Link project to reporting item">
                <Button onClick={onLinkProject} variant="contained" color="secondary">Link project</Button>
                </Tooltip>
              </div>
              } />
            </Grid>
            
              {
                projectId ? 
                <Grid item className="gridItem" xs={12}><Typography variant="h5">
                  LINKED TO CORP <strong>#{projectId}</strong>
                  
                </Typography><Divider /></Grid>
                : null
              }
            
            <Grid item className="gridItem" xs={12} lg={6}>
              <FormGroup>
                <Field name="name" component={TextInput} label="Name" helpText="Cannot be longer than 100 characters." disabled={disableProjectFields} inputProps={{maxLength: 100}} />
              </FormGroup>
            </Grid>
            <Grid item className="gridItem" xs={12} lg={6}>
              
                <Field name="positionId" component={SelectWithSuggestions} isSearchable={true} disabled={disableProjectFields} helpText="Please select project manager." label="Project manager" options={positionOptions} placeholder="Select project manager" />
              
            </Grid>
            <Grid item className="gridItem" xs={12}>
              <FormGroup>
                <Field name="description" component={TextInput} disabled={disableProjectFields} label="Description" multiline />
              </FormGroup>
            </Grid>
            <Grid item className="gridItem" xs={12} lg={6}>
                
                <Field name="activityId" component={SelectWithSuggestions} isSearchable={true} disabled={disableProjectFields} label="Activity" options={activityOptions} placeholder="Select activity" />
                
            </Grid>
            <Grid item className="gridItem" xs={12} lg={6}>
            <FormGroup>
                <Field name="commentaryFrequency" component={TextInput} label="Commentary Frequency" placeholder="0-120" 
                endAdornment={<InputAdornment position="end" className="daysSign noWrap">days</InputAdornment>}
                />
                </FormGroup>
            </Grid>
            {
                projectId ? <Grid item className="gridItem" xs={12}>
              <FormGroup>
                <Field 
                  name="reportFrequency" 
                  className="inlineRadioGroup" 
                  component={RadioGroupInput} 
                  disabled={disableProjectFields}
                  dataOptions={constants.REPORTING_FREQUENCIES} 
                  label="Report Frequency" />  
              </FormGroup>
            </Grid> : null }
            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12}>
              { disableSubmit ? submitButton : <Tooltip  title="Create reporting item" aria-label="Create">{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
};


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.REPORTING_ITEM_FORM_NAME, // a unique identifier for this form
  validate,
  //asyncValidate
})(ReportingItemForm)),formNames.REPORTING_ITEM_FORM_NAME);
