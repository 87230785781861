import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import styles from '../theme/styles';

function ComponentLoading(props) {
  const { classes } = props;
  return (
    <div className={classes.componentLoading}>
      <CircularProgress className={classes.progress} thickness={2} />
      {props.hideText ? null : <p>
      <br />
      <br />
      <Typography variant="caption">Loading</Typography>
      </p> }
    </div>
  );
}

ComponentLoading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ComponentLoading);