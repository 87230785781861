import { KeycloakManager } from '@cucumber/keycloak-manager';

const env = window.env;

const keycloakConfig = {
  realm: env.KEYCLOAK_REALM,
  url: env.KEYCLOAK_API_HOST + env.KEYCLOAK_API_PATH,
  clientId: env.KEYCLOAK_CLIENT,
  groupsKey: 'adgroups',
};

export default new KeycloakManager(keycloakConfig);
