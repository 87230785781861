import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";


  export function putProgressReport(data) {
    return function(dispatch) {
      ConsoleUtil.log('putProgressReport');
      return reportingClient
        .put(`ProgressReports/${data.projectId}`,data)
        //.put(`MockProgressReports/${data.projectId}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_PROGRESS_REPORT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROGRESS_REPORT))
        );
    };
  }

  export function getProgressReport(projectId, year = null, period = null) {
    return function(dispatch) {
      ConsoleUtil.log('getProgressReport');
      var queryPart = (year && period) ? '?year='+year+"&period="+period : '';
      return reportingClient
        .get(`ProgressReports/${projectId}${queryPart}`)
        //.get(`MockProgressReports/${projectId}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_PROGRESS_REPORT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROGRESS_REPORT))
        );
    };
  }