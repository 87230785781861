import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import { Switch, Route } from "react-router-dom";
import DashboardFilter from './dashboard/DashboardFilter';
import { Hidden } from '@material-ui/core';
import classnames from 'classnames';

function PageDrawerHeader(props) {


    const { classes,userInfo } = props;

  return <div className={classnames("hideForPrint",classes.drawerHeader)}>
        <Hidden smDown><Switch>
        
        
        <Route 
        exact 
        path="/dashboard/" >
          <DashboardFilter userInfo={userInfo}/>
        </Route>

        </Switch></Hidden>

        </div>
}


export default withStyles(styles, { withTheme: true })(PageDrawerHeader);