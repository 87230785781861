
export const PROGRESS_REPORT_FORM_NAME = "ProgressReportForm";
export const REPORTING_ITEM_COMMENTARY_FORM_NAME = "ReportingItemCommentaryForm";
export const REPORTING_ITEM_QUICK_COMMENT_FORM_NAME = "ReportingItemQuickCommentForm";
export const PROJECT_DOCUMENTS_FORM_NAME = "ProjectDocumentsForm";
export const REPORTING_ITEM_FORM_NAME = "ReportingItemForm";
export const REPORT_OUTPUT_FORM_NAME = "ReportOutputForm";
export const REPORT_SCHEDULE_FORM_NAME = "ReportScheduleForm";

export const BINDER_CATEGORY_FORM_NAME = "BinderCategoryForm";
export const BINDER_FORM_NAME = "BinderForm";
export const BINDER_PRINT_SETUP_FORM_NAME = "BinderPrintSetupForm";
export const BINDER_SHARE_FORM_NAME = "BinderShareForm";

export const ACTIVITY_SUMMARY_REVIEW = "ActivitySummaryReview";
export const REPORTING_ITEM_COMMENTARY_REVIEW_FORM_NAME = "ReportingItemCommentaryReviewForm";

