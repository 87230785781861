import React from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../theme/styles';
import classnames from 'classnames';
import { compose } from '@shakacode/recompose';
import * as formNames from '../../../../constants/forms';
import * as constants from '../../../../constants/constants';
import UiHelperUtil from '../../../../utils/UiHelperUtil';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';
import Fab from '@material-ui/core/Fab';
import TickIcon from '@material-ui/icons/Check';
import { Grid, Tooltip, Button } from '@material-ui/core';
import warnAboutUnsavedForm from '../../../../hoc/withWarnAboutUnsavedChanges';
import PreviewEditCommentPerGroup from '../../../commentary/PreviewEditCommentPerGroup';
import StoreUtil from '../../../../utils/StoreUtil';
import { useDispatch, useSelector } from 'react-redux';

class ActivitySummaryReviewForm extends React.Component {
    copyComments = () => {
        const { changeFieldValue } = this.props;
        let asrFormPath =
            'form.' +
            formNames.ACTIVITY_SUMMARY_REVIEW +
            '.values.activitySummaryReport';
        let fromFieldNONF = StoreUtil.getValueByKey(
            asrFormPath + '.nonFinancialComment'
        );
        let fromFieldF = StoreUtil.getValueByKey(
            asrFormPath + '.financialComment'
        );

        changeFieldValue(
            formNames.ACTIVITY_SUMMARY_REVIEW,
            'activitySummaryReport.areaManagerNonFinancialComment',
            fromFieldNONF
        );
        changeFieldValue(
            formNames.ACTIVITY_SUMMARY_REVIEW,
            'activitySummaryReport.areaManagerFinancialComment',
            fromFieldF
        );
    };

    _handleDraftSave = () => {
        this.props.proceedAsDraft(this.props.formValues);
    };

    render() {
        const {
            handleSubmit,
            //pristine,
            submitting,
            classes,
            proceedSubmit,
            // proceedAsDraft,
            initialValues,
            activitySummary,
            readOnly,
        } = this.props;

        const disableSubmit = submitting;
        const submitButton = (
            <Fab
                key={'Submit'}
                color="secondary"
                type="submit"
                size="large"
                disabled={disableSubmit}
            >
                <TickIcon />
            </Fab>
        );
        const draftButton = (
            <Button
                key={'Draft'}
                type="Button"
                className="DraftButton"
                variant="outlined"
                color="primary"
                disabled={disableSubmit}
                onClick={this._handleDraftSave}
            >
                Save as Draft
            </Button>
        );

        //disable and hide logic
        const isAMFieldHidden =
            activitySummary.currentState === constants.AS_STATE_COMPLETE;

        const isAAMFieldHidden =
            activitySummary.currentState === constants.AS_STATE_SUMMARYDUE ||
            (activitySummary.currentState === constants.AS_STATE_REVIEWDUE &&
                !initialValues.isActivityAreaManager);

        const isAMFieldDisabled =
            activitySummary.currentState === constants.AS_STATE_REVIEWDUE &&
            initialValues.isActivityAreaManager;
        const isAAMFieldDisabled =
            activitySummary.currentState === constants.AS_STATE_COMPLETE &&
            !initialValues.isActivityAreaManager;
        //disable and hide logic

        let hideSubmit =
            readOnly ||
            ((isAMFieldHidden || isAMFieldDisabled) &&
                (isAAMFieldHidden || isAAMFieldDisabled));

        return (
            <div>
                <form
                    onSubmit={handleSubmit((values) => {
                        proceedSubmit(values);
                    })}
                    className={classnames(classes.customForm)}
                >
                    <div className="maxWidthPart">
                        <Grid container>
                            {isAMFieldHidden ? null : (
                                <Grid item className="gridItem" xs={12}>
                                    <PreviewEditCommentPerGroup
                                        expandable={false}
                                        copyable={false}
                                        showAllInReadOnly={true}
                                        disabled={readOnly || isAMFieldDisabled}
                                        formName={
                                            formNames.ACTIVITY_SUMMARY_REVIEW
                                        }
                                        objectPathUserId="activitySummaryReport.commentedUserId"
                                        objectPathCreatedDate="activitySummaryReport.createdDate"
                                        fields={[
                                            {
                                                label: 'Non-financial',
                                                name: 'activitySummaryReport.nonFinancialComment',
                                                placeholder:
                                                    'Enter non-financial summary',
                                            },
                                            {
                                                label: 'Financial',
                                                name: 'activitySummaryReport.financialComment',
                                                placeholder:
                                                    'Enter financial summary',
                                            },
                                        ]}
                                    />
                                </Grid>
                            )}

                            {isAAMFieldHidden ? null : (
                                <Grid item className="gridItem" xs={12}>
                                    {!isAAMFieldDisabled &&
                                    !isAMFieldHidden &&
                                    !readOnly ? (
                                        <div>
                                            <Button
                                                color="default"
                                                variant="outlined"
                                                size="small"
                                                onClick={this.copyComments}
                                            >
                                                Copy comments
                                            </Button>
                                        </div>
                                    ) : null}
                                    <PreviewEditCommentPerGroup
                                        expandable={false}
                                        copyable={false}
                                        showAllInReadOnly={true}
                                        disabled={
                                            readOnly || isAAMFieldDisabled
                                        }
                                        formName={
                                            formNames.ACTIVITY_SUMMARY_REVIEW
                                        }
                                        fields={[
                                            {
                                                label: 'Non-financial',
                                                name: 'activitySummaryReport.areaManagerNonFinancialComment',
                                                placeholder:
                                                    'Enter non-financial summary',
                                            },
                                            {
                                                label: 'Financial',
                                                name: 'activitySummaryReport.areaManagerFinancialComment',
                                                placeholder:
                                                    'Enter financial summary',
                                            },
                                        ]}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    {hideSubmit ? null : (
                        <div
                            className={classnames(
                                'narrowMaxMediumWidthPart',
                                classes.textRightStrong
                            )}
                        >
                            <br />
                            <br />
                            {disableSubmit
                                ? [draftButton, submitButton]
                                : [
                                      <Tooltip
                                          key="ttDraft"
                                          enterDelay={
                                              constants.TOOLTIP_CARDS_DELAY
                                          }
                                          title="Update summary as draft"
                                          aria-label="Update"
                                      >
                                          {draftButton}
                                      </Tooltip>,
                                      <Tooltip
                                          key="ttSummary"
                                          enterDelay={
                                              constants.TOOLTIP_CARDS_DELAY
                                          }
                                          title="Update summary"
                                          aria-label="Update"
                                      >
                                          {submitButton}
                                      </Tooltip>,
                                  ]}
                            <br />
                            <br />
                            <br />
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    formValues: getFormValues(formNames.ACTIVITY_SUMMARY_REVIEW)(state),
});

const mapDispatchToProps = (dispatch) => ({
    changeFieldValue: function (formName, field, value) {
        dispatch(change(formName, field, value));
    },
});

const warnAboutUnsavedFormHOC = (formName) => (WrappedComponent) =>
    warnAboutUnsavedForm(WrappedComponent, formName);

export default compose(
    reduxForm({
        form: formNames.ACTIVITY_SUMMARY_REVIEW, // a unique identifier for this form
        validate: validate,
        onSubmitFail: UiHelperUtil.scrollToFirstErrorMessage,
        // touchOnBlur: false
    }),
    warnAboutUnsavedFormHOC(formNames.ACTIVITY_SUMMARY_REVIEW),
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, { withTheme: true })
)(ActivitySummaryReviewForm);
