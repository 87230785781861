import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import StoreUtil from '../utils/StoreUtil';
import ConsoleUtil from "../utils/ConsoleUtil";


export function getAudiences() {
  return function(dispatch) {
    
    //do not reload
    if(!StoreUtil.isEmptyInStore('audiences')){
      ConsoleUtil.log('get audiences action not reloaded');
      return Promise.resolve();
    }
    
    ConsoleUtil.log('get audiences action');
    return reportingClient.get(`Audiences`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_AUDIENCES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_AUDIENCES))
      );
  };
}
