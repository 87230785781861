import React from 'react';
import { FormControl, Checkbox } from '@material-ui/core';
import InputHelperText from './InputHelperText';
import LabelTooltip from './LabelTooltip';

export default ({
    input,
    label,
    meta: { touched, error, form },
    children,
    helpText,
    ...custom
}) => {
    var showError = touched && error ? true : false;

    return (
        <FormControl
            error={showError}
            className={showError ? 'checkboxWrap error' : 'checkboxWrap'}
        >
            <LabelTooltip
                label={label}
                inputName={input.name}
                helpText={helpText}
                error={error}
                touched={touched}
                formName={form}
            />
            <Checkbox
                {...custom}
                {...input}
                className={input.value ? 'checked' : 'notChecked'}
                checked={
                    input.value === 1 ||
                    input.value === '1' ||
                    input.value === true
                        ? true
                        : false
                }
                onChange={input.onChange}
            />
            {InputHelperText({ touched, error })}
        </FormControl>
    );
};
