import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
//import localStaticClient from "../api/localStaticClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";
import StoreUtil from "../utils/StoreUtil";

export function getUsers() {
  return function(dispatch) {
    ConsoleUtil.log('get users action');
    return reportingClient.get(`Users`)
    //return localStaticClient
      //.get(`/test/api/users.json`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_USERS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_USERS))
      );
  };
}

export function getKeycloakUsers() {
  return function(dispatch) {
    //do not reload
    if(!StoreUtil.isEmptyInStore('keycloakUsers')){
      ConsoleUtil.log('get KeycloakUsers action not reloaded');
      return Promise.resolve();
    }
    ConsoleUtil.log('get keycloack users action');
    return reportingClient.get(`KeycloakUsers`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_KEYCLOAK_USERS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_KEYCLOAK_USERS))
      );
  };
}
