import objectPath from 'object-path';
import * as constants from '../constants/constants';
import ReportingItemsUtil from './ReportingItemsUtil';

export default class ActivitySummaryUtil {



  static adjustASname(name){
    return name;// && name.toLowerCase().indexOf('activity summary') === -1 ? name+" Activity Summary" : name;
  }

  static getASCurrentStateText(currentState){
    currentState = currentState.toLowerCase();//double check if lower case
        switch(currentState) {
          case constants.AS_STATE_NOTDUE:
            return constants.AS_STATE_NOTDUE_TEXT;
          case constants.AS_STATE_REPORTINGDUE:
            return constants.AS_STATE_REPORTINGDUE_TEXT;
          case constants.AS_STATE_SUMMARYDUE:
            return constants.AS_STATE_SUMMARYDUE_TEXT;
          case constants.AS_STATE_REVIEWDUE:
            return constants.AS_STATE_REVIEWDUE_TEXT;
          case constants.AS_STATE_COMPLETE:
            return constants.AS_STATE_COMPLETE_TEXT;
          default:
            return '';
        }
  }


  static isDetailReady(activitySummary){
    let currentState = objectPath.get(activitySummary, 'currentState', '').toLowerCase();
    return [constants.AS_STATE_SUMMARYDUE, constants.AS_STATE_REVIEWDUE, constants.AS_STATE_COMPLETE].includes(currentState)
  }

  static getRIcompleteVSCount(activitySummary) {
    let reportingItems = objectPath.get(activitySummary, 'reportingItems', []);
    //let responsible = objectPath.get(activitySummary, 'responsible', false);
    let currentState = objectPath.get(activitySummary, 'currentState', '').toLowerCase();
    return ActivitySummaryUtil.isDetailReady(activitySummary)
    ? 
    ActivitySummaryUtil.getASCurrentStateText(currentState) 
    : 
    ReportingItemsUtil.getRIcompleteCount(reportingItems,constants.DASHBOARD_CARD_TYPE_AS) + '/' + reportingItems.length;
  }

  static getASstateClasses(item) {
    let classNames = ['as'];

    if (item.responsible) {
      classNames.push('responsible');
    }
    if(item.currentState){
      classNames.push('currentState-'+item.currentState);
    }
    if(item.dueState){
      classNames.push('dueState-'+item.dueState);
    }

    return classNames.join(' ');
  }

  static isASreviewAccessible(item) {
    return objectPath.get(item,'accessReview', false);
  }

  static getActivityReportPeriod(reportYear, reportPeriod){
    return { year: reportYear, period: reportPeriod };
    // switch(reportPeriod) {
    //   case 1:
    //   case 2:
    //     return { year: reportYear - 1, period: 12 }
    //   case 4:
    //   case 5:
    //     return { year: reportYear, period: 3 }
    //   case 7:
    //   case 8:
    //     return { year: reportYear, period: 6 }
    //   case 10:
    //   case 11:
    //     return { year: reportYear, period: 9 }
    //   default:
    //     return { year: reportYear, period: reportPeriod }
    // }
  }
}
