import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import { EditableProgressReportForm } from "../../../components/forms/progressReport/summary/form";
import * as progressReports from "../../../actions/progressReport";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import ComponentLoading from "../../../components/ComponentLoading";

import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import ConsoleUtil from "../../../utils/ConsoleUtil";
import UrlBuilderUtil from "../../../utils/UrlBuilderUtil";



class ViewProgressReport extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }

  getYear = () => {
    const { match, isInDialog } = this.props;
    return !isInDialog ? objectPath.get(match, "params.year", null) : null;
  }

  getPeriod = () => {
    const { match, isInDialog } = this.props;
    return !isInDialog ? objectPath.get(match, "params.period", null) : null;
  }

  getProjectId = () => {
    const { match, dialogProjectId, isInDialog } = this.props;
    return isInDialog && dialogProjectId ? dialogProjectId : objectPath.get(match, "params.projectId");//in dialog take id from prop
  }

  mountItem(){
    const { actions } = this.props;
    const _this = this;
    var projectId = this.getProjectId();


    if(projectId){


      return actions.getProgressReport(projectId,this.getYear(),this.getPeriod()).then( response => {
        let progressReport = objectPath.get(response, "data.data");

        return _this.setState({
          item: progressReport,
        })
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  onMainTabClick = (event,newTabId) => {
    const { match, history, isInDialog } = this.props;
    var projectId = objectPath.get(match, "params.projectId");

    if(isInDialog){
      this.setState({activeTabId : newTabId});
    } else {
      history.push('/progress-report/view/'+projectId+'/'+newTabId);
    }
  }


  

  render() {
    const { onBackBtn } = this.props;
    var projectId = this.getProjectId();
    var year = this.getYear();
    var period = this.getPeriod();
    const { item, dataLoading } = this.state;
    const snapshotParams = (year && period) ? ' ('+year+'/'+period+')' : '';

    return (
      <div>
        <PageHeading mutedHeading={'#'+projectId} heading={dataLoading ? "loading ..." : item ? item.projectName + snapshotParams : null} actions={
          <div>
            <HeadingBackBtn onBackBtn={onBackBtn} redirectToUrl={UrlBuilderUtil.getDashboard(null, true)} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 
      <div>
        <EditableProgressReportForm 
        proceedSubmit={() => { ConsoleUtil.log('read only form')}} 
        readOnly={true}
        initialValues={item}
      /> 

      </div> }
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
({
  actions: bindActionCreators(
    {
      ...progressReports,
    },
    dispatch
  )
});


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(ViewProgressReport)
    
    )
);