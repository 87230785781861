import objectPath from 'object-path';
import ArrayUtil from './ArrayUtil';
import StoreUtil from './StoreUtil';


export default class PositionsUtil {

  static formatForProjectManagerSelector(items, item){
    
    var options = [];
    var positionIdOfEdited = objectPath.get(item,'positionId');
    if(ArrayUtil.isNonEmptyArray(items)){
      items.forEach((i,index) => {
        var isActive = objectPath.get(i,'isUserActive',false);

        var positionId = objectPath.get(i,'positionId');
        if(isActive || positionId === positionIdOfEdited){ // name present or edited item has selected
          options.push({
            value: i.positionId,
            label: objectPath.get(i,'userFullname','Unassigned') + " ( "+i.positionId+" )"
          });
        }
        
      });
    }
    return options;
  }


  static formatForUserSelector(includePositionId = true){
    var positions = StoreUtil.getValueByKey('positions',[]);
    var unassigned = 'Unassigned';
    return positions.filter(x => x && x.isUserActive).map(x => {
      let userName = objectPath.get(x,'userFullname', unassigned);
      if(!userName || userName.trim().length < 1) {
        userName = unassigned;
      }
      return {
        value: x.positionId,
        label: userName + " " + (includePositionId ? "( "+x.positionId+" )" : '' )
      }});
  }

  static getPositionById(items,id){
    return items.find(x => x.positionId===id);
  }

  static getPositionNameAndUsernameById(items,id){
    var position = PositionsUtil.getPositionById(items,id)
    return position ? position.positionId+" "+position.userFullname : "";
  }


  static getUsernameFromStoreById(id, defaultValue = 'Unassigned'){
    var position = PositionsUtil.getPositionById(StoreUtil.getValueByKey('positions',[]),id)
    return objectPath.get(position,'userFullname', defaultValue);
  }


}
