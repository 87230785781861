export default (theme, themeConfig, themeColors, themeFonts) => {
    return {
        searchCorpWrap: {
            marginTop: -themeConfig.commonPadding,

            marginLeft: -themeConfig.commonPadding,
            marginRight: -themeConfig.commonPadding,
            paddingLeft: themeConfig.commonPadding,
            paddingRight: themeConfig.commonPadding,
            [theme.breakpoints.up('lg')]: {
                marginLeft: -(theme.spacing(1) * 3),
                marginRight: -(theme.spacing(1) * 3),
                paddingLeft: theme.spacing(1) * 3,
                paddingRight: theme.spacing(1) * 3,
            },
            backgroundColor: themeColors.primarySupportingPalette.pms316c,

            '& input[type="text"]': {
                backgroundColor: 'transparent',
                color: themeColors.primaryPalette.white,
                border: 0,
                width: '100%',
                outline: 0,
            },
            '& button': {
                color: themeColors.primaryPalette.white,
            },
            '& .btnWrap': {
                flexBasis: 65,
                flexShrink: 0,
            },
            '& > div': {
                alignItems: 'center',
            },
        },
        projectPreviewCard: {
            marginTop: themeConfig.commonPadding * 2,
            '& hr': {
                marginTop: themeConfig.commonPadding,
                marginBottom: themeConfig.commonPadding,
            },
            '& .secondaryInfoWrap span': {
                display: 'inline-block',
                paddingRight: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            },
        },
        customForm: {
            '&.secondaryForm': {
                backgroundColor: themeColors.customPalette.tableRowBorder,
                marginBottom: themeConfig.setImportant(
                    theme.spacing(1) * 4 + 'px'
                ),
                paddingBottom: themeConfig.setImportant(
                    theme.spacing(1) * 3 + 'px'
                ),
            },
            paddingTop: themeConfig.commonPadding,

            '& .inputLabelColumnsComponentWrap + .inputLabelColumnsComponentWrap':
                {
                    marginTop: -theme.spacing(1),
                },
            '& .inputLabelColumnsComponentWrap.error + .inputLabelColumnsComponentWrap':
                {
                    marginTop: theme.spacing(1),
                },
            '& p.hasError': {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1) / 2,
            },
            '& .checkboxWrap': {
                '& .notChecked svg': {
                    transform: 'scale(0.85)',
                    fill: themeColors.primaryPalette.white,
                    stroke: themeColors.primaryPalette.white,
                    backgroundColor: themeColors.primaryPalette.white,
                    border: '2px solid ' + themeColors.primaryPalette.grey,
                    borderRadius: 4,
                },
            },
            '& .radioGroupWrap': {
                '& .notChecked svg': {
                    transform: 'scale(0.85)',
                    fill: themeColors.primaryPalette.white,
                    stroke: themeColors.primaryPalette.white,
                    backgroundColor: themeColors.primaryPalette.white,
                    border: '2px solid ' + themeColors.primaryPalette.grey,
                    borderRadius: '100%',
                },
            },
            '& .milestonesSection, & .kpiSection, & .childProjectsSection': {
                '&.milestonesSection, &.kpiSection': {
                    '& thead tr': {
                        height: 'auto',
                    },
                },
                '& table': {
                    '& td,th': {
                        padding: theme.spacing(1) / 2,
                        borderBottom: 0,
                    },
                    '& td.expandCellWidth, & th.expandCellWidth': {
                        [theme.breakpoints.down('md')]: {
                            paddingLeft: theme.spacing(1),
                            paddingRight: theme.spacing(1),
                        },
                    },
                    '& td.actionsColumn, & th.actionsColumn': {
                        '& button': {
                            backgroundColor: themeColors.primaryPalette.white,
                            boxShadow: 'none',
                            width: 30,
                            height: 30,
                            minHeight: 0,
                            '& svg': {
                                fontSize: 18,
                                color: themeColors.primarySupportingPalette
                                    .pms316c,
                            },
                        },
                    },
                    '& td:not(.expandCellWidth), & th:not(.expandCellWidth)': {
                        width: 95,
                        padding: theme.spacing(1) / 2,
                    },
                },
                '& .kpiSection table': {
                    '& td:not(.expandCellWidth), & th:not(.expandCellWidth)': {
                        width: 125,
                        padding: theme.spacing(1) / 2,
                    },
                },
            },
            '& .childProjectsSection table.childProjectsTable': {
                '& td': {
                    borderTop:
                        '1px solid ' + themeColors.customPalette.tableRowBorder,
                },
                '& td:not(.expandCellWidth), & th:not(.expandCellWidth)': {
                    width: 160,
                    [theme.breakpoints.down('lg')]: {
                        width: 90,
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: 85,
                    },
                },
                '& th, & td': {
                    '&.actionsColumn': {
                        width: themeConfig.setImportant(40 + 'px'),
                    },
                    '&.idColumn': {
                        width: themeConfig.setImportant(75 + 'px'),
                    },
                    '&.doneColumn': {
                        width: themeConfig.setImportant(60 + 'px'),
                        [theme.breakpoints.down('xs')]: {
                            width: themeConfig.setImportant(25 + 'px'),
                        },
                    },
                    '& .indicator': {
                        [theme.breakpoints.down('md')]: {
                            width: 20,
                        },
                        [theme.breakpoints.down('xs')]: {
                            width: 12,
                        },
                    },
                },
            },
            '& .percentageSign': {
                transform: 'translateX(-' + theme.spacing(1) + 'px)',
            },
            '& .daysSign': {
                transform: 'translateX(-' + theme.spacing(1) + 'px)',
                '& p': {
                    fontSize: themeConfig.smallFontSize,
                },
            },
            '& input[type="text"]:not([name="searchProjectId"]), textarea': {
                fontSize: themeConfig.smallFontSize,
            },
            '& h4 svg': {
                fontSize: 18,
            },
            '& .nativeSelectWrap': {
                display: 'flex',
                '& > span': {
                    display: 'block',
                    paddingBottom: theme.spacing(1) * 1.5,
                },
            },
            '& .nativeSelect': {
                marginLeft: -(theme.spacing(1) / 2),
                borderBottom: 0,
                backgroundColor: themeColors.primaryPalette.white,
                borderRadius: theme.spacing(1) / 2,
                paddingLeft: theme.spacing(1) * 2,
                fontSize: themeConfig.smallFontSize,
                display: 'block',
                width: '100%',
                '&:before,&:after': {
                    borderBottom: '0 !important',
                },
                '& select': {
                    backgroundColor: themeColors.primaryPalette.white,
                },
            },
            '& .rateInputWrap': {
                '& button': {
                    minWidth: 45,
                    marginRight: theme.spacing(1),
                    marginBottom: theme.spacing(1) / 2,
                    padding: themeConfig.setSpacing(6, 0),
                    boxShadow: 'none',
                    '&.notSelected': {
                        backgroundColor: themeColors.primaryPalette.white,
                    },
                },
            },

            '& .percentageInputWrap .textFieldWrap > div': {
                maxWidth: 80,
            },
            '& .indicator': {
                position: 'static',
                height: theme.spacing(1),
                display: 'inline-block',
                borderRadius: theme.spacing(1) / 2,
                width: 50,
                [theme.breakpoints.down('sm')]: {
                    width: 20,
                },
            },
            '& .labelWrap': {
                fontWeight: 600,
                paddingBottom: theme.spacing(1) / 2,
                fontSize: 14,
                '& svg': {
                    marginLeft: '0.25rem',
                    fontSize: 18,
                },
            },
            '& .selectSuggestionWrap': {
                display: 'flex',
                '&.disabled': {
                    opacity: 0.5,
                },
            },
            '& .suggestionInputComponentWrapper > div': {
                paddingLeft: theme.spacing(1),
                maxHeight: '100%',
            },
            '& .error  .suggestionInputComponentWrapper > div:after': {
                borderBottomColor: themeColors.primarySupportingPalette.pms200c,
                transform: 'scaleX(1)',
            },
            '& .textFieldWrap input,& .textFieldWrap textarea, & .materialUISelect':
                {
                    padding: '9px ' + theme.spacing(1) + 'px',
                    fontSize: themeConfig.smallFontSize,
                },
            '& .textFieldWrap > div, & .suggestionInputComponentWrapper > div':
                {
                    backgroundColor: themeColors.primaryPalette.white,
                    borderRadius: 2,
                },

            '& .textFieldWrap > div:before, & .suggestionInputComponentWrapper div:before':
                {
                    display: 'none',
                },
            [theme.breakpoints.down('xs')]: {
                '& .suggestionInputComponentWrapper': {
                    maxWidth: 275,
                },
            },
        },

        customSwitch: {
            '& .customSwitchIcon': {
                color: 'white',
                height: 16,
                width: 16,
            },
            '& .customSwitchIconChecked': {
                color: themeColors.primaryPalette.primary,
                backgroundImage: 'url("/assets/images/tick.svg")',
                backgroundSize: 8,
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
            },
            '& .customSwitchBar': {
                borderRadius: 8,
                height: theme.spacing(1),
                marginTop: -4,
                backgroundColor: themeColors.primaryPalette.primary,
            },
        },
        calendarInputWrap: {
            [theme.breakpoints.down('md')]: {
                '& .react-calendar': {
                    maxWidth: '285px !important',
                },
            },
            '& ul': {
                paddingTop: 0,
            },
            '& .react-calendar': {
                border: 0,
                borderRadius: theme.spacing(1) / 2,
                fontFamily: themeFonts.defaultFont,
                maxWidth: '100%',
                '& button, & abbr[title]': {
                    fontFamily: themeFonts.defaultFont,
                    textDecoration: 'none',
                    fontSize: themeConfig.smallFontSize,
                    position: 'relative',
                },
                '& .indicator': {
                    width: 8,
                    position: 'absolute',
                    top: 6,
                    right: 6,
                },
                '& .react-calendar__navigation': {
                    marginBottom: 0,
                },
                '& .react-calendar__tile--active': {
                    backgroundColor: themeColors.primaryPalette.primary,
                },
                '& .react-calendar__tile:disabled': {
                    opacity: 0.4,
                    backgroundColor: themeColors.primaryPalette.white,
                },
                '& .react-calendar__month-view__weekdays abbr': {
                    fontSize: '11px !important',
                    fontWeight: '100 !important',
                },
            },
        },
        progressForm: {
            [theme.breakpoints.up('md')]: {
                '& h4': {
                    padding: themeConfig.setSpacing(
                        theme.spacing(1) * 1.5,
                        theme.spacing(1) * 2
                    ),
                    paddingBottom: 0,
                },
            },
        },

        extendedOutlinedFab: {
            backgroundColor: 'transparent',
            color: themeColors.primaryPalette.black,
            borderColor: themeColors.primaryPalette.secondary,
            boxShadow: 'none',
            borderWidth: 2,
            borderStyle: 'solid',
            '&:hover': {
                color: themeColors.primaryPalette.white,
                backgroundColor: themeColors.primaryPalette.secondary,
            },
        },

        readOnlyForm: {
            opacity: 0.9,
            '& label, & button, & input,& textarea,& select, & .inputLabelColumnsInputWrap, & .textFieldWrap, & .textFieldWrap':
                {
                    pointerEvents: 'none !important',
                    cursor: 'not-allowed !important',
                    userSelect: 'none !important',
                },
        },
    };
};
