import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import * as constants from '../constants/constants';
import ConsoleUtil from "../utils/ConsoleUtil";
import FilterUtil from "../utils/FilterUtil";
import objectPath from "object-path";

export function getDashboardData(paginationObject = { pageSize: constants.DEFAULT_LISTING_PAGE_SIZE, page: constants.DEFAULT_LISTING_PAGE, filter: null }) {
    return function(dispatch) {
        ConsoleUtil.log('ItemCardSummary called');
        var filterQuery = FilterUtil.constructUrlParams(objectPath.get(paginationObject, 'filter'));
        filterQuery = filterQuery.replace('?', '&');
        return reportingClient
            .get('ItemCardSummary?' +
                constants.LISTING_PAGE_PARAM + '=' + paginationObject.page + filterQuery
            )
            .then(
                response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_DASHBOARD_DATA)),
                error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_DASHBOARD_DATA))
            );
    };
}


export function downloadDashboardData(paginationObject = { page: constants.DEFAULT_LISTING_PAGE, filter: null }) {
    const filename = 'DashboardCardDetails_';
    const fileDownload = require('js-file-download');

    return function () {

        var filterQuery = FilterUtil.constructUrlParams(objectPath.get(paginationObject, 'filter'));
        filterQuery = filterQuery.replace('?', '&');
        reportingClient
            .get('ItemCardSummary/Export?' + filterQuery)
            .then(response => {
                fileDownload(response.data, filename + new Date().toISOString().slice(0, 10).replace(/-/g, '_') + '.csv'); //yyyy_mm_dd
            });
    }

}


export function setMergedDashboardData(items = [], filter = {}, queryPage = 0) {
    return {
        type: types.SET_MERGED_DASHBOARD_DATA,
        data: {
            items,
            filter,
            queryPage
        }
    };
}
