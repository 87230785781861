import objectPath from 'object-path';
import ArrayUtil from './ArrayUtil';
import * as constants from '../constants/constants';
import AssessmentUtil from './AssessmentUtil';
import StoreUtil from './StoreUtil';

const pagePath = "/scorecard"
const firstLevelPropName = 'strategicPerspectives';
const secondLevelPropName = 'activityAreas';
const thirdLevelPropName = 'activities';


export default class StrategicPlansUtil {


    static getActivityNameById(activityId, items = []){
      
        var activity = StrategicPlansUtil.getActivityById(activityId, items);
        return activity ? activity.name : '';
    }

    static getActivityNameByReportingApiId(reportingApiActivityId, items = []){
      var activity = StrategicPlansUtil.getActivityByReportingApiId(reportingApiActivityId, items);
        return activity ? activity.name : '';
    }


    static getActivityByReportingApiId(reportingApiActivityId, items = []){
      var activity = null;
      if (!ArrayUtil.isNonEmptyArray(items)) {//get from store if not set
        items = StoreUtil.getValueByKey('strategicPlans');
      }
      if (ArrayUtil.isNonEmptyArray(items)) {
        items.forEach((x, index_0) => {
          let firstLevelObjects = objectPath.get(x, firstLevelPropName);
          if (ArrayUtil.isNonEmptyArray(firstLevelObjects)) {
            firstLevelObjects.forEach((sp, index_1) => {
              let secondLevelObjects = objectPath.get(sp, secondLevelPropName);
              if (ArrayUtil.isNonEmptyArray(secondLevelObjects)) {
                secondLevelObjects.forEach((aA, index_2) => {
                  let thirdLevelObjects = objectPath.get(aA, thirdLevelPropName);
                  if (ArrayUtil.isNonEmptyArray(thirdLevelObjects)) {
                    thirdLevelObjects.forEach((a, index_3) => {
                      if(activity === null && a.activitySummaryId === reportingApiActivityId){
                        activity = a;
                      }
                    });
                  }
                });
              }
            });
          }
        })
      }
      return activity;
    }


  static getActivityById(activityId, items = []){
    var activity = null;
    if (!ArrayUtil.isNonEmptyArray(items)) {//get from store if not set
      items = StoreUtil.getValueByKey('strategicPlans');
    }
    if (ArrayUtil.isNonEmptyArray(items)) {
      items.forEach((x, index_0) => {
        let firstLevelObjects = objectPath.get(x, firstLevelPropName);
        if (ArrayUtil.isNonEmptyArray(firstLevelObjects)) {
          firstLevelObjects.forEach((sp, index_1) => {
            let secondLevelObjects = objectPath.get(sp, secondLevelPropName);
            if (ArrayUtil.isNonEmptyArray(secondLevelObjects)) {
              secondLevelObjects.forEach((aA, index_2) => {
                let thirdLevelObjects = objectPath.get(aA, thirdLevelPropName);
                if (ArrayUtil.isNonEmptyArray(thirdLevelObjects)) {
                  thirdLevelObjects.forEach((a, index_3) => {
                    if(activity === null && a.id === activityId){
                      activity = a;
                    }
                  });
                }
              });
            }
          });
        }
      })
    }
    return activity;
  }


  static getActivityAreaByActivityId(activityId){
    var activityArea = null;
    let items = StoreUtil.getValueByKey('strategicPlans');

    if (ArrayUtil.isNonEmptyArray(items)) {
      items.forEach((x, index_0) => {
        let firstLevelObjects = objectPath.get(x, firstLevelPropName);
        if (ArrayUtil.isNonEmptyArray(firstLevelObjects)) {
          firstLevelObjects.forEach((sp, index_1) => {
            let secondLevelObjects = objectPath.get(sp, secondLevelPropName);
            if (ArrayUtil.isNonEmptyArray(secondLevelObjects)) {
              secondLevelObjects.forEach((aA, index_2) => {
                let thirdLevelObjects = objectPath.get(aA, thirdLevelPropName);
                if (ArrayUtil.isNonEmptyArray(thirdLevelObjects)) {
                  thirdLevelObjects.forEach((a, index_3) => {
                    if(activityArea === null && a.id === activityId){
                      activityArea = aA;
                    }
                  });
                }
              });
            }
          });
        }
      })
    }
    return activityArea;
  }


  static getActivityAreaNameByActivityId(activityId){
    var activityArea = StrategicPlansUtil.getActivityAreaByActivityId(activityId);
        return activityArea ? activityArea.name : '';
  }

  static getActivityAreaIndicator(assessments) {
    return AssessmentUtil.getIndicator(assessments.overall);
  }

  static getCurrentActivities(items, node) {
    return objectPath.ensureExists(items, (node.objectPath + '.' + thirdLevelPropName), []);
  }

  static formatForActivitySelector(items = []) {
    
    if (!ArrayUtil.isNonEmptyArray(items)) {//get from store if not set
      items = StoreUtil.getValueByKey('strategicPlans');
    }

    var options = [];
    if (ArrayUtil.isNonEmptyArray(items)) {
      items.forEach((x, index_0) => {
        let firstLevelObjects = objectPath.get(x, firstLevelPropName);
        let secondLevelObjects = [];
        let thirdLevelObjects = [];
        if (ArrayUtil.isNonEmptyArray(firstLevelObjects)) {//list groups
          firstLevelObjects.forEach((sp, index_1) => {
            options.push({
              value: sp.id,
              label: sp.name,
              isDisabled: true,
              level: 0,
            });
            secondLevelObjects = objectPath.get(sp, secondLevelPropName);
            if (ArrayUtil.isNonEmptyArray(secondLevelObjects)) {
              secondLevelObjects.forEach((aA, index_2) => {
                options.push({
                  value: aA.id,
                  label: aA.name,
                  isDisabled: true,
                  level: 1,
                });
                thirdLevelObjects = objectPath.get(aA, thirdLevelPropName);
                if (ArrayUtil.isNonEmptyArray(thirdLevelObjects)) {
                  thirdLevelObjects.forEach((a, index_3) => {
                    options.push({
                      value: a.id,
                      label: a.name,
                      isDisabled: false,
                      level: 2,
                    });
                  });
                }
              });
            }
          });
        }
      })
    }
    return options;
  }

  static formatMenu(items, zeroLevelId, firstLevelId, secondLevelId) {

    let menu = {
      firstLevel: [],
      secondLevel: [],
      activeNode: null
    };

    if (Array.isArray(items)) {
      items.forEach((x, index_0) => {
        let firstLevelObjects = objectPath.get(x, firstLevelPropName);
        if (firstLevelObjects.length) {//list groups
          menu.firstLevel.push({//push group
            id: x.id,
            name: x.name,
            children: firstLevelObjects.map((sp, index_1) => {
              var active = false;
              let secondLevelObjects = objectPath.get(sp, secondLevelPropName);

              var node = {
                id: sp.id,
                label: sp.name,
                objectPath: ([index_0, firstLevelPropName, index_1].join('.')),
                path: (pagePath + '/' + x.id + '/' + sp.id),
                active,
                showChips: true,
                childrenCount: secondLevelObjects.length,
                partialMetCount: AssessmentUtil.getAssessmentStatusCount(secondLevelObjects, constants.ASSESSMENT_PARTIALMET, 'overall'),
                notMetCount: AssessmentUtil.getAssessmentStatusCount(secondLevelObjects, constants.ASSESSMENT_NOTMET, 'overall')
              };
              if (x.id === zeroLevelId && sp.id === firstLevelId) { // is active
                active = true;
                node.active = true;
                menu.activeNode = node;
                //fill secondary level
                if (secondLevelObjects.length) {
                  secondLevelObjects.forEach((aA, index_2) => {
                    let thirdLevelObjects = objectPath.get(aA, thirdLevelPropName);
                    var childNode = {//push group
                      id: aA.id,
                      label: aA.name,
                      objectPath: ([node.objectPath, secondLevelPropName, index_2].join('.')),
                      path: (pagePath + '/' + x.id + '/' + node.id + '/' + aA.id),
                      active: (aA.id === secondLevelId),
                      childrenCount: thirdLevelObjects.length,
                      showChips: false,
                      showStatusDot: true,
                      parentLabel: node.label,
                      indicator: StrategicPlansUtil.getActivityAreaIndicator(aA.assessments)
                    };
                    if (childNode.active) {
                      menu.activeNode = childNode;
                    }
                    menu.secondLevel.push(childNode);
                  });
                }
              }
              return node;
            })
          })
        }
      })
    }
    return menu;
  }

}
