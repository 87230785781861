import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import styles from '../../theme/styles';
import { CardContent, Divider, Typography, CardActions } from '@material-ui/core';
import StrategicPlansUtil from '../../utils/StrategicPlansUtil';
import store from '../../store';
import PositionsUtil from '../../utils/PositionsUtil';

function ProjectPreviewCard(props) {

  const { classes, project, actionButtons } = props;


  if(project){

    var strategicPlans = store.getState()['strategicPlans'];
    var positions = store.getState()['positions'];

    return (
        <Card className={classes.projectPreviewCard}>
        <CardContent>
            <div>
                <Typography variant="h5"><span>#{project.id} - </span><strong>{project.name}</strong></Typography>
            </div>
            
            <Divider />
            <div className="secondaryInfoWrap">
                <Typography variant="caption">
                <strong>Activity: </strong>
                </Typography> <Typography variant="caption"> {StrategicPlansUtil.getActivityNameById(project.activityId, strategicPlans)} &nbsp;&nbsp;&nbsp;</Typography>
                <Typography variant="caption">
                <strong>Project Manager: </strong>
                </Typography> <Typography variant="caption"> {PositionsUtil.getPositionNameAndUsernameById(positions, project.positionId)} </Typography>
            </div>
            <div>
                <Typography variant="caption">
                    {project.description}
                </Typography> 
            </div>
            
            
        </CardContent>
        <CardActions className={classes.cardBottomActions}>
                {actionButtons}
            </CardActions>
      
    </Card>
    );
  }
  return null;
}

ProjectPreviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProjectPreviewCard);