export default (theme, themeConfig, themeColors) => {
    return {
        drawer: {
            flexShrink: 0
          },
          drawerLevel_1: {
            width: themeConfig.drawerWidthLevel_1,
          },
          drawerPaper: {
            maxHeight: "100vh",
            transition: themeConfig.setImportant('none'),
            [theme.breakpoints.up("md")]: {
              overflow: "hidden !important",
            },
            border: 0,
            boxShadow: themeConfig.customShadow,
            '& svg': {
              color: themeColors.primaryPalette.grey
            },
          },
          mobileDrawer: {
            top: themeConfig.toolbarMinHeight,
            height: `calc(100vh - ${themeConfig.toolbarMinHeight}px)`,
          },
          drawerGridContainer: {
            '& > div': {
              overflow: "auto",
              height: "100vh",
              position: "relative",
              boxShadow: themeConfig.customShadow,
              paddingTop: themeConfig.toolbarMinHeight,
              paddingBottom: themeConfig.toolbarMinHeight,
              '& > ul:first-child': {
                paddingTop: 0
              },
              '& hr': {
                backgroundColor: themeColors.customPalette.bodyBg
              },
              '& .partialActive a, & a.active': {
                borderLeft: '3px solid '+themeColors.primaryPalette.primary,
                backgroundColor: themeColors.customPalette.bodyBg,
                '& svg': {
                  color: themeColors.primaryPalette.primary
                },
              },
              
            },
          },
          drawerPaperLevel_1: {
            width: themeConfig.drawerWidthLevel_1
          },
          drawerLevel_2: {
            width: themeConfig.drawerWidthLevel_2,
          },
          drawerPaperLevel_2: {
            width: themeConfig.drawerWidthLevel_2
          },
          drawerLevel_3: {
            width: themeConfig.drawerWidthLevel_3,
          },
          drawerPaperLevel_3: {
            width: themeConfig.drawerWidthLevel_3
          },
          drawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            ...theme.mixins.toolbar,
            justifyContent: "flex-end",
            [theme.breakpoints.down("sm")]: {
              minHeight: themeConfig.toolbarMinHeight
            }
          },
    }
}