export default (theme, themeConfig, themeColors) => {
    return {
        financialOverviewCard: {
            backgroundColor: 'transparent',
            '& h6': {
                fontSize: 16,
                fontWeight: 600,
                color: themeColors.primaryPalette.grey,
            },
            '& .cardCaption': {
                fontWeight: 600,
                fontSize: 12,
                opacity: 0.7,
                lineHeight: 1.5,
            },
            '& .cardCaption, & p': {
                marginTop: theme.spacing(1),
            },
            [theme.breakpoints.down('sm')]: {
                '& .cardCaption, & p': {
                    fontSize: 12,
                    fontWeight: 400,
                },
                '& h6': {
                    fontSize: 14,
                },
            },
        },
        financialOverviewSectionWrap: {
            [theme.breakpoints.down('xs')]: {
                '& .gridItem.sectionOverviewItem': {
                    maxWidth: 90,
                },
                '& .mobileLabelColumn.gridItem.sectionOverviewItem': {
                    minWidth: 115,
                    '& p': {
                        fontSize: 11,
                        minHeight: 18,
                    },
                },
            },
        },
    };
};
