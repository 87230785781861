import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import ComponentLoading from '../ComponentLoading';
import Schedule from './Schedule';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as reportScheduleActions from '../../actions/reportSchedule';
import * as binderActions from '../../actions/binder';
import { DialogActions, Button, DialogContent, DialogTitle, Hidden, Dialog, Typography } from '@material-ui/core';


    class ScheduleDialog extends React.Component {


      constructor(props){
        super(props);
        this.state={
          dataLoading: true
        }
      }


      componentDidMount(){
          const { actions, binderId } = this.props;
          Promise.all([
            actions.getBinder(binderId),
            actions.getReportSchedules()
          ]).then(() =>
            this.setState({
              dataLoading: false
            })
            );
      }


      getSchedule = () => {
          const { reportSchedules, binder } = this.props;
          return reportSchedules.find(x => x.id === binder.reportScheduleId)
      }

      getDialogInnerPart = () => {
        const { binder, onClose } = this.props;
            const { dataLoading } = this.state;
      return dataLoading ? <ComponentLoading /> : 
      <div>
        <DialogTitle key="DialogTitle"><strong>{(dataLoading ? "Loading " : binder.name)}</strong><Typography variant="body2" className="muted">Report Schedule</Typography></DialogTitle>
      <DialogContent key="dialogContent"><Schedule schedule={this.getSchedule()} /></DialogContent>
      <DialogActions key="dialogActions"><Button variant="text" color="default" onClick={onClose}>Cancel</Button></DialogActions>
    </div>;
      }

      


      
        render() {
            const {  onClose } = this.props;

            
            return <div>
            <Hidden smDown key="desktop"><Dialog
         fullWidth={true}
         maxWidth={"sm"}
         onClose={onClose}
         open={true}
       >
         {this.getDialogInnerPart()}
       </Dialog></Hidden>
       <Hidden mdUp key="mobile"><Dialog 
         fullScreen
         fullWidth={true}
         onClose={onClose}
         open={true}
       >
         {this.getDialogInnerPart()}
       </Dialog></Hidden>
       </div>
        }
}


function mapStateToProps(state) {
  return {
    binder: state.binder,
    reportSchedules: state.reportSchedules
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...reportScheduleActions,
        ...binderActions,
      },
      dispatch
    )
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ScheduleDialog));

