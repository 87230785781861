import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../theme/styles';
import * as formNames from "../../../../constants/forms";
import classnames from 'classnames';
import { SelectWithSuggestions, SingleLineCheckboxInput } from "../../../inputs/";
import * as constants from '../../../../constants/constants';
import TickIcon from "@material-ui/icons/Check";
import validate from "./validate";
import { Grid, Fab, Tooltip, Button } from "@material-ui/core";


const BinderPrintSetupForm = props => {
  const {
    handleSubmit,
    classes,
    className,
    submitting,
    proceedSubmit,
    reportOutputs,
    audiences,
    onCancel
  } = props;




  
  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><TickIcon /></Fab>;



  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classnames(classes.customForm, className)}>
           
          <div className="maxWidthPart narrowMaxWidthPart">
          <Grid container>
            
            


            <Grid item className="gridItem" xs={12}>
              
                <Field name="reportOutputId" component={SelectWithSuggestions} isSearchable={true} options={reportOutputs} label="Report Output"  />
              
            </Grid>


            <Grid item className="gridItem" xs={12}>
              
                <Field name="audience" component={SelectWithSuggestions} isSearchable={true} 
                options={audiences} 
                label="Audience"  />
              
            </Grid>

            <Grid item className="gridItem" xs={12}>

              <Field
                name="includeDescriptions"
                component={SingleLineCheckboxInput}
                label="Include Descriptions"
              />
              
            </Grid>

            

            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12}>
  { onCancel ? <Button size="small" color="default" onClick={()=>onCancel()}>Cancel</Button> : null } &nbsp; { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Print" aria-label="Print">{submitButton}</Tooltip> }
</Grid> 
            
            </Grid>
          </div>
        </form>
    </div>
  );
};


export default withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.BINDER_PRINT_SETUP_FORM_NAME, // a unique identifier for this form
  validate,
})(BinderPrintSetupForm));
