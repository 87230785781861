export default (theme, themeConfig, themeColors, themeFonts) => {
    return {
        '@global': {
            'body.drawerClosed .mobileDrawer': {
                display: themeConfig.setImportant('none'),
            },
            '.inputLabelColumnsComponentGroupWrap + .inputLabelColumnsComponentGroupWrap':
                {
                    marginTop: -10,
                },
            '.muted': {
                color: themeColors.primaryPalette.grey,
            },
            '.inlineTypo': {
                display: 'inline',
            },
            '.noWrap': {
                whiteSpace: 'nowrap',
            },
            button: {
                boxShadow: themeConfig.setImportant('none'),
            },
            '.masonry-grid-wrap': {
                marginTop: theme.spacing(1),
                '& .masonry-item-sizer, & .masonry-item': {
                    display: 'block',
                    width: '100%',
                    padding: theme.spacing(1),
                    float: 'left',
                    [theme.breakpoints.up('sm')]: {
                        width: '50%',
                    },
                    [theme.breakpoints.up('lg')]: {
                        width: '33%',
                    },
                    [theme.breakpoints.up('xl')]: {
                        width: '25%',
                    },
                },
                '& .masonry-item-sizer': {
                    height: 1,
                    clear: 'both',
                },
            },
            '.appDialogContent': {
                minWidth: 300,
                minHeight: 100,
                '& .table': {
                    marginTop: 0,
                },
            },
            '.card .personIcon': {
                marginRight: 4,
            },
            '.fullPseudoAfter': {
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
            '.card a span.positionIconName': {
                opacity: 1,
                display: 'inline-block',
                paddingLeft: 8,
                '& .personName': {
                    color: themeColors.primarySupportingPalette.pms316c,
                    fontSize: themeConfig.smallFontSize,
                },
                '& .personIcon': {
                    fontSize: themeConfig.smallFontSize,
                },
            },
            'hr.sectionDivider': {
                backgroundColor: themeColors.primaryPalette.primary,
            },
            '.timelineLoadMoreWrap': {
                paddingTop: themeConfig.setImportant('100px'),
            },
            '.maxWidthPart': {
                maxWidth: themeConfig.customMaxWidth,
            },
            '.narrowMaxWidthPart': {
                maxWidth: themeConfig.customNarrowMaxWidthPart,
            },
            '.narrowMaxMediumWidthPart': {
                maxWidth: themeConfig.customNarrowMaxMediumWidthPart,
            },
            '.imageInputWrap': {
                '& input': {
                    display: 'none',
                },
                '& label': {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
            '.imageBtnInputWrap .processingWrap': {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(155,155,155,0.5)',
                borderRadius: '100%',
                '& > div': {
                    paddingTop: 0,
                    paddingLeft: 0,
                    '& > div': {
                        width: themeConfig.setImportant(34 + 'px'),
                        height: themeConfig.setImportant(34 + 'px'),
                    },
                },
            },
            '.fluid-image': {
                maxWidth: '100%',
                margin: 'auto',
                display: 'block',
                maxHeight: '85vh',
            },
            '.imgAvatar': {
                borderRadius: 0,
                background: 'transparent',
                '& .fluid-image': {
                    maxWidth: '100%',
                    height: 'auto',
                },
            },
            '.outlinedIconButton': {
                border: '1px solid ' + themeColors.primaryPalette.secondary,
                marginLeft: theme.spacing(1) / 2,
                marginRight: theme.spacing(1) / 2,
                '&.due': {
                    border:
                        '1px solid ' +
                        themeColors.primarySupportingPalette.pms200c,
                    backgroundColor:
                        themeColors.primarySupportingPalette.pms200c,
                    color: themeColors.primaryPalette.white,
                },
            },
            '.gridItem': {
                padding: theme.spacing(1),
                '&.sectionOverviewItem': {
                    padding: theme.spacing(1) * 3,
                    [theme.breakpoints.down('sm')]: {
                        padding: theme.spacing(1) * 2,
                        paddingRight: 0,
                        paddingLeft: theme.spacing(1),
                    },
                },
                '&.sectionSidePaddingItem': {
                    paddingLeft: theme.spacing(1) * 3,
                    paddingRight: theme.spacing(1) * 3,
                    [theme.breakpoints.down('sm')]: {
                        paddingRight: 0,
                        paddingLeft: theme.spacing(1),
                    },
                },
                '&.sectionVerticalPaddingItem': {
                    paddingTop: theme.spacing(1) * 3,
                    paddingBottom: theme.spacing(1) * 3,
                    [theme.breakpoints.down('sm')]: {
                        paddingTop: theme.spacing(1) * 2,
                        paddingBottom: theme.spacing(1) * 2,
                    },
                },
                '& > div': {
                    //flexWrap: 'nowrap' // ??? wrapping items in select suggest
                },
            },
            '.scrollTopIconWrap': {
                boxShadow: themeConfig.customShadow,
                padding: 5,
                backgroundColor: themeColors.primarySupportingPalette.pms316c,
                borderRadius: '100%',
                '& svg': {
                    color: themeColors.primaryPalette.white,
                    fontSize: 20,
                },
            },
            '.riCommentaryReview': {
                '& .quickAudienceCommentCard:not(.cardHeader)': {
                    marginTop: theme.spacing(1) * 2,
                    marginBottom: theme.spacing(1) * 2,
                },
            },
            '.reportingItemsStepper': {
                marginTop: theme.spacing(1) * 4,
                '& p': {
                    display: 'inline-block',
                    marginRight: theme.spacing(1) * 2,
                },
                '& .navButton': {
                    padding: theme.spacing(1) * 1.5,
                    marginLeft: theme.spacing(1) * 1,
                    minWidth: 0,
                    borderRadius: '100%',
                },
            },
            '.dialogFormContent': {
                backgroundColor: themeColors.customPalette.bodyBg,
                minWidth: 280,
                [theme.breakpoints.up('md')]: {
                    minWidth: 500,
                },
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: theme.spacing(1) * 2,
                    paddingRight: theme.spacing(1) * 2,
                    '& form .gridItem': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
                '& .shareListWrapper': {
                    maxHeight: '40vh',
                    overflowY: 'auto',
                    '& li span': {
                        fontSize: themeConfig.smallFontSize,
                    },
                },
            },
            '.dialogPaperWithForm, .dialogFormContent': {
                //inner select not shown
                overflowY: 'visible',
            },
            '.recent-menu': {
                '& li': {
                    whiteSpace: 'normal',
                    fontSize: themeConfig.smallFontSize,
                },
            },
            'button.errorBtn': {
                backgroundColor: themeColors.primarySupportingPalette.pms200c,
                color: themeColors.primaryPalette.white,
            },
            '.inlineBlock': {
                display: 'inline-block',
            },
            '.inlineBlockSpacer': {
                display: 'inline-block',
                minWidth: theme.spacing(1) * 2,
            },
            '.linkBtn': {
                display: 'inline-block',
                color: themeColors.primaryPalette.primary,
                textDecoration: 'none',
                border: '1px solid ' + themeColors.primaryPalette.primary,
                padding:
                    theme.spacing(1) * 2 + 'px ' + theme.spacing(1) * 3 + 'px',
                borderRadius: theme.spacing(1) * 4,
                fontWeight: 600,
            },
            '.commentOuterWrapperWithActions': {
                position: 'relative',
                '& .commentActions': {
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    '& .fab,& button,& a': {
                        marginLeft: theme.spacing(1),
                        borderRadius: '100%',
                        padding: 0,
                        '&, & > span': {
                            minWidth: 'auto',
                            width: 36,
                            height: 36,
                            minHeight: 0,
                        },
                    },
                },
            },
            '.folderNameWrap': {
                '& svg': {
                    fontSize: themeConfig.smallFontSize,
                },
            },
            '.rte': {
                '& a': {
                    color: themeColors.primaryPalette.primary,
                    '&:hover': {
                        textDecoration: 'none',
                    },
                },
            },
            '.textRightLgUp': {
                [theme.breakpoints.up('lg')]: {
                    textAlign: 'right',
                },
            },
        },
    };
};
