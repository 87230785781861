import * as actionTypes from './actionTypes'

//APP
export const APP_NAME = "WBOPDC Reporting system";
export const IMAGE_LOADER_PATH = "/assets/images/image_loader.svg";
export const BROKEN_IMAGE_PATH = "/assets/images/broken_image.svg";
export const DEFAULT_LISTING_PAGE = 0;
export const DEFAULT_LISTING_PAGE_SIZE = 25;
export const LISTING_PAGE_PARAM = 'page';
export const LISTING_PAGE_SIZE_PARAM = 'size';

export const LISTING_OBJECT_PAGE_PARAM = 'number';
export const LISTING_OBJECT_PAGE_SIZE_PARAM = 'size';

export const LOCALE = 'en-NZ';
export const CURRENCY = 'NZD';

export const UNCATEGORIZED_BINDER_CATEGORY_ID = "UNCATEGORIZED";

export const DEV_HOSTS = ['localhost','wbopdc-qa.dev.eks.cucumbersoftware.com'];

export const YES_NO_OPTIONS = [{value:"true",label:"Yes"}, {value:"false",label:"No"}];

//UI
export const TOOLTIP_MENU_DELAY = 3000;
export const TOOLTIP_CARDS_DELAY = 1500;

//ERRORS
export const REDIRECTED_ERROR_STATUSES = [500];
export const ERROR_RESPONSE_TYPE_REPORTING_API = 1;//Reporting API
export const ERROR_RESPONSE_TYPE_OZONE_API = 2;//Ozone API
export const ERROR_CUSTUM_HANDLED_GET_REQUESTS = [actionTypes.GET_UNLINKED_PROJECT];

// USER ROLES
export const ADMIN = "ADMIN";
export const REPORTING_ADMIN = "REPORTING_ADMIN";
export const USER = "USER";

// REPORTING ITEM FIELDS TO OVERWRITE
export const OVERWRITE_REPORTING_ITEMS_FIELDS = ["activityId","positionId","name","description", "reportFrequency"];

// ASSESSMENT
export const ASSESSMENT_MET = 1;
export const ASSESSMENT_PARTIALMET = 2;
export const ASSESSMENT_NOTMET = 3;
export const ASSESSMENT_NOTCOMMENCED = 0;//not used for indicator
export const ASSESSMENT_MET_TEXT = 'Met';
export const ASSESSMENT_PARTIALMET_TEXT = 'Partially met';
export const ASSESSMENT_NOTMET_TEXT = 'Not met';
export const ASSESSMENT_NOTCOMMENCED_TEXT = 'Not Commenced';//not used for indicator

//PROGRESS REPORT ASSESSMENT TYPES
export const PROGRESS_REPORT_FINANCIAL_TYPE = 0;
export const PROGRESS_REPORT_MILESTONE_TYPE = 1;
export const PROGRESS_REPORT_KPI_TYPE = 2;

//CostForecast
export const COST_FORECAST_ON_TARGET = '0';
export const COST_FORECAST_OVER_BUDGET = '1';
export const COST_FORECAST_UNDER_BUDGET = '2';
export const COST_FORECAST_TOO_EARLY_TO_PREDICT = '3';

export const COST_FORECAST_ON_TARGET_TEXT = 'On Target';
export const COST_FORECAST_OVER_BUDGET_TEXT = 'Over Budget';
export const COST_FORECAST_UNDER_BUDGET_TEXT = 'Under Budget';
export const COST_FORECAST_TOO_EARLY_TO_PREDICT_TEXT = 'Too early to predict';

// PROJECT
export const PROJECT_ASSESSMENT_TYPE_FINANCIALLY_ASSESSED = 'Financially Assessed';

export const REPORTING_FREQUENCIES = [{value: "Q", label: "Quarterly"},{value: "M", label: "Monthly"}];

export const REPORTING_ITEM_ALL_DUE_STATES = [
    "notdue","reminder1", "reminder2","overdue","complete"
];

export const REPORTING_ITEM_DUE_STATES = [
    "reminder1", "reminder2","overdue"
];

// REPORTING ITEM
export const DEFAULT_COMMENTARY_FREQUENCY = 30;
export const REPORTING_ITEM_STATUS_STATE_NOTDUE = "notdue";
export const REPORTING_ITEM_STATUS_STATE_NOTDUE_TEXT = "Not due";
export const REPORTING_ITEM_STATUS_STATE_NOTCOMPLETE = "notcomplete";
export const REPORTING_ITEM_STATUS_STATE_NOTCOMPLETE_TEXT = "Not complete";
export const REPORTING_ITEM_STATUS_STATE_COMPLETE = "complete";
export const REPORTING_ITEM_STATUS_STATE_COMPLETE_TEXT = "Complete";

export const REPORTING_ITEM_STATUS_STATES = [
    REPORTING_ITEM_STATUS_STATE_NOTDUE, REPORTING_ITEM_STATUS_STATE_NOTCOMPLETE, REPORTING_ITEM_STATUS_STATE_COMPLETE
];

export const REPORTING_ITEM_COMMENTARY_STATE_NOTDUE = "notdue";
export const REPORTING_ITEM_COMMENTARY_STATE_NOTDUE_TEXT = "Not due";
export const REPORTING_ITEM_COMMENTARY_STATE_COMMENTARYDUE = "commentarydue";
export const REPORTING_ITEM_COMMENTARY_STATE_COMMENTARYDUE_TEXT = "Commentary due";
export const REPORTING_ITEM_COMMENTARY_STATE_QUICKCOMMENTDUE = "quickcommentdue";
export const REPORTING_ITEM_COMMENTARY_STATE_QUICKCOMMENTDUE_TEXT = "Quick comment due";

export const REPORTING_ITEM_COMMENTARY_STATES = [
    REPORTING_ITEM_COMMENTARY_STATE_NOTDUE, REPORTING_ITEM_COMMENTARY_STATE_COMMENTARYDUE, REPORTING_ITEM_COMMENTARY_STATE_QUICKCOMMENTDUE
];

//UI
export const UI_BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl'];

// Activity Summary
export const AS_STATE_NOTDUE = "notdue";
export const AS_STATE_NOTDUE_TEXT = "Not due";
export const AS_STATE_REPORTINGDUE = "reportingdue";
export const AS_STATE_REPORTINGDUE_TEXT = "Reporting due";
export const AS_STATE_SUMMARYDUE = "summarydue";
export const AS_STATE_SUMMARYDUE_TEXT = "Summary due";
export const AS_STATE_REVIEWDUE = "reviewdue";
export const AS_STATE_REVIEWDUE_TEXT = "Review due";
export const AS_STATE_COMPLETE = "complete";
export const AS_STATE_COMPLETE_TEXT = "Complete";

export const ACTIVITY_SUMMARY_STATES = [
    AS_STATE_NOTDUE, AS_STATE_REPORTINGDUE, AS_STATE_SUMMARYDUE, AS_STATE_REVIEWDUE, AS_STATE_COMPLETE
];

// Binder

export const BINDER_STATE_NOTDUE = "notdue";
export const BINDER_STATE_NOTDUE_TEXT = "Not due";
export const BINDER_STATE_REPORTINGDUE = "reportingdue";
export const BINDER_STATE_REPORTINGDUE_TEXT = "Reporting due";
export const BINDER_STATE_REVIEWDUE = "reviewdue";
export const BINDER_STATE_REVIEWDUE_TEXT = "Review due";
export const BINDER_STATE_COMPLETE = "complete";
export const BINDER_STATE_COMPLETE_TEXT = "Complete";

export const BINDER_STATES = [
    BINDER_STATE_NOTDUE, BINDER_STATE_REPORTINGDUE, BINDER_STATE_REVIEWDUE, BINDER_STATE_COMPLETE
];

//Dashboard
export const DASHBOARD_CARD_TYPE_RI = 1;
export const DASHBOARD_CARD_TYPE_AS = 2;
export const DASHBOARD_CARD_TYPE_BINDER = 3;

//CommentsTypes
export const WHATS_HAPPEND_COMMENTS_KEY = 'whatsHappenedComments';
export const WHATS_NEXT_COMMENTS_KEY = 'whatsNextComments';
export const QUICK_COMMENTS_KEY = 'quickComments';


//Timeline
export const TIMELINE_ITEM_TYPE_REPORT_UPDATE = "reportUpdate";
export const TIMELINE_ITEM_TYPE_COMMENTARY = "commentary";
export const TIMELINE_ITEM_TYPE_QUICK_COMMENT = "quickComment";
