import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography } from '@material-ui/core';



    class PreviousValue extends React.Component {



        render() {
            const { text, classes } = this.props;

            return text && text.length ? <div className={classes.previousValue}>
            <Typography component="div" variant="body2"><span>{text}</span></Typography> </div> : null
        }
    }


    PreviousValue.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PreviousValue);