import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";

export function getInfoText() {
  return function(dispatch) {
    ConsoleUtil.log('get info text action');
    return reportingClient.get(`InfoTexts`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_INFO_TEXT)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_INFO_TEXT))
      );
  };
}
