import * as types from '../constants/actionTypes';
import ConsoleUtil from '../utils/ConsoleUtil';



export function getBinderCategoriesSummary(state = [], action) {
    if (action.type === types.GET_BINDER_CATEGORIES_SUMMERY) {
        ConsoleUtil.log(types.GET_BINDER_CATEGORIES_SUMMERY+' set in store');
        return action.data.data;
    }
    return state;
}

export function getBinder(state = [], action) {
    if (action.type === types.GET_BINDER) {
        ConsoleUtil.log(types.GET_BINDER+' set in store');
        return action.data.data;
    }
    return state;
}

export function getBidnerReviewReportingItemCommentary(state = null, action){
    if (action.type === types.GET_BINDER_REVIEW_REPORTING_ITEM_COMMENTARY) {
        ConsoleUtil.log(types.GET_BINDER_REVIEW_REPORTING_ITEM_COMMENTARY+' set in store');
        return action.data.data;
    }
    return state;
    
}


