export default (theme, themeConfig, themeColors) => {
    return {
        quickAudienceCommentCardsOuterWrap: {
            '& .header h5': {
                fontWeight: 900,
                marginTop: theme.spacing(1) * 2,
                paddingLeft: theme.spacing(1) * 2,
            },
            '& .chatIcon': {
                marginBottom: -theme.spacing(1) * 3,
                marginLeft: theme.spacing(1) * 3,
                position: 'relative',

                zIndex: 2,
                width: 40,
                height: 40,
                backgroundColor: themeColors.primaryPalette.white,
                paddingTop: theme.spacing(1) * 1.25,
                borderRadius: '100%',
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        quickAudienceCommentCardsWrap: {
            '& > div:not(:first-child),& > div:not(:last-child)': {
                borderRadius: themeConfig.setImportant(0),
            },
            '& > div:first-child': {
                borderTopLeftRadius: themeConfig.setImportant(
                    theme.spacing(1) / 2 + 'px'
                ),
                borderTopRightRadius: themeConfig.setImportant(
                    theme.spacing(1) / 2 + 'px'
                ),
            },
            '& > div:last-child': {
                borderBottomLeftRadius: themeConfig.setImportant(
                    theme.spacing(1) / 2 + 'px'
                ),
                borderBottomRightRadius: themeConfig.setImportant(
                    theme.spacing(1) / 2 + 'px'
                ),
            },
        },
        audienceCommentCard: {
            width: '100%',
            maxWidth: themeConfig.customNarrowMaxMediumWidthPart,

            '&:not(.quickAudienceCommentCard)': {
                marginTop: theme.spacing(1) * 3,
                marginBottom: theme.spacing(1) * 2,
            },
            '& .audienceCommentCardContent': {
                padding: themeConfig.setImportant(0),
                fontSize: themeConfig.smallFontSize,
                '& > div > div': {
                    position: 'relative',
                },
                '& .gridItem': {
                    padding: themeConfig.setImportant(
                        theme.spacing(1) + 'px ' + theme.spacing(1) * 2 + 'px'
                    ),
                },
                '& .gridContainer.gridContainer0': {
                    paddingTop: themeConfig.setImportant(
                        theme.spacing(1) * 2 + 'px'
                    ),
                    paddingBottom: themeConfig.setImportant(
                        theme.spacing(1) + 'px'
                    ),
                },
                '& .gridContainer:not(.gridContainer0)': {
                    paddingTop: themeConfig.setImportant(
                        theme.spacing(1) + 'px'
                    ),
                    paddingBottom: themeConfig.setImportant(
                        theme.spacing(1) + 'px'
                    ),
                    borderTop:
                        '1px solid ' + themeColors.customPalette.tableRowBorder,
                },
                '& button': {
                    textTransform: themeConfig.setImportant('none'),
                },
            },

            '&.lastAudianceComment': {
                backgroundColor: themeColors.primarySupportingPalette.pms316c,
                color: themeColors.primaryPalette.white,
                '& button': {
                    color: themeColors.primaryPalette.white,
                },
                '& .gridContainer:not(.gridContainer0)': {
                    borderColor: themeConfig.setImportant(
                        themeColors.customPalette.transparentWhite
                    ),
                },
            },
            '&.quickAudienceCommentCard': {
                boxShadow: 'none',
                marginTop: -2,
                border:
                    '2px solid ' + themeColors.primarySupportingPalette.pms316c,
                backgroundColor: 'transparent',
                color: themeColors.primaryPalette.black,
                '& .gridContainer:not(.gridContainer0)': {
                    borderColor: themeConfig.setImportant(
                        themeColors.customPalette.commentBorderFade
                    ),
                },
                '& .headingWithIcon svg': {
                    fontSize: 18,
                    marginRight: theme.spacing(1),
                },
            },
        },
        audienceCommentCardHeader: {
            borderBottom: '1px solid ' + themeColors.primaryPalette.black,
            '& p, & span': {
                fontWeight: themeConfig.setImportant(900),
            },
            '&.lastAudianceComment': {
                borderBottom: '1px solid ' + themeColors.primaryPalette.white,
                '& p, & span': {
                    color: themeColors.primaryPalette.white,
                },
            },
        },

        quickCommentActionsWrap: {
            paddingTop: theme.spacing(1) * 3,
            '& button': {
                marginLeft: theme.spacing(1),
            },
        },

        commentPerGroup: {
            width: '100%',
            maxWidth: themeConfig.customNarrowMaxMediumWidthPart,
            marginBottom: theme.spacing(1) * 3,
            marginTop: theme.spacing(1) * 3,
            [theme.breakpoints.down('sm')]: {
                overflow: themeConfig.setImportant('visible'),
            },
            '& .headingWithIcon svg': {
                fontSize: 18,
                marginRight: theme.spacing(1),
            },
            '& .textFieldWrap': {
                display: 'flex',
                position: 'relative',
                borderTop:
                    '1px solid ' + themeColors.customPalette.tableRowBorder,
                '& textarea': {
                    padding: themeConfig.setImportant(
                        theme.spacing(1) + 'px ' + theme.spacing(1) * 2 + 'px'
                    ),
                    marginBottom: theme.spacing(1) / 2,
                },
                '& .rteWrap': {
                    padding: themeConfig.setImportant(
                        theme.spacing(1) + 'px ' + theme.spacing(1) * 2 + 'px'
                    ),
                    marginBottom: theme.spacing(1) / 2,
                    fontSize: themeConfig.smallFontSize,
                },
                '& .ck-editor__editable': {
                    fontSize: 14,
                },
                '& label': {
                    position: 'absolute',
                    borderRadius: 4,
                    background: themeColors.primaryPalette.white,
                    top: -theme.spacing(1) * 1.5,
                    right: theme.spacing(1) * 2,
                    fontSize: themeConfig.smallFontSize,
                    padding: themeConfig.setImportant(
                        '0 ' + theme.spacing(1) * 2 + 'px'
                    ),
                    zIndex: 2,
                },
                '& p.hasError': {
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    paddingRight: theme.spacing(1) * 4,
                    color: themeColors.primarySupportingPalette.pms200c,
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                },
                [theme.breakpoints.up('sm')]: {
                    '& label': {
                        top: theme.spacing(1),
                        padding: themeConfig.setImportant(
                            theme.spacing(1) / 2 +
                                'px ' +
                                theme.spacing(1) * 2 +
                                'px'
                        ),
                    },
                    '& textarea, & .rteWrap': {
                        paddingRight: themeConfig.setImportant(175 + 'px'),
                        marginBottom: themeConfig.setImportant(30 + 'px'),
                    },
                },
                '&.hasError': {
                    borderBottom:
                        '2px solid ' +
                        themeColors.primarySupportingPalette.pms200c,
                },
            },
            '& .clickToAddBtn': {
                backgroundColor: themeColors.primarySupportingPalette.pms316c,

                marginTop: theme.spacing(1),
                //borderRadius: themeConfig.setImportant('0'),
                '& span,p,div,strong': {
                    color: themeColors.primaryPalette.white,
                    fontSize: themeConfig.smallFontSize,
                },
            },
            '& .copyTxtBtn': {
                background: themeColors.primaryPalette.white,
                padding: themeConfig.setImportant(
                    theme.spacing(1) / 2 + 'px ' + theme.spacing(1) + 'px'
                ),
                textTransform: themeConfig.setImportant('none'),
                margin: theme.spacing(1),
                marginTop: 0,
                [theme.breakpoints.up('sm')]: {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                },
            },
        },
        commentPerGroupHeader: {
            '& p, & span': {
                fontWeight: themeConfig.setImportant(900),
            },
        },
        commentPerGroupContent: {
            padding: themeConfig.setImportant(0),
            '& > div > div': {
                position: 'relative',
            },
        },
        historyCommentaryListWrapper: {
            '& .mobileCommentaryHistoryNavWrapper': {
                paddingTop: theme.spacing(1) * 2,
                paddingBottom: theme.spacing(1) * 2,
                marginTop: theme.spacing(1) * 3,
                '& .arrow': {
                    padding: 0,
                },
            },
            [theme.breakpoints.down('xs')]: {
                position: 'relative',
                '& .historyCommentaryItemWrapper': {
                    '&.first': {
                        paddingBottom: theme.spacing(1) * 3,
                        borderBottom:
                            '1px solid ' +
                            themeColors.customPalette.tableRowBorder,
                    },
                    '&.second': {
                        paddingTop: theme.spacing(1) * 4,
                    },
                },
            },
            [theme.breakpoints.up('sm')]: {
                '& .historyCommentaryItemWrapper': {
                    position: 'relative',
                    '& h5': {
                        fontSize: 16,
                    },
                    '& .rte': {
                        minHeight: 150,
                        paddingTop: theme.spacing(1),
                    },
                    '&.first': {
                        paddingRight: theme.spacing(1) * 4,
                        '& .divider': {
                            position: 'absolute',
                            width: 1,
                            right: 0,
                            top: '15%',
                            bottom: '5%',
                            backgroundColor:
                                themeColors.customPalette.tableRowBorder,
                        },
                    },
                    '&.second': {
                        paddingLeft: theme.spacing(1) * 4,
                    },
                },
            },
        },
    };
};
