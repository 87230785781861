import React from "react";
import { FormControl, NativeSelect } from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import LabelTooltip from "./LabelTooltip";

export default ({
  input,
  label,
  meta: { touched, error, form, initial },
  children,
  dataOptions,
  multiple,
  helpText,
  ...custom
}) => {
  var inputValue = !Array.isArray(input.value) && multiple ? [] : input.value;


  return (
    <FormControl error={touched && error ? true : false} className="nativeSelectWrap">
      <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
      <NativeSelect variant="filled" {...input} {...custom} multiple={multiple} value={inputValue} className="nativeSelect">
      { !initial ? <option key="notSelected"> --- </option> : null }
        {dataOptions && dataOptions.length
          ? dataOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })
          : null}
      </NativeSelect>
      {InputHelperText({ touched, error })}
    </FormControl>
  );
};
