import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";

export function getReportingParameters() {
  return function(dispatch) {
    ConsoleUtil.log('get ReportingParameters action');
    return reportingClient.get(`ReportingParameters`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_PARAMETERS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPORTING_PARAMETERS))
      );
  };
}

export function getReportingTargetParameters() {
  return function(dispatch) {
    ConsoleUtil.log('get ReportingTargetParameters action');
    return reportingClient.get(`ReportingTargetParameters`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_TARGET_PARAMETERS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPORTING_TARGET_PARAMETERS))
      );
  };
}
