import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import objectPath from 'object-path';
import { Grid, Divider, Typography, Hidden } from '@material-ui/core';
import PreviousComment from './PreviousComment';
import * as formNames from '../../constants/forms';
import * as constants from '../../constants/constants';
import { formValueSelector } from 'redux-form'
import {
  TextLabelColumnsInput,
  RadioGroupInput,
  NativeSelectInput
} from "../inputs";
import { Field } from "redux-form";
import { connect } from "react-redux";
import classnames from "classnames";
import PreviousValue from './PreviousValue';
import ProgressReportUtil from '../../utils/ProgressReportUtil';
import FormatterUtil from '../../utils/FormatterUtil';


class ProjectForecastSection extends React.Component {




  render(){


  const { item, classes, currentSelectedForecastOnTime, currentSelectedForecastCost, currentSelectedForecastAdditionalFundingRequired } = this.props;
  //const projectForecast = objectPath.get(item,'projectForecast');

  const previousProjectForecast = objectPath.get(item,'previousProgressReport');

  const previousOnTimeReason = objectPath.get(previousProjectForecast,'projectForecast.onTimeReason','');
  const previousOnTimeReasonDts = objectPath.get(previousProjectForecast,'projectForecast.onTimeReasonDts');

  const previousCostForecastValue = objectPath.get(previousProjectForecast,'projectForecast.costForecast',null);
  const previousCostForecastReason = objectPath.get(previousProjectForecast,'projectForecast.costForecastReason','');
  const previousCostForecastReasonDts = objectPath.get(previousProjectForecast,'projectForecast.costForecastReasonDts');
  let previousCostForecastOverAmount = objectPath.get(previousProjectForecast,'projectForecast.costForecastOverAmount',0);
  const previousCostForecastAmountLabelText = "Forecast "+(previousCostForecastValue === constants.COST_FORECAST_UNDER_BUDGET ? "Under" : "Over");
  previousCostForecastOverAmount = "<p>"+previousCostForecastAmountLabelText+": "+FormatterUtil.formatNZDollar(previousCostForecastOverAmount)+"</p>";

  const previousAdditionalFundingReason = objectPath.get(previousProjectForecast,'projectForecast.additionalFundingReason','');
  const previousAdditionalFundingReasonDts = objectPath.get(previousProjectForecast,'projectForecast.additionalFundingReasonDts');
  let previousAdditionalFundingAmount = objectPath.get(previousProjectForecast,'projectForecast.additionalFundingAmount',0);
  previousAdditionalFundingAmount = "<p>Amount Required: "+FormatterUtil.formatNZDollar(previousAdditionalFundingAmount)+"</p>";

  const costForecastOptions = [
    { value:constants.COST_FORECAST_ON_TARGET,label:constants.COST_FORECAST_ON_TARGET_TEXT },
    { value:constants.COST_FORECAST_OVER_BUDGET,label:constants.COST_FORECAST_OVER_BUDGET_TEXT },
    { value:constants.COST_FORECAST_UNDER_BUDGET,label:constants.COST_FORECAST_UNDER_BUDGET_TEXT },
    { value:constants.COST_FORECAST_TOO_EARLY_TO_PREDICT,label:constants.COST_FORECAST_TOO_EARLY_TO_PREDICT_TEXT }
  ];
  const previousCostForecastSelectedText = objectPath.get(costForecastOptions.find(x => x.value === previousCostForecastValue), 'label', null);


  const showForecastOnTimeFields = ProgressReportUtil.showForecastOnTimeFields(currentSelectedForecastOnTime);
  
  const showForecastCostFields = ProgressReportUtil.showForecastCostFields(currentSelectedForecastCost);
  const showAdditionalFundingRequiredFields = ProgressReportUtil.showAdditionalFundingRequiredFields(currentSelectedForecastAdditionalFundingRequired);

  const costForecastAmountLabelText = "Forecast "+(currentSelectedForecastCost === constants.COST_FORECAST_UNDER_BUDGET ? "Under" : "Over")+" $";
  

  return <Grid container>
  <Grid item className="gridItem" xs={12}>
    <Typography variant="h4">PROJECT YEAR END FORECAST</Typography>
  </Grid>

{/* Time forecast */}

  <Grid item className="gridItem sectionOverviewItem" xs={12}>
  <Field name="projectForecast.onTime" label="On Time Forecast" component={RadioGroupInput} className="inlineRadioGroup" dataOptions={constants.YES_NO_OPTIONS}  />  
  
  </Grid>

  <Grid item className={ classnames((!showForecastOnTimeFields && classes.hiddenXs), "gridItem")} xs={12} sm={6} md={7}>
{ showForecastOnTimeFields ? <Field name="projectForecast.onTimeReason" component={TextLabelColumnsInput} label="Reason"  multiline={true} rows="4" /> : null }
</Grid>

<Grid item className={ classnames((previousOnTimeReason.length < 1 && classes.hiddenXs), "gridItem")} xs={12} sm={6} md={5}>
{ previousOnTimeReason.length ?  <PreviousComment date={previousOnTimeReasonDts} text={previousOnTimeReason} /> : null }
</Grid>  


{/* Cost forecast */}

  <Grid item className="gridItem sectionOverviewItem" xs={12} sm={6} md={7}>
      <Hidden xsDown>
    <Field name="projectForecast.costForecast" label="Cost Forecast" className="inlineRadioGroup" component={RadioGroupInput} dataOptions={costForecastOptions}  />  
    </Hidden>
    <Hidden smUp>
    <Field name="projectForecast.costForecast" label="Cost Forecast" component={NativeSelectInput} dataOptions={costForecastOptions}  />  
    </Hidden>
  </Grid>
  <Grid item className="gridItem sectionVerticalPaddingItem" xs={12} sm={6} md={5}>
      <PreviousValue text={previousCostForecastSelectedText} />
  </Grid>

  <Grid item className={ classnames((!showForecastCostFields && classes.hiddenXs), "gridItem")}  xs={12} sm={6} md={7}>
{ 
  
  showForecastCostFields ? <Field name="projectForecast.costForecastOverAmount" component={TextLabelColumnsInput} label={costForecastAmountLabelText} /> : null }
{ showForecastCostFields 
  ? <Field name="projectForecast.costForecastReason" component={TextLabelColumnsInput} label="Reason" multiline={true} rows="4" /> : null }
</Grid>

<Grid item className={ classnames((previousCostForecastReason.length < 1 && classes.hiddenXs), "gridItem")} xs={12} sm={6} md={5}>
{ previousCostForecastReason.length ?  <PreviousComment date={previousCostForecastReasonDts} text={previousCostForecastOverAmount+previousCostForecastReason} /> : null }
</Grid>


{/* Funding forecast */}


  <Grid item className="gridItem sectionOverviewItem" xs={12}>
  <Field name="projectForecast.additionalFundingRequired" label="Scope Change and/or Additional Funding Required" className="inlineRadioGroup" component={RadioGroupInput} dataOptions={[...constants.YES_NO_OPTIONS].reverse()}  />  
  
  </Grid>
  

<Grid item className={ classnames((!showAdditionalFundingRequiredFields && classes.hiddenXs), "gridItem")} xs={12} sm={6} md={7}>
{ 
  showAdditionalFundingRequiredFields
  ? <Field name="projectForecast.additionalFundingAmount" component={TextLabelColumnsInput} label={"Amount Required $"} /> : null }
{ showAdditionalFundingRequiredFields 
  ? <Field name="projectForecast.additionalFundingReason" component={TextLabelColumnsInput} label="Reason for scope change or additional funding" multiline={true} rows="4" /> : null }
</Grid>

<Grid item className={ classnames((previousAdditionalFundingReason.length < 1 && classes.hiddenXs), "gridItem")} xs={12} sm={6} md={5}>
{ previousAdditionalFundingReason.length ?  <PreviousComment date={previousAdditionalFundingReasonDts} text={previousAdditionalFundingAmount+previousAdditionalFundingReason} /> : null }
</Grid>   

<Grid item className="gridItem" xs={12}>
  <Divider className="sectionDivider"/>
</Grid>
</Grid>
}
}

ProjectForecastSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const selector = formValueSelector(formNames.PROGRESS_REPORT_FORM_NAME);

export default connect(state => ({
    currentSelectedForecastOnTime: selector(state, 'projectForecast.onTime'),
    currentSelectedForecastCost: selector(state, 'projectForecast.costForecast'),
    currentSelectedForecastAdditionalFundingRequired: selector(state, 'projectForecast.additionalFundingRequired'),
}))(withStyles(styles, { withTheme: true })(ProjectForecastSection));