import * as types from "../constants/actionTypes";
import ConsoleUtil from "../utils/ConsoleUtil";


function openDrawer() {
    ConsoleUtil.log('openDrawer');
    var body = document.getElementsByTagName("BODY")[0];
    body.classList.add("drawerOpen");
    body.classList.remove("drawerClosed");
    return {type: types.OPEN_DRAWER };
}

function closeDrawer() {
    ConsoleUtil.log('closeDrawer');
    var body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("drawerOpen");
    body.classList.add("drawerClosed");
    return {type: types.CLOSE_DRAWER };
}


export {
    openDrawer,
    closeDrawer
}