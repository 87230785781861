import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";




export function getReportOutputs(binderId = null) {
  var queryString = binderId ? '/?binderId='+binderId : '';
  return function(dispatch) {
    ConsoleUtil.log('getReportOutputs called');
    return reportingClient
      .get(`ReportOutputs${queryString}`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, binderId ? types.GET_REPORT_OUTPUTS_BY_BINDER_ID : types.GET_REPORT_OUTPUTS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, binderId ? types.GET_REPORT_OUTPUTS_BY_BINDER_ID : types.GET_REPORT_OUTPUTS))
      );
  };
}



export function getReportOutput(id) {
    return function(dispatch) {
      ConsoleUtil.log('getReportOutput '+id+' called');
      return reportingClient
        .get(`ReportOutputs/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORT_OUTPUT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPORT_OUTPUT))
        );
    };
  }


  export function postReportOutput(data) {
    return function(dispatch) {
      ConsoleUtil.log('postReportOutput');
      return reportingClient
        .post(`ReportOutputs`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_REPORT_OUTPUT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_REPORT_OUTPUT))
        );
    };
  }


  export function putReportOutput(data) {
    return function(dispatch) {
      ConsoleUtil.log('putReportOutput');
      return reportingClient
        .put(`ReportOutputs/${data.id}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_REPORT_OUTPUT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_REPORT_OUTPUT))
        );
    };
  }

  export function deleteReportOutput(id) {
    return function(dispatch) {
      ConsoleUtil.log('deleteReportOutput');
      return reportingClient
        .delete(`ReportOutputs/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_REPORT_OUTPUT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_REPORT_OUTPUT))
        );
    };
  }
  
  
