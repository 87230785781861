export default class LocalStorageUtil {

    static set(key,value, simpleType = true) {
        if(simpleType === false){
            value = value ? JSON.stringify(value) : value;
        }
        localStorage.setItem(key, value);
    }


    static get(key, defaultValue = null, simpleType = true) {
        let value = localStorage.getItem(key);
        if(simpleType === false){
            value = value ? JSON.parse(value) : value;
        }
        return value ? value : defaultValue;
    }

    
  }
  