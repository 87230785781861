import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import LabelTooltip from "./LabelTooltip";

export default ({
  input,
  label,
  meta: { touched, error, form },
  children,
  helpText,
  dataOptions,
  disabled,
  ...custom
}) => {

    

  return (
    <FormControl error={touched && error ? true : false} className="radioGroupWrap"  >
      <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
      <RadioGroup {...input} {...custom}>
        {dataOptions && dataOptions.length
          ? dataOptions.map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio color="primary" disabled={disabled} className={input.value === option.value ? 'checked' : 'notChecked'} />}
                  label={option.label}
                />
              );
            })
          : null}
      </RadioGroup>
      {InputHelperText({ touched, error })}
    </FormControl>
  );
};
