import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";

export function getUnlinkedProjects(id) {
  return function(dispatch) {
    ConsoleUtil.log('UnlinkedProjects called');
    return reportingClient
      .get(`UnlinkedProjects/${id}`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_UNLINKED_PROJECT)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_UNLINKED_PROJECT))
      );
  };
}