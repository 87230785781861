import moment from 'moment';
import ArrayUtil from './ArrayUtil';
import { Hidden } from '@material-ui/core';
import React from 'react';

export default class DateUtil {

    static responsiveDate(date, time){
      return <span>
        <Hidden smUp>{DateUtil.basicDateSlashes(time)}</Hidden><Hidden xsDown>{date}</Hidden>
      </span>
    }

    static stripDateFromTime(date){
      return DateUtil.backendDateFormat(date);
    }

    static basicDate(time, responsive = false) {
      return responsive ? DateUtil.responsiveDate(moment(time).format("DD MMM YYYY"), time) : moment(time).format("DD MMM YYYY");
    }

    static basicDateWithWeekday(time) {
      return moment(time).format("ddd DD MMM YYYY");
    }

    static basicDateWithoutYear(time) {
      return moment(time).format("DD MMM YY");
    }

    static formatForSorting(date) {
      return moment(date).format("YYYYMMDD");
    }

    static basicDateSlashes(time) {
      return moment(time).format("DD/MM/YY");
    }

    static fullNameMonthDate(time) {
      return moment(time).format("DD MMMM YYYY");
    }

    static backendDateFormat(time){
      return moment(time).format('YYYY-MM-DD');
    }

    static sortArrayASC(array){
      return Array.isArray(array) ? array.sort(function (a, b) { //sort

        return moment(a).diff(moment(b))
      }) : array;
    }

    static isPresentOrFuture(date){
      return moment(date).isSameOrAfter(moment());
    }

    static isDateSelected(date, selectedValue){
      if(ArrayUtil.isNonEmptyArray(selectedValue)){
        return selectedValue.includes(DateUtil.backendDateFormat(date));
      }else{
        return selectedValue === DateUtil.backendDateFormat(date);
      }
    }

}
  