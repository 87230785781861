import React from "react";
import PageHeading from "../../components/PageHeading";
import SyncButton from "../../components/buttons/SyncButton"
import FormatterUtil from "../../utils/FormatterUtil";
import ComponentLoading from '../../components/ComponentLoading';

const entityName = "Sync";
class Sync extends React.Component {
  constructor(props){
    super(props);
    this.state={
      dataLoading: true
    }
  }
      render(){
            return <div><PageHeading heading={FormatterUtil.camelToNormalForm(entityName)} /><div>< SyncButton color="primary" fontSize="large"/></div></div> 
    }
}
export default Sync;

    

