import React from 'react';
import objectPath from 'object-path';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import {
    Grid,
    InputAdornment,
    Input,
    FormControlLabel,
    Switch,
    Tooltip,
} from '@material-ui/core';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import CancelIcon from '@material-ui/icons/CloseOutlined';
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import FilterUtil from '../../utils/FilterUtil';

class ReportingItemListFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: objectPath.get(props, 'keyword', ''),
        };
    }

    redirectToCurrentFilter = (stateFilter = null) => {
        const { history } = this.props;
        if (stateFilter === null) {
            stateFilter = this.getFilterState();
        }

        history.push(UrlBuilderUtil.getReportingItemList(null, stateFilter));
    };

    keyPress = (e) => {
        if (e.keyCode === 13) {
            //enter pressed
            this.redirectToCurrentFilter();
        }
    };

    getFilterState = () => {
        return {
            archivedonly: this.props.archivedonly,
            keyword: this.state.keyword,
        };
    };

    changeArchivedOnlyCheckbox = () => {
        var stateFilter = this.getFilterState();
        stateFilter.archivedonly = !stateFilter.archivedonly;
        this.redirectToCurrentFilter(stateFilter);
    };

    hasKeyword = () => {
        var keyword = FilterUtil.getRequestedKeywordFromUrl();

        return keyword && keyword.length;
    };

    render() {
        const { classes, onToggleExpandAll, expandAll, archivedonly } =
            this.props;
        const { keyword } = this.state;

        return (
            <div
                className={classnames(
                    classes.innerPageHeaderLikeWrap,
                    classes.headerFilterWrap
                )}
            >
                <Grid container className="gridContainer">
                    <Grid item xs={12} sm={5}>
                        <Input
                            className="keywordSearch"
                            placeholder="Search"
                            onKeyDown={this.keyPress}
                            value={keyword}
                            onChange={(e) =>
                                this.setState({ keyword: e.target.value })
                            }
                            startAdornment={
                                <Tooltip title="Search by Title">
                                    <InputAdornment
                                        onClick={() =>
                                            this.redirectToCurrentFilter()
                                        }
                                        position="start"
                                    >
                                        <SearchIcon
                                            color="primary"
                                            fontSize="small"
                                        />
                                    </InputAdornment>
                                </Tooltip>
                            }
                            endAdornment={
                                this.hasKeyword() ? (
                                    <Tooltip title="Clear search">
                                        <InputAdornment
                                            onClick={() =>
                                                this.setState(
                                                    { keyword: '' },
                                                    () =>
                                                        this.redirectToCurrentFilter()
                                                )
                                            }
                                            position="end"
                                        >
                                            <CancelIcon
                                                color="default"
                                                className="muted"
                                                fontSize="small"
                                            />
                                        </InputAdornment>
                                    </Tooltip>
                                ) : null
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        className={classes.textRightStrong}
                    >
                        <FormControlLabel
                            classes={{
                                label: 'controlLabel',
                            }}
                            control={
                                <Switch
                                    className={classes.customSwitch}
                                    checked={
                                        archivedonly === 1 ||
                                        archivedonly === '1' ||
                                        archivedonly === true
                                            ? true
                                            : false
                                    }
                                    onChange={this.changeArchivedOnlyCheckbox}
                                    color="primary"
                                />
                            }
                            label="Archived only"
                        />

                        <FormControlLabel
                            classes={{
                                label: 'controlLabel',
                            }}
                            control={
                                <Switch
                                    className={classes.customSwitch}
                                    checked={
                                        expandAll === 1 ||
                                        expandAll === '1' ||
                                        expandAll === true
                                            ? true
                                            : false
                                    }
                                    onChange={onToggleExpandAll}
                                    color="primary"
                                />
                            }
                            label={expandAll ? 'Hide details' : 'Expand all'}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ReportingItemListFilter)
);
