import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import ArrayUtil from '../../utils/ArrayUtil';
import { Typography, Grid, IconButton, Hidden, Button } from '@material-ui/core';
import CommentaryHistoryItem from './CommentaryHistoryItem';
import objectPath from 'object-path'
import ArrowLeft from '@material-ui/icons/ArrowBackIosRounded';
import ArrowRight from '@material-ui/icons/ArrowForwardIosRounded';

class CommentaryHistoryList extends React.Component {

    constructor(props){
        super(props);
        this.state={
          currentIndex: 0
        }
      }

    

    render() {
        const { items, classes } = this.props;
        const { currentIndex } = this.state;
    
        return ArrayUtil.isNonEmptyArray(items) ? <Grid container className={classes.historyCommentaryListWrapper}>
            <Hidden xsDown><IconButton className="arrow arrowLeft" disabled={ArrayUtil.getSimpleNext(items,currentIndex) ? false : true} onClick={() => { this.setState({currentIndex: (currentIndex+1)}) }}>
            <ArrowLeft />
            </IconButton></Hidden>
            <Grid item xs={12} sm={6}>
                <CommentaryHistoryItem title="What's Happened" className="first" item={objectPath.get(items, currentIndex+'.whatsHappenedComment',null)} />    
            </Grid>
            <Grid item xs={12} sm={6}>
                <CommentaryHistoryItem title="What's Next" className="second" item={objectPath.get(items, currentIndex+'.whatsNextComment',null)} />
            </Grid>
            <Hidden xsDown><IconButton className="arrow arrowRight" disabled={ArrayUtil.getSimplePrev(items,currentIndex) ? false : true} onClick={() => { this.setState({currentIndex: (currentIndex-1)}) }}>
            <ArrowRight  />
            </IconButton></Hidden>
            <Hidden smUp><Grid container className="mobileCommentaryHistoryNavWrapper"><Grid item xs={6}>
            <Button variant="text" size="small" className="arrow arrowLeft" disabled={ArrayUtil.getSimpleNext(items,currentIndex) ? false : true} onClick={() => { this.setState({currentIndex: (currentIndex+1)}) }}>
            <ArrowLeft fontSize="small" /> Previous
            </Button>
            </Grid><Grid item xs={6} className={classes.textRightStrong}>
            <Button variant="text" size="small" className={"arrow arrowRight"} disabled={ArrayUtil.getSimplePrev(items,currentIndex) ? false : true} onClick={() => { this.setState({currentIndex: (currentIndex-1)}) }}>
            Next <ArrowRight fontSize="small" />
            </Button>
            </Grid></Grid></Hidden>
        </Grid> : <div><br /><br /><Typography variant="body2" className="muted">No commentary found</Typography><br /><br /></div>;
      }
}



export default withStyles(styles, { withTheme: true })(CommentaryHistoryList);