import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import objectPath from 'object-path';
import { Grid, Divider, Typography, Button, TableRow, TableCell, Table, TableBody, TableHead, InputAdornment, Hidden, Tooltip } from '@material-ui/core';

import classnames from 'classnames'
import EditIcon from '@material-ui/icons/EditOutlined';
import {
  TextInput,
  RadioGroupInput,
  NativeSelectInput
} from "../inputs";
import { Field } from "redux-form";
import DateUtil from '../../utils/DateUtil';
import AssessmentUtil from '../../utils/AssessmentUtil';
import * as constants from '../../constants/constants';
import StoreUtil from '../../utils/StoreUtil';


class MilestonesSection extends React.Component {
  renderInputField = (i,index) => {
    var currentRowKey = "milestoneOverview.milestoneList."+index+".percentComplete";
    return <Field name={currentRowKey} component={TextInput} disabled={i.completeDate ? true : false} label="" placeholder="" 
    endAdornment={<InputAdornment position="end" className="percentageSign">%</InputAdornment>}
    />
  }

  getPreviousMilestonePercentComplete = (index) => {
    const { item } = this.props;
    let previousProgressReportMilestoneItem = objectPath.get(item,'previousProgressReport.milestoneOverview.milestoneList.'+index+'.percentComplete', null);
    return previousProgressReportMilestoneItem ? previousProgressReportMilestoneItem+'%' : ' - ';
  }

  render() {

  const { item, classes, progressReportAssessmentType } = this.props;
  const milestoneOverview = objectPath.get(item,'milestoneOverview');
  const milestonesExtarnalLinkUrl = objectPath.get(milestoneOverview,'hyperlink.url');
  const milestoneList = objectPath.get(milestoneOverview,'milestoneList', []);
  const showFullMilestones = constants.PROGRESS_REPORT_MILESTONE_TYPE === progressReportAssessmentType;
  const isContentNarrow = StoreUtil.getValueByKey('contentSubmenuLevel');
  const projectPhases = StoreUtil.getValueByKey('projectPhases')?.map((phase) => { return { value: phase, label: phase}});
  

  return milestoneOverview ? <Grid container className="milestonesSection">
  <Grid item className="gridItem" xs={12}>
    <Typography variant="h4">MILESTONES 
    <Hidden xsDown>&nbsp; {milestonesExtarnalLinkUrl ? <Tooltip title="Open milestones in Ozone"><Button color="primary" size="small" variant="outlined" target="_blank" href={milestonesExtarnalLinkUrl}><EditIcon /> &nbsp; Edit</Button></Tooltip> : null }</Hidden>
    </Typography>
    
  </Grid>

  { milestoneList.length < 1 ? <Grid item className="gridItem sectionOverviewItem" xs={12}>
  <Typography  variant="body2">No milestones set</Typography>
</Grid> : null }
  
  <Hidden only={isContentNarrow ? ['lg','xl'] : ['md','lg', 'xl']}>
    {milestoneList.map((i,index) => {
      
      return  <Grid item className="gridItem mobileMilestoneCard" xs={12} key={index}>
      <Grid container>
      <Grid item xs={12}>
      <Typography variant="caption" >{i.name}</Typography>
      </Grid>
      { !showFullMilestones ? <Grid item xs={4} className="gridItem sectionVerticalPaddingItem">
      <Typography variant="caption"><strong>Start</strong></Typography>
      </Grid> : null }
      <Grid item xs={4} className="gridItem sectionVerticalPaddingItem">
      <Typography variant="caption"><strong>Due</strong></Typography>
      </Grid>
      { !showFullMilestones ? <Grid item xs={4} className="gridItem sectionVerticalPaddingItem">
      <Typography variant="caption"><strong>Complete</strong></Typography>
      </Grid> : null }
      { showFullMilestones ? <Grid item xs={4} className="gridItem sectionVerticalPaddingItem">
      <Typography variant="caption"><strong>Last %</strong></Typography>
      </Grid> : null }
      { showFullMilestones ? <Grid item xs={4} className="gridItem sectionVerticalPaddingItem">
      <Typography variant="caption"><strong>% Complete</strong></Typography>
      </Grid> : null }
      { !showFullMilestones ? <Grid item xs={4}>
      <Typography variant="caption" >{DateUtil.basicDateSlashes(i.startDate)}</Typography>
      </Grid> : null }
      <Grid item xs={4}>
      <Typography variant="caption" >{DateUtil.basicDateSlashes(i.dueDate)}</Typography>
      </Grid>
      { !showFullMilestones ? <Grid item xs={4}>
      <Typography variant="caption" >{i.completeDate ? DateUtil.basicDateSlashes(i.completeDate) : ' - '}</Typography>
      </Grid> : null }
      { showFullMilestones ? <Grid item xs={4}>
      <Typography variant="caption" >{this.getPreviousMilestonePercentComplete(index)}</Typography>
      </Grid> : null }

      { showFullMilestones ? <Grid item xs={4}>
        {this.renderInputField(i,index)}
      </Grid> : null }
      </Grid>
      <Grid item className="gridItem" xs={12}>
  <Divider />
</Grid>
      </Grid>}) }
    
  </Hidden>

  <Hidden only={isContentNarrow ? ['xs','sm','md'] : ['xs','sm']}>
  <Grid item className="gridItem sectionSidePaddingItem" xs={12}>
  { milestoneList.length ? <Table>
      <TableHead>
          <TableRow>
          <TableCell className="expandCellWidth">
              &nbsp;
          </TableCell>
          <TableCell>
              <Typography variant="caption"><strong>Start</strong></Typography>
          </TableCell>
          <TableCell>
              <Typography variant="caption"><strong>Due</strong></Typography>
          </TableCell>
          <TableCell>
              <Typography variant="caption"><strong>Complete</strong></Typography>
          </TableCell>
          { showFullMilestones ? <TableCell>
              <Typography variant="caption"><strong>Last %</strong></Typography>
          </TableCell> : null }

          { showFullMilestones ? <TableCell>
              <Typography variant="caption"><strong>% Complete</strong></Typography>
          </TableCell> : null}
          </TableRow>
      </TableHead>
      <TableBody>
      {milestoneList.map((i,index) => {
      return  <TableRow key={index}>
      <TableCell className="expandCellWidth"><Typography variant="caption" >{i.name}</Typography></TableCell>
      <TableCell><Typography variant="caption" >{DateUtil.basicDateSlashes(i.startDate)}</Typography></TableCell>
      <TableCell><Typography variant="caption" >{DateUtil.basicDateSlashes(i.dueDate)}</Typography></TableCell>
      <TableCell><Typography variant="caption" >{i.completeDate ? DateUtil.basicDateSlashes(i.completeDate) : ' - '}</Typography></TableCell>
      { showFullMilestones ? <TableCell><Typography variant="caption" >{this.getPreviousMilestonePercentComplete(index)}</Typography></TableCell> : null }
      { showFullMilestones ? <TableCell>
      {this.renderInputField(i,index)}
        </TableCell> : null }
      
  </TableRow>
  }
 )}
      </TableBody>
  </Table> : null }
  </Grid>
  </Hidden>

  { showFullMilestones ? <Grid item className="gridItem sectionOverviewItem" xs={12}>
    <Typography variant="caption"><strong>System assesment: </strong> &nbsp; {AssessmentUtil.getIndicatorText(milestoneOverview.milestoneAssessment)} &nbsp; <span className={classnames("indicator",AssessmentUtil.getIndicatorClassname(classes, milestoneOverview.milestoneAssessment))}></span></Typography>
  </Grid> : null }
  
  <Grid item className="gridItem sectionOverviewItem" xs={12} sm={7}>
    <Hidden xsDown>
      <Field name="milestoneOverview.projectPhase" className="inlineRadioGroup" component={RadioGroupInput} dataOptions={projectPhases} helpText="help text" label="Project Phase" />  
    </Hidden>
    <Hidden smUp>
      <Field name="milestoneOverview.projectPhase" component={NativeSelectInput} dataOptions={projectPhases} helpText="help text" label="Project Phase" />  
    </Hidden>
  </Grid>

  <Grid item className="gridItem" xs={12}>
    <Divider className="sectionDivider" />
  </Grid>

</Grid> : null
}
}

MilestonesSection.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(MilestonesSection);