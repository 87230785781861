import React from "react";
import { withStyles } from "@material-ui/core/styles";

import styles from "../../theme/styles";
import { TableRow, TableCell, Hidden } from "@material-ui/core";
import { withRouter } from "react-router-dom"; 
import classnames from 'classnames';



function ReportingItemTableRowSpacer(props) {


  const { classes } = props;



return <TableRow className="spacerRow">

<TableCell className={classnames("expandCellWidth",classes.tableCellProjectName)}> </TableCell>

<TableCell className={classes.tableCellProjectId}> </TableCell>


  <TableCell className="expandCellWidth"> </TableCell>

<Hidden xsDown>
<TableCell className={classes.tableCellActionWrap}> </TableCell>
</Hidden>
</TableRow>

}

export default withRouter(withStyles(styles, { withTheme: true })(ReportingItemTableRowSpacer));

