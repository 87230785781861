import React from 'react';
//import { Button } from '@material-ui/core';
import ExportIcon from '../icons/ExportIcon';
import FilterUtil from "../../utils/FilterUtil";
import * as dashboardActions from '../../actions/dashboard';


class ExportButton extends React.Component {
    render() {
        return <button className="Export" onClick={getContent()}><ExportIcon /></button>;
    }
}
function getContent() {
    return dashboardActions.downloadDashboardData({ filter: FilterUtil.getRequestedFilterFromUrl() });
}


export default ExportButton;