export default (theme, themeConfig, themeColors) => {
    return {
        actionSummaryResultsHeading: {
            position: 'relative',
            paddingTop: themeConfig.setImportant(theme.spacing(1) * 4 + 'px'),
            paddingBottom: themeConfig.setImportant(theme.spacing(1) + 'px'),
            paddingRight: themeConfig.setImportant('100px'),
            borderBottom: '1px solid ' + themeColors.primaryPalette.white,
            '& .actionsWrap': {
                position: 'absolute',
                top: theme.spacing(1) * 1.5,
                right: theme.spacing(1) * 1.5,
            },
            '& p.muted': {
                fontSize: 12,
            },
        },
        actionSummaryResults: {
            '&.open': {
                paddingBottom: theme.spacing(1) * 6,
            },
            '& table': {
                marginTop: themeConfig.setImportant(theme.spacing(1) + 'px'),
            },
            '& .projectTitle': {
                color: themeColors.primarySupportingPalette.pms316c,
                textAlign: 'left',
                lineHeight: '1.25em',
            },
        },
    };
};
