import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export default ({ input, label }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={
                        input.value === 1 ||
                        input.value === '1' ||
                        input.value === true
                            ? true
                            : false
                    }
                    onChange={input.onChange}
                />
            }
            label={label}
        />
    );
};
