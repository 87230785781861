import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import { NavLink } from "react-router-dom";
import { Typography } from '@material-ui/core';
import UsersUtil from '../../utils/UsersUtil';
import UserIconName from '../UserIconName';


class ProjectStatusLinkRecord extends React.Component {



    render() {
        const { data, projectId } = this.props;

        return data ? <div>
            <NavLink className={"linkBtn hideForPrint"} to={UrlBuilderUtil.getProgressReportSnapshot(projectId, data.year, data.period)}>
                View Project Status
            </NavLink>
            { data.reportUpdatedUserId ? <Typography variant="body2" className="hideForPrint">
                <br /><UserIconName userIds={data.reportUpdatedUserId} />
            </Typography> : null }

            <div className="hidden showForPrint">
                <Typography variant="h5"><strong>Project Status</strong></Typography>
                <br />
                <Typography variant="body2" component="div">
                <strong>Project ID:</strong> &nbsp; {projectId}<br />
                <strong>Year:</strong> &nbsp; {data.year}<br />
                <strong>Period:</strong> &nbsp; {data.period}<br />
                <strong>Created By:</strong> &nbsp; {UsersUtil.getNameFromStoreById(data.reportUpdatedUserId)}<br />
                {UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProgressReportSnapshot(projectId, data.year, data.period)}</Typography>
            </div>


            </div> : null
    }
}


export default withStyles(styles, { withTheme: true })(ProjectStatusLinkRecord);