import * as types from "../constants/actionTypes";

export function setUserInfo(info) {
  return function(dispatch) {
    if (info) {
      return dispatch(success(info));
    } else {
      return dispatch(error());
    }
  };
}

function success(response) {
  return {
    type: types.SET_USER_INFO_SUCCESS,
    data: response
  };
}

function error() {
  return {
    type: types.SET_USER_INFO_ERROR,
    data: null
  };
}
