import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid, Chip, IconButton, Tooltip, DialogActions, Button, Hidden, CardActions} from '@material-ui/core';
import { withRouter } from "react-router-dom";  
import styles from '../../theme/styles';
import objectPath from 'object-path';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import ShowActionsIcon from "@material-ui/icons/MoreVert";
import AppDialog from '../AppDialog';
import PrintIcon from "@material-ui/icons/PrintOutlined";
import ShareIcon from "@material-ui/icons/ShareOutlined";
import ScheduleIcon from "@material-ui/icons/DateRangeOutlined";
import BinderPrintDialog from './BinderPrintDialog';
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import { NavLink } from "react-router-dom";
import * as constants from '../../constants/constants';
import ReportingItemsUtil from '../../utils/ReportingItemsUtil';
import classnames from 'classnames';
import BinderUtil from '../../utils/BinderUtil';
import BinderShareDialog from './BinderShareDialog';
import ScheduleDialog from '../schedule/ScheduleDialog';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import DateUtil from '../../utils/DateUtil'
import PositionIconName from '../PositionIconName';

    class BinderCard extends React.Component {


      constructor(props){
        super(props);
        this.state={
          displayConfirmation: false,
          displayPrintDialogForItem: null,
          displayShareDialogForItem: null,
          displayScheduleDialogForItem: null
        }
      }


      hideConfirmation = () => {
        this.setState({
          displayConfirmation: false
        });
      }

      renderConfirmationDialog = () => {
        const { item, handleDeleteBinder } = this.props;
        let message = 'Please confirm you wish to remove the Binder "'+item.name+'"';
    
        return (<AppDialog 
          warningStyle={true}
          title="Are you sure?"
        message={message}
        onClose={this.hideConfirmation}
        actions={
          <DialogActions>
            <Button variant="text" onClick={this.hideConfirmation}>Cancel</Button>
            <Button variant="contained" color="default" className="errorBtn" onClick={() => handleDeleteBinder(item.id)}>Confirm</Button>
          </DialogActions>
        } />)
      }



      renderBinderActions = () => {
        const { showLowResActions } = this.state;
        const { handleShowBinderFormForCategory, item, categoryId } = this.props;
        const totalItems = objectPath.get(item, 'reportingItems',[]).length;

        const RemoveBtn = <Tooltip key="removeBtn"  title={"Remove binder"} aria-label={"Remove binder"}>
          <IconButton size="small" onClick={() => this.setState({displayConfirmation: true})}>
              <DeleteIcon fontSize="small" />
          </IconButton>
          </Tooltip>;

          const PrintBtn = <Tooltip key="printBtn"  title={"Print binder"} aria-label={"Print binder"}>
            <IconButton size="small" className="printBtn smallIconBtn" onClick={() => this.setState({displayPrintDialogForItem: item})}>
                <PrintIcon fontSize="small" />
            </IconButton>
            </Tooltip>;
        
          const EditBtn = <Tooltip key="editBtn" title="Edit binder">
              <IconButton size="small" aria-label="Edit" onClick={() => handleShowBinderFormForCategory(categoryId, item.id)}>
              <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>;
        
              const showActionsBtn = <Tooltip title="Show actions" aria-label="Show actions">
              <IconButton size="small" onClick={() => this.setState({ showLowResActions: !showLowResActions })}>
                  <ShowActionsIcon fontSize="small" />
              </IconButton>
              </Tooltip>;

          const shareBtn = <Tooltip key="shareBtn"  title={"Share binder"} aria-label={"Share binder"}>
              <IconButton size="small" onClick={() => this.setState({displayShareDialogForItem: item})}>
                  <ShareIcon fontSize="small" />
              </IconButton>
              </Tooltip>;

          const scheduleBtn = <Tooltip key="scheduleBtn"  title={"View binder schedule"} aria-label={"View binder schedule"}>
              <IconButton size="small" onClick={() => this.setState({displayScheduleDialogForItem: item})}>
                  <ScheduleIcon fontSize="small" />
              </IconButton>
              </Tooltip>;
    
          let actionBtns = [];

          if (totalItems === 0) {
            actionBtns = [scheduleBtn,shareBtn,PrintBtn,RemoveBtn,EditBtn];
          } else {
            actionBtns = [scheduleBtn,shareBtn,PrintBtn,EditBtn];
          }
    
               
        return <div className="actionsWrap">
          <Hidden mdDown>
            { actionBtns }
          </Hidden>
          <Hidden lgUp>
            {
              showLowResActions ? actionBtns : showActionsBtn
            }
          </Hidden>
        </div>;
      }

        render() {
            const { classes, item, cardExpanded, toggleCardInfo, editedBinderId, handleSubmitBinder } = this.props;
            const { displayConfirmation, displayPrintDialogForItem, displayShareDialogForItem, displayScheduleDialogForItem } = this.state;
            let reportingItems = objectPath.get(item, 'reportingItems',[]);


  return item ? <Card className={classnames('card',classes.binderCard, BinderUtil.getBinderStateClasses(item))}>
    <CardContent>
      <Grid container className={classes.binderCardHeader}>
      <Grid item xs={6} lg={4} className="dateColumnWrap">
                        { item.reportCloseDate ? <Typography variant="caption" className="muted date">
                                <CalendarIcon className="calendarIcon" /> { DateUtil.basicDate(item.reportCloseDate, true) }
                            </Typography> : null }
                        </Grid>
        <Grid item xs={6} lg={8}>
        
        { editedBinderId !== item.id ? this.renderBinderActions() : null }
        
        </Grid>
      </Grid>
      <Grid container onClick={() => toggleCardInfo(item.id)}>
        <Grid item xs={12}>
          <Typography variant="h5" className="cardMainActionElement">
            <strong>{item.name}</strong>
            <span className="fullPseudoAfter"></span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
        {
            cardExpanded && reportingItems.length ? reportingItems.map((x, index) => <Grid item xs={12} key={index} className={classnames('cardReportingItemLink',classes.cardReportingItemLink, ReportingItemsUtil.getRIstateClasses(x, constants.DASHBOARD_CARD_TYPE_BINDER))}>
            <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="View commentary in binder view">
            <NavLink 
            to={UrlBuilderUtil.getReportingItemTimeline(x.id)}>
            <Typography variant="body2">
                {/* <span className='cardRIstateIndicator'><CheckIcon /></span> */}
                <span>{x.name}</span>
                <PositionIconName positionIds={x.positionId} />
            </Typography></NavLink></Tooltip>
        </Grid>) : null
        }    
        </Grid>
      </Grid>
      { displayConfirmation ? this.renderConfirmationDialog() : null}
      { displayScheduleDialogForItem ? <ScheduleDialog binderId={item.id} onClose={() => this.setState({displayScheduleDialogForItem : null})} /> : null}
      { displayPrintDialogForItem ? <BinderPrintDialog item={displayPrintDialogForItem} onClose={() => this.setState({displayPrintDialogForItem : null})}/> : null}
      { displayShareDialogForItem ? <BinderShareDialog handleSubmitBinder={(values) => {this.setState({displayShareDialogForItem : null}, () => {
        handleSubmitBinder(values)
      });}} item={displayShareDialogForItem} onClose={() => this.setState({displayShareDialogForItem : null})}/> : null}
      </CardContent>
      <CardActions className={classes.cardBottomActions}><div>
      <Typography className="leftTextWrap muted"> </Typography>
      <Chip label={reportingItems.length} variant="outlined" clickable={false} className="countChip" />
      </div>
      </CardActions>
    </Card> : null}
    }


    BinderCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(BinderCard));
