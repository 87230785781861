import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import { Grid, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import FormatterUtil from '../../utils/FormatterUtil';
import ArrayUtil from '../../utils/ArrayUtil';
import DateUtil from '../../utils/DateUtil';

function Schedule(props) {

  const { classes, schedule } = props;

  return <div className="scheduleWrap">{ schedule ? <Grid container>
    <Grid item xs={12}>
    <Table className={classnames(classes.table,'table')}>
        <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <strong>Report Close days</strong>
              </TableCell>
              <TableCell align="right">
                {FormatterUtil.formatDays(schedule.dueDays)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <strong>Reminder One days</strong>
              </TableCell>
              <TableCell align="right">
                {FormatterUtil.formatDays(schedule.reminderDays1)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <strong>Reminder Two days</strong>
              </TableCell>
              <TableCell align="right">
                {FormatterUtil.formatDays(schedule.reminderDays2)}
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th" scope="row">
                    { ArrayUtil.isNonEmptyArray(schedule.meetingSchedules) ? <Typography variant="body2"><strong>Meeting Schedule</strong></Typography> : <Typography variant="body2" color="error"><strong>No meeting schedules</strong></Typography> }
                </TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </Grid>

    { ArrayUtil.isNonEmptyArray(schedule.meetingSchedules) ? schedule.meetingSchedules.map((x,index)=> <Grid xs={4} key={index} item className="gridItem">
    <Typography variant="body2">{DateUtil.basicDate(x, true)}</Typography>
                    </Grid>
                ) : null
            }
  </Grid>: <Typography variant="body2" color="error">Schedule not set on binder</Typography>}</div>;
}


export default withStyles(styles, { withTheme: true })(Schedule);