import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import { Typography, Button, DialogTitle, DialogContent, DialogActions, Dialog } from '@material-ui/core';
import classnames from 'classnames';
import UiHelperUtil from '../utils/UiHelperUtil';
import RichTextRenderer from './RichTextRenderer';



    class ShowContentInDialog extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                showMore: props.showMore,
            }
          }

          handleToggle = () => {
            const { text, maxLenght } = this.props
              if(text && text.length > maxLenght){
                this.setState({showMore:!this.state.showMore});
              }
            
          }

       


        render() {
            const { text, classes, additionalClasses, DialogTitleText, maxLenght } = this.props;

            return <div  className={classnames(classes.ShowContentInDialog, additionalClasses, (this.state.showMore === false && 'compact'))}>
            
                <div onClick={this.handleToggle} variant="body2" dangerouslySetInnerHTML={{__html: UiHelperUtil.truncateIfNeeded(text, maxLenght)}} />
                { this.state.showMore ? 
            <Dialog
            fullWidth={true}
            maxWidth='md'
            open={true}
            onClose={this.handleToggle}
          >
            { DialogTitleText ? <DialogTitle>{DialogTitleText}</DialogTitle> : null }
            <DialogContent>
              <Typography component="div" variant="body2">
              <RichTextRenderer text={text} />
              </Typography>
              
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleToggle} color="default" size="small">
                Close
              </Button>
            </DialogActions>
          </Dialog>
            
             : null }
                </div>
        }
    }


export default withStyles(styles, { withTheme: true })(ShowContentInDialog);