import React from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import reportingClient from "../../api/reportingClient";
import ConsoleUtil from "../../utils/ConsoleUtil";
import { Button, Typography } from "@material-ui/core";

class SyncButton extends React.Component {
    constructor(props){
        super(props);
        this.state={
           submitDisabled: false,
           isErrored:false
        }
      }

      sync = (e) => {
        const _this = this;
        return function(dispatch) {
           ConsoleUtil.log('syncing');
           _this.setState({
               submitDisabled: true,
               isErrored: false
             });
       
           return reportingClient
             .post('sync/syncall/')
             .then(() => {
                 _this.setState({
                submitDisabled: false,
                isErrored:false
              })},
              error =>  _this.setState({
                submitDisabled: false,
                isErrored:true
              })
            )
      }
    }
 
    render() {
        const {submitDisabled, isErrored} = this.state;
        return  (<div><br/>
        <Button color="primary" 
          size="small" 
          variant="outlined" 
          target="_blank" 
          disabled={submitDisabled}
          onClick={this.sync()}><SyncIcon /> &nbsp; Sync</Button><br/>
     
       { submitDisabled ? <Typography variant="body1">The sync operation may take a few moments</Typography> : null}
       { isErrored ? <Typography variant="body1" color="error">An unexpected error occurred.  Please try again later.</Typography> : null}

       </div>)
    }
}


export default SyncButton;