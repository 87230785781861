import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";

export function checkObjectiveById(objectiveId, projectId) {
    return function(dispatch) {
        return reportingClient.get('/ReportExternalDocuments/CheckObjective?id=' + objectiveId + "&projectid=" + projectId).then(
            response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_OBJECTIVE_DATA)),
            error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
}

export function create(projectId, payload) {
    return function(dispatch) {
        return reportingClient.post('/ReportExternalDocuments?id=' + projectId, payload).then(
            response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.SET_OBJECTIVE_DATA)),
            error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
}

export function removeLink(id) {
    return function(dispatch) {
        return reportingClient.delete(`/ReportExternalDocuments/${id}`).then(
            response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.SET_OBJECTIVE_DATA)),
            error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
}