import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as reportingItemActions from '../../actions/reportingItem';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import styles from '../../theme/styles';
import {
    CardContent,
    Typography,
    Grid,
    CardActions,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    Hidden,
    Fab,
} from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import CommentaryIcon from '@material-ui/icons/AssignmentOutlined';
import DateUtil from '../../utils/DateUtil';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import QuickCommentIcon from '@material-ui/icons/ChatBubbleOutline';
import ReportingItemQuickCommentForm from '../forms/reportingItem/quickComment/form';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@material-ui/icons/EventNoteOutlined';
import objectPath from 'object-path';
import SnackBarUtil from '../../utils/SnackBarUtil';
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import { withSnackbar } from 'notistack';
import ReportingItemsUtil from '../../utils/ReportingItemsUtil';
import * as constants from '../../constants/constants';
import PositionIconName from '../PositionIconName';
import ComponentLoading from '../ComponentLoading';
import CommentaryHistoryList from '../commentary/CommentaryHistoryList';
import CameraIcon from '@material-ui/icons/CameraAltOutlined';
import NonFormImageInput from '../inputs/NonFormImageInput';
import GoogleTagManagerUtil from '../../utils/GoogleTagManagerUtil';
import { NavLink } from 'react-router-dom';
import RoomIcon from '@material-ui/icons/Room';

class ReportingItemCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommentaryModal: false,
            showQCForm: false,
            commentaryHistory: null,
            commentaryHistoryLoading: false,
            imageForQC: null,
        };
    }

    mountCommentaryHistory = () => {
        const { item, actions } = this.props;
        this.setState({ commentaryHistoryLoading: true });
        actions.getReportingItemHistoryCommentary(item.id).then((response) => {
            this.setState({
                commentaryHistory: objectPath.get(response, 'data.data', []),
                commentaryHistoryLoading: false,
            });
        });
    };

    showCommentaryModal = (showQCForm = false) => {
        const { item } = this.props;
        this.setState(
            {
                showCommentaryModal: true,
                showQCForm: showQCForm,
            },
            () => {
                if (!showQCForm) {
                    this.mountCommentaryHistory();
                    GoogleTagManagerUtil.trackDashboardCardCommentaryModal(
                        item
                    );
                }
            }
        );
    };

    hideCommentaryModal = () => {
        this.setState(
            {
                showCommentaryModal: false,
                showQCForm: false,
                imageForQC: null,
            },
            () => {}
        );
    };

    handleQuickCommentOnRI = (values) => {
        const { item, postReportingItemQuickCommentary, enqueueSnackbar } =
            this.props;
        let successMessage = item
            ? 'Quick comment added to "' +
              objectPath.get(item, 'name', null) +
              '"'
            : 'Quick comment added to Reporting item';

        return postReportingItemQuickCommentary(
            objectPath.get(item, 'id', null),
            values
        ).then((response) => {
            GoogleTagManagerUtil.trackQuickComment(item);
            if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
                SnackBarUtil.showSuccessMessage(
                    response,
                    enqueueSnackbar,
                    successMessage
                );
                this.hideCommentaryModal();
            }
        });
    };

    openMap = (url) => (e) => {
        e.preventDefault();
        window.open(url);
    };

    getDialogInnerPart = () => {
        const { item, classes } = this.props;
        const { showQCForm, commentaryHistoryLoading, commentaryHistory } =
            this.state;

        const mapUrl = objectPath.get(item, 'projectMapUrl', '');
        const showPinpointMapIcon = mapUrl !== '';

        const modalControls = (
            <Grid container className="reportingItemDialogControls">
                {!showQCForm ? (
                    <Grid
                        item
                        xs={12}
                        className={classnames(classes.textRightStrong)}
                    >
                        {showPinpointMapIcon ? (
                            <Tooltip
                                enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                title="View map"
                            >
                                <Fab
                                    className="roundedDialogIcon"
                                    onClick={this.openMap(mapUrl)}
                                >
                                    <RoomIcon />
                                </Fab>
                            </Tooltip>
                        ) : null}

                        <Tooltip
                            enterDelay={constants.TOOLTIP_CARDS_DELAY}
                            title="View history"
                        >
                            <NavLink
                                to={UrlBuilderUtil.getReportingItemTimeline(
                                    item.id
                                )}
                            >
                                <Fab className="roundedDialogIcon">
                                    <HistoryIcon />
                                </Fab>
                            </NavLink>
                        </Tooltip>

                        {item.editable ? (
                            <Tooltip
                                enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                title="Add quick comment"
                            >
                                <Fab
                                    className="roundedDialogIcon"
                                    onClick={() =>
                                        this.setState({ showQCForm: true })
                                    }
                                >
                                    <QuickCommentIcon />
                                </Fab>
                            </Tooltip>
                        ) : null}
                    </Grid>
                ) : null}
            </Grid>
        );

        return (
            <div>
                <DialogTitle
                    className="formDialogTitle"
                    onClose={this.hideCommentaryModal}
                    disableTypography={true}
                >
                    <Typography variant="h5">
                        <strong>{item.name}</strong>
                        <Tooltip
                            enterDelay={constants.TOOLTIP_CARDS_DELAY}
                            title="Close dialog"
                        >
                            <IconButton
                                size="small"
                                onClick={this.hideCommentaryModal}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </DialogTitle>
                <DialogContent
                    className={
                        showQCForm
                            ? 'dialogFormContent'
                            : 'dialogHistoryContent'
                    }
                >
                    <Hidden smUp>{modalControls}</Hidden>
                    {showQCForm ? (
                        <ReportingItemQuickCommentForm
                            expandable={true}
                            reportingItemId={item.id}
                            initialValues={this.getQCInitialValues()}
                            onCancel={() => {
                                Array.isArray(commentaryHistory)
                                    ? this.showCommentaryModal()
                                    : this.hideCommentaryModal();
                            }}
                            proceedSubmit={this.handleQuickCommentOnRI}
                        />
                    ) : commentaryHistoryLoading ? (
                        <ComponentLoading hideText={true} />
                    ) : (
                        <CommentaryHistoryList items={commentaryHistory} />
                    )}

                    <Hidden xsDown>{modalControls}</Hidden>
                </DialogContent>
            </div>
        );
    };

    onFileProccessed = (document) => {
        this.setState(
            {
                imageForQC: document,
            },
            () => this.showCommentaryModal(true)
        );
    };

    getQCInitialValues = () => {
        const { imageForQC } = this.state;
        return imageForQC
            ? {
                  document: imageForQC,
              }
            : null;
    };

    render() {
        const { classes, item, history, showCardResponsibilityStates } =
            this.props;
        const { showCommentaryModal } = this.state;
        const closedDate = objectPath.get(item, 'reportCloseDate', null);
        return (
            <Card
                className={classnames(
                    classes.dashboardCard,
                    showCardResponsibilityStates
                        ? ReportingItemsUtil.getRIstateClasses(item)
                        : ''
                )}
            >
                <CardContent>
                    <span className="typeIndicator"></span>
                    <span className="bar"></span>
                    <Grid container className={classes.dashboardCardHeader}>
                        <Grid item xs={5} sm={4}>
                            {closedDate ? (
                                <Typography
                                    variant="caption"
                                    className="muted date"
                                >
                                    <CalendarIcon className="calendarIcon" />{' '}
                                    {DateUtil.basicDate(closedDate, true)}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={7} sm={8} align="right">
                            <Typography
                                variant="caption"
                                className={classnames(
                                    'muted',
                                    classes.textRightStrong
                                )}
                            >
                                <PositionIconName
                                    positionIds={item.positionId}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                className="cardMainActionElement"
                                onClick={() => this.showCommentaryModal(false)}
                            >
                                <strong>{item.name}</strong>
                                <span className="fullPseudoAfter"></span>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={classes.cardBottomActions}>
                    <Typography className="leftTextWrap muted">
                        {item.projectId ? '#' + item.projectId : ' '}
                    </Typography>
                    {item.editable ? (
                        <Hidden mdUp>
                            <IconButton
                                size="small"
                                className="smallIconBtn imageBtnInputWrap"
                            >
                                <NonFormImageInput
                                    onFileProccessed={this.onFileProccessed}
                                    name={'mobile_img_upload_ri_' + item.id}
                                >
                                    <Tooltip
                                        enterDelay={
                                            constants.TOOLTIP_CARDS_DELAY
                                        }
                                        title={'Add image'}
                                    >
                                        <CameraIcon fontSize="small" />
                                    </Tooltip>
                                </NonFormImageInput>
                            </IconButton>
                        </Hidden>
                    ) : null}
                    {item.editable ? (
                        <Tooltip
                            enterDelay={constants.TOOLTIP_CARDS_DELAY}
                            title="Add quick comment"
                            onClick={() => this.showCommentaryModal(true)}
                        >
                            <IconButton size="small" className="smallIconBtn">
                                <QuickCommentIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {item.editable ? (
                        item.projectId ? (
                            <Tooltip
                                enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                title={
                                    '#' + item.projectId + ' progress report'
                                }
                            >
                                <NavLink
                                    to={
                                        '/progress-report/edit/' +
                                        item.projectId
                                    }
                                >
                                    <IconButton
                                        size="small"
                                        className="smallIconBtn iconBtnWrappedWithLink"
                                    >
                                        <AssignmentIcon fontSize="small" />
                                    </IconButton>
                                </NavLink>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                title={'View commentary'}
                            >
                                <IconButton
                                    size="small"
                                    className="smallIconBtn"
                                    onClick={() =>
                                        history.push(
                                            '/reporting-item-commentary/' +
                                                item.id
                                        )
                                    }
                                >
                                    <CommentaryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    ) : null}
                </CardActions>

                <Hidden smDown>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'md'}
                        onClose={this.hideCommentaryModal}
                        open={showCommentaryModal}
                        className={classnames(
                            'dialogWithArrows',
                            classes.formDialogWrap
                        )}
                        classes={{
                            paper: 'paper',
                        }}
                    >
                        {this.getDialogInnerPart()}
                    </Dialog>
                </Hidden>
                <Hidden mdUp>
                    <Dialog
                        fullScreen
                        fullWidth={true}
                        onClose={this.hideCommentaryModal}
                        open={showCommentaryModal}
                        className={classnames(
                            'dialogWithArrows',
                            classes.formDialogWrap
                        )}
                        classes={{
                            paper: 'paper',
                        }}
                    >
                        {this.getDialogInnerPart()}
                    </Dialog>
                </Hidden>
            </Card>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            ...reportingItemActions,
        },
        dispatch
    ),
});

export default withRouter(
    withSnackbar(
        withStyles(styles, { withTheme: true })(
            connect(mapStateToProps, mapDispatchToProps)(ReportingItemCard)
        )
    )
);
