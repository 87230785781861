import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";





  export function getBinderCategorySummary() {
    return function(dispatch) {
      ConsoleUtil.log('getBinderCategorySummary called');
      return reportingClient
        .get('BinderCategorySummary')
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_BINDER_CATEGORIES_SUMMERY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


export function getBinderCategory(id) {
    return function(dispatch) {
      ConsoleUtil.log('getBinderCategory '+id+' called');
      return reportingClient
        .get(`BinderCategories/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_BINDER_CATEGORY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function postBinderCategory(data) {
    return function(dispatch) {
      ConsoleUtil.log('postBinderCategory');
      return reportingClient
        .post(`BinderCategories`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_BINDER_CATEGORY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  export function deleteBinderCategory(id) {
    return function(dispatch) {
      ConsoleUtil.log('deleteBinderCategory');
      return reportingClient
        .delete(`BinderCategories/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_BINDER_CATEGORY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  


  export function putBinderCategory(data) {
    return function(dispatch) {
      ConsoleUtil.log('putBinderCategory');
      return reportingClient
        .put(`BinderCategories/${data.id}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_BINDER_CATEGORY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }
  
  








  export function putBinder(data) {
    return function(dispatch) {
      ConsoleUtil.log('putBinder');
      return reportingClient
        .put(`Binders/${data.id}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_BINDER)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function postBinder(data) {
    return function(dispatch) {
      ConsoleUtil.log('postBinder');
      return reportingClient
        .post(`Binders`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_BINDER)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  export function getBinder(id) {
    return function(dispatch) {
      ConsoleUtil.log('getBinder');
      return reportingClient
        .get(`Binders/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_BINDER)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  export function deleteBinder(id) {
    return function(dispatch) {
      ConsoleUtil.log('deleteBinder');
      return reportingClient
        .delete(`Binders/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_BINDER)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }



  export function getBinderSummary(id) {
    return function(dispatch) {
      ConsoleUtil.log('getBinderSummary');
      return reportingClient
        .get(`BinderSummary/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_BINDER_SUMMARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function getBinderReviewReportingItemCommentary(bId,riId){
    return function(dispatch) {
      ConsoleUtil.log('getBinderReviewReportingItemCommentary');
      return reportingClient
        .get(`BinderCommentaryReviews/${bId}/reportingItem/${riId}`) 
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_BINDER_REVIEW_REPORTING_ITEM_COMMENTARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };

  }

  export function setBinderReviewReportingItemCommentary(binderCommentary){
    return function(dispatch) {
      ConsoleUtil.log('setBinderReviewReportingItemCommentary', binderCommentary);
      return dispatch({
        type : types.GET_BINDER_REVIEW_REPORTING_ITEM_COMMENTARY,
        data: {
          data: binderCommentary
        }
      });
    };

  }

  export function postBinderCommentaryReview(bId){
    return function(dispatch) {
      ConsoleUtil.log('postBinderCommentaryReview ',bId);
      return reportingClient
        .post(`BinderCommentaryReviews/${bId}`) 
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_BINDER_COMMENTARY_REVIEW)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  export function postBinderCommentaryDocs(values){
    return function(dispatch) {
      ConsoleUtil.log('postBinderCommentaryDocs');
      return reportingClient
        .post(`BinderCommentaryDocs`,values) 
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_BINDER_COMMENTARY_DOCS_PRINT)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }