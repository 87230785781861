import * as types from "../constants/actionTypes";


function setContentSubmenuLevel(level) {
    return { type: types.SET_CONTENT_SUBMENU_LEVEL, level: level }
}



export {
    setContentSubmenuLevel
}