import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";




export function getReportSchedules() {
  return function(dispatch) {
    ConsoleUtil.log('getReportSchedules called');
    return reportingClient
      .get(`ReportSchedules`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORT_SCHEDULES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPORT_SCHEDULES))
      );
  };
}



export function getReportSchedule(id) {
    return function(dispatch) {
      ConsoleUtil.log('getReportSchedule '+id+' called');
      return reportingClient
        .get(`ReportSchedules/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORT_SCHEDULE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPORT_SCHEDULE))
        );
    };
  }


  export function postReportSchedule(data) {
    return function(dispatch) {
      ConsoleUtil.log('postReportSchedule');
      return reportingClient
        .post(`ReportSchedules`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_REPORT_SCHEDULE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_REPORT_SCHEDULE))
        );
    };
  }


  export function putReportSchedule(data) {
    return function(dispatch) {
      ConsoleUtil.log('putReportSchedule');
      return reportingClient
        .put(`ReportSchedules/${data.id}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_REPORT_SCHEDULE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_REPORT_SCHEDULE))
        );
    };
  }


  export function deleteReportSchedule(id) {
    return function(dispatch) {
      ConsoleUtil.log('deleteReportSchedule');
      return reportingClient
        .delete(`ReportSchedules/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_REPORT_SCHEDULE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_REPORT_SCHEDULE))
        );
    };
  }
  
  
