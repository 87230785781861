import React, { Component } from 'react';
import FormatterUtil from '../../utils/FormatterUtil';
import ComponentLoading from '../ComponentLoading';

export default class NonFormImageInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
        };
    }

    filesChanged = (e) => {
        const { onFileProccessed } = this.props;

        if (e.target && e.target.files && e.target.files[0]) {
            var fileObject = e.target.files[0];
            this.setState({ processing: true });
            FormatterUtil.getBase64(fileObject).then((data) => {
                this.setState({ processing: false });
                onFileProccessed({
                    name: fileObject.name,
                    base64String: data,
                    size: fileObject.size,
                });
            });
        }
    };

    render() {
        const { processing } = this.state;
        return (
            <div className="imageInputWrap">
                <label htmlFor={this.props.name}>
                    <input
                        name={this.props.name}
                        id={this.props.name}
                        type="file"
                        accept="image/*"
                        onChange={this.filesChanged}
                    />
                    {this.props.children}
                    {processing ? (
                        <div className="processingWrap">
                            <ComponentLoading hideText={true} />
                        </div>
                    ) : null}
                </label>
            </div>
        );
    }
}
