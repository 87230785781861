import * as types from '../constants/actionTypes';
import ConsoleUtil from '../utils/ConsoleUtil';
import initialState from './initialState';

export function getDashboardData(state = null, action) {
    if (action.type === types.GET_DASHBOARD_DATA) {
        ConsoleUtil.log(types.GET_DASHBOARD_DATA+' set in store');
        return action.data.data;
    }
    return state;
}


export function setMergedDashboardData(state = initialState.dashboardMergedData, action) {
    if (action.type === types.SET_MERGED_DASHBOARD_DATA) {
        ConsoleUtil.log(types.SET_MERGED_DASHBOARD_DATA+' set in store');
        return action.data;
    }
    return state;
}