import objectPath from "object-path";
import { DEV_HOSTS } from '../constants/constants';


export default class ConsoleUtil {

    static log(message, object = 'NOT_SET') {
      var hostName = objectPath.get(window, 'location.hostname');
      if(hostName && DEV_HOSTS.includes(hostName)){
        if(object !== 'NOT_SET'){
            console.log(message, object);
        }else{
            console.log(message);
        }
      }
    }
}