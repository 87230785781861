import React from "react";
import { reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../theme/styles';
import classnames from 'classnames';
import * as formNames from '../../../../constants/forms';
import * as constants from '../../../../constants/constants';
import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import TickIcon from "@material-ui/icons/Check";
import { Grid, Tooltip, Button } from "@material-ui/core";
import warnAboutUnsavedForm from "../../../../hoc/withWarnAboutUnsavedChanges";

import CommentPerGroup from '../../../commentary/CommentPerGroup';


class ReportingItemQuickCommentForm extends React.Component {


  render () {
    const {
      handleSubmit,
      //pristine,
      submitting,
      classes,
      proceedSubmit,
      readOnly,
      reportingItemId,
      expandable,
      heading,
      onCancel
      //initialValues,
      //viewProgressReportById
    } = this.props;

    const disableSubmit = submitting;
    const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><TickIcon /></Fab>;

    
    

    return  <div>
    <form onSubmit={handleSubmit(values => proceedSubmit(values, reportingItemId))} className={classnames(classes.customForm, classes.progressForm, readOnly && classes.readOnlyForm)}>
    <div className="maxWidthPart">
    <Grid container>




<Grid item className="gridItem" xs={12}>
<CommentPerGroup
heading={heading}
expandable={expandable}
copyable={true}
formName={formNames.REPORTING_ITEM_QUICK_COMMENT_FORM_NAME}
hasImage={true}
fields={
  [
    {
      label: "Elected Members",
      name: "electedMemberComment",
      placeholder: "Elected Members comment"
    },
    {
      label: "Public",
      name: "publicComment",
      placeholder: "Public comment"
    },
    {
      label: "Management",
      name: "managementComment",
      placeholder: "Management comment"
    }
  ]
} />
</Grid>

<Grid item className={classnames("gridItem narrowMaxMediumWidthPart",classes.textRightStrong)} xs={12}>
{ onCancel ? <Button variant="text" size="small" onClick={() => onCancel()}>
 Cancel
</Button> : null } &nbsp; 
{ disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Update comments">{submitButton}</Tooltip> }
</Grid>

</Grid>
</div>
        
      
    </form>
</div>
  }
}


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.REPORTING_ITEM_QUICK_COMMENT_FORM_NAME, // a unique identifier for this form
  validate: validate,
})(ReportingItemQuickCommentForm)),formNames.REPORTING_ITEM_QUICK_COMMENT_FORM_NAME);


