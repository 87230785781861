import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import { Tooltip, Fab, Button, IconButton, DialogActions, Hidden } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import ShowActionsIcon from "@material-ui/icons/MoreVert";
import { Typography } from '@material-ui/core';
import PageHeading from '../PageHeading';
import BinderForm from '../forms/binder/form';
import ArrayUtil from '../../utils/ArrayUtil';
import objectPath from 'object-path';
import Masonry from 'react-masonry-component';
import BinderCard from './BinderCard';
import AppDialog from '../AppDialog';
import * as constants from "../../constants/constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from '@material-ui/icons/EditOutlined';
import BinderCategoryForm from '../../components/forms/binderCategory/form'
import ComponentLoading from '../ComponentLoading';

class BinderCategory extends React.Component {


  constructor(props){
    super(props);
    this.state={
      displayConfirmation: false,
      showLowResActions: false
    }
  }


  hideConfirmation = () => {
    this.setState({
      displayConfirmation: false
    });
  }

  renderConfirmationDialog = () => {
    const { category, handleDeleteBinderCategory } = this.props;
    let message = 'Please confirm you wish to remove the Binder Category "'+category.name+'"';

    return (<AppDialog
      warningStyle={true}
      title="Are you sure?"
    message={message}
    onClose={this.hideConfirmation}
    actions={
      <DialogActions>
        <Button variant="text" onClick={this.hideConfirmation}>Cancel</Button>
        <Button variant="contained" color="default" className="errorBtn" onClick={() => handleDeleteBinderCategory(category.id)}>Confirm</Button>
      </DialogActions>
    } />)
  }


  getEditedBinderIfInCategory = () => {
    const { 
      category, editedBinder
    } = this.props;
    let binderCatId = objectPath.get(editedBinder, 'binderCategoryId',undefined);
    let categoryId = this.getCategoryId();
    return (category && editedBinder && binderCatId === categoryId) ? editedBinder : null;
  }

  getInitialValues = () => {
    const { 
      category,
    } = this.props;
    const editedBinder = this.getEditedBinderIfInCategory();
    return editedBinder ? editedBinder : {binderCategoryId: objectPath.get(category,'id',undefined)};
  }

  renderCategoryActions = (binders, categoryId) => {
    const { 
      showLowResActions } = this.state;

      const { 
        category, 
        handleShowBinderCategoryFormById,
        handleShowBinderFormForCategory,
        showBinderFormCategoryId,
        handleToggleCategory
       } = this.props;

       const renderActionsRule = (categoryId !== undefined && showBinderFormCategoryId !== categoryId); // if it is uncategorized

    const RemoveBtn = renderActionsRule && binders.length < 1 ? 
          <Tooltip key="removeBtn" title={"Remove category "+category.name} aria-label={"Remove category "+category.name}>
            <IconButton size="small" onClick={() => this.setState({displayConfirmation: true})}>
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Tooltip> : null;

    const EditBtn = renderActionsRule ? <Tooltip key="editBtn" title="Edit Binder Category" aria-label="Edit Binder Category">
    <IconButton size="small" onClick={() => handleShowBinderCategoryFormById(categoryId)}>
        <EditIcon fontSize="small" />
    </IconButton>
    </Tooltip> : null;

      const AddBtn = renderActionsRule ? <Tooltip key="addBtn" title="Add new binder" aria-label="Add new binder">
              <Fab color="secondary" size="small" onClick={() => handleShowBinderFormForCategory(categoryId, null)}>
                  <AddIcon fontSize="small" />
              </Fab>
          </Tooltip> : null;

        const ToggleBtn = binders.length ? <IconButton size="small" key="toggle" onClick={() => handleToggleCategory(categoryId !== undefined ? categoryId : constants.UNCATEGORIZED_BINDER_CATEGORY_ID)}>
            { this.isOpen() ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" /> }
        </IconButton> : null;

      const showActionsBtn = <Tooltip title="Show actions" aria-label="Show actions" key="showActionsBtn">
      <IconButton size="small" onClick={() => this.setState({ showLowResActions: !showLowResActions })}>
          <ShowActionsIcon />
      </IconButton>
      </Tooltip>;
    
    
    return <div className="actionsWrap">
    <Hidden mdDown>
      { [ToggleBtn, RemoveBtn, EditBtn, AddBtn] }
    </Hidden>
    <Hidden lgUp>
      {
        showLowResActions ? [ToggleBtn, RemoveBtn, EditBtn, AddBtn] : [ToggleBtn, showActionsBtn]
      }
    </Hidden>
    </div>;

    
  }

  getCategoryId = () => {
    const { category } = this.props;
    return objectPath.get(category,'id',undefined);
  }

  isOpen = () => {
    const { unexpandedCategoryIds } = this.props;
    let categoryId = this.getCategoryId();
    categoryId = (categoryId !== undefined) ? categoryId : constants.UNCATEGORIZED_BINDER_CATEGORY_ID;
    return !unexpandedCategoryIds.includes(categoryId);
  }


    render() {
      const { displayConfirmation } = this.state;

      const { 
        classes, category, 
        handleShowBinderFormForCategory,
        handleSubmitBinderCategory,
        handleDeleteBinder,
        handleShowBinderCategoryFormById,
        handleSubmitBinder, 
        reportingItems, 
        reportOutputs,
        reportSchedules, 
        binderCategorySummary,
        userInfo, 
        expandedCardIds,
        showBinderFormCategoryId,
        editedCategory,
        editedCategoryId,
        editedBinderLoading,
        editedCategoryLoading,
        handleToggleCategory,
        toggleCardInfo  
      } = this.props;

      const masonryOptions = {
        itemSelector: '.masonry-item',
        columnWidth: '.masonry-item-sizer',
        percentPosition: true,
        horizontalOrder: true
      };

      const binders = objectPath.get(category,'binders',[]);

      //edited object
      const editedBinder = this.getEditedBinderIfInCategory();
      const editedBinderId = editedBinder ? editedBinder.id : null;
      let binderInitialValues = this.getInitialValues();

      let categoryId = this.getCategoryId();

      return (
        category ? <div className={classes.binderCategory}>
            <div className={classnames(classes.binderCategoryHeading, "fullWidthBgComponent", this.isOpen() ? "open" : "closed")}>
                    <Typography variant="h5" onClick={() => handleToggleCategory(categoryId !== undefined ? categoryId : constants.UNCATEGORIZED_BINDER_CATEGORY_ID)}>{category.name}</Typography>
                    { binders.length < 1 ? 
                    <Typography variant="body2" className="muted">No binders in category</Typography> 
                    :
                    !this.isOpen() ? 
                    <Typography variant="body2" className="muted">{binders.length} binder{binders.length > 1 ? 's' : ''} in category</Typography> 
                    : null }
                    { this.renderCategoryActions(binders, categoryId) }
                    
                    
            </div>
           
            {
              showBinderFormCategoryId === categoryId || editedBinder ? <div>
                <PageHeading heading={editedBinder ? "Edit binder "+editedBinder.name : "New binder for "+category.name} variantClass="secondary" actions={
                  <Button onClick={() => handleShowBinderFormForCategory(null, null)} color="default" className="muted">
                    Cancel
                  </Button>
                }/>
                { editedBinderLoading ? <ComponentLoading /> : <BinderForm 
                  className="secondaryForm fullWidthBgComponent"
                  proceedSubmit={handleSubmitBinder}
                  reportingItems={ArrayUtil.mapRIToValueLabel(objectPath.get(reportingItems,'content',[]))}
                  reportOutputs={ArrayUtil.mapIdNameToValueLabel(reportOutputs)}
                  reportSchedules={ArrayUtil.mapIdNameToValueLabel(reportSchedules)}
                  binderCategorySummary={ArrayUtil.mapIdNameToValueLabel(binderCategorySummary.filter(x => x.id))}
                  initialValues={binderInitialValues}
                  userInfo={userInfo}
                /> }
              </div>
              : null
            }

            {
              editedCategoryId === categoryId ? <div>
                <PageHeading heading={"Edit Binder Category "+(editedCategory ? editedCategory.name : "")} variantClass="secondary" actions={
                  <Button onClick={() => handleShowBinderCategoryFormById(null)} color="default" className="muted">
                    Cancel
                  </Button>
                }/>
                { editedCategoryLoading ? <ComponentLoading /> : <BinderCategoryForm 
                  className="secondaryForm fullWidthBgComponent"
                  proceedSubmit={handleSubmitBinderCategory}
                  initialValues={editedCategory}
                  reportOutputs={ArrayUtil.mapIdNameToValueLabel(reportOutputs)}
                /> }
              </div>
              : null
            }

       { binders.length && this.isOpen() ? <Masonry
                //ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
                className={'masonry-grid-wrap'} // default ''
                options={masonryOptions} // default {}
            >
            <div className="masonry-item-sizer"></div>
                {binders.map(( item, index )=> <div className="masonry-item" key={index}>
            <BinderCard 
            item={item} 
            editedBinderId={editedBinderId}
            categoryId={categoryId} 
            handleSubmitBinder={handleSubmitBinder}
            handleShowBinderFormForCategory={handleShowBinderFormForCategory}
            handleDeleteBinder={handleDeleteBinder}
            cardExpanded={expandedCardIds.includes(item.id)} 
            toggleCardInfo={toggleCardInfo} />
            </div>)}
            </Masonry> : null}

            { displayConfirmation ? this.renderConfirmationDialog() : null}

    </div> : null
  );
    }

  
}

BinderCategory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(BinderCategory);