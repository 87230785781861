import * as themeColors from "./constants/colors";
import * as themeFonts from "./constants/fonts";


import config from './constants/config';

import globalStyle from './components/global';
import rootStyle from './components/root';
import appLoadingStyle from './components/appLoading'
import logoStyle from './components/logo';
import menuStyle from './components/menu';
import activityStyle from './components/activity';
import pageHeadingStyle from './components/pageHeading';
import footerStyle from './components/footer';
import appBarStyle from './components/appBar';
import drawerStyle from './components/drawer';
import contentStyle from './components/content';
import cardStyle from './components/card';
import indicatorStyle from './components/indicator';
import snackbarStyle from './components/snackbar';
import tableStyle from './components/table';
import formStyle from './components/form';
import reportingItemStyle from './components/reportingItem';
import financialOverviewStyle from './components/financialOverview';
import fullscreendialogStyle from './components/fullscreenDialog';
import binderStyle from './components/binder';
import commentStyle from './components/comment';
import progressReportStyle from './components/progressReport';
import activitySummaryStyle from './components/activitySummary';
import filterStyle from './components/filter';
import timelineStyle from './components/timeline';




const styles = theme => {

  const themeConfig = config(theme);

  return {
    ...globalStyle(theme, themeConfig, themeColors, themeFonts),
     ...rootStyle(theme, themeConfig, themeColors, themeFonts),
     
     ...appLoadingStyle(theme, themeConfig, themeColors),
     ...logoStyle(theme, themeConfig, themeColors),
     ...menuStyle(theme, themeConfig, themeColors, themeFonts),
     ...activityStyle(theme, themeConfig, themeColors),
     ...pageHeadingStyle(theme, themeConfig, themeColors, themeFonts),
     ...footerStyle(theme, themeConfig, themeColors, themeFonts),
     ...appBarStyle(theme, themeConfig, themeColors),
     ...drawerStyle(theme, themeConfig, themeColors),
     ...contentStyle(theme, themeConfig, themeColors),
     ...cardStyle(theme, themeConfig, themeColors),
     ...indicatorStyle(theme, themeConfig, themeColors),
     ...snackbarStyle(theme, themeConfig, themeColors),
     ...tableStyle(theme, themeConfig, themeColors),
     ...formStyle(theme, themeConfig, themeColors, themeFonts),
     ...reportingItemStyle(theme, themeConfig, themeColors),
     ...financialOverviewStyle(theme, themeConfig, themeColors),
     ...fullscreendialogStyle(theme, themeConfig, themeColors),
     ...binderStyle(theme, themeConfig, themeColors),
     ...commentStyle(theme, themeConfig, themeColors),
     ...progressReportStyle(theme, themeConfig, themeColors),
     ...activitySummaryStyle(theme, themeConfig, themeColors),
     ...filterStyle(theme, themeConfig, themeColors),
     ...timelineStyle(theme, themeConfig, themeColors),
     

    };

}

export default styles;
