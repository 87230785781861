import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import * as reportingItemActions from "../../../actions/reportingItem";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import UrlBuilderUtil from "../../../utils/UrlBuilderUtil";
import * as constants from '../../../constants/constants';
import ReportingItemCommentaryFormWrapper from "../../../components/reportingItem/ReportingItemCommentaryFormWrapper";
import GoogleTagManagerUtil from "../../../utils/GoogleTagManagerUtil";
import * as externalDocumentActions from '../../../actions/externalDocuments';
import classnames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import ProjectDocumentsForm from '../../../components/forms/projectDocument/form'
import LinkIcon from '@material-ui/icons/Link';

import { Hidden, Button, Tooltip, Typography, IconButton, DialogTitle, DialogContent, Dialog } from "@material-ui/core";

class ReportingItemCommentary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      item: null,
      dataLoading: true,
      documents: [],
      showCommentaryModal: false,
    }
  }

  componentDidMount() {
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
    );
  }

  mountItem(){
    const { match, history, actions } = this.props;
    const _this = this;
    var reportingItemId = objectPath.get(match, "params.reportingItemId");

    if (reportingItemId) {

      return actions.getReportingItemCommentary(reportingItemId).then(response => {
        let reportingItemCommentary = objectPath.get(response, "data.data");

        if (reportingItemCommentary) {
          return _this.setState({ 
            item: reportingItemCommentary,
            externalDocuments: objectPath.get(reportingItemCommentary, 'externalDocuments', [])
          });
        } else {
          return history.push("/404");
        }

      });

    } else {
      return new Promise((resolve) => {
        _this.setState({ item: null }, resolve);
      });
    }
  }

  handleResponse = response => {
    const { enqueueSnackbar, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar,
         this.getItemName()+" commentary updated"
         );
      setImmediate(() => {
        history.push(UrlBuilderUtil.getDashboard());
      });
    }
  }

  onMainTabClick = (event,newTabId) => {
    const { history } = this.props;
    var projectId = this.getRelatedProjectId();
    if(newTabId === "summary" && projectId){
      history.push(UrlBuilderUtil.getProgressReport(projectId));
    }
  }

  handleReportingItemCommentary = values => {
    const { actions, match } = this.props;
    var reportingItemId = objectPath.get(match, "params.reportingItemId");
    if(
      objectPath.get(values,'dueCommentaryState') === constants.REPORTING_ITEM_COMMENTARY_STATE_COMMENTARYDUE
      ||
      objectPath.get(values,'openCommentary') === true
      ){
      return  actions.postReportingItemCommentary(values).then(response => this.handleResponse(response));
    } else {
      //submit QC
      return  actions.postReportingItemQuickCommentary(reportingItemId, values).then(response => {
        GoogleTagManagerUtil.trackQuickComment({id: reportingItemId, addedFrom: 'Reporting item commentary', reportingItemName: this.getItemName()});
        return this.handleResponse(response);
      });
    }
  };

  getRelatedProjectId = () => {
    const { item } = this.state;
    return objectPath.get(item, 'projectId', null);
  }

  getItemName = () => {
    const { item } = this.state;
    const projectId = this.getRelatedProjectId()
    return projectId ? item.projectName : item ? item.name : ''
  }

  hideModal = () => {
    this.setState({
      showCommentaryModal: false
    }, () => { });
  }

  handleCreateDocumentCallback = (data) => {
    const documents = data.slice();

    this.setState({
      externalDocuments: documents
    });

    this.hideModal();
  }

  showModal = () => {
    this.setState({
      showCommentaryModal: true,
    }, () => { });
  }

  handleDeleteCallback = (id) => {
    const { externalDocuments } = this.state;

    const documents = externalDocuments.slice().filter((item) => item.id !== id);
    this.setState({
      externalDocuments: documents
    });
  };

  render() {
    const { classes, actions, enqueueSnackbar } = this.props;
    var projectId = this.getRelatedProjectId();
    const { item, dataLoading, externalDocuments, showCommentaryModal } = this.state;

    const mapUrl = objectPath.get(item, 'projectMapUrl', '');
    const canEdit = objectPath.get(item, 'editable', false);

    return (
      <div>
        <PageHeading 
          mutedHeading={projectId ? '#'+projectId : null} 
          heading={dataLoading ? "loading ..." : this.getItemName()} 
          subHeading={dataLoading ? "" : item.reportingItemDescription} 
          mapUrl={mapUrl}
          showPinpointMapIcon={mapUrl !== ''}
          externalDocuments={externalDocuments}
          canEdit={canEdit}
          onDeleteDocumentCallback={this.handleDeleteCallback}
          apiActions={actions}
          actions={
          <div>
            <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getDashboard()} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 

      <div>

        <Tabs classes={{root: classes.tabsWrapper}} indicatorColor="primary" value={"commentary"} onChange={this.onMainTabClick}>
          { projectId ?  <Tab label="Status" value="summary" classes={{root: classes.tabBtn}} /> : null }
          <Tab label="Commentary" value="commentary" classes={{root: classes.tabBtn}} />
        </Tabs>

        { canEdit ? 
          <Hidden mdDown>
            
            <div className={classnames("hideForPrint", classes.textRightStrong, classes.summaryActionsWrap)}>
              { canEdit ?
                <Tooltip title="Link Document(s)">
                  <Button color="primary" 
                    size="small" 
                    variant="outlined" 
                    target="_blank" 
                    onClick={this.showModal}><LinkIcon /> &nbsp; Link Document(s)</Button>
                </Tooltip>
                : null
              }
            </div>

          </Hidden> : null 
        }

        <ReportingItemCommentaryFormWrapper  
          includeOpenCommentary={true}
          handleReportingItemCommentarySubmit={this.handleReportingItemCommentary} 
          reportingItemCommentary={item}
        />

      </div>
      }
      
        <div>
          <Dialog fullWidth={true} maxWidth={"md"}
            onClose={this.hideCommentaryModal} open={showCommentaryModal}
            className={classnames("dialogWithArrows", classes.formDialogWrap)}
            classes={{ paper: "paper" }}>

            <DialogTitle className="formDialogTitle" onClose={this.hideModal} disableTypography={true}>
              {
                projectId ? <Typography key="mutedHeading" variant="h5" className="muted"><strong>#{projectId}</strong></Typography> :  null
              }
              <Typography variant="h5">
                <strong>{dataLoading ? "loading ..." : this.getItemName()}</strong>
                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Close dialog">
                  <IconButton size="small" onClick={this.hideModal}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </DialogTitle>

            <DialogContent className={ "dialogFormContent" }>
              <ProjectDocumentsForm
                currentProject={item}
                onCancel={this.hideModal}
                actions={actions}
                snackbar={enqueueSnackbar}
                onCreateDocumentCallback={this.handleCreateDocumentCallback}
              />
            </DialogContent>

          </Dialog>
        </div>

      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators({
        ...reportingItemActions,
        ...externalDocumentActions
      },
      dispatch
    )
  });

export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, {
      withTheme: true
    })(ReportingItemCommentary)
  )
));