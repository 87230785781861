//import objectPath from 'object-path';
import ValidationUtil from '../../../../utils/ValidationUtil';

const validate = (values, {registeredFields} = {}) => {


  let errors = {};


  const requiredFields = [
    'managementComment',
    'electedMemberComment',
    'publicComment'
  ];


  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  errors = ValidationUtil.checkImageField(['image'],values,errors,10485760);




  return errors;
};

export default validate;
