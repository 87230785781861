import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from '../../theme/styles';
//import classnames from 'classnames';
import { CardHeader, Grid, Button, Hidden } from '@material-ui/core';
import DateUtil from '../../utils/DateUtil';
import objectPath from 'object-path';
import classnames from 'classnames';
import UsersUtil from '../../utils/UsersUtil';
import RichTextRenderer from '../RichTextRenderer';
import ImageList from '../ImageList';


class GroupCommentCard extends React.Component {

    constructor(props){
        super(props);
        this.state={
          showAll: objectPath.get(props,'showAll', false)
        }
      }

    renderUserName = () => {
      let { objectPathUserId, comment } = this.props;
      objectPathUserId = objectPathUserId ? objectPathUserId : 'commentedUserId';
      let userName = UsersUtil.getNameFromStoreById(objectPath.get(comment,objectPathUserId), null);
      return userName ? <small className="noWrap">{userName}</small> : '';
    }

    renderCreatedDate = () => {
      let { objectPathCreatedDate, comment } = this.props;
      objectPathCreatedDate = objectPathCreatedDate ? objectPathCreatedDate : 'createdDate';
      let createdDate = objectPath.get(comment,objectPathCreatedDate, null);
      return createdDate ? <strong>{DateUtil.basicDate(createdDate)}</strong> : null;
    }


    render() {
        const { heading, fields, classes, comment, additionalClass, hasImage, nonExpandable  } = this.props;
        const { showAll } = this.state;
        
        let { showMoreText, showLessText } = this.props;
        showMoreText = showMoreText ? showMoreText : "+ show more";
        showLessText = showLessText ? showLessText : "- show less";

    
        const noFurtherProgress = (objectPath.get(comment, 'noFurtherProgress') === true);
        const showExpandableBtn = (noFurtherProgress || nonExpandable === true) ? false : true;

        return (
          
            comment ? <Card className={classnames("commentCard",classes.audienceCommentCard,additionalClass)}>
            { heading ? <CardHeader title={heading} className={classnames(classes.audienceCommentCardHeader,additionalClass,'cardHeader')}></CardHeader> : null }
            <CardContent className={"audienceCommentCardContent"}>
            
            { fields.map((x,index) => {
                return (index < 1 || showAll) ? <Grid container className={"gridContainer gridContainer"+index} key={index}>
                {index === 0 ? <Grid item className="gridItem" xs={5}>
                {this.renderCreatedDate()} <Hidden smUp><br/></Hidden><Hidden xsDown>&nbsp;</Hidden> {this.renderUserName()}
                </Grid> : null}
                <Grid item xs={index === 0 ? 7 : 12} className={classnames(classes.textRightStrong,"gridItem")}>
                <strong>{x.label}</strong>
                </Grid>
                <Grid item xs={12} className="gridItem">
                  { noFurtherProgress ? <small>No futher progress</small> : <RichTextRenderer text={objectPath.get(comment,x.name)} /> }
                </Grid>
                </Grid> : null
            })
            }
            { showExpandableBtn ? <Grid container><Grid item xs={12} className="gridItem">
                <Button variant="text" size="small" onClick={()=> this.setState({showAll:!showAll})}>{showAll ? showLessText : showMoreText}</Button>
                </Grid>
                </Grid> : null }
                { hasImage ? <Grid container><Grid item xs={12} className="gridItem"><ImageList disabled={true} files={comment.document ? [comment.document] : null} /></Grid></Grid> : null }
            </CardContent>
            
        </Card> : null
        );
      }
}



export default withStyles(styles, { withTheme: true })(GroupCommentCard);