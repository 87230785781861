import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import GroupIcon from '@material-ui/icons/GroupOutlined';
import PositionsUtil from '../utils/PositionsUtil';

function PositionIconName(props) {

  const { positionIds } = props;

  let icon = <PersonIcon className="personIcon" fontSize="small" key="icon" />;
  let names;

  if (Array.isArray(positionIds)){
      if(positionIds.length > 1){
        icon = <GroupIcon className="personIcon" fontSize="small" key="icon"/>;
      }
      names = <span className="personName" key="name">{positionIds.map(x =>  PositionsUtil.getUsernameFromStoreById(x)).join(', ')}</span>;
  } else {
    names = <span className="personName" key="name">{PositionsUtil.getUsernameFromStoreById(positionIds)}</span>;
  }

  return <span className="positionIconName position">{[icon, names]}</span>;
}


export default withStyles(styles, { withTheme: true })(PositionIconName);