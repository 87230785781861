import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";

export function getStrategicPlans() {
  return function(dispatch) {
    ConsoleUtil.log('getStrategicPlans called');
    return reportingClient
      .get(`StrategicPlans`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_STRATEGIC_PLANS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_STRATEGIC_PLANS))
      );
  };
}
