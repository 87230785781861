import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import ReportScheduleForm from "../../../components/forms/reportSchedule/form";
import * as reportSchedules from "../../../actions/reportSchedule";
import * as projects from "../../../actions/project";
import * as formNames from "../../../constants/forms";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import FormatterUtil from "../../../utils/FormatterUtil";

const entityName = "ReportSchedule";
const entityListUrl = "/admin/report-schedules/";
const entityFormName = formNames.REPORT_SCHEDULE_FORM_NAME;


class EditReportSchedule extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true,
      project: null,
      displayConfirmation: false
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }


  mountItem(){
    const { actions, actionType, match } = this.props;
    const _this = this;
    var itemId = objectPath.get(match, "params.id");
    if(actionType === "edit" && itemId){
      return actions['get'+entityName](itemId).then( response => {
        return _this.setState({
          item: objectPath.get(response, "data.data"),
          linkedProjectId: null
        })
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  handleResponse = response => {
    const { enqueueSnackbar, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar);
      setImmediate(() => {
        history.push(entityListUrl);
      });
    }
  }

  handleSubmit = values => {
    const { actions, actionType, match } = this.props;
    let action = actions['post'+entityName];
    if(actionType !== "create") {
      action = actions['put'+entityName];
      values.id = objectPath.get(match, 'params.id');
    }
    //has to return promise so submitting flag works!
    return action(values).then(response => this.handleResponse(response));
  };


  render() {
    const { actionType } = this.props;
    const { item, dataLoading } = this.state;
    

    return (
      <div>
        <PageHeading heading={(actionType === "create" ? "New" : "Update")+" "+FormatterUtil.camelToNormalForm(entityName)} actions={
          <div>
            <HeadingBackBtn redirectToUrl={entityListUrl} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 
      <ReportScheduleForm 
        proceedSubmit={this.handleSubmit} 
        initialValues={item}

      /> }
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      
      ...reportSchedules,
      ...projects,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditReportSchedule)
));