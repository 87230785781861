import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import { Typography } from '@material-ui/core';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function FullScreenDialog(props) {
    const { closeDialog, children, classes, showBar, title } = props;

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={closeDialog}
            TransitionComponent={Transition}
            className={classes.fullscreenDialogWrap}
        >
            {showBar === false ? null : (
                <AppBar>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={closeDialog}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                        {title ? (
                            <Typography variant="body1" color="inherit">
                                {title}
                            </Typography>
                        ) : null}
                    </Toolbar>
                </AppBar>
            )}
            <div className={classes.fullscreenDialogContent}>
                <div>
                    <img {...children.props} />
                </div>
            </div>
        </Dialog>
    );
}

export default withStyles(styles)(FullScreenDialog);
