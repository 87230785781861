import React from "react";
import { reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../theme/styles';
import classnames from 'classnames';
import * as formNames from '../../../../constants/forms';
import objectPath from 'object-path';
import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import TickIcon from "@material-ui/icons/Check";
import CancelIcon from '@material-ui/icons/CloseOutlined';

import { Button, Tooltip } from "@material-ui/core";
import warnAboutUnsavedForm from "../../../../hoc/withWarnAboutUnsavedChanges";
import * as constants from '../../../../constants/constants';
import CommentPerGroup from '../../../commentary/CommentPerGroup';


class ReportingItemCommentaryReviewForm extends React.Component {




  


  render () {
    const {
      handleSubmit,
      //pristine,
      section,
      classes,
      proceedSubmit,
      heading,
      initialValues,
      toggleEditableComment
    } = this.props;

    

    return  <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classnames(classes.customForm)}>
    <div className="commentOuterWrapperWithActions narrowMaxMediumWidthPart">
    <CommentPerGroup
      expandable={false}
      copyable={true}
      heading={heading}
      formName={formNames.REPORTING_ITEM_COMMENTARY_REVIEW_FORM_NAME}
      hasImage={section === constants.QUICK_COMMENTS_KEY ? true : false}
      fields={
        [
          {
            label: "Elected Members",
            name: "electedMemberComment"
          },
          {
            label: "Public",
            name: "publicComment"
          },
          {
            label: "Management",
            name: "managementComment"
          }
        ]
      } />
      {
        objectPath.get(initialValues, 'noFurtherProgress') !== true ? 
        <div className={classnames("commentActions maxWidthPart narrowMaxMediumWidthPart",classes.textRightStrong)}>
          <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Cancel"><Button variant="outlined" size="small" className="toggleBtn"
          color={"default"} 
          onClick={() => toggleEditableComment(objectPath.get(initialValues, 'id'))}><CancelIcon fontSize="small" /></Button></Tooltip>
          <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Save"><Fab className="fab" color="secondary" type="submit" size="small"><TickIcon fontSize="small" /></Fab></Tooltip>
        </div> : null
      }
      </div>
    </form>
  }
}


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.REPORTING_ITEM_COMMENTARY_REVIEW_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  validate: validate,
})(ReportingItemCommentaryReviewForm)),formNames.REPORTING_ITEM_COMMENTARY_REVIEW_FORM_NAME);


