import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import {  Button } from '@material-ui/core';
import classnames from 'classnames';



    class ShowHideContent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                showMore: props.showMore,
            }
          }
       


        render() {
            const { children, classes, showMoreText, showLessText, additionalClasses } = this.props;

            return children ? <div onClick={()=>this.setState({showMore:!this.state.showMore})} className={classnames(classes.showHideComponent, additionalClasses, (this.state.showMore === false && 'compact'))}>
            { this.state.showMore ? children : null }
            
            <div className="btnWrap">
            <Button size="small" color="default">{this.state.showMore ? showLessText : showMoreText }</Button>
            </div>
        </div> : null
        }
    }


export default withStyles(styles, { withTheme: true })(ShowHideContent);