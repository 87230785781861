import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import styles from '../../theme/styles';
import BinderShareForm from '../forms/binder/share/form';
import {
    DialogTitle,
    Typography,
    DialogContent,
    Dialog,
} from '@material-ui/core';
import ComponentLoading from '../ComponentLoading';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../../actions/users';
import * as binderActions from '../../actions/binder';

class BinderShareDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoading: true,
        };
    }

    componentDidMount() {
        const { actions, item } = this.props;
        Promise.all([
            actions.getBinder(item.id),
            actions.getKeycloakUsers(),
        ]).then(() =>
            this.setState({
                dataLoading: false,
            })
        );
    }

    getKeycloakUsersOptions = () => {
        let { keycloakUsers } = this.props;
        return keycloakUsers.map((x) => {
            return {
                label: x.firstName + ' ' + x.lastName + ' <' + x.email + '>',
                value: x.id,
            };
        });
    };

    render() {
        const { item, onClose, binder, handleSubmitBinder } = this.props;
        const { dataLoading } = this.state;

        return (
            <Dialog
                onClose={onClose}
                open={true}
                classes={{
                    paper: 'dialogPaperWithForm',
                }}
            >
                <DialogTitle
                    className="formDialogTitle"
                    onClose={() => onClose()}
                    disableTypography={true}
                >
                    <Typography variant="h5">
                        <strong>{item.name}</strong>
                    </Typography>
                    <Typography variant="body2" className="muted">
                        binder sharing
                    </Typography>
                </DialogTitle>
                <DialogContent className="dialogFormContent">
                    {dataLoading ? (
                        <div>
                            <ComponentLoading />
                        </div>
                    ) : (
                        <BinderShareForm
                            binder={item}
                            initialValues={binder}
                            onCancel={onClose}
                            users={this.getKeycloakUsersOptions()}
                            proceedSubmit={handleSubmitBinder}
                        />
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        keycloakUsers: state.keycloakUsers,
        binder: state.binder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
                ...binderActions,
            },
            dispatch
        ),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles, { withTheme: true })(BinderShareDialog))
);
