import objectPath from 'object-path';
import * as constants from '../constants/constants';
import ReportingItemsUtil from './ReportingItemsUtil';

export default class BinderUtil {



  static adjustBinderName(name){
    return name;// && name.toLowerCase().indexOf('binder') === -1 ? name+" Binder" : name;
  }

  static isBinderInReviewMode(binderCurrentState){
    return [constants.BINDER_STATE_REVIEWDUE, constants.BINDER_STATE_COMPLETE].includes(binderCurrentState);
  }

  static isDetailReady(binderSummary){
    let currentState = objectPath.get(binderSummary, 'currentState', '').toLowerCase();
    return [constants.BINDER_STATE_COMPLETE,constants.BINDER_STATE_REVIEWDUE].includes(currentState) 
  }

  static getRIcompleteVSCount(binderSummary) {
    let reportingItems = objectPath.get(binderSummary, 'reportingItems', []);
    let currentState = objectPath.get(binderSummary, 'currentState', '').toLowerCase();
    return BinderUtil.isDetailReady(binderSummary)
    ? 
    BinderUtil.getBinderCurrentStateText(currentState) 
    : 
    ReportingItemsUtil.getRIcompleteCount(reportingItems,constants.DASHBOARD_CARD_TYPE_BINDER) + '/' + reportingItems.length;
  }

  static getBinderCurrentStateText(currentState){
    currentState = currentState.toLowerCase();//double check if lower case
        switch(currentState) {
          case constants.BINDER_STATE_NOTDUE:
            return constants.BINDER_STATE_NOTDUE_TEXT;
          case constants.BINDER_STATE_REPORTINGDUE:
            return constants.BINDER_STATE_REPORTINGDUE_TEXT;
          case constants.BINDER_STATE_REVIEWDUE:
            return constants.BINDER_STATE_REVIEWDUE_TEXT;
          case constants.BINDER_STATE_COMPLETE:
            return constants.BINDER_STATE_COMPLETE_TEXT;
          default:
            return '';
        }
  }

  static getBinderStateClasses(item) {
    let classNames = ['binder'];

    if (item.responsible) {
      classNames.push('responsible');
    }
    if(item.currentState){
      classNames.push('currentState-'+item.currentState);
    }
    if(item.dueState){
      classNames.push('dueState-'+item.dueState);
    }

    return classNames.join(' ');
  }

  




}
