import React from 'react';
import objectPath from 'object-path';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Grid, Hidden } from '@material-ui/core';
import classnames from 'classnames';
import { withRouter } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import TickIcon from "@material-ui/icons/Check";
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import NonFormNativeSelect from '../inputs/NonFormNativeSelect';
import StoreUtil from '../../utils/StoreUtil';

class ActivitySummarySnapshotFilter extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedYear: objectPath.get(props,'selectedYear',null),
            selectedPeriod: objectPath.get(props,'selectedPeriod',null),
        }
      }

      hasFilterChanged = () => {
        const { selectedYear, selectedPeriod } = this.state;
        const { match } = this.props;
        var urlYear = objectPath.get(match, "params.year", this.props.selectedYear);
        var urlPeriod = objectPath.get(match, "params.period", this.props.selectedPeriod);
        return (selectedYear.toString() !== urlYear.toString() || selectedPeriod.toString() !== urlPeriod.toString());
      }

      redirectToCurrentFilter = () => {
          const { history, match } = this.props;
          const { selectedYear, selectedPeriod } = this.state;

          var activitySummaryId = objectPath.get(match, "params.activitySummaryId", null);

          history.push(UrlBuilderUtil.getActivitySnapshot(activitySummaryId, selectedYear, selectedPeriod));
      }

    


    render() {
        const { classes } = this.props;
        const { selectedYear, selectedPeriod } = this.state;


        const yearOptions = StoreUtil.getValueByKey('reportingParameters.uiActivityReportingYears',[]);
        const periodOptions = StoreUtil.getValueByKey('reportingParameters.uiActivityReportingPeriods',[]);

        return <div className={classnames(classes.ASSnapshotFilterWrap,'maxWidthPart narrowMaxWidthPart')}>
            <Grid container className="gridContainer">
                <Grid item xs={6} sm={4} className="gridItem">
                <NonFormNativeSelect 
                label="Reporting Year"
                value={selectedYear}
                dataOptions={yearOptions.map(x=> {return {value: x.toString(), label: x.toString()}})}
                onChange={(e) => this.setState({selectedYear: e.target.value})} />
                </Grid>
                <Grid item xs={6} sm={4} className="gridItem">
                <NonFormNativeSelect 
                label="Period"
                value={selectedPeriod}
                dataOptions={periodOptions.map(x=> {return {value: x.toString(), label: x.toString()}})}
                
                onChange={(e) => this.setState({selectedPeriod: e.target.value})} />
                </Grid>
                <Grid item xs={12} sm={4} className={classnames("gridItem",classes.textRightOnlyXs)}>
                <Hidden xsDown><br /></Hidden>
                <Fab color="secondary" className="filterConfirmBtn" size="small" disabled={this.hasFilterChanged() ? false : true} onClick={()=> this.redirectToCurrentFilter()}><TickIcon /></Fab>
                </Grid>

                </Grid>
            
            </div>;
    }

}


export default withRouter(withStyles(styles, { withTheme: true })(ActivitySummarySnapshotFilter));