import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageHeading from '../../../components/PageHeading';
import * as activitySummaryActions from '../../../actions/activitySummary';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import ComponentLoading from '../../../components/ComponentLoading';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import UrlBuilderUtil from '../../../utils/UrlBuilderUtil';
import ActivitySummaryResultsSection from '../../../components/activitySummary/ActivitySummaryResultsSection';
import ListingTable from '../../../components/ListingTable';
import HeadingBackBtn from '../../../components/buttons/HeadingBackBtn';
import StrategicPlansUtil from '../../../utils/StrategicPlansUtil';
import { Typography, Hidden } from '@material-ui/core';
import GroupCommentCard from '../../../components/commentary/GroupCommentCard';
import StoreUtil from '../../../utils/StoreUtil';
import ActivitySummarySnapshotFilter from '../../../components/activitySummary/ActivitySummarySnapshotFilter';
import SystemThresholdsTable from '../../../components/activitySummary/SystemThresholdsTable';
import FullScreenDialog from '../../../components/FullScreenDialog';
import ViewProgressReport from '../progressReport/ViewProgressReport';
import ActivitySummaryUtil from '../../../utils/ActivitySummaryUtil';

class ActivitySummarySnapshot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activitySummaryReview: null,
            dataLoading: true,
            dialogProjectId: null,
        };
    }

    componentDidUpdate(prevProps) {
        // reload data on url change
        if (this.props.location !== prevProps.location) {
            this.setState({
                dataLoading: true,
            });
            this.reloadData();
        }
    }

    componentDidMount() {
        this.reloadData();
    }

    viewProgressReportById = (projectId) => {
        this.setState({
            dialogProjectId: projectId,
        });
    };

    reloadData = () => {
        const { match } = this.props;
        var { year, period } = this.getSelectedPeriod();
        var activitySummaryId = objectPath.get(
            match,
            'params.activitySummaryId',
            null
        );
        Promise.all([
            this.mountActivityReview(activitySummaryId, year, period),
        ]).then(() =>
            Promise.all([]).then(() =>
                this.setState({
                    dataLoading: false,
                })
            )
        );
    };

    mountActivityReview(activitySummaryId, year, period) {
        // for menu and page state purposes and based on condition load AS review
        const { actions, history } = this.props;

        const _this = this;
        if (activitySummaryId) {
            return actions
                .getActivitySummaryReview(activitySummaryId, year, period)
                .then((response) => {
                    let activitySummaryReview = objectPath.get(
                        response,
                        'data.data'
                    );

                    if (activitySummaryReview) {
                        return _this.setState({
                            activitySummaryReview,
                        });
                    } else if (
                        objectPath.get(response, 'data.status') !== 204
                    ) {
                        return history.push('/404');
                    }
                });
        } else {
            return new Promise((resolve) => {
                _this.setState(
                    {
                        activitySummaryReview: null,
                    },
                    resolve
                );
            });
        }
    }

    getHeadingText = () => {
        const { activitySummaryReview } = this.state;
        const { match } = this.props;
        var activitySummaryId = objectPath.get(
            match,
            'params.activitySummaryId',
            null
        );
        return activitySummaryReview
            ? objectPath.get(activitySummaryReview, 'name')
            : StrategicPlansUtil.getActivityNameByReportingApiId(
                  activitySummaryId
              );
    };

    getSelectedPeriod() {
        const { match } = this.props;

        var year = objectPath.get(match, 'params.year', null);
        var period = objectPath.get(match, 'params.period', null);
        if (year && period) {
            return { year, period };
        }
        return ActivitySummaryUtil.getActivityReportPeriod(
            StoreUtil.getValueByKey('reportingParameters.currentReportingYear'),
            StoreUtil.getValueByKey(
                'reportingParameters.currentReportingPeriod'
            )
        );
    }

    renderActivitySummaryReview = () => {
        const { activitySummaryReview } = this.state;

        const nonFinancialResults = objectPath.get(
            activitySummaryReview,
            'nonFinancialResults',
            []
        );
        const financialResults = objectPath.get(
            activitySummaryReview,
            'financialResults',
            []
        );
        const activitySummaryReport = objectPath.get(
            activitySummaryReview,
            'activitySummaryReport',
            null
        );
        const aAMcomment = objectPath.get(
            activitySummaryReport,
            'areaManagerNonFinancialComment',
            null
        );
        const aMcomment = objectPath.get(
            activitySummaryReport,
            'nonFinancialComment',
            null
        );

        var { year, period } = this.getSelectedPeriod();

        return activitySummaryReview ? (
            <div>
                <div className="maxWidthPart">
                    <ActivitySummarySnapshotFilter
                        selectedPeriod={period}
                        selectedYear={year}
                    />

                    {aAMcomment ? (
                        <GroupCommentCard
                            comment={activitySummaryReport}
                            objectPathUserId="areaManagerCommentedUserId"
                            objectPathCreatedDate="areaManagerCommentedDate"
                            showAll={true}
                            nonExpandable={true}
                            fields={[
                                {
                                    label: 'Non-financial',
                                    name: 'areaManagerNonFinancialComment',
                                },
                                {
                                    label: 'Financial',
                                    name: 'areaManagerFinancialComment',
                                },
                            ]}
                        />
                    ) : aMcomment ? (
                        <GroupCommentCard
                            comment={activitySummaryReport}
                            objectPathUserId="commentedUserId"
                            objectPathCreatedDate="commentedDate"
                            showAll={true}
                            nonExpandable={true}
                            fields={[
                                {
                                    label: 'Non-financial',
                                    name: 'nonFinancialComment',
                                },
                                {
                                    label: 'Financial',
                                    name: 'financialComment',
                                },
                            ]}
                        />
                    ) : (
                        <Typography variant="body2" color="error">
                            <br />
                            <br />
                            No financial / non-financial summary comments
                            available.
                        </Typography>
                    )}

                    <ActivitySummaryResultsSection
                        isEmpty={nonFinancialResults.length < 1}
                        heading={`Non Financial Results (${year}/${period})`}
                        emptyText="No results"
                    >
                        <ListingTable
                            items={nonFinancialResults}
                            rowClassName="ActivitySummaryNonFinancialResult"
                            viewProgressReportById={this.viewProgressReportById}
                            additionalClasses="activitySummaryResultsSection"
                        />
                    </ActivitySummaryResultsSection>

                    <ActivitySummaryResultsSection
                        isEmpty={financialResults.length < 1}
                        heading={`Financial Results (${year}/${period})`}
                        emptyText="No results"
                    >
                        <ListingTable
                            items={financialResults}
                            rowClassName="ActivitySummaryFinancialResult"
                            additionalClasses="activitySummaryResultsSection"
                        />
                    </ActivitySummaryResultsSection>

                    <Hidden smDown>
                        <SystemThresholdsTable />
                    </Hidden>
                </div>
            </div>
        ) : (
            <div>
                <div className="maxWidthPart">
                    <br />
                    <br />
                    <Typography variant="body2" color="error">
                        This Activity does not have any linked Reporting items.
                    </Typography>
                </div>
            </div>
        );
    };

    render() {
        const { dataLoading, dialogProjectId } = this.state;

        return (
            <div>
                <PageHeading
                    heading={
                        dataLoading ? 'loading ...' : this.getHeadingText()
                    }
                    actions={
                        <HeadingBackBtn
                            redirectToUrl={UrlBuilderUtil.getScorecard(true)}
                        />
                    }
                />

                {dataLoading ? (
                    <ComponentLoading />
                ) : (
                    this.renderActivitySummaryReview()
                )}
                {dialogProjectId ? (
                    <FullScreenDialog
                        closeDialog={() => this.viewProgressReportById(null)}
                        showBar={true}
                    >
                        <ViewProgressReport
                            dialogProjectId={dialogProjectId}
                            isInDialog={true}
                            onBackBtn={() => this.viewProgressReportById(null)}
                        />
                    </FullScreenDialog>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            ...activitySummaryActions,
        },
        dispatch
    ),
});

export default withRouter(
    withSnackbar(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(withStyles(styles, { withTheme: true })(ActivitySummarySnapshot))
    )
);
