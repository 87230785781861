
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import * as constants from '../../constants/constants';
import { Button, Tooltip } from '@material-ui/core';
import MailtoIcon from '@material-ui/icons/EmailOutlined';
import UsersUtil from '../../utils/UsersUtil';
import DateUtil from '../../utils/DateUtil';
import objectPath from 'object-path';
import UiHelperUtil from '../../utils/UiHelperUtil';

function MailtoAuthorButton(props) {

  const { comment, reportingItem, sectionTitle } = props;

    const mailToNewLine = "%0D%0A";
    const userId = objectPath.get(comment,'commentedUserId');
    const userEmail = UsersUtil.getEmailFromStoreById(userId);

    if(!userEmail) {
      return <small>User email not found</small>;
    }
    if(reportingItem === null) {
      return <small>RI not found</small>;
    }

    

    const emailSubject = "Project Review Query: "+objectPath.get(reportingItem,'name') + 
    (objectPath.get(reportingItem,'projectId') ? " #"+objectPath.get(reportingItem,'projectId') : '');

    let emailBody = [];
    emailBody.push(sectionTitle+mailToNewLine);//Comment type
    emailBody.push(DateUtil.basicDate(objectPath.get(comment,'createdDate')));//Created date
    emailBody.push(UsersUtil.getNameFromStoreById(userId)+mailToNewLine);//Author
    emailBody.push("Elected members : "+mailToNewLine+UiHelperUtil.stripHtml(objectPath.get(comment,'electedMemberComment',''), true));//elected members
    
    emailBody.push(mailToNewLine+"Public : "+mailToNewLine+UiHelperUtil.stripHtml(objectPath.get(comment,'publicComment',''), true));//public
    
    emailBody.push(mailToNewLine+"Management : "+mailToNewLine+UiHelperUtil.stripHtml(objectPath.get(comment,'managementComment',''), true));//Management

    let mailToLink = "mailto:"+userEmail+"?subject="+emailSubject+"&body="+emailBody.join(mailToNewLine);


    return <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Email author"><Button variant="outlined" size="small"
    color="default" 
    href={mailToLink}
    >
      <MailtoIcon fontSize="small" />
    </Button></Tooltip>
}


export default withStyles(styles, { withTheme: true })(MailtoAuthorButton);