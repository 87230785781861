import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import { Tooltip, IconButton} from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";


class ActivitySummaryResultsSection extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        }
    }



    renderCategoryActions = () => {
        const { shown } = this.state;
        const { isEmpty } = this.props;
        const ToggleBtn = !isEmpty ? <Tooltip key="toggleBtn" title="Show / hide section" aria-label="Show / hide section">
            <IconButton size="small" onClick={() => this.setState({ shown: !shown })}>
                {shown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </Tooltip> : null;

        return <div className="actionsWrap">

            {[ToggleBtn]}

        </div>;


    }



    render() {
        const { shown } = this.state;
        const { children, heading, emptyText, classes, isEmpty } = this.props;

        return (
            <div className={classes.actionSummaryResults}>
                <div className={classnames(classes.actionSummaryResultsHeading, shown ? "open" : "closed")}>
                    <Typography variant="h5" onClick={() => this.setState({ shown: !shown })}>{heading}</Typography>
                    {isEmpty ? <Typography variant="body2" className="muted">{emptyText}</Typography> : null}
                        { this.renderCategoryActions() }
                </div>
                { (!isEmpty && shown) ? children : null }

            </div>
        );
    }


}


export default withStyles(styles, { withTheme: true })(ActivitySummaryResultsSection);