import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import InfoTextUtil from '../../utils/InfoTextUtil';

export default ({ label, inputName, helpText, touched, error, formName }) => {
    const infoTextKey = (formName + '.' + inputName).toLowerCase(); //lower case server force first letter of object property to lowercase so that is way
    const tooltipText = InfoTextUtil.getInfoTextByInputName(
        infoTextKey,
        helpText
    );

    return label && label.length ? (
        <Typography
            variant="caption"
            className="labelWrap"
            color={touched && error ? 'error' : 'inherit'}
        >
            <label htmlFor={inputName} infotextkey={infoTextKey}>
                {label}
            </label>{' '}
            {tooltipText ? (
                <Tooltip title={tooltipText}>
                    <InfoIcon />
                </Tooltip>
            ) : null}
        </Typography>
    ) : null;
};
