import React from "react";
import { FormControl, NativeSelect } from "@material-ui/core";
import LabelTooltip from "./LabelTooltip";

export default ({
  label,
  dataOptions,
  value,
  onChange
}) => {


  return (
    <FormControl className="nativeSelectWrap">
      <LabelTooltip label={label} />
      <NativeSelect  value={value} className="nativeSelect" onChange={onChange}>
      
        {dataOptions && dataOptions.length
          ? dataOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })
          : null}
      </NativeSelect>
    </FormControl>
  );
};
