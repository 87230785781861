import React from "react";
import { Route, Switch, Redirect } from "react-router";

//containers
//import Home from "../containers/pages/Home";
import Admin from "../containers/pages/Admin";
//import TestPage from "../containers/pages/TestPage";
import NoMatch from "../containers/errors/NoMatch";
import UnauthorisedAccessPage from "../containers/errors/UnauthorisedAccessPage";
import ConnectionError from "../containers/errors/ConnectionError";
import Scorecard from "../containers/pages/Scorecard";
import Dashboard from "../containers/pages/Dashboard";
import ListReportingItem from "../containers/pages/reportingItem/ListReportingItem";
import EditReportingItem from "../containers/pages/reportingItem/EditReportingItem";
import EditProgressReport from "../containers/pages/progressReport/EditProgressReport";
import ServerError from "../containers/errors/ServerError";
import Binders from "../containers/pages/Binders";
import ReportingItemCommentary from "../containers/pages/reportingItem/ReportingItemCommentary";
import ReviewActivitySummary from "../containers/pages/activitySummary/ReviewActivitySummary";
import ActivitySummarySnapshot from "../containers/pages/activitySummary/ActivitySummarySnapshot";
import ReviewActivitySummaryMenu from "../containers/pages/activitySummary/ReviewActivitySummaryMenu";
import ReviewBinderSummary from "../containers/pages/binder/ReviewBinderSummary";
import ReviewBinderSummaryMenu from "../containers/pages/binder/ReviewBinderSummaryMenu";
import UrlBuilderUtil from "../utils/UrlBuilderUtil";
import ReportingItemTimeline from "../containers/pages/reportingItem/ReportingItemTimeline";
import ViewProgressReport from "../containers/pages/progressReport/ViewProgressReport";

import * as AuthUtil from '../utils/AuthUtil';

const AppRoutes = () => {

  const isAdmin = AuthUtil.hasAdminRole();
  const isReportingAdmin = AuthUtil.hasReportingAdminRole();

  return (
    <Switch>

      <Route
        path="/"
        exact
        component={() => <Redirect to={UrlBuilderUtil.getDashboard()} />}
      />

      {/* <Route exact path="/test-page" component={TestPage} /> */}

      <Route 
      exact 
      path="/dashboard/" 
      component={Dashboard} />
      
      <Route 
      exact 
      path="/binders/" 
      component={Binders} />
      
      <Route exact path="/progress-report/edit/:projectId" component={EditProgressReport} />
      <Route exact path="/progress-report-snapshot/:projectId/:year?/:period?" component={ViewProgressReport} />
      <Route exact path="/reporting-item-commentary/:reportingItemId" component={ReportingItemCommentary} />
      <Route exact path="/reporting-item-timeline/:reportingItemId" component={ReportingItemTimeline} />

      <Route exact path="/activity-summary-snapshot/:activitySummaryId/:year?/:period?" component={ActivitySummarySnapshot} />
      <Route exact path="/activity-summary/:activitySummaryId/reporting-item-commentary/:reportingItemId" component={ReviewActivitySummary} />
      <Route exact path="/activity-summary/:activitySummaryId/progress-report/edit/:projectId" component={ReviewActivitySummary} />
      <Route exact path="/activity-summary/:activitySummaryId/menu/" component={ReviewActivitySummaryMenu} />
      <Route exact path="/activity-summary/:activitySummaryId" component={ReviewActivitySummary} />
      

      <Route exact path="/binder-summary/:binderSummaryId/reporting-item-commentary/:reportingItemId" component={ReviewBinderSummary} />
      <Route exact path="/binder-summary/:binderSummaryId/menu/" component={ReviewBinderSummaryMenu} />
      <Route exact path="/binder-summary/:binderSummaryId" component={ReviewBinderSummary} />

      {/* <Route exact path="/progress-report/view/:projectId/:tabId?" component={ViewProgressReport} /> */}
      <Route 
      path="/admin/" 
      component={isAdmin ? Admin : UnauthorisedAccessPage} />

      <Route exact path="/reporting-items" component={isReportingAdmin ? ListReportingItem : UnauthorisedAccessPage} />
      <Route exact path="/reporting-items/create" render={() => isReportingAdmin ? <EditReportingItem actionType="create" /> : <UnauthorisedAccessPage />} />
      <Route exact path="/reporting-items/edit/:id?" render={() => isReportingAdmin ? <EditReportingItem actionType="edit" /> : <UnauthorisedAccessPage />} />

      <Route 
      exact 
      path="/scorecard/:zeroLevelId?/:firstLevelId?/:secondLevelId?/:thirdLevelId?" 
      component={Scorecard} />

      <Route exact path="/connection-error" component={ConnectionError} />

      <Route exact path="/404" component={NoMatch} />
      <Route exact path="/500" component={ServerError} />
      <Route exact path="/401" component={UnauthorisedAccessPage} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default AppRoutes;
