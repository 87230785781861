import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, Grid, Hidden } from '@material-ui/core';
import RichTextRenderer from '../RichTextRenderer';
import UsersUtil from '../../utils/UsersUtil';
import DateUtil from '../../utils/DateUtil';
import objectPath from 'object-path'


class CommentaryHistoryItem extends React.Component {


    renderUserName = () => {
        let { item } = this.props;
        let userName = UsersUtil.getNameFromStoreById(objectPath.get(item,'commentedUserId'), null);
        return userName ? <small className="noWrap">{userName}</small> : '';
      }
  
      renderCreatedDate = () => {
        let { item } = this.props;
        let createdDate = objectPath.get(item,'createdDate', null);
        return createdDate ? <strong><small>{DateUtil.basicDate(createdDate)}</small></strong> : null;
      }

    render() {
        const { item, title, classes, className } = this.props;
    
        return item ? <Grid container className={"historyCommentaryItemWrapper "+className}>
                <div className="divider"></div>
                <Grid item xs={12}>
                <Typography variant="h5">
                <strong>{title}</strong>
            </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography fontSize="small" variant="body2">{this.renderCreatedDate()} <Hidden smUp><br/></Hidden><Hidden xsDown>&nbsp;</Hidden> {this.renderUserName()}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.textRight}>
                        <strong><small>Elected Members</small></strong>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" component="div"><RichTextRenderer text={item.electedMemberComment} /></Typography>
                </Grid>
            </Grid> : <Typography variant="body2">Comment not found</Typography>
      }
}



export default withStyles(styles, { withTheme: true })(CommentaryHistoryItem);