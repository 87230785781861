import React from "react";
import { withStyles } from "@material-ui/core/styles";

import styles from "../../theme/styles";
import { TableRow, TableCell, Typography, Tooltip, IconButton, Hidden } from "@material-ui/core";
import classnames from 'classnames';
import * as constants from '../../constants/constants';
import { withRouter } from "react-router-dom"; 
import EditIcon from '@material-ui/icons/EditOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import ReinstateIcon from '@material-ui/icons/UnarchiveOutlined';
import UrlBuilderUtil from "../../utils/UrlBuilderUtil";

class ReportingItemExpandedTableRow extends React.Component {



  mainCell = () => {
    const { item, activity } = this.props;
    return <div><Typography variant="body2">{activity ? activity.name : null}</Typography> {item.description ? <Typography variant="body2">{item.description}</Typography> : null}</div>

  }

  render() {
    const { item, classes, onExpandRow, history, onArchiveToggleClicked  } = this.props;
    
    


    return (
        <TableRow key={item.id+"_expanded"} onClick={()=> onExpandRow(item.id) } className={classnames('expandedAdditionalRow',classes.expandedAdditionalRow)}>
              
                <Hidden xsDown>
                <TableCell className="expandCellWidth" colSpan={3}>
                  { this.mainCell() }
                </TableCell>
                </Hidden>

                <Hidden smUp>
                <TableCell className="expandCellWidth" colSpan={2}>
                { this.mainCell() }
                </TableCell>
                </Hidden>
                
                <TableCell>
                <Typography variant="body2">
                { constants.REPORTING_FREQUENCIES.filter(x => x.value === item.reportFrequency).map(x => x.label) }
                </Typography>

                <Hidden smUp><Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Edit Reporting Item">
                  <IconButton size="small" aria-label="Edit" onClick={() => history.push(UrlBuilderUtil.getReportingItemEdit(item.id))}>
                    <EditIcon  fontSize="small"/>
                  </IconButton>
                </Tooltip>
                { item.archived ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Reinstate Reporting Item">
                  <IconButton size="small" aria-label="Reinstate" onClick={() => onArchiveToggleClicked(item)}>
                    <ReinstateIcon  fontSize="small"/>
                  </IconButton>
                </Tooltip>
                :
                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Archive Reporting Item">
                  <IconButton size="small" aria-label="Archive" onClick={() => onArchiveToggleClicked(item)}>
                    <ArchiveIcon  fontSize="small"/>
                  </IconButton>
                </Tooltip> }
                </Hidden>
                </TableCell>
              
            </TableRow>
    );
  }
}


export default withRouter(withStyles(styles, { withTheme: true })(ReportingItemExpandedTableRow));
