import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './user';
import menuReducer from './menu';
import * as usersReducer from './users';
import * as positionsReducer from './positions';
import * as audiencesReducer from './audiences';
import * as infoTextReducer from './infoText';
import * as reportingItemsReducer from './reportingItems';
import * as activitySummariesReducer from './activitySummaries';
import * as bindersReducer from './binders';
import * as reportOutputsReducer from './reportOutputs';
import * as reportSchedulesReducer from './reportSchedules';
import * as drawerReducer from './drawer';
import * as uiReducer from './ui';
import * as reportingParametersReducer from './reportingParameters';
import * as strategicPlansReducer from './strategicPlans';
import * as dashboardReducer from './dashboard';
import * as routerReducer from './router';
import * as errorReducer from './error';
import * as projectPhasesReducer from './projectPhases';

const rootReducer = combineReducers({
    form: formReducer,
    userInfo: userReducer,
    menu: menuReducer,
    users: usersReducer.getUsers,
    keycloakUsers: usersReducer.getKeycloakUsers,
    positions: positionsReducer.getPositions,
    audiences: audiencesReducer.getAudiences,
    drawerOpen: drawerReducer.showHideDrawer,
    contentSubmenuLevel: uiReducer.setContentSubmenuLevel,
    strategicPlans: strategicPlansReducer.getStrategicPlans,
    reportingItemSummaryList: reportingItemsReducer.getReportingItemSummaryList,
    activitySummaries: activitySummariesReducer.getActivitySummaries,
    allReportingItems: reportingItemsReducer.getAllReportingItems,
    binderCategoriesSummary: bindersReducer.getBinderCategoriesSummary,
    binder: bindersReducer.getBinder,
    binderReviewReportingItemCommentary: bindersReducer.getBidnerReviewReportingItemCommentary,
    reportingItem: reportingItemsReducer.getReportingItem,
    reportingItems: reportingItemsReducer.getReportingItems,
    reportOutputs: reportOutputsReducer.getReportOutputs,
    reportOutputsByBinderId: reportOutputsReducer.getReportOutputs,
    reportSchedules: reportSchedulesReducer.getReportSchedules,
    infoText: infoTextReducer.getInfoText,
    reportingParameters: reportingParametersReducer.getReportingParameters,
    reportingTargetParameters: reportingParametersReducer.getReportingTargetParameters,
    dashboardData: dashboardReducer.getDashboardData,
    dashboardMergedData: dashboardReducer.setMergedDashboardData,
    routerLocations: routerReducer.getRouterLocations,
    requestErrors: errorReducer.getRequestErrors,
    lastRequestError: errorReducer.getLastRequestError,
    projectPhases: projectPhasesReducer.getProjectPhases
});

export default rootReducer;