import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import LaunchIcon from '@material-ui/icons/Launch';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as constants from '../constants/constants';

import { Typography, IconButton, Grid, Hidden, Tooltip } from '@material-ui/core';

function PageHeading(props) {

  const {
    classes,
    heading,
    mutedHeading,
    subHeading,
    actions,
    variantClass,
    showPinpointMapIcon,
    mapUrl,
    externalDocuments,
    canEdit,
    apiActions,
    onDeleteDocumentCallback
  } = props;

  const openMap = (url) => (e) => {
    e.preventDefault();
    window.open(url);
  }

  const deleteDocument = (id, title) => (e) => {
    let response = window.confirm("Are you sure you want to remove the link to " + title + "?");
    
    if (response === true) {
      apiActions.removeLink(id).then((response) => {
        onDeleteDocumentCallback(id);
      });
    }

  };

  const getHeadingContent = () => {
    var output = [];

    if (mutedHeading) {
      output.push(<Typography key="mutedHeading" variant="h1" className="muted">{ mutedHeading }</Typography>);
      output.push(<Hidden key="lineBreak1" mdUp><br /></Hidden>);
    }

    output.push(<Typography key="mainHeading" variant="h1">{ heading }</Typography>);

    if (showPinpointMapIcon) {
      output.push(
        <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="View map">
          <IconButton className="smallRoundedDialogIcon" key={mapUrl} onClick={openMap(mapUrl)}>
            <RoomIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return output;   
  }

  const createTable = () => {
    let output = [];

    for (let i = 0; i < externalDocuments.length; i++) {
      output.push(
        <Grid item xs={12} md={6} lg={6} key={"object" + i}>

          <Grid container style={{marginBottom: "8px"}}>
            <Grid item xs={11} md={9} lg={9}>
              {externalDocuments[i].objectiveId !== undefined ?
                <a href={"https://dms.wbopdc.govt.nz/id:" + externalDocuments[i].objectiveId} target="_blank" style={{textDecoration: "none"}}>
                  <GreenTextLinkTypography><LanguageIcon />{externalDocuments[i].folderName}/{externalDocuments[i].title}</GreenTextLinkTypography>
                </a>
              : <a href={externalDocuments[i].url} target="_blank" style={{textDecoration: "none"}}>
                  <GreenTextLinkTypography><LaunchIcon />{externalDocuments[i].title}</GreenTextLinkTypography>
                </a>}
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              { canEdit === true ? 
                  <IconButton className="smallRoundedDialogIcon" onClick={deleteDocument(externalDocuments[i].id, externalDocuments[i].title)}>
                    <HighlightOffIcon />
                  </IconButton> : 
                  null
              }
            </Grid>
          </Grid>
          
        </Grid>
      );
    }

    return output;
  }

  const GreenTextHeadlineTypography = withStyles({
    root: {
      color: "#577624",
      borderBottom: "1px solid #577624",
      marginBottom: "10px"
    }
  })(Typography);

  const GreenTextLinkTypography = withStyles({
    root: {
      color: "#577624",
      textDecoration: "none",
      fontSize: "10pt"
    }
  })(Typography);

  return (
    <Paper className={classnames(classes.pageHeading, "pageHeading fullWidthBgComponent", variantClass)} square elevation={0}>
      <Grid container>
        { actions ? 
          <Grid item xs={12} sm={9}>
            { getHeadingContent() }
          </Grid> : 
          <Grid item xs={12}>
            { getHeadingContent() }
          </Grid> 
        }

        { actions ? 
          <Grid item xs={12} sm={3} className={classnames("hideForPrint", classes.pageHeadingActions)}>
            {actions}
          </Grid> : null 
        }

        { subHeading ? 
          <Grid item xs={12}>
            <Typography variant="body2">{ subHeading }</Typography>
          </Grid> : null
        }
      </Grid>
    
      { externalDocuments && externalDocuments.length > 0 ?
        <Grid container className={classnames("hideForPrint")}>
          <Grid item xs={12} lg={12}>
            <GreenTextHeadlineTypography key="linkedDocuments" variant="subtitle1">Linked Documents</GreenTextHeadlineTypography>
          </Grid>

          { createTable() }

        </Grid>
        : null
      }

    </Paper>
  );
}


export default withStyles(styles, { withTheme: true })(PageHeading);