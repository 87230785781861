import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import * as constants from '../../constants/constants';
import classnames from 'classnames';
import { withRouter } from "react-router-dom";
import TimelineUtil from "../../utils/TimelineUtil";
import ArrayUtil from "../../utils/ArrayUtil";
import DateUtil from "../../utils/DateUtil";
import { Grid, Typography } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import CommentaryIcon from '@material-ui/icons/AssignmentOutlined';
import QuickCommentIcon from "@material-ui/icons/ChatBubbleOutline";
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import GroupCommentCard from '../commentary/GroupCommentCard';
import ProjectStatusLinkRecord from '../reportingItemProgress/ProjectStatusLinkRecord';

  class ReportingItemTimelinePeriodSection extends React.Component {



    render(){
      const { classes, data, projectId } = this.props;
      const orderedDates = TimelineUtil.getOrderedDates(data);
      const groupedData = TimelineUtil.getDateGroupedData(data, orderedDates);

      let timelineRows = [];
      
        if(ArrayUtil.isNonEmptyArray(orderedDates)) {
            orderedDates.forEach((date,index) => {
                if(groupedData[date] && ArrayUtil.isNonEmptyArray(groupedData[date])){
                    timelineRows.push(groupedData[date].map( (record, index) => <div className={classnames("fullWidthBgComponent",classes.timelineRow,record.itemType)} key={index}>
                            <Grid container justify = "center">
                                <Grid item xs={12} sm={3} md={3} lg={2} xl={1} className={classnames("dateWrap",classes.textCenter)}>
                                    <div className={classnames("iconWrap")}>{ 
                                        record.itemType === constants.TIMELINE_ITEM_TYPE_REPORT_UPDATE ?
                                        <AssignmentIcon color="primary" size="large" /> :
                                        record.itemType === constants.TIMELINE_ITEM_TYPE_COMMENTARY ?
                                        <CommentaryIcon color="primary" size="large" /> :
                                        record.itemType === constants.TIMELINE_ITEM_TYPE_QUICK_COMMENT ?
                                        <QuickCommentIcon color="primary" size="large" /> : null
                                    }</div>
                                    <Typography variant="caption" className="muted date">
                                        <CalendarIcon fontSize="small" className="calendarIcon" /> { DateUtil.basicDate(date, true) }
                                    </Typography>
                                    
                                </Grid>
                                <Grid item xs={12} sm={9} md={9} lg={10} xl={11} className="gridItem timelineRecordWrap">
                                {record.itemType === constants.TIMELINE_ITEM_TYPE_REPORT_UPDATE ?
                                        <ProjectStatusLinkRecord data={record} projectId={projectId} /> :
                                        record.itemType === constants.TIMELINE_ITEM_TYPE_COMMENTARY ?
                                        <div className="commentaryWrap">
                                            <GroupCommentCard
                                                heading="What's Happened?" 
                                                comment={record}
                                                objectPathUserId="whatsHappenedComment.commentedUserId"
                                                objectPathCreatedDate="whatsHappenedComment.createdDate"
                                                showMoreText="+ See All Audiences"
                                                showLessText="- See less audiences"
                                                fields={
                                                [
                                                    {
                                                    label: "Elected Members",
                                                    name: "whatsHappenedComment.electedMemberComment",
                                                    },
                                                    {
                                                    label: "Public",
                                                    name: "whatsHappenedComment.publicComment",
                                                    },
                                                    {
                                                    label: "Management",
                                                    name: "whatsHappenedComment.managementComment",
                                                    }
                                                ]} />
                                                <GroupCommentCard
                                                heading="What's Next?" 
                                                comment={record}
                                                objectPathUserId="whatsNextComment.commentedUserId"
                                                objectPathCreatedDate="whatsNextComment.createdDate"
                                                showMoreText="+ See All Audiences"
                                                showLessText="- See less audiences"
                                                fields={
                                                [
                                                    {
                                                    label: "Elected Members",
                                                    name: "whatsNextComment.electedMemberComment",
                                                    },
                                                    {
                                                    label: "Public",
                                                    name: "whatsNextComment.publicComment",
                                                    },
                                                    {
                                                    label: "Management",
                                                    name: "whatsNextComment.managementComment",
                                                    }
                                                ]} />
                                        </div>
                                          :
                                        record.itemType === constants.TIMELINE_ITEM_TYPE_QUICK_COMMENT ?
                                        <GroupCommentCard
                                            heading="Quick Comment" 
                                            comment={record}
                                            showMoreText="+ See All Audiences"
                                            showLessText="- See less audiences"
                                            hasImage={true}
                                            fields={
                                            [
                                                {
                                                label: "Elected Members",
                                                name: "electedMemberComment",
                                                },
                                                {
                                                label: "Public",
                                                name: "publicComment",
                                                },
                                                {
                                                label: "Management",
                                                name: "managementComment",
                                                }
                                            ]} /> : null}
                                </Grid>
                            </Grid>
                            
                        </div>
                    ))
                }
            });
        }

        return timelineRows;
    }
  
}


export default withRouter(withStyles(styles, { withTheme: true })(ReportingItemTimelinePeriodSection));