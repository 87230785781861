import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";

export function getPositions() {
  return function(dispatch) {
    ConsoleUtil.log('get positions action');
    return reportingClient.get(`Positions`)
    //return localStaticClient
      //.get(`/test/api/users.json`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_POSITIONS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_POSITIONS))
      );
  };
}
