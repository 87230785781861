import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import ReportOutputForm from "../../../components/forms/reportOutput/form";
import * as reportOutputs from "../../../actions/reportOutput";
import * as audiencesActions from '../../../actions/audiences';
import * as projects from "../../../actions/project";
import * as formNames from "../../../constants/forms";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import FormatterUtil from "../../../utils/FormatterUtil";

const entityName = "ReportOutput";
const entityListUrl = "/admin/report-outputs/";
const entityFormName = formNames.REPORT_OUTPUT_FORM_NAME;


class EditReportOutput extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true,
      audiences: []
    }
  }

  componentDidMount(){
    const { actions } = this.props;
    Promise.all([
      actions.getAudiences(),
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }


  mountItem(){
    const { actions, actionType, match } = this.props;
    const _this = this;
    var itemId = objectPath.get(match, "params.id");
    if(actionType === "edit" && itemId){
      return actions['get'+entityName](itemId).then( response => {
        return _this.setState({
          item: objectPath.get(response, "data.data"),
        })
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  handleResponse = response => {
    const { enqueueSnackbar, actionType, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = objectPath.get(response, "data.data.name")+" item "+(actionType === "create" ? " added" : " updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      setImmediate(() => {
        history.push(entityListUrl);
      });
    }
  }

  handleSubmit = values => {
    const { actions, actionType, match } = this.props;
    let action = actions['post'+entityName];
    if(actionType !== "create") {
      action = actions['put'+entityName];
      values.id = objectPath.get(match, 'params.id');
    }
    //has to return promise so submitting flag works!
    return action(values).then(response => this.handleResponse(response));
  };


  render() {
    const { actionType, audiences } = this.props;
    const { item, dataLoading } = this.state;
    

    return (
      <div>
        <PageHeading heading={(actionType === "create" ? "New" : "Update")+" "+FormatterUtil.camelToNormalForm(entityName)} actions={
          <div>
            <HeadingBackBtn redirectToUrl={entityListUrl} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 
      <ReportOutputForm 
        proceedSubmit={this.handleSubmit} 
        initialValues={item}
        audiences={audiences}

      /> }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  audiences: state.audiences
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...audiencesActions,
      ...reportOutputs,
      ...projects,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditReportOutput)
));