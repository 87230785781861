import * as constantsConstants from '../constants/constants';

export default class FormatterUtil {
  static bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  static keywordEncodeURI(keyword = ''){
    //remove amp
    keyword = keyword.replace('&','%26');
    //remove question mark
    keyword = keyword.replace('?','%3F');
    return encodeURI(keyword);
  }

  static keywordDecodeURI(keyword = ''){
    //remove amp
    keyword = keyword.replace('%26','&');
    //remove question mark
    keyword = keyword.replace('%3F','?');
    return decodeURI(keyword);
  }


  static formatDays(days){
    return days + ' ' + (parseInt(days) === 1 ? 'day' : 'days');
  }

  static formatNZDollar(number){
    //if(Intl && Intl.NumberFormat){ // old safari (<10) throws Error 
      //return Intl.NumberFormat(constantsConstants.LOCALE, { maximumSignificantDigits: 3, style: "currency", currency: constantsConstants.CURRENCY  }).format(number);
    //} else {
      let result = number.toLocaleString(constantsConstants.LOCALE, 
        {
          style: "currency",
          currency: constantsConstants.CURRENCY,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
          //maximumSignificantDigits: 3
        });
      return result;
    //}
    
  }


  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  static formatPercentage(number, decimalPlaces = 0){
    var output = number;
    if(number >= 0){
      output = decimalPlaces > 0 ? parseFloat(number).toFixed(decimalPlaces) : Math.floor(number);
    } else {
      output = decimalPlaces > 0 ? parseFloat(number).toFixed(decimalPlaces) : Math.ceil(number);
    }
    return output.toString()+'%';
  }

  static camelToNormalForm(string){
    return string
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([a-z])/g, ' $1$2')
    .replace(/ +/g, ' ')
  }

  static formatActionNameForErrorMessage(actionName){
    actionName = FormatterUtil.replaceAll(actionName,'GET_', '');
    actionName = FormatterUtil.replaceAll(actionName,'_', ' ');
    return actionName.toLowerCase();
  }

  static replaceAll(str, find, replace) {
    return str.toString().replace(new RegExp(find, 'g'), replace);
  } 
}
