import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import classnames from 'classnames';
import * as formNames from '../../../constants/forms';
import * as constants from '../../../constants/constants';
import { TextInput, SelectWithSuggestions } from '../../inputs/';

import validate from './validate';
import Fab from '@material-ui/core/Fab';
import TickIcon from '@material-ui/icons/Check';
import FormGroup from '@material-ui/core/FormGroup';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import warnAboutUnsavedForm from '../../../hoc/withWarnAboutUnsavedChanges';
import ReportOutputUtil from '../../../utils/ReportOutputUtil';
import objectPath from 'object-path';

const ReportOutputForm = (props) => {
    const {
        handleSubmit,
        //pristine,
        submitting,
        initialValues,
        classes,
        proceedSubmit,
        audiences,
    } = props;

    const disableSubmit = submitting;
    const submitButton = (
        <Fab
            color="secondary"
            type="submit"
            size="large"
            disabled={disableSubmit}
        >
            <TickIcon />
        </Fab>
    );

    const fileName = ReportOutputUtil.getDocumentName(initialValues);
    const folderName = ReportOutputUtil.getFolderName(initialValues);

    return (
        <div>
            <form
                onSubmit={handleSubmit((values) => proceedSubmit(values))}
                className={classes.customForm}
            >
                <div className="maxWidthPart">
                    <Grid container>
                        <Grid item className="gridItem" xs={12} lg={7}>
                            <FormGroup>
                                <Field
                                    name="name"
                                    component={TextInput}
                                    label="Name"
                                    inputProps={{ maxLength: 50 }}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item className="gridItem" xs={12} lg={7}>
                            <FormGroup>
                                <Field
                                    name="audiences"
                                    component={SelectWithSuggestions}
                                    isSearchable={false}
                                    isMulti={true}
                                    options={audiences.map((x) => {
                                        return { label: x.name, value: x.id };
                                    })}
                                    label="Audiences"
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item className="gridItem" xs={12} lg={7}>
                            <FormGroup>
                                <Field
                                    name="objectiveId"
                                    component={TextInput}
                                    label="Linked documentsss"
                                    helpText="link to an objective word document (enter the objective id)"
                                    placeholder="Objective ID"
                                />

                                {fileName ? (
                                    <Typography variant="body2">
                                        Document:{' '}
                                        <strong>
                                            {objectPath.get(
                                                initialValues,
                                                'documentName',
                                                'Name not found'
                                            )}
                                        </strong>
                                    </Typography>
                                ) : null}
                                {folderName ? (
                                    <Typography
                                        variant="body2"
                                        className="muted"
                                    >
                                        Folder:{' '}
                                        <strong>
                                            {objectPath.get(
                                                initialValues,
                                                'folderName',
                                                'Folder not found'
                                            )}
                                        </strong>
                                    </Typography>
                                ) : null}
                            </FormGroup>
                        </Grid>

                        <Grid
                            item
                            className={classnames(
                                'gridItem',
                                classes.textRightStrong
                            )}
                            xs={12}
                            lg={7}
                        >
                            {disableSubmit ? (
                                submitButton
                            ) : (
                                <Tooltip
                                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                    title="Save"
                                >
                                    {submitButton}
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    );
};

export default warnAboutUnsavedForm(
    withStyles(styles, { withTheme: true })(
        reduxForm({
            form: formNames.REPORT_OUTPUT_FORM_NAME, // a unique identifier for this form
            validate,
            //asyncValidate
        })(ReportOutputForm)
    ),
    formNames.REPORT_OUTPUT_FORM_NAME
);
