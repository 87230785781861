import objectPath from 'object-path';
import StoreUtil from './StoreUtil';


export default class UsersUtil {


  static formatForUserSelector(){
    var users = StoreUtil.getValueByKey('users',[]);
    return users.map(x => {return {
      value: x.userId,
      label: x.firstName+" "+x.surname,
    }});
  }


  static getUserById(items,id){
    
    return id ? items.find(x => x.userId===id) : null;
  }

  static getNameFromStoreById(id, defaultValue = 'User not found'){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('users',[]),id);
    return user ? objectPath.get(user,'firstName')+" "+objectPath.get(user,'surname') : defaultValue;
  }

  static getEmailFromStoreById(id, defaultValue = null){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('users',[]),id);
    return objectPath.get(user,'email', defaultValue);
  }


  static getKeycloakUserById(items,id){
    
    return id ? items.find(x => x.id===id) : null;
  }

  static getKeycloakNameFromStoreById(id, defaultValue = 'User not found'){
    var user = UsersUtil.getKeycloakUserById(StoreUtil.getValueByKey('keycloakUsers',[]),id);
    return user ? objectPath.get(user,'firstName')+" "+objectPath.get(user,'lastName') : defaultValue;
  }

  static getKeycloakEmailFromStoreById(id, defaultValue = null){
    var user = UsersUtil.getKeycloakUserById(StoreUtil.getValueByKey('keycloakUsers',[]),id);
    return objectPath.get(user,'email', defaultValue);
  }


}
