import React from "react";
import DashboardIcon from "@material-ui/icons/ViewQuiltOutlined";
import ScorecardIcon from "@material-ui/icons/BallotOutlined";
import BinderIcon from "@material-ui/icons/FolderOpenOutlined";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import AdminIcon from "@material-ui/icons/SettingsOutlined";

export default {
    primaryMenu: [
        {
            label: "Dashboard",
            path: '/dashboard/',
            title: "View dashboard",
            showIcon: true,
            icon: <DashboardIcon />
            
        },
        /* {
            label: "Activity Summaries",
            path: "/activity-cummaries",
            showIcon: true
        },
        {
            label: "Community Boards",
            path: "/community-boards",
            showIcon: true
        },
        {
            label: "Projects",
            path: "/projects",
            showIcon: true
        }, */
        {
            label: "Binders",
            path: "/binders/",
            icon: <BinderIcon />,
            showIcon: true
        },
        /* {
            label: "Test form page",
            path: "/test-page"
        }, */
    ],
    secondaryMenu: [
        {
            label: "Scorecard",
            path: "/scorecard/",
            showIcon: true,
            icon: <ScorecardIcon />,
            children: []
        },
        {
            label: "Reporting Items",
            path: "/reporting-items",
            showIcon: true,
            icon: <AssignmentIcon />,
            children: []
        },
        {
            label: "Admin",
            path: "/admin/",
            showIcon: true,
            icon: <AdminIcon />,
            children: [
                {
                    label: "Report Schedules",
                    path: "/admin/report-schedules/",
                },
                {
                    label: "Report Outputs",
                    path: "/admin/report-outputs/",
                },
                 {
                    label: "Sync",
                    path: "/admin/sync",
                }, 
            ]
        }
    ],

}