import * as types from '../constants/actionTypes';
import ConsoleUtil from '../utils/ConsoleUtil';

export function getReportingItems(state = [], action) {
    if (action.type === types.GET_REPORTING_ITEMS) {
        ConsoleUtil.log(types.GET_REPORTING_ITEMS+' set in store');
        return action.data.data;
    }
    return state;
}

export function getReportingItemSummaryList(state = [], action) {
    if (action.type === types.GET_REPORTING_ITEM_SUMMARY_LIST) {
        ConsoleUtil.log(types.GET_REPORTING_ITEM_SUMMARY_LIST+' set in store');
        return action.data.data;
    }
    return state;
}

export function getAllReportingItems(state = [], action) {
    if (action.type === types.GET_ALL_REPORTING_ITEMS) {
        ConsoleUtil.log(types.GET_ALL_REPORTING_ITEMS+' set in store');
        return action.data.data;
    }
    return state;
}

export function getReportingItem(state = [], action) {
    if (action.type === types.GET_REPORTING_ITEM) {
        ConsoleUtil.log(types.GET_REPORTING_ITEM+' set in store');
        return action.data.data;
    }
    return state;
}

/* export function postReportingItem(state = [], action) {
    if (action.type === types.POST_REPORTING_ITEM) {
        ConsoleUtil.log(types.POST_REPORTING_ITEM+' set in store');
        return action.data.data;
    }
    return state;
} */

