import React from "react";
import SubMenu from "../../components/menu/desktop/SubMenu";
import store from '../../store';
import objectPath from 'object-path';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../theme/styles";
import { Route, Switch } from "react-router";
import NoMatch from "../errors/NoMatch";
import EditReportSchedule from '../pages/reportSchedule/EditReportSchedule';
import ListReportSchedule from '../pages/reportSchedule/ListReportSchedule';
import EditReportOutput from '../pages/reportOutput/EditReportOutput';
import ListReportOutput from '../pages/reportOutput/ListReportOutput';
import Sync from '../pages/Sync';

class Admin extends React.Component {



  render() {
    let secondaryMenuItems = objectPath.get(store.getState(), 'menu.secondaryMenu',[]);
    let submenu = secondaryMenuItems.find(x => x.path === "/admin/");
    let submenuChildren = objectPath.get(submenu, 'children');

    const { classes } = this.props;

    return <div className={classnames("contentWithSubmenu", classes.contentWithSubmenu, classes['contentWithSubmenuLevel_1'])}>
      { submenuChildren ? 
        <SubMenu items={submenuChildren} groups={false} level={1} />
        : 
        null
      }
      <Switch>
        <Route exact path="/admin/" render={() => <div></div>} />


      {/* schedules */}
        
        <Route exact path="/admin/report-schedules/create/" render={() => <EditReportSchedule actionType="create" />} />
        <Route exact path="/admin/report-schedules/edit/:id?" render={() => <EditReportSchedule actionType="edit" />} />
        <Route exact path="/admin/report-schedules/" component={ListReportSchedule} />


      {/* outputs */}
      
        <Route exact path="/admin/report-outputs/create/" render={() => <EditReportOutput actionType="create" />} />
        <Route exact path="/admin/report-outputs/edit/:id?" render={() => <EditReportOutput actionType="edit" />} />
        <Route exact path="/admin/report-outputs/" render={() => <ListReportOutput />} />
        
        {/* sync */}
        <Route exact path="/admin/sync/"  render={() => <Sync />}/>
 
        <Route component={NoMatch} />
      </Switch>
      
    </div>
  }
}

export default withStyles(styles, { withTheme: true })(Admin);