
import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";

export function getProjectPhases() {
    return function(dispatch) {
      ConsoleUtil.log('get ProjectPhases action');
      return reportingClient.get(`projectPhases`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_PROJECT_PHASES)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROJECT_PHASES))
        );
    };
}