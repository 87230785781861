import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as binderActions from "../../../actions/binder";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import ComponentLoading from "../../../components/ComponentLoading";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import classnames from 'classnames';
import BinderSummarySubmenu from "../../../components/menu/BinderSummarySubmenu";


class ReviewBinderSummaryMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      binderSummary: null,
      dataLoading: true,
    }
  }



  componentDidMount() {
    this.reloadData();
  }


  reloadData = () => {
    const { match } = this.props;
    var binderSummaryId = objectPath.get(match, "params.binderSummaryId", null);
    Promise.all([
      this.mountBinderSummary(binderSummaryId)
    ]).then(() =>
        this.setState({
          dataLoading: false
        })
    );
  }

  mountBinderSummary(id) { // for menu and page state purposes and based on condition load AS review
    const { actions, history } = this.props;


    const _this = this;
    if (id) {
      return actions.getBinderSummary(id).then(response => {
        let binderSummary = objectPath.get(response, "data.data");

        if (!binderSummary) {
          return history.push("/404");
        } else {
          _this.setState({
            binderSummary,
          });
        }
        
      });
    } else {
      return new Promise((resolve) => {
        _this.setState({
          binderSummary: null,
        }, resolve);
      });
    }
  }



  render() {
    const { classes } = this.props;
    const { dataLoading, binderSummary } = this.state;

    return (
      <div className={classnames("contentWithSubmenu", classes.contentWithSubmenu, classes['contentWithSubmenuLevel_1'])}>
        {dataLoading ? <ComponentLoading /> :<BinderSummarySubmenu binderSummary={binderSummary} level={1} /> }
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...binderActions,
      },
      dispatch
    )
  });


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, { withTheme: true })(ReviewBinderSummaryMenu)
  )
));