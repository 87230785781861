import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import styles from "../../../theme/styles";

class DesktopNavBar extends React.Component {
  render() {
    const { classes } = this.props;


    return (
      <AppBar
        position="fixed"
        className={classnames(classes.appBar, classes.appBarShiftLevel_1,"hideForPrint")}
      >
        <Toolbar>
        </Toolbar>
      </AppBar>
    );
  }
}

DesktopNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(DesktopNavBar);
