import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import ScrollToTop from 'react-scroll-up';

//actions
import * as userActions from '../actions/user';
import * as usersActions from '../actions/users';
import * as drawerActions from '../actions/drawer';
import * as strategicPlansActions from '../actions/strategicPlans';
import * as positionsActions from '../actions/positions';
import * as infoTextActions from '../actions/infoText';
import * as reportingParametesActions from '../actions/reportingParameters';
import * as projectPhasesActions from '../actions/projectPhases';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpwardOutlined';

//styles
import styles from '../theme/styles';
import CustomTheme from '../theme/custom';
import { MuiThemeProvider } from '@material-ui/core/styles';

//components
import AppRoutes from '../components/AppRoutes';

import withAuth from '../hoc/withAuth';
import * as AuthUtil from '../utils/AuthUtil';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Hidden } from '@material-ui/core';
import DesktopNavBar from '../components/menu/desktop/DesktopNavBar';
import DesktopDrawer from '../components/menu/desktop/DesktopDrawer';
import MobileDrawer from '../components/menu/mobile/MobileDrawer';
import MobileNavBar from '../components/menu/mobile/MobileNavBar';
import AppLoading from '../components/AppLoading';
import ConsoleUtil from '../utils/ConsoleUtil';
import StoreUtil from '../utils/StoreUtil';
import ConfigUtil from '../utils/ConfigUtil';
import scrollToTopStyle from '../theme/components/scrollToTop';
import PageDrawerHeader from '../components/PageDrawerHeader';
import GetErrorsListener from '../components/GetErrorsListener';

import keycloakManager from '../keycloakManager';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appInitLoading: true,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        let _this = this;
        //set user first
        Promise.all([_this.getUserInfo()])
            .then(() => {
                Promise.all([
                    actions.getUsers(),
                    actions.getStrategicPlans(),
                    actions.getPositions(),
                    actions.getInfoText(),
                    actions.getReportingParameters(),
                    actions.getReportingTargetParameters(),
                    actions.getProjectPhases()
                ])
                    .then(() => {
                        ConsoleUtil.log('app init loading done');
                        _this.setState({ appInitLoading: false });

                        //catch errors
                    })
                    .catch(function (err) {
                        _this.appLoadingFailed(err);
                    });
            })
            .catch(function (err) {
                _this.appLoadingFailed(err);
            });

        this.listenRouteChanges();
    }

    listenRouteChanges = () => {
        const { history, actions } = this.props;

        history.listen((location, action) => {
            ConsoleUtil.log('route changed');
            //hide menu on redirect
            var isDrawerOpen = StoreUtil.getValueByKey('drawerOpen');
            if (isDrawerOpen) {
                actions.closeDrawer();
            }
        });
    };

    appLoadingFailed = (err) => {
        const { history } = this.props;
        ConsoleUtil.log(err);
        this.setState({ appInitLoading: false }, () => {
            history.push('/500');
        });
    };

    getUserInfo() {
        const userInfo = keycloakManager.getUserInfo();
        if (userInfo) {
            this.props.actions.setUserInfo({
                name: userInfo.name,
                adgroups: keycloakManager.getUserGroups(),
                role: AuthUtil.getUserRole(),
            });
        }
    }

    hasHelpLink = () => {
        var url = ConfigUtil.getHelUrl();
        return url && url.length;
    };

    openHelp = () => {
        var url = ConfigUtil.getHelUrl();
        if (url && url.length) {
            window.open(url, '_blank');
        }
    };

    logout = () => {
        const keycloak: any = keycloakManager.keycloak;
        window.location = `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location.href}&id_token_hint=${keycloak.idToken}`;
    };

    render() {
        const { classes, actions, drawerOpen, menu, userInfo } = this.props;
        const { appInitLoading } = this.state;

        return (
            <MuiThemeProvider theme={CustomTheme}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    classes={{
                        variantSuccess: classnames(
                            classes.snackbarWrap,
                            classes.success
                        ),
                        variantError: classnames(
                            classes.snackbarWrap,
                            classes.error
                        ),
                        variantWarning: classnames(
                            classes.snackbarWrap,
                            classes.warning
                        ),
                        variantInfo: classnames(
                            classes.snackbarWrap,
                            classes.info
                        ),
                    }}
                    action={
                        <Button
                            color="default"
                            className={classes.snackbarBtn}
                            variant="text"
                            size="small"
                        >
                            <CloseIcon />
                        </Button>
                    }
                >
                    <div>
                        {appInitLoading ? (
                            <AppLoading />
                        ) : (
                            <div>
                                <div
                                    className={classes.root}
                                    id="fullScreenDialogContainer"
                                >
                                    <CssBaseline />
                                    <ScrollToTop
                                        showUnder={500}
                                        style={scrollToTopStyle}
                                    >
                                        <div className="scrollTopIconWrap hideForPrint">
                                            <ArrowUpwardIcon />
                                        </div>
                                    </ScrollToTop>
                                    <Hidden smDown>
                                        <DesktopNavBar />

                                        <DesktopDrawer
                                            menu={menu}
                                            userInfo={userInfo}
                                            handleHelp={
                                                this.hasHelpLink()
                                                    ? this.openHelp
                                                    : null
                                            }
                                            handleLogout={this.logout}
                                        />
                                    </Hidden>

                                    <Hidden mdUp>
                                        <MobileNavBar
                                            drawerOpen={drawerOpen}
                                            handleDrawerOpen={
                                                actions.openDrawer
                                            }
                                            handleDrawerClose={
                                                actions.closeDrawer
                                            }
                                        />
                                        <MobileDrawer
                                            menu={menu}
                                            drawerOpen={drawerOpen}
                                            userInfo={userInfo}
                                            handleDrawerClose={
                                                actions.closeDrawer
                                            }
                                            handleHelp={
                                                this.hasHelpLink()
                                                    ? this.openHelp
                                                    : null
                                            }
                                            handleLogout={this.logout}
                                        />
                                    </Hidden>

                                    <main
                                        className={classnames(
                                            classes.content,
                                            classes.contentShift,
                                            {
                                                [classes[
                                                    'contentShiftLevel_1'
                                                ]]: true,
                                            }
                                        )}
                                    >
                                        <PageDrawerHeader userInfo={userInfo} />

                                        <AppRoutes userInfo={userInfo} />

                                        {/* <AppFooter /> */}
                                    </main>
                                </div>
                            </div>
                        )}
                        <GetErrorsListener />
                    </div>
                </SnackbarProvider>
            </MuiThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        menu: state.menu,
        users: state.users,
        userInfo: state.userInfo,
        drawerOpen: state.drawerOpen,
        strategicPlans: state.strategicPlans,
        infoText: state.infoText,
        projectPhases: state.projectPhases,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...usersActions,
                ...userActions,
                ...drawerActions,
                ...strategicPlansActions,
                ...positionsActions,
                ...infoTextActions,
                ...reportingParametesActions,
                ...projectPhasesActions,
            },
            dispatch
        ),
    };
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const StyledApp = withStyles(styles, { withTheme: true })(App);

const ReduxApp = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(StyledApp)
);

const AuthedApp = withAuth(ReduxApp);

export default AuthedApp;
