import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import styles from '../../theme/styles';
import { CardHeader, Divider, List, ListItem, Typography, Grid } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/MonetizationOnOutlined';
import FormatterUtil from '../../utils/FormatterUtil';
import AssessmentUtil from '../../utils/AssessmentUtil';
import { NavLink } from "react-router-dom";
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';

function ActivityListItem(props) {

  const { classes, item } = props;
  const activitySummaryLink = item && item.activitySummaryId ? UrlBuilderUtil.getActivitySnapshot(item.activitySummaryId) : '#';
  
  return (
    <Card className={classes.activityListItem}>
    <CardHeader className={classes.activityListItemHeader}
        title={ <NavLink to={activitySummaryLink}>{item.name}<span className="fullPseudoAfter" /></NavLink> }
      />
      <div>
      <List>
        <ListItem>
        <Grid container>
          <Grid item xs={12}>
          <Typography variant="caption">Non Financial</Typography>
          <div className={AssessmentUtil.getIndicatorClassname(classes, item.assessments.nonFinancial)} />
            </Grid>
            </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid container>
            
            <Grid item xs={12}>
              <Typography variant="caption">YTD Budget Variance</Typography>
              <div className={AssessmentUtil.getIndicatorClassname(classes, item.assessments.financial)} />
            </Grid>
            <Grid item xs={12}>
            <Typography variant="body2" className={classes.activityListItemMoneyWrap}><MoneyIcon /> 
              <strong className={classes[AssessmentUtil.getIndicator(item.assessments.financial)+'Text']}> {FormatterUtil.formatNZDollar(item.budgetToDate)} </strong> 
              <span> | </span> 
              <strong className={classes[AssessmentUtil.getIndicator(item.assessments.financial)+'Text']}> {FormatterUtil.formatPercentage(item.variance, 1)} </strong></Typography>
            </Grid>
          </Grid>
        </ListItem>
      </List>
        
      </div>
    </Card>
  );
}

ActivityListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ActivityListItem);