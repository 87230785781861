export default (theme, themeConfig, themeColors) => {
    return {

card: {
    width: 300,
    [theme.breakpoints.up("md")]: {
      width: 450
    },
    margin: "auto"
  },
  cardError: {
    backgroundColor: themeColors.primarySupportingPalette.pms200c,
    marginTop: themeConfig.commonPadding*2,
    "& h1,& p": {
      color: themeColors.primaryPalette.white
    }
  },
  cardWarning: {
    backgroundColor: themeColors.secondarySupportingPalette.pms1575c,
    marginTop: themeConfig.commonPadding*2,
    "& h1,& p": {
      color: themeColors.primaryPalette.white
    }
  },
}
}