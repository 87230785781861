import objectPath from 'object-path';
import DateUtil from './DateUtil';

export default class TimelineUtil {


    static getDateGroupedData(data, orderedDates = null){
        let dateGroupedData = {};
        if(data){
            if(orderedDates === null){
                orderedDates = TimelineUtil.getOrderedDates(data);
            }
            orderedDates.forEach(x => {
                dateGroupedData[x] = [];//create array for each day
            });
            

            let reportUpdates = objectPath.get(data,'reportUpdates', []);
            //loop reportUpdates
            reportUpdates.forEach( (x) => {
                var timeStripedDate = DateUtil.stripDateFromTime(x.createdDate);
                x.itemType = "reportUpdate";
                dateGroupedData[timeStripedDate].push(x);
            });

            let commentaries = objectPath.get(data,'commentaries', []);
            //loop commentaries
            commentaries.forEach( (x) => {
                var timeStripedDate = DateUtil.stripDateFromTime(x.createdDate);
                x.itemType = "commentary";
                dateGroupedData[timeStripedDate].push(x);
            });


            let quickComments = objectPath.get(data,'quickComments', []);
            //loop quickComments
            quickComments.forEach( (x) => {
                var timeStripedDate = DateUtil.stripDateFromTime(x.createdDate);
                x.itemType = "quickComment";
                dateGroupedData[timeStripedDate].push(x);
            });
        }
        
        return dateGroupedData;
    }


    static getOrderedDates(data, sortArray = true){
        var dates = [];
        if(data){

            var timeStripedDate = null;

            let reportUpdates = objectPath.get(data,'reportUpdates', []);
            //loop reportUpdates
            reportUpdates.forEach( (x) => {
                timeStripedDate = DateUtil.stripDateFromTime(x.createdDate);
                if(!dates.includes(timeStripedDate)){
                    dates.push(timeStripedDate);
                }
            });

            let commentaries = objectPath.get(data,'commentaries', []);
            //loop reportUpdates
            commentaries.forEach( (x) => {
                timeStripedDate = DateUtil.stripDateFromTime(x.createdDate);
                if(!dates.includes(timeStripedDate)){
                    dates.push(timeStripedDate);
                }
            });

            let quickComments = objectPath.get(data,'quickComments', []);
            //loop reportUpdates
            quickComments.forEach( (x) => {
                timeStripedDate = DateUtil.stripDateFromTime(x.createdDate);
                if(!dates.includes(timeStripedDate)){
                    dates.push(timeStripedDate);
                }
            });

        }

        if(sortArray){
            return dates.sort((a,b) => DateUtil.formatForSorting(b) - DateUtil.formatForSorting(a))
        }
        return dates;

    }
  
}
