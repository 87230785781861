import objectPath from 'object-path';
import UiHelperUtil from './UiHelperUtil';

export default class ReportOutputUtil {

    static getDocumentName(item, truncateTo = null) {
      var documentName = objectPath.get(item
        ,'documentName'
        ,'');
      
      if(truncateTo){
        documentName = UiHelperUtil.truncateIfNeeded(documentName,truncateTo);  
      }

      return documentName.length ? documentName : null;
    }

    static getFolderName(item, truncateTo = null) {
      var documentName = objectPath.get(item
        ,'folderName'
        ,'');
      
      if(truncateTo){
        documentName = UiHelperUtil.truncateIfNeeded(documentName,truncateTo);  
      }

      return documentName.length ? documentName : null;
    }


    static getFilteredAudienceOptions(selectedReportOutputOption, audiences){
      
  const filteredAudiencesIds = objectPath.get(selectedReportOutputOption,'audiences', []);
  return audiences.filter(x => filteredAudiencesIds.includes(x.value));
    }
    
  }
  