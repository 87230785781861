import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import * as constants from '../../constants/constants';
import styles from '../../theme/styles';
import { MenuList, MenuItem, Tooltip, Grid, Typography, Hidden } from "@material-ui/core";
import BinderSummaryCard from "../binder/BinderSummaryCard";
import ReportingItemsUtil from "../../utils/ReportingItemsUtil";
import UrlBuilderUtil from "../../utils/UrlBuilderUtil";
import CheckIcon from '@material-ui/icons/CheckOutlined';
import PositionsUtil from "../../utils/PositionsUtil";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as uiActions from "../../actions/ui";
import StoreUtil from "../../utils/StoreUtil";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeftRounded";

class BinderSummarySubmenu extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    let level = 1;
    let contentSubmenuLevel = StoreUtil.getValueByKey('contentSubmenuLevel');
    if(level > contentSubmenuLevel){
      actions.setContentSubmenuLevel(level);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    let contentSubmenuLevel = StoreUtil.getValueByKey('contentSubmenuLevel');
    if(contentSubmenuLevel !== 0){
      actions.setContentSubmenuLevel(0);
    }
  }

  renderMenuLinks = () => {
    const {
        binderSummary,
        classes
      } = this.props;


    return Array.isArray(binderSummary.reportingItems) ? binderSummary.reportingItems.map((x,index) => <MenuItem key={index} className={classnames(classes.menuItem, ReportingItemsUtil.getRIstateClasses(x, constants.DASHBOARD_CARD_TYPE_BINDER))}>
    <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={'View reporting item'}>
    <NavLink activeClassName={classnames(classes.activeMenuItem,'active')} to={UrlBuilderUtil.getBinderSummaryReportingItem(binderSummary.id, x.id ,x.projectId)}>
    
    <Grid container className="headerInfoWrap">
      <Grid item xs={3}>
        <Typography variant="caption" className={classnames('muted')} >
            { x.projectId ? '#'+x.projectId : '' }
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="caption" className={classnames('muted', classes.textRightStrong)} >
            {PositionsUtil.getUsernameFromStoreById(x.positionId)}
        </Typography>
      </Grid>
    </Grid>
    <div className="bodyInfoWrap">
      <div className="cardRIstateIndicator">
        <CheckIcon />
      </div>
        <div className={classnames(classes.menuItemText,'menuItemText')} >
          {x.name}
        </div>
    </div>
    
    </NavLink></Tooltip>
  </MenuItem>) : null
  }

  render() {
    const {
      classes,
      binderSummary,
      level,
    } = this.props;

    return (
        <div className={classnames("submenu", classes.submenu,classes.summaryPageSubmenu,classes['submenuLevel_'+level])}>
          <div className='submenuInnerWrap'>
          <Hidden mdUp>
                <MenuItem className={classnames(classes.menuItem)}><NavLink to={UrlBuilderUtil.getDashboard()}>
                <div className={classnames(classes.menuItemText,'menuItemText', 'backToBtn')} >
                <ChevronLeftIcon />  Back to dashboard
                </div>
              </NavLink>
              </MenuItem>
                </Hidden>
          { binderSummary ? <div>
            <BinderSummaryCard
                  expandable={false}
                  item={binderSummary} />
              
                <MenuList>

                {this.renderMenuLinks()}

                



                </MenuList>
              </div> : null }
          </div>
        </div>
    );
  }
}

BinderSummarySubmenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};


const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...uiActions,
      },
      dispatch
    )
  });


export default 
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, { withTheme: true })(BinderSummarySubmenu)
  );

