
export default (theme, themeConfig, themeColors) => {
    return {
        successIndicator: {
            backgroundColor: themeColors.customPalette.successIndicator,
            color: themeColors.customPalette.successIndicator,
          },
          errorIndicator: {
            backgroundColor: themeColors.primarySupportingPalette.pms200c,
            color: themeColors.primarySupportingPalette.pms200c,
          },
          warningIndicator: {
            backgroundColor: themeColors.secondarySupportingPalette.pms1575c,
            color: themeColors.secondarySupportingPalette.pms1575c,
          },
        
          successIndicatorText: {
            color: themeColors.customPalette.successIndicator,
          },
          errorIndicatorText: {
            color: themeColors.primarySupportingPalette.pms200c,
          },
          warningIndicatorText: {
            color: themeColors.secondarySupportingPalette.pms1575c,
          },
        
          
        
          statusIndicator: {
            display: 'block',
            width: 12,
            position: 'absolute',
            top: 1,
            right: 0,
            bottom: 0,
          },
    }
}