import React from 'react';
import objectPath from 'object-path';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import styles from '../../theme/styles';
import { CardContent, Typography, Grid, CardActions, Tooltip, Chip, Hidden, ButtonBase } from '@material-ui/core';
import classnames from 'classnames';
import { withRouter } from "react-router-dom";
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import ReportingItemsUtil from '../../utils/ReportingItemsUtil';
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import * as constants from '../../constants/constants';
import { NavLink } from "react-router-dom";

import CheckIcon from '@material-ui/icons/CheckOutlined';
import DateUtil from '../../utils/DateUtil';
import ActivitySummaryUtil from '../../utils/ActivitySummaryUtil';
import PositionIconName from '../PositionIconName';
import ActivitySummaryIcon from "@material-ui/icons/VerticalSplitOutlined";
import ArrayUtil from '../../utils/ArrayUtil';

class ActivitySummaryCard extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isOpen: true
        }
      }



    isOpen = () => {
        const { item, toggleCardReportingItemsById, expandedCardIds, expandable } = this.props;
        const { isOpen } = this.state;
        let itemId = objectPath.get(item, 'id', null);
        if(toggleCardReportingItemsById){ // dashboard fnc
            return (expandable && expandedCardIds && expandedCardIds.includes(itemId));
        } else if (expandable) {
            return isOpen
        }
    }

    toggleOpen = (itemId) => {
        const { toggleCardReportingItemsById } = this.props;
        if(toggleCardReportingItemsById){ // dashboard fnc
            toggleCardReportingItemsById(itemId);
        } else { //standalone for mobile submenu 
            this.setState({
                isOpen: !this.isOpen()
            })
        }
    }



    render() {
        const { classes, item, history, expandable, showCardResponsibilityStates } = this.props;
        let itemId = objectPath.get(item, 'id', null);
        
        const riCountChip = <ButtonBase 
        className="chipBtnWrap"
        onClick={() => ActivitySummaryUtil.isDetailReady(item) ? history.push(UrlBuilderUtil.getActivitySummary(itemId)) : this.toggleOpen(itemId)} 
        disabled={!expandable}><Chip
            label={ActivitySummaryUtil.getRIcompleteVSCount(item)}
            className={classes.chip}
            variant="outlined"
        /></ButtonBase>;

        const closedDate = objectPath.get(item, 'reportCloseDate', null);

        const desktopLink = UrlBuilderUtil.getActivitySummaryDashboardDesktop(item);
        

        return (
            <Card className={classnames('card',classes.dashboardCard, showCardResponsibilityStates ? ActivitySummaryUtil.getASstateClasses(item) : '')}>
                <CardContent>
                <span className="typeIndicator"></span>
                    <span className="bar"></span>
                    <Grid container className={classnames('cardHeading',classes.dashboardCardHeader)}>
                        <Grid item xs={6}>
                        { closedDate ? <Typography variant="caption" className="muted date">
                                <CalendarIcon className="calendarIcon" /> { DateUtil.basicDate(closedDate, true) }
                            </Typography> : null }
                        </Grid>
                        <Grid item xs={6} className="positionColumnWrap">
                            <Typography variant="caption" className={classnames('muted', classes.textRightStrong)} >
                                <PositionIconName positionIds={item.positionId} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12}>
                            {expandable ? <div>
                            { /* mobile link to AS menu*/ }
                            <Hidden mdUp><Typography variant="h5">
                            <NavLink className="cardMainActionElement" to={UrlBuilderUtil.getActivitySummaryMenu(itemId)}>
                                <strong>{ActivitySummaryUtil.adjustASname(item.name)}</strong>
                                <span className="fullPseudoAfter"></span>
                                </NavLink>
                            </Typography>
                            </Hidden>

                            { /* desktop link to AS */ }
                            <Hidden smDown><Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Go to activity summary review"><Typography variant="h5">
                            <NavLink className="cardMainActionElement" to={desktopLink}>
                                <strong>{ActivitySummaryUtil.adjustASname(item.name)}</strong>
                                <span className="fullPseudoAfter"></span></NavLink>
                            </Typography></Tooltip></Hidden>
                            </div>
                                :
                                <Typography variant="h5"><strong>{ActivitySummaryUtil.adjustASname(item.name)}</strong></Typography>}
                        </Grid>
                        {this.isOpen() ? 
                        (ArrayUtil.isNonEmptyArray(item.reportingItems) ? 
                            item.reportingItems.map((x, index) => <Grid item xs={12} key={index} className={classnames(classes.cardReportingItemLink, ReportingItemsUtil.getRIstateClasses(x, constants.DASHBOARD_CARD_TYPE_AS))}>
                            <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="View progress in activity summary view">
                            <NavLink 
                            to={UrlBuilderUtil.getActivitySummaryDefaultReportingItem(itemId, x.id ,x.projectId)}>
                            <Typography variant="body2">
                                <span className='cardRIstateIndicator'><CheckIcon /></span><span>{x.name}</span>
                                <PositionIconName positionIds={x.positionId} />
                            </Typography></NavLink></Tooltip>
                        </Grid>) : <Typography className="muted" variant="body2">No reporting items found</Typography>) : null}
                    </Grid>
                </CardContent>
                <CardActions className={classes.cardBottomActions}>
                    <ActivitySummaryIcon fontSize="small"  className="cardTypeIcon" />
                    <Typography className="leftTextWrap muted"> </Typography>
                    {riCountChip}
                </CardActions>
            </Card>
        );
    }

}

export default withRouter(withStyles(styles, { withTheme: true })(ActivitySummaryCard));