export default (theme, themeConfig, themeColors) => {
    const fontSize = 14; //themeConfig.smallFontSize;
    return {
        ASSnapshotFilterWrap: {
            paddingTop: theme.spacing(1) * 3,
        },

        headerFilterWrap: {
            zIndex: 1201,
            width: '100%',
            minHeight: themeConfig.toolbarMinHeight,
            fontSize: fontSize,
            color: themeColors.primaryPalette.primary,
            [theme.breakpoints.up('md')]: {
                '& .gridContainer': {
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                },
            },
            '& .controlLabel, & label': {
                color: themeColors.primaryPalette.primary,
                fontSize: fontSize,
            },
            '& .recentFilters': {
                display: 'inline-block',
                float: 'right',
                '& button svg': {
                    marginRight: 8,
                },
            },
            '& .filterConfirmBtn': {
                marginLeft: 0,
            },
            '& .filterBtn': {
                backgroundColor: 'transparent',

                '& svg': {
                    fontSize: 18,
                    stroke: themeConfig.setImportant(
                        themeColors.primaryPalette.primary
                    ),
                },
            },
            '&.open': {
                minHeight: themeConfig.toolbarMinHeight * 2,
                '& .filterBtn': {
                    backgroundColor: themeColors.primaryPalette.primary,
                    '& svg': {
                        stroke: themeConfig.setImportant(
                            themeColors.primaryPalette.white
                        ),
                        color: themeConfig.setImportant(
                            themeColors.primaryPalette.white
                        ),
                    },
                },
            },
            '& button': {
                marginLeft: theme.spacing(1) * 2,
            },
            '& .filter-select__control': {
                borderWidth: 0,
                '&.filter-select__control--is-focused': {
                    boxShadow:
                        '0 0 0 1px ' + themeColors.primaryPalette.primary,
                },
                '& .filter-select__indicator-separator': {
                    display: 'none',
                },
            },
            '& .filter-select__option': {
                '&:active, &.filter-select__option--is-focused': {
                    backgroundColor: themeColors.customPalette.tableRowBorder,
                },
            },
            '& .keywordSearch': {
                '& > div': {
                    cursor: 'pointer',
                    borderRadius: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    paddingTop: theme.spacing(1),
                },
                width: '100%',
                '& input': {
                    fontSize: fontSize,
                },
                '&::after, &::before': {
                    display: 'none',
                },
            },
        },
        innerPageHeaderLikeWrap: {
            [theme.breakpoints.up('md')]: {
                position: 'fixed',
                top: 0,
                width: 'auto',
                right: theme.spacing(1) * 3,
                left: themeConfig.drawerWidthLevel_1 + theme.spacing(1) * 4,
                zIndex: 1101,
            },
            '& span': {
                color: themeColors.primaryPalette.primary,
            },
        },
    };
};
