import objectPath from 'object-path';
import * as constants from '../constants/constants';

export default class ProgressReportUtil {

    static showFinancialOverviewVarianceExplanation(progressReport){
        var sectionObject = objectPath.get(progressReport,'financialOverview');
        return sectionObject && (sectionObject.costAssessment === constants.ASSESSMENT_NOTMET || sectionObject.costAssessment === constants.ASSESSMENT_PARTIALMET);
    }

    static showKPIExplanation(kpiOverviewKPIList){
        return (
            Array.isArray(kpiOverviewKPIList) 
            && 
            kpiOverviewKPIList.filter(x => (x.targetMet && x.targetMet.toString() === "true")).length !== kpiOverviewKPIList.length
            ) ? true : false;
    }

    static showRiskExplanation(progressReport, currentSelectedRiskCAR){
        const previousProgressReportRiskCAR = objectPath.get(progressReport,'previousProgressReport.risk.riskCAR',null);

        return previousProgressReportRiskCAR && (currentSelectedRiskCAR !== previousProgressReportRiskCAR);
    }

    static showForecastOnTimeFields(currentSelectedForecastOnTime){
        return  currentSelectedForecastOnTime === "false"
    }

    static showForecastCostFields(currentSelectedForecastCost){
        return  (currentSelectedForecastCost === constants.COST_FORECAST_OVER_BUDGET 
            || 
            currentSelectedForecastCost === constants.COST_FORECAST_UNDER_BUDGET)
    }

    static showAdditionalFundingRequiredFields(currentSelectedForecastAdditionalFundingRequired){
        return  currentSelectedForecastAdditionalFundingRequired === "true";
    }

    static emptyConditionalFields(progressReport){
        //FINANCIAL OVERVIEW
        if(!ProgressReportUtil.showFinancialOverviewVarianceExplanation(progressReport)){
            objectPath.set(progressReport, 'financialOverview.varianceExplanation',null);
        }
        //RISK SECTION
        if(!ProgressReportUtil.showRiskExplanation(progressReport, objectPath.get(progressReport, 'risk.riskCAR'))){
            objectPath.set(progressReport, 'risk.riskCommentary',null);
        }
        //KPI
        if(!ProgressReportUtil.showKPIExplanation(objectPath.get(progressReport, 'kpiOverview.kpiList'))){
            objectPath.set(progressReport, 'kpiOverview.kpiCommentary',null);
        }
        //PROJECT FORECAST
        if(!ProgressReportUtil.showForecastOnTimeFields(objectPath.get(progressReport, 'projectForecast.onTime'))){
            objectPath.set(progressReport, 'projectForecast.onTimeReason',null);//comment
        }
        if(!ProgressReportUtil.showForecastCostFields(objectPath.get(progressReport, 'projectForecast.costForecast'))){
            objectPath.set(progressReport, 'projectForecast.costForecastReason',null);//comment
            objectPath.set(progressReport, 'projectForecast.costForecastOverAmount',null);//number
        }
        if(!ProgressReportUtil.showAdditionalFundingRequiredFields(objectPath.get(progressReport, 'projectForecast.additionalFundingRequired'))){
            objectPath.set(progressReport, 'projectForecast.additionalFundingReason',null);//comment
            objectPath.set(progressReport, 'projectForecast.additionalFundingAmount',null);//number
        }

        return progressReport;
    }

}
