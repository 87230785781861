import * as types from '../constants/actionTypes';
import ConsoleUtil from '../utils/ConsoleUtil';

export function getReportingParameters(state = null, action) {
    if (action.type === types.GET_REPORTING_PARAMETERS) {
        ConsoleUtil.log(types.GET_REPORTING_PARAMETERS+' set in store');
        return action.data.data;
    }
    return state;
}

export function getReportingTargetParameters(state = null, action) {
    if (action.type === types.GET_REPORTING_TARGET_PARAMETERS) {
        ConsoleUtil.log(types.GET_REPORTING_TARGET_PARAMETERS+' set in store');
        return action.data.data;
    }
    return state;
}