import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import objectPath from 'object-path';
import { Grid, Divider, Typography } from '@material-ui/core';
import PreviousComment from './PreviousComment';
import * as formNames from '../../constants/forms';
import { formValueSelector } from 'redux-form'
import {
  TextLabelColumnsInput,
  RatingNumberInput
} from "../inputs";
import { Field } from "redux-form";
import { connect } from "react-redux";
import ProgressReportUtil from '../../utils/ProgressReportUtil';


class RiskSection extends React.Component {




  render(){


  const { item, currentSelectedRiskCAR } = this.props;
  const progressReportCurrentRiskLevel = objectPath.get(item,'risk.riskLevel', 'N/A');

  
  const previousProgressReportRiskCAR = objectPath.get(item,'previousProgressReport.risk.riskCAR',null);
  const previousProgressReportRiskComment = objectPath.get(item,'previousProgressReport.risk.riskCommentary','');
  const previousProgressReportRiskCommentDate = objectPath.get(item,'previousProgressReport.risk.riskCommentaryDts');

  const riskOptions = ["1","2","3","4","5","6","7","8","9","10"];

  return <Grid container>
  <Grid item className="gridItem" xs={12}>
    <Typography variant="h4">RISK</Typography>
  </Grid>
  <Grid item className="gridItem sectionOverviewItem" xs={12}>
  <Typography variant="body2"><strong>Risk:</strong> {progressReportCurrentRiskLevel}</Typography>
    
  </Grid>

  <Grid item className="gridItem sectionSidePaddingItem" xs={12}>
  <Field name="risk.riskCAR" component={RatingNumberInput} previousValue={previousProgressReportRiskCAR} helpText="help text" range={riskOptions} label="Control Adequancy Rating" />
  </Grid>

  <Grid item className="gridItem" xs={12} sm={6} md={7}>
{ ProgressReportUtil.showRiskExplanation(item,currentSelectedRiskCAR) ? <Field name="risk.riskCommentary" component={TextLabelColumnsInput} label="Change Explanation" multiline={true} rows="4" /> : null }
  
    
    
</Grid>
<Grid item className="gridItem" xs={12} sm={6} md={5}>
{ previousProgressReportRiskComment.length ?  <PreviousComment date={previousProgressReportRiskCommentDate} text={previousProgressReportRiskComment} /> : null }
</Grid>   

<Grid item className="gridItem" xs={12}>
  <Divider className="sectionDivider"/>
</Grid>
</Grid>
}
}

RiskSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const selector = formValueSelector(formNames.PROGRESS_REPORT_FORM_NAME);

export default connect(state => ({
  currentSelectedRiskCAR: selector(state, 'risk.riskCAR'),
}))(withStyles(styles, { withTheme: true })(RiskSection));