import React from 'react'
import AppCard from '../../components/AppCard';
import { Typography, Button } from '@material-ui/core';

const NoMatch = ({ location }) => (
  <div>
    <AppCard type="error">
          <Typography variant="h1">404 - Page not found</Typography>
          <br />
          <Typography variant="body1">
          Requested page does not exist <code><br />{location.pathname.indexOf('404') === -1 ? location.pathname : ''}</code>
          </Typography>
          <br />
          <br />
          <Button href="/" variant="contained" color="default">Home page</Button>
    </AppCard>
  </div>
)

export default NoMatch
