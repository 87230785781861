import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import ReportingItemForm from "../../../components/forms/reportingItem/form";
import * as reportingItems from "../../../actions/reportingItem";
import * as projects from "../../../actions/project";
import * as constants from "../../../constants/constants";
import * as formNames from "../../../constants/forms";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import store from '../../../store';
import SnackBarUtil from '../../../utils/SnackBarUtil';
import PositionsUtil from '../../../utils/PositionsUtil';
import StrategicPlansUtil from '../../../utils/StrategicPlansUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import AppDialog from "../../../components/AppDialog";
import { Button, DialogActions } from "@material-ui/core";
import { change } from 'redux-form';
import FormatterUtil from "../../../utils/FormatterUtil";
import UrlBuilderUtil from "../../../utils/UrlBuilderUtil";

const entityName = "ReportingItem";
const entityFormName = formNames.REPORTING_ITEM_FORM_NAME;


class EditReportingItem extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true,
      project: null,
      displayConfirmation: false
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }


  mountItem(){
    const { actions, actionType, match } = this.props;
    const _this = this;
    var itemId = objectPath.get(match, "params.id");
    if(actionType === "edit" && itemId){
      return actions['get'+entityName](itemId).then( response => {
        return _this.setState({
          item: objectPath.get(response, "data.data"),
          linkedProjectId: null
        })
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: {
            commentaryFrequency: constants.DEFAULT_COMMENTARY_FREQUENCY
          },
        }, resolve);
      });
    }
  }

  handleResponse = response => {
    const { enqueueSnackbar, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar);
      setImmediate(() => {
        history.push(UrlBuilderUtil.getReportingItemList());
      });
    }
  }

  handleSubmit = values => {
    const { actions, actionType, match } = this.props;
    let action = actions['post'+entityName];
    if(actionType !== "create") {
      action = actions['put'+entityName];
      values.id = objectPath.get(match, 'params.id');
    }
    //has to return promise so submitting flag works!
    return action(values).then(response => this.handleResponse(response));
  };

  getActivityOptions = () => {
    let strategicPlans = store.getState()['strategicPlans'];
    return StrategicPlansUtil.formatForActivitySelector(strategicPlans);
    
  }

  getPositionOptions = () => {
    const { item } = this.state;
    let positions = store.getState()['positions'];
    return PositionsUtil.formatForProjectManagerSelector(positions, item);
  }

  

  handleSearchForProject = () => {
    const { enqueueSnackbar, actions } = this.props;
    const form = store.getState()['form'];
    const _this = this;
    const searchProjectId = objectPath.get(form, entityFormName+'.values.searchProjectId','');

    if(searchProjectId.length){
      actions.getUnlinkedProjects(searchProjectId).then( response => {
        if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
          return _this.setState({
            project: objectPath.get(response, "data.data")
          })  
        }
      });
    }
  }

  linkProject = () => {
    const { project } = this.state;
    const { changeFieldValue } = this.props;

    if(project){
      //overwrite values
      
      changeFieldValue( 'searchProjectId', '' );//remove searched project
      
      changeFieldValue( 'projectId', project.id );// not in array of fields because of different prop
      constants.OVERWRITE_REPORTING_ITEMS_FIELDS.forEach(fN => {
        changeFieldValue( fN, objectPath.get(project, fN) );
      });

      this.setState({
        project: null,//remove searched project,
        displayConfirmation: false
      })
    }
  }

  getLinkedProjectId = () => {
    const form = store.getState()['form'];
    return objectPath.get(form, entityFormName+'.values.projectId',null);
  }

  onLinkProject = () => {
    
    const form = store.getState()['form'];
    let values = objectPath.get(form, entityFormName+'.values',null);
    
    let hasAnyFieldValue = false; 
    constants.OVERWRITE_REPORTING_ITEMS_FIELDS.forEach(fN => {
      if(!hasAnyFieldValue && objectPath.get(values, fN)){
        hasAnyFieldValue = true;
      }
    });
    if(hasAnyFieldValue){ // if any field value show confirmation
      this.setState({
        displayConfirmation: true
      })
    }else{
      this.linkProject();
    }
  }


  hideConfirmation = () => {
    this.setState({
      displayConfirmation: false
    })
  }

  renderConfirmationDialog = () => {
    const { project } = this.state;
    const form = store.getState()['form'];
    let currentRIname = objectPath.get(form, entityFormName+'.values.name','');
    let message = "Please confirm you wish to convert the Reporting Item "+currentRIname+" and link to project #"+project.id+": "+project.name+". The current reporting item name, activity, description and project manager will be overwritten by the Corp details";

    return (<AppDialog 
      title="Are you sure?"
    message={message}
    onClose={this.hideConfirmation}
    actions={
      <DialogActions>
        <Button variant="text" onClick={this.hideConfirmation}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={this.linkProject}>Confirm</Button>
      </DialogActions>
    } />)
  }

  render() {
    const { actionType } = this.props;
    const { item, dataLoading, project, displayConfirmation } = this.state;
    

    return (
      <div>
        <PageHeading heading={(actionType === "create" ? "New" : "Update")+" "+FormatterUtil.camelToNormalForm(entityName)} actions={
          <div>
            <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getReportingItemList(null,null,true)} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 
      <ReportingItemForm 
        proceedSubmit={this.handleSubmit} 
        searchForProject={this.handleSearchForProject}
        onLinkProject={this.onLinkProject}
        project={project}
        positionOptions={this.getPositionOptions()} 
        activityOptions={this.getActivityOptions()} 
        initialValues={item}
        projectId={this.getLinkedProjectId()}

      /> }
      { displayConfirmation ? this.renderConfirmationDialog() : null}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      
      ...reportingItems,
      ...projects,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditReportingItem)
));