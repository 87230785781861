// AJAX
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";

// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

// UI
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const SET_CONTENT_SUBMENU_LEVEL = "SET_CONTENT_SUBMENU_LEVEL";

// GROUP CATEGORIES HIERARCHY
export const GET_STRATEGIC_PLANS = "GET_STRATEGIC_PLANS";

// USERS
export const GET_USERS = "GET_USERS";
export const GET_KEYCLOAK_USERS = "GET_KEYCLOAK_USERS";

// POSITIONS
export const GET_POSITIONS = "GET_POSITIONS";

// AUDIENCES
export const GET_AUDIENCES = "GET_AUDIENCES";

// INFO TEXT
export const GET_INFO_TEXT = "GET_INFO_TEXT";

// SERVER PARAMS
export const GET_REPORTING_PARAMETERS = "GET_REPORTING_PARAMETERS";
export const GET_REPORTING_TARGET_PARAMETERS = "GET_REPORTING_TARGET_PARAMETERS";

// PROJECT PHASES
export const GET_PROJECT_PHASES = "GET_PROJECT_PHASES";

// REPORTING ITEMS
export const GET_REPORTING_ITEMS = "GET_REPORTING_ITEMS";
export const GET_REPORTING_ITEM = "GET_REPORTING_ITEM";
export const POST_REPORTING_ITEM = "POST_REPORTING_ITEM";
export const PUT_REPORTING_ITEM = "PUT_REPORTING_ITEM";
export const GET_REPORTING_ITEM_SUMMARY_LIST = "GET_REPORTING_ITEM_SUMMARY_LIST";
export const GET_REPORTING_ITEM_SUMMARY = "GET_REPORTING_ITEM_SUMMARY";
export const GET_ALL_REPORTING_ITEMS = "GET_ALL_REPORTING_ITEMS";

export const GET_REPORTING_ITEM_COMMENTARY = "GET_REPORTING_ITEM_COMMENTARY";
export const POST_REPORTING_ITEM_COMMENTARY = "POST_REPORTING_ITEM_COMMENTARY";
export const GET_REPORTING_ITEM_TIMELINE = "GET_REPORTING_ITEM_TIMELINE";
export const GET_REPORTING_ITEM_HISTORY_COMMENTARY = "GET_REPORTING_ITEM_HISTORY_COMMENTARY";
export const POST_REPORTING_ITEM_QUICK_COMMENTARY = "POST_REPORTING_ITEM_QUICK_COMMENTARY";


export const PUT_WHAT_NEXT_COMMENT = "PUT_WHAT_NEXT_COMMENT";
export const PUT_WHAT_HAPPENED_COMMENT = "PUT_WHAT_HAPPENED_COMMENT";
export const PUT_QUICK_COMMENT = "PUT_QUICK_COMMENT";

// ACTIVITY SUMMARY
export const GET_ACTIVITY_SUMMARIES = "GET_ACTIVITY_SUMMARIES";
export const GET_ACTIVITY_SUMMARY = "GET_ACTIVITY_SUMMARY";
export const GET_ACTIVITY_SUMMARY_REVIEW = "GET_ACTIVITY_SUMMARY_REVIEW";
export const POST_ACTIVITY_SUMMARY_REVIEW = "POST_ACTIVITY_SUMMARY_REVIEW";
export const POST_ACTIVITY_SUMMARY_REVIEW_DRAFT = "POST_ACTIVITY_SUMMARY_REVIEW_DRAFT";

// REPORTING OUTPUTS
export const GET_REPORT_OUTPUTS = "GET_REPORT_OUTPUTS";
export const GET_REPORT_OUTPUTS_BY_BINDER_ID = "GET_REPORT_OUTPUTS_BY_BINDER_ID";
export const GET_REPORT_OUTPUT = "GET_REPORT_OUTPUT";
export const POST_REPORT_OUTPUT = "POST_REPORT_OUTPUT";
export const PUT_REPORT_OUTPUT = "PUT_REPORT_OUTPUT";
export const DELETE_REPORT_OUTPUT = "DELETE_REPORT_OUTPUT";

// REPORTING SCHEDULES
export const GET_REPORT_SCHEDULES = "GET_REPORT_SCHEDULES";
export const GET_REPORT_SCHEDULE = "GET_REPORT_SCHEDULE";
export const POST_REPORT_SCHEDULE = "POST_REPORT_SCHEDULE";
export const PUT_REPORT_SCHEDULE = "PUT_REPORT_SCHEDULE";
export const DELETE_REPORT_SCHEDULE = "DELETE_REPORT_SCHEDULE";


// BINDERS
export const GET_BINDER_CATEGORIES_SUMMERY = "GET_BINDER_CATEGORY_SUMMARY";
export const GET_BINDER_CATEGORY = "GET_BINDER_CATEGORY";
export const POST_BINDER_CATEGORY = "POST_BINDER_CATEGORY";
export const PUT_BINDER_CATEGORY = "PUT_BINDER_CATEGORY";
export const DELETE_BINDER_CATEGORY = "DELETE_BINDER_CATEGORY";

export const POST_BINDER = "POST_BINDER";
export const PUT_BINDER = "PUT_BINDER";
export const GET_BINDER = "GET_BINDER";
export const DELETE_BINDER = "DELETE_BINDER";


export const GET_BINDER_SUMMARY = "GET_BINDER_SUMMARY";

export const GET_BINDER_REVIEW_REPORTING_ITEM_COMMENTARY = "GET_BINDER_REVIEW_REPORTING_ITEM_COMMENTARY";
export const POST_BINDER_COMMENTARY_REVIEW = "POST_BINDER_COMMENTARY_REVIEW";
export const POST_BINDER_COMMENTARY_DOCS_PRINT = "POST_BINDER_COMMENTARY_DOCS_PRINT";

// PROGRESS REPORT
export const PUT_PROGRESS_REPORT = "PUT_PROGRESS_REPORT";
export const GET_PROGRESS_REPORT = "GET_PROGRESS_REPORT";

//PROJECT
export const GET_UNLINKED_PROJECT = "GET_UNLINKED_PROJECT";

// USER ROLES
export const SET_USER_INFO_SUCCESS = "SET_USER_INFO_SUCCESS";
export const SET_USER_INFO_ERROR = "SET_USER_INFO_ERROR";

//DASHBOARD
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const SET_MERGED_DASHBOARD_DATA = "SET_MERGED_DASHBOARD_DATA";

//OBJECTIVE
export const GET_OBJECTIVE_DATA = "GET_OBJECTIVE_DATA";
export const SET_OBJECTIVE_DATA = "SET_OBJECTIVE_DATA";


