import objectPath from 'object-path';
import ValidationUtil from '../../../../utils/ValidationUtil';

const validate = (values, {registeredFields} = {}) => {


  let errors = {};


  const requiredFields = [
    'financialOverview.varianceExplanation',

    'risk.riskCAR',
    'risk.riskCommentary',

    'projectForecast.onTime',
    'projectForecast.onTimeReason',
    'projectForecast.costForecast',
    'projectForecast.costForecastOverAmount',
    'projectForecast.costForecastReason',
    'projectForecast.additionalFundingRequired',
    'projectForecast.additionalFundingAmount',
    'projectForecast.additionalFundingReason',
    
    'kpiOverview.kpiCommentary',

    'status.percentComplete',
    'status.pmAssessment',
  ];

  //add milestones percentage as required
  let percentageFields = objectPath.get(values, 'milestoneOverview.milestoneList', []);
  percentageFields.forEach((x,index) => {
    requiredFields.push('milestoneOverview.milestoneList.'+index+'.percentComplete');
  });
  //validate required
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  
  //validate percentage field
  percentageFields = percentageFields.map((x,index) => 'milestoneOverview.milestoneList.'+index+'.percentComplete');
  percentageFields.push('status.percentComplete');
  errors = ValidationUtil.checkPercentageFields(percentageFields,values,errors);

  

  let integerFields = [
    'projectForecast.costForecastOverAmount',
    'projectForecast.additionalFundingAmount',
  ]


  errors = ValidationUtil.checkIntegerMinMax(integerFields,values,errors, 0, 100000000);
  



  return errors;
};

export default validate;
