import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import objectPath from 'object-path';

import styles from "../../../theme/styles";
import HeadingBackBtn from "../../buttons/HeadingBackBtn";

//config
import { MenuList, Typography, Hidden } from "@material-ui/core";
import DrawerMenuItem from "../AppMenuItem";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as uiActions from "../../../actions/ui";
import StoreUtil from "../../../utils/StoreUtil";

class SubMenu extends React.Component {

  renderMenuLinks = (items, showIcon) => {
    const {
      userInfo,
    } = this.props;

    return items.map((m, index) => {
      return <DrawerMenuItem menuItem={m} key={index} showIcon={showIcon} userInfo={userInfo} />
    })
  }

  componentDidMount() {
    const { actions, level } = this.props;
    let contentSubmenuLevel = StoreUtil.getValueByKey('contentSubmenuLevel');
    if(level > contentSubmenuLevel){
      actions.setContentSubmenuLevel(level);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    let contentSubmenuLevel = StoreUtil.getValueByKey('contentSubmenuLevel');
    if(contentSubmenuLevel !== 0){
      actions.setContentSubmenuLevel(0);
    }
  }

  render() {
    const {
      items,
      classes,
      groups,
      level,
      previousLevelBackBtnUrl
      //theme,
    } = this.props;


    return (
        <div className={classnames("submenu", classes.submenu,classes['submenuLevel_'+level])}>
          {level > 1 && previousLevelBackBtnUrl ? <Hidden only={['xs','sm','lg','xl']}>
            <div className={classes.submenuBackBtn}>
              <HeadingBackBtn redirectToUrl={previousLevelBackBtnUrl} />
            </div>
          </Hidden> : null }
          <div className='submenuInnerWrap'>
            {
              groups ? items.map((i,index) => 
                <div key={index}>
                  <Typography variant="h4">{i.name}</Typography>
                  <MenuList>{this.renderMenuLinks(i.children, false)}</MenuList>
                </div>
                ) : <div>
                <Typography variant="h4">{objectPath.get(items, '0.parentLabel')}</Typography>
                <MenuList>{this.renderMenuLinks(items, false)}</MenuList>
              </div>
            }
            
          </div>
        </div>
    );
  }
}

SubMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};



const mapStateToProps = (state) => ({
  routerLocations: state.routerLocations//force rerender on location change
});

const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...uiActions,
      },
      dispatch
    )
  });

export default 
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, { withTheme: true })(SubMenu)
  );
