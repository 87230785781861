import React from "react";
import { reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../theme/styles';
import classnames from 'classnames';
import * as formNames from '../../../../constants/forms';
import * as constants from '../../../../constants/constants';
import objectPath from 'object-path';
import UiHelperUtil from '../../../../utils/UiHelperUtil';




import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import TickIcon from "@material-ui/icons/Check";
import { Grid, Tooltip } from "@material-ui/core";
import warnAboutUnsavedForm from "../../../../hoc/withWarnAboutUnsavedChanges";
import FinanciallyOverviewSection from "../../../reportingItemProgress/FinanciallyOverviewSection";
import RiskSection from "../../../reportingItemProgress/RiskSection";
import MilestonesSection from "../../../reportingItemProgress/MilestonesSection";
import KpiSection from "../../../reportingItemProgress/KpiSection";
import ProjectForecastSection from "../../../reportingItemProgress/ProjectForecastSection";
import OverallStatus from "../../../reportingItemProgress/OverallStatus";
import ChildrenProjects from "../../../reportingItemProgress/ChildrenProjects";


class ProgressReportForm extends React.Component {

  render () {
    const {
      handleSubmit,
      submitting,
      classes,
      proceedSubmit,
      readOnly,
      initialValues,
      viewProgressReportById
    } = this.props;

    const disableSubmit = submitting;
    const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><TickIcon /></Fab>;

    const progressReportAssessmentType= objectPath.get(initialValues,'assessmentType');
    


    return  <div>
    <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classnames(classes.customForm, classes.progressForm, readOnly && classes.readOnlyForm)}>
    


      
        
      <div className="maxWidthPart"><Grid container>
      
<ChildrenProjects item={initialValues} viewProgressReportById={viewProgressReportById} />

{
  [constants.PROGRESS_REPORT_FINANCIAL_TYPE,constants.PROGRESS_REPORT_MILESTONE_TYPE].includes(progressReportAssessmentType) ? 
  <FinanciallyOverviewSection item={initialValues}/> : null
}



<RiskSection item={initialValues}/>


{
  [constants.PROGRESS_REPORT_FINANCIAL_TYPE,constants.PROGRESS_REPORT_MILESTONE_TYPE].includes(progressReportAssessmentType) ? 
  <MilestonesSection item={initialValues} progressReportAssessmentType={progressReportAssessmentType} /> : null
}


{
  constants.PROGRESS_REPORT_KPI_TYPE === progressReportAssessmentType ? 
  <KpiSection item={initialValues} /> : null
}


{
  constants.PROGRESS_REPORT_KPI_TYPE !== progressReportAssessmentType ? 
<ProjectForecastSection item={initialValues} /> : null
}

<OverallStatus item={initialValues} progressReportAssessmentType={progressReportAssessmentType} />



{ readOnly ? null : <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12}>
{ disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Update progress report summary" aria-label="Update">{submitButton}</Tooltip> }
</Grid> }

</Grid></div>



        
        
      
    </form>
</div>
  }
}


const EditableProgressReportForm = warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.PROGRESS_REPORT_FORM_NAME, // a unique identifier for this form
  validate: validate,
  onSubmitFail: UiHelperUtil.scrollToFirstErrorMessage,
})(ProgressReportForm)),formNames.PROGRESS_REPORT_FORM_NAME);

export {
  EditableProgressReportForm
}
