export default (theme, themeConfig, themeColors) => {
    return {
        content: {
            minHeight: '100vh',
            position: 'relative',
            flexGrow: 1,
            padding: theme.spacing(1) * 2,
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(1) * 3,
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
                paddingBottom: themeConfig.toolbarMinHeight * 2,
                minHeight: '100vh',
            },
            paddingTop: '0 !important', //there is header div placeholder
            paddingBottom: `${
                themeConfig.toolbarMinHeight + themeConfig.commonPadding * 2
            }px !important`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: 0,
            '& .fullWidthBgComponent': {
                padding: themeConfig.setSpacing(
                    theme.spacing(1),
                    themeConfig.commonPadding * 2
                ),
                margin: themeConfig.setSpacing(0, -themeConfig.commonPadding),
                [theme.breakpoints.up('lg')]: {
                    margin: themeConfig.setSpacing(0, -(theme.spacing(1) * 3)),
                },
                [theme.breakpoints.down('xs')]: {
                    margin: themeConfig.setSpacing(0, -theme.spacing(1)),
                    padding: themeConfig.setSpacing(
                        theme.spacing(1) * 1.5,
                        themeConfig.commonPadding
                    ),
                },
            },
            '& .countChip': {
                height: 'auto',
                marginRight: 3,
                '& > span': {
                    paddingRight: 10,
                    paddingLeft: 10,
                    minHeight: 24,
                    fontSize: themeConfig.smallFontSize,
                    fontWeight: 600,
                    lineHeight: '20px',
                },
                '&.warning': {
                    borderColor:
                        themeColors.secondarySupportingPalette.pms1575c,
                    color: themeColors.secondarySupportingPalette.pms1575c,
                },
                '&.error': {
                    borderColor: themeColors.primarySupportingPalette.pms200c,
                    backgroundColor:
                        themeColors.primarySupportingPalette.pms200c,
                    color: themeColors.primaryPalette.white,
                },
            },
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.between('md', 'lg')]: {
                minHeight: '100vh',
            },
        },
        contentShiftLevel_1: {
            [theme.breakpoints.up('md')]: {
                marginLeft: themeConfig.drawerWidthLevel_1,
                width: `calc(100% - ${themeConfig.drawerWidthLevel_1}px)`,
            },
        },
        contentShiftLevel_2: {
            [theme.breakpoints.up('md')]: {
                marginLeft: themeConfig.drawerWidthLevel_2,
            },
        },
        contentShiftLevel_3: {
            [theme.breakpoints.up('md')]: {
                marginLeft: themeConfig.drawerWidthLevel_3,
            },
        },
        contentWithoutHeader: {
            paddingTop: theme.spacing(1) * 3,
        },
    };
};
