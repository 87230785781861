import React from 'react';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../theme/styles';
import classnames from 'classnames';
import * as formNames from '../../../../constants/forms';
import * as constants from '../../../../constants/constants';
import UiHelperUtil from '../../../../utils/UiHelperUtil';
import validate from './validate';
import Fab from '@material-ui/core/Fab';
import TickIcon from '@material-ui/icons/Check';
import { Grid, Divider, Tooltip } from '@material-ui/core';
import warnAboutUnsavedForm from '../../../../hoc/withWarnAboutUnsavedChanges';

import CommentPerGroup from '../../../commentary/CommentPerGroup';

class ReportingItemCommentaryForm extends React.Component {
    render() {
        const {
            handleSubmit,
            //pristine,
            submitting,
            classes,
            proceedSubmit,
            readOnly,
            //viewProgressReportById
        } = this.props;

        const disableSubmit = submitting;
        const submitButton = (
            <Fab color="secondary" type="submit" size="large">
                <TickIcon />
            </Fab>
        );

        return (
            <div>
                <form
                    onSubmit={handleSubmit((values) => proceedSubmit(values))}
                    className={classnames(
                        classes.customForm,
                        classes.progressForm,
                        readOnly && classes.readOnlyForm
                    )}
                >
                    <div className="maxWidthPart">
                        <Grid container>
                            <Grid item className="gridItem" xs={12}>
                                <CommentPerGroup
                                    heading="What's Happened?"
                                    objectPathUserId="whatsHappenedComment.commentedUserId"
                                    objectPathCreatedDate="whatsHappenedComment.createdDate"
                                    copyable={true}
                                    formName={
                                        formNames.REPORTING_ITEM_COMMENTARY_FORM_NAME
                                    }
                                    fields={[
                                        {
                                            label: 'Elected Members',
                                            name: 'whatsHappenedComment.electedMemberComment',
                                            placeholder:
                                                'Enter what has happend...',
                                        },
                                        {
                                            label: 'Public',
                                            name: 'whatsHappenedComment.publicComment',
                                            placeholder:
                                                'Enter what has happend...',
                                        },
                                        {
                                            label: 'Management',
                                            name: 'whatsHappenedComment.managementComment',
                                            placeholder:
                                                'Enter what has happend...',
                                        },
                                    ]}
                                />
                            </Grid>

                            <Grid item className="gridItem" xs={12}>
                                <Divider className="sectionDivider" />
                            </Grid>

                            <Grid item className="gridItem" xs={12}>
                                <CommentPerGroup
                                    heading="What's Next?"
                                    copyable={true}
                                    formName={
                                        formNames.REPORTING_ITEM_COMMENTARY_FORM_NAME
                                    }
                                    objectPathUserId="whatsNextComment.commentedUserId"
                                    objectPathCreatedDate="whatsNextComment.createdDate"
                                    fields={[
                                        {
                                            label: 'Elected Members',
                                            name: 'whatsNextComment.electedMemberComment',
                                            placeholder: "Enter what's next...",
                                        },
                                        {
                                            label: 'Public',
                                            name: 'whatsNextComment.publicComment',
                                            placeholder: "Enter what's next...",
                                        },
                                        {
                                            label: 'Management',
                                            name: 'whatsNextComment.managementComment',
                                            placeholder: "Enter what's next...",
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <div
                            className={classnames(
                                'narrowMaxMediumWidthPart sectionVerticalPaddingItem',
                                classes.textRightStrong
                            )}
                        >
                            {disableSubmit ? (
                                submitButton
                            ) : (
                                <Tooltip
                                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                    title="Update comments"
                                >
                                    {submitButton}
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default warnAboutUnsavedForm(
    withStyles(styles, { withTheme: true })(
        reduxForm({
            form: formNames.REPORTING_ITEM_COMMENTARY_FORM_NAME, // a unique identifier for this form
            validate: validate,
            onSubmitFail: UiHelperUtil.scrollToFirstErrorMessage,
        })(ReportingItemCommentaryForm)
    ),
    formNames.REPORTING_ITEM_COMMENTARY_FORM_NAME
);
