
import ValidationUtil from '../../../utils/ValidationUtil';
import objectPath from 'object-path';

const validate = values => {
  let errors = {};
  let requiredFields = [
    "name",
  ];

  if(objectPath.get(values,'reportScheduleId',null)){
    requiredFields.push('reviewerPositionIds');
  }

  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  errors = ValidationUtil.checkStringMaxLength(['name'],values,errors,50);

  return errors;
};

export default validate;
