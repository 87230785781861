import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, Grid, Divider, Hidden } from '@material-ui/core';
import classnames from 'classnames';
import PositionsUtil from '../../utils/PositionsUtil';
import StrategicPlansUtil from '../../utils/StrategicPlansUtil';
import ArrayUtil from '../../utils/ArrayUtil';

  class ReportingItemSummaryIntro extends React.Component {



    



    render(){
      const { classes, item } = this.props;


      const activityNamePart = <div><Typography className="inlineBlock labelLike"><strong>Activity: </strong></Typography> <Typography className="inlineBlock" variant="body2">
      { StrategicPlansUtil.getActivityNameById(item.activityId) }
    </Typography></div>; 

  return <div className={classnames("maxWidthPart", classes.timelineIntroWrap)}><Grid container>
    { item.description ? <Grid item className="gridItem" xs={12}>
    <Typography variant="body2">{ item.description }</Typography>
    </Grid> : null }
  </Grid>
  { item ? <Grid container>
    <Grid item className="gridItem" xs={12}>
  <Divider />
</Grid>
    <Grid item className="gridItem" xs={12} sm={6} xl={8}>
    <Typography className="inlineBlock labelLike"><strong>Parent Project: </strong></Typography> <Typography className="inlineBlock" variant="body2">
    { item.parentProject ? <span>{item.parentProject.id}<span className="inlineBlockSpacer"></span>{item.parentProject.name}</span> : " - " }
    </Typography>
    </Grid>
    <Grid item className="gridItem" xs={12} sm={6} xl={4}>
    <Typography className="inlineBlock labelLike"><strong>Has Children: </strong></Typography> <Typography className="inlineBlock" variant="body2">
    { ArrayUtil.isNonEmptyArray(item.childrenProjects) ? <span>Yes ({item.childrenProjects.length})</span> : " No " }
    </Typography>
    </Grid>
  </Grid> : null }

  { item ? <Grid container>
    
    <Grid item className="gridItem" xs={12} sm={6} xl={4}>
    <Typography className="inlineBlock labelLike"><strong>Project manager: </strong></Typography> <Typography className="inlineBlock" variant="body2">
        { PositionsUtil.getUsernameFromStoreById(item.positionId) }
    </Typography>
    </Grid>
    <Grid item className="gridItem" xs={12} sm={6} xl={4}>
    <Typography className="inlineBlock labelLike"><strong>Activity Area: </strong></Typography> <Typography className="inlineBlock" variant="body2">
    { StrategicPlansUtil.getActivityAreaNameByActivityId(item.activityId) }
    </Typography>
    <Hidden xlUp>
      <br /><br />
      {activityNamePart}
    </Hidden>
    </Grid>
    <Hidden lgDown><Grid item className="gridItem" xs={12} sm={6} xl={4}>
        {activityNamePart}
    </Grid></Hidden>
  </Grid> : null }


      
      </div>;
    }
  
}


export default withStyles(styles, { withTheme: true })(ReportingItemSummaryIntro);