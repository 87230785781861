import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import BlockIcon from '@material-ui/icons/BlockOutlined';
import * as constants from '../constants/constants';
import styles from '../theme/styles';
import {
    Table,
    TableBody,
    Grid,
    TableCell,
    TableRow,
    Tooltip,
    IconButton,
    TableHead,
    Hidden,
    Divider,
    Typography,
    Button,
} from '@material-ui/core';
import classnames from 'classnames';
import AudiencesUtil from '../utils/AudiencesUtil';
import { withRouter } from 'react-router-dom';
import objectPath from 'object-path';
import DateUtil from '../utils/DateUtil';
import ArrayUtil from '../utils/ArrayUtil';
import AssessmentUtil from '../utils/AssessmentUtil';
import FormatterUtil from '../utils/FormatterUtil';
import ShowHideContent from './ShowHideContent';
import ShowContentInDialog from './ShowContentInDialog';
import ReportOutputUtil from '../utils/ReportOutputUtil';
import RichTextRenderer from './RichTextRenderer';
import FolderIcon from '@material-ui/icons/FolderOutlined';

class ListingTable extends React.Component {
    renderRows = () => {
        const {
            items,
            rowClassName,
            classes,
            history,
            onDeleteClicked,
            viewProgressReportById,
        } = this.props;

        let rows = [];

        items.forEach((row, index) => {
            var rowComponent = null;
            switch (rowClassName) {
                case 'ActivitySummaryNonFinancialResult':
                    rowComponent = (
                        <TableRow
                            key={index}
                            className={'displayColumnsToRows'}
                        >
                            <TableCell className="projectLinkColumn">
                                <Tooltip
                                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                    title="View latest progress report"
                                >
                                    <Button
                                        variance="text"
                                        disableFocusRipple={true}
                                        onClick={() =>
                                            viewProgressReportById
                                                ? viewProgressReportById(
                                                      objectPath.get(
                                                          row,
                                                          'projectId'
                                                      )
                                                  )
                                                : null
                                        }
                                    >
                                        <strong className="projectTitle">
                                            {objectPath.get(
                                                row,
                                                'projectProgressReportID'
                                            )}
                                            <br />
                                            {objectPath.get(row, 'projectName')}
                                        </strong>
                                    </Button>
                                </Tooltip>
                            </TableCell>
                            <Hidden mdDown>
                                <TableCell className="expandCellWidth">
                                    <ShowContentInDialog
                                        text={objectPath.get(
                                            row,
                                            'whatsHappenedComment.electedMemberComment',
                                            ' - '
                                        )}
                                        DialogTitleText="What's happened"
                                        maxLenght={300}
                                    />
                                </TableCell>
                            </Hidden>
                            <Hidden mdDown>
                                <TableCell className="expandCellWidth">
                                    <ShowContentInDialog
                                        text={objectPath.get(
                                            row,
                                            'whatsNextComment.electedMemberComment',
                                            ' - '
                                        )}
                                        DialogTitleText="What's next"
                                        maxLenght={300}
                                    />
                                </TableCell>
                            </Hidden>

                            <TableCell className="resultsIndicatorColumnWrap">
                                <span
                                    className={classnames(
                                        'indicator',
                                        AssessmentUtil.getIndicatorClassname(
                                            classes,
                                            objectPath.get(
                                                row,
                                                'status.pmAssessment'
                                            )
                                        )
                                    )}
                                ></span>
                                {AssessmentUtil.getIndicatorText(
                                    objectPath.get(row, 'status.pmAssessment')
                                )}
                                <br />
                                {objectPath.get(row, 'status.percentComplete')
                                    ? FormatterUtil.formatPercentage(
                                          objectPath.get(
                                              row,
                                              'status.percentComplete'
                                          )
                                      )
                                    : null}
                                <br />
                                {objectPath.get(row, 'reportDate')
                                    ? DateUtil.fullNameMonthDate(
                                          objectPath.get(row, 'reportDate')
                                      )
                                    : null}
                            </TableCell>
                            <Hidden lgUp>
                                <TableCell>
                                    <ShowHideContent
                                        showLessText="- Hide comments"
                                        showMoreText="+ Show comments"
                                    >
                                        <Typography
                                            component="div"
                                            variant="caption"
                                        >
                                            <strong className="muted">
                                                What's happened:
                                            </strong>
                                        </Typography>
                                        <div>
                                            <RichTextRenderer
                                                text={objectPath.get(
                                                    row,
                                                    'whatsHappenedComment.electedMemberComment',
                                                    ' - '
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <br />
                                            <Divider />
                                            <br />
                                        </div>
                                        <Typography variant="caption">
                                            <strong className="muted">
                                                What's next:
                                            </strong>
                                        </Typography>
                                        <div>
                                            <RichTextRenderer
                                                text={objectPath.get(
                                                    row,
                                                    'whatsNextComment.electedMemberComment',
                                                    ' - '
                                                )}
                                            />
                                        </div>
                                    </ShowHideContent>
                                </TableCell>
                            </Hidden>
                        </TableRow>
                    );
                    break;
                case 'ActivitySummaryFinancialResult':
                    rowComponent = (
                        <TableRow
                            key={index}
                            className={'displayColumnsToRows'}
                        >
                            <TableCell className="projectLinkColumn">
                                <Tooltip
                                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                    title="View in Ozone"
                                >
                                    <Button
                                        variance="text"
                                        disableFocusRipple={true}
                                        target="_blank"
                                        href={objectPath.get(
                                            row,
                                            'reportUrl',
                                            '#'
                                        )}
                                    >
                                        <strong className="projectTitle">
                                            {objectPath.get(
                                                row,
                                                'subActivityId'
                                            )}{' '}
                                            {objectPath.get(row, 'groupCode')}
                                        </strong>
                                    </Button>
                                </Tooltip>
                            </TableCell>
                            <TableCell className="expandCellWidth">
                                <Hidden lgUp>
                                    <strong className="muted">
                                        Description:{' '}
                                    </strong>
                                </Hidden>
                                {objectPath.get(row, 'subActivityName')}
                            </TableCell>
                            <TableCell>
                                <Hidden lgUp>
                                    <strong className="muted">Group: </strong>
                                </Hidden>
                                {objectPath.get(row, 'groupName')}
                            </TableCell>
                            <TableCell className="textRightLgUp">
                                <Hidden lgUp>
                                    <strong className="muted">
                                        Budget YTD:{' '}
                                    </strong>
                                </Hidden>
                                {FormatterUtil.formatNZDollar(
                                    objectPath.get(row, 'budgetYTD')
                                )}
                            </TableCell>
                            <TableCell className="textRightLgUp">
                                <Hidden lgUp>
                                    <strong className="muted">
                                        Actual YTD:{' '}
                                    </strong>
                                </Hidden>
                                {FormatterUtil.formatNZDollar(
                                    objectPath.get(row, 'actualYTD')
                                )}
                            </TableCell>
                            <TableCell className="textRightLgUp">
                                <Hidden lgUp>
                                    <strong className="muted">
                                        Variance:{' '}
                                    </strong>
                                </Hidden>
                                {FormatterUtil.formatPercentage(
                                    objectPath.get(row, 'variancePercent'),
                                    1
                                )}
                            </TableCell>
                            <TableCell className="resultsIndicatorColumnWrap">
                                <span
                                    className={classnames(
                                        'indicator',
                                        AssessmentUtil.getIndicatorClassname(
                                            classes,
                                            objectPath.get(
                                                row,
                                                'progressAssessment'
                                            )
                                        )
                                    )}
                                ></span>
                                {AssessmentUtil.getIndicatorText(
                                    objectPath.get(row, 'progressAssessment')
                                )}
                            </TableCell>
                        </TableRow>
                    );
                    break;
                case 'ReportOutput':
                    rowComponent = (
                        <TableRow key={index}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                                <Hidden xsDown>
                                    {ReportOutputUtil.getDocumentName(row, 50)}
                                    <br />{' '}
                                    {ReportOutputUtil.getFolderName(row) ? (
                                        <span className="muted folderNameWrap">
                                            <FolderIcon fontSize="small" />{' '}
                                            {ReportOutputUtil.getFolderName(
                                                row
                                            )}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </Hidden>
                                <Hidden smUp>{row.objectiveId}</Hidden>
                            </TableCell>
                            <Hidden xsDown>
                                <TableCell>
                                    {AudiencesUtil.renderNames(row.audiences)}
                                </TableCell>
                            </Hidden>
                            <TableCell className={classes.tableCellActionWrap}>
                                <Tooltip
                                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                    title="Edit"
                                >
                                    <IconButton
                                        size="small"
                                        aria-label="Edit"
                                        onClick={() =>
                                            history.push(
                                                '/admin/report-outputs/edit/' +
                                                    row.id
                                            )
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                {onDeleteClicked ? (
                                    <Tooltip
                                        enterDelay={
                                            constants.TOOLTIP_CARDS_DELAY
                                        }
                                        title="Delete"
                                    >
                                        <IconButton
                                            size="small"
                                            aria-label="Delete"
                                            onClick={() => onDeleteClicked(row)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </TableCell>
                        </TableRow>
                    );
                    break;
                case 'ReportSchedule':
                    rowComponent = (
                        <TableRow key={index}>
                            <TableCell>{row.name}</TableCell>
                            <Hidden xsDown>
                                <TableCell>{row.dueDays}</TableCell>
                            </Hidden>
                            <Hidden xsDown>
                                <TableCell>
                                    {row.reminderDays1} / {row.reminderDays2}
                                </TableCell>
                            </Hidden>
                            <TableCell>
                                {ArrayUtil.isNonEmptyArray(row.meetingSchedules)
                                    ? DateUtil.basicDateSlashes(
                                          objectPath.get(
                                              row,
                                              'meetingSchedules.0'
                                          )
                                      )
                                    : ' - '}
                            </TableCell>
                            <TableCell className={classes.tableCellActionWrap}>
                                <Tooltip
                                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                                    title="Edit"
                                >
                                    <IconButton
                                        size="small"
                                        aria-label="Edit"
                                        onClick={() =>
                                            history.push(
                                                '/admin/report-schedules/edit/' +
                                                    row.id
                                            )
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                {onDeleteClicked ? (
                                    row.system ? (
                                        <IconButton
                                            disabled
                                            size="small"
                                            aria-label="System report schedule"
                                        >
                                            <BlockIcon />
                                        </IconButton>
                                    ) : (
                                        <Tooltip
                                            enterDelay={
                                                constants.TOOLTIP_CARDS_DELAY
                                            }
                                            title="Delete"
                                        >
                                            <IconButton
                                                size="small"
                                                aria-label="Delete"
                                                onClick={() =>
                                                    onDeleteClicked(row)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                ) : null}
                            </TableCell>
                        </TableRow>
                    );
                    break;
                default:
                    break;
            }
            if (rowComponent) {
                rows.push(rowComponent);
            }
        });
        return rows;
    };

    renderHeaderCell = (content, className = null) => {
        return (
            <TableCell className={className}>
                <strong>{content}</strong>
            </TableCell>
        );
    };

    renderHeader = () => {
        const { rowClassName, classes } = this.props;

        var headerRow = null;
        switch (rowClassName) {
            case 'ActivitySummaryNonFinancialResult':
                headerRow = (
                    <Hidden mdDown>
                        <TableRow>
                            {this.renderHeaderCell(
                                'Project',
                                'projectLinkColumn'
                            )}
                            {this.renderHeaderCell(
                                "What's happened",
                                'expandCellWidth'
                            )}
                            {this.renderHeaderCell(
                                "What's Next",
                                'expandCellWidth'
                            )}
                            {this.renderHeaderCell(
                                ' ',
                                'resultsIndicatorColumnWrap'
                            )}
                        </TableRow>
                    </Hidden>
                );
                break;
            case 'ActivitySummaryFinancialResult':
                headerRow = (
                    <Hidden mdDown>
                        <TableRow>
                            {this.renderHeaderCell(
                                'Cost Centre',
                                'projectLinkColumn'
                            )}
                            {this.renderHeaderCell(
                                'Description',
                                'expandCellWidth'
                            )}
                            {this.renderHeaderCell('Group')}
                            {this.renderHeaderCell(
                                'Budget YTD',
                                'textRightLgUp'
                            )}
                            {this.renderHeaderCell(
                                'Actual YTD',
                                'textRightLgUp'
                            )}
                            {this.renderHeaderCell('Variance', 'textRightLgUp')}
                            {this.renderHeaderCell(
                                ' ',
                                'resultsIndicatorColumnWrap'
                            )}
                        </TableRow>
                    </Hidden>
                );
                break;
            case 'ReportOutput':
                headerRow = (
                    <TableRow>
                        {this.renderHeaderCell('Name')}
                        {this.renderHeaderCell('Template')}
                        <Hidden xsDown>
                            {this.renderHeaderCell('Audiences')}
                        </Hidden>
                        {this.renderHeaderCell('', classes.tableCellActionWrap)}
                    </TableRow>
                );
                break;
            case 'ReportSchedule':
                headerRow = (
                    <TableRow>
                        {this.renderHeaderCell('Schedule Name')}
                        <Hidden xsDown>
                            {this.renderHeaderCell('Report Close days')}
                        </Hidden>
                        <Hidden xsDown>
                            {this.renderHeaderCell('Reminder days')}
                        </Hidden>
                        {this.renderHeaderCell('Next meeting')}
                        {this.renderHeaderCell('', classes.tableCellActionWrap)}
                    </TableRow>
                );
                break;
            default:
                break;
        }
        return headerRow;
    };

    render() {
        const { classes, additionalClasses } = this.props;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Table
                        className={classnames(classes.table, additionalClasses)}
                    >
                        <TableHead>{this.renderHeader()}</TableHead>
                        <TableBody>{this.renderRows()}</TableBody>
                    </Table>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ListingTable)
);
