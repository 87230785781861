import * as constants from "../constants/constants";
import keycloakManager from "../keycloakManager";

export function getUserRole() {
  if (hasAdminRole()) {
    return constants.ADMIN;
  }
  if (hasReportingAdminRole()) {
    return constants.REPORTING_ADMIN;
  }
  return constants.USER;
}

export function hasAdminRole() {
  const { SYSTEM_ADMIN_GROUP_IDS } = window.env;
  return keycloakManager.isInGroup(
    (SYSTEM_ADMIN_GROUP_IDS || '').split(','),
  );
}

export function hasReportingAdminRole() {
  let { REPORTING_ADMIN_GROUP_IDS } = window.env;
  return keycloakManager.isInGroup(
    (REPORTING_ADMIN_GROUP_IDS || '').split(','),
  );
}
