import ValidationUtil from '../../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "name",
    "description",
    "activityId",
    "positionId",
    "commentaryFrequency"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);


  errors = ValidationUtil.checkStringMaxLength(['name'],values,errors,100);

  errors = ValidationUtil.checkIntegerMinMax(['commentaryFrequency'],values,errors,0,120);

  return errors;
};

export default validate;
