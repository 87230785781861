import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as reportingItemActions from '../../actions/reportingItem';
import * as dashboardActions from '../../actions/dashboard';
import * as constants from '../../constants/constants';
import ComponentLoading from "../../components/ComponentLoading";
import Masonry from 'react-masonry-component';
import objectPath from 'object-path';
import ReportingItemCard from '../../components/reportingItem/ReportingItemCard';
import ActivitySummaryCard from '../../components/activitySummary/ActivitySummaryCard';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../theme/styles";
import ArrayUtil from "../../utils/ArrayUtil";
import BinderSummaryCard from "../../components/binder/BinderSummaryCard";
import DashboardFilter from "../../components/dashboard/DashboardFilter";
import { Button, Hidden, Typography } from "@material-ui/core";
import FilterUtil from "../../utils/FilterUtil";


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      listingDataItems: [],
      dataLoading: true,
      expandedCardIds: [],
      queryPage: 0,
      showShowMoreBtn: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      FilterUtil.addToLocalStorage(this.props.location);
      this.setState({
        queryPage: 0
      }, () => {
        this.mountItems()
      });
    }
  }

  mountItems = () => {
    const _this = this;
    const { actions, dashboardMergedData } = this.props;
    const { queryPage } = this.state;
    const requestedFilter = FilterUtil.getRequestedFilterFromUrl();

    //if (dashboardMergedData.items.length < 1 || storedPage !== queryPage) {//TODO maybe more conditions for request
      this.setState({
        dataLoading: true
      })

      actions.getDashboardData({
        page: queryPage,
        filter: requestedFilter
      }).then((response) => {
        var currentRequestDashobardData = objectPath.get(response, 'data.data', null);
        if(currentRequestDashobardData){
          var currentRequestDashobardDataItems = objectPath.get(currentRequestDashobardData, 'content', []);
          let previuousRequestDashobardDataItems = [];
          if(objectPath.get(dashboardMergedData,'queryPage',0) < queryPage) { //append if query page is higher
            previuousRequestDashobardDataItems = objectPath.get(dashboardMergedData,'items',[]);
          }
          //condition to append or start again
          //if filter same and page different then append
          actions.setMergedDashboardData(
            previuousRequestDashobardDataItems.concat(currentRequestDashobardDataItems),
            requestedFilter,
            queryPage 
          );
          

          return _this.setState({
            showShowMoreBtn: (parseInt(currentRequestDashobardData.number)+1) < parseInt(currentRequestDashobardData.totalPages),
            queryPage: currentRequestDashobardData.number,
            dataLoading: false
          });
        } else {
          return _this.setState({
            showShowMoreBtn: false,
            queryPage: 0,
            dataLoading: false
          });
        }
        
      });
    //}
  }


  componentDidMount() {
    FilterUtil.addToLocalStorage(this.props.location);
    this.mountItems();
  }

  showMore = () => {
    const { queryPage } = this.state;
    this.setState({
      queryPage: (queryPage+1)
    }, () => {
      this.mountItems();
    });
    
  }

  toggleCardReportingItemsById = (id) => {
    const { expandedCardIds } = this.state;
    if(expandedCardIds.includes(id)){
      this.setState({expandedCardIds: ArrayUtil.removeFromArrayIfExist(expandedCardIds, id)});
    } else {
      this.setState({expandedCardIds: ArrayUtil.addToArrayIfNotExist(expandedCardIds, id)});
    }
  }

  render() {
    const { dataLoading, expandedCardIds, showShowMoreBtn } = this.state;
    const { classes, actions, dashboardMergedData } = this.props;

    const listingDataItems = objectPath.get(dashboardMergedData,'items',[]);

    const masonryOptions = {
      itemSelector: '.masonry-item',
      columnWidth: '.masonry-item-sizer',
      percentPosition: true,
      horizontalOrder: true
    };

    return <div>
      {dataLoading && listingDataItems.length < 1 ? <ComponentLoading /> :
        <div className={classes.contentWithoutHeader}>
          <Hidden mdUp><DashboardFilter /></Hidden>
          <Masonry
            //ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
            className={'masonry-grid-wrap'} // default ''
            options={masonryOptions} // default {}
          >
            <div className="masonry-item-sizer"></div>
            {listingDataItems.map((item, index) => {

              if(constants.DASHBOARD_CARD_TYPE_AS === item.type){ // Activity summary
                return <div className="masonry-item" key={index}>
                  <ActivitySummaryCard
                  expandable={true}
                  expandedCardIds={expandedCardIds}
                  toggleCardReportingItemsById={this.toggleCardReportingItemsById}
                  showCardResponsibilityStates={true}
                  item={item}
                  />
                </div>;
              } else if(constants.DASHBOARD_CARD_TYPE_BINDER === item.type){ // binder
                return <div className="masonry-item" key={index}>
                  <BinderSummaryCard
                  expandable={true}
                  expandedCardIds={expandedCardIds}
                  toggleCardReportingItemsById={this.toggleCardReportingItemsById}
                  showCardResponsibilityStates={true}
                  item={item}
                  />
                </div>;
              } else if(constants.DASHBOARD_CARD_TYPE_RI === item.type){ // reporting item
                return <div className="masonry-item" key={index}><ReportingItemCard
                  postReportingItemQuickCommentary={actions.postReportingItemQuickCommentary}
                  showCardResponsibilityStates={true}
                  item={item} /></div>;
              }
                return null;
            })}
          </Masonry>
          { dataLoading &&  listingDataItems.length > 0 ? <ComponentLoading /> : null }
          { !dataLoading &&  listingDataItems.length < 1 ? <div className={classnames(classes.textCenter, classes.contentWithoutHeader)}><Typography variant="body2" className="muted">No results</Typography></div> : null }
          { !dataLoading && showShowMoreBtn ? <div className="gridItem"><br/><br/><Button size="large" color="primary" fullWidth={true} variant="outlined" onClick={this.showMore}>Show More</Button></div> : null}
          
          </div>}
    </div>
  }
}


function mapStateToProps(state) {
  return {
    dashboardMergedData: state.dashboardMergedData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...reportingItemActions,
        ...dashboardActions,
      },
      dispatch
    )
  };
}


export default withRouter(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)));
