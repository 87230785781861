import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../theme/styles';
import * as formNames from "../../../../constants/forms";
import classnames from 'classnames';
import {
   SelectWithSuggestions,
} from "../../../inputs";
import * as constants from '../../../../constants/constants';
import TickIcon from "@material-ui/icons/Check";
import validate from "./validate";
import { Grid, Fab, Tooltip, Button, Typography, ListItem, List, ListItemIcon, ListItemText, Hidden} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/PersonOutlined';
import { connect } from "react-redux";
import { formValueSelector } from 'redux-form'
import ArrayUtil from "../../../../utils/ArrayUtil";
import UsersUtil from "../../../../utils/UsersUtil";

class BinderShareForm extends React.Component {

  constructor(props){
      super(props);
      this.state = {
          showConfirmation: false,
      }
    }

  render(){
    const {
      handleSubmit,
      classes,
      className,
      submitting,
      proceedSubmit,
      users,
      onCancel,
      currentUserIds
    } = this.props;

    const {
      showConfirmation
    } = this.state;



  const shareButton = <Fab color="secondary" onClick={() => this.setState({showConfirmation:true})} size="large"><TickIcon /></Fab>;
  const confirmButton = <Button disabled={submitting} variant="contained" color={ArrayUtil.isNonEmptyArray(currentUserIds) ? "secondary" : "error"} type="submit" size="large">Confirm</Button>;


  const formBody = <div className="maxWidthPart narrowMaxWidthPart">
          
  { !showConfirmation ? <Grid container>
    
    <Grid item className="gridItem" xs={12}>
          <Field name="userIds" component={SelectWithSuggestions} isSearchable={true} isMulti={true} options={users} label="Share with:"  />
    </Grid>
    
    <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12}>
        <Button size="small" color="default" onClick={()=>onCancel()}>Cancel</Button> &nbsp; <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Share" aria-label="Share">{shareButton}</Tooltip>
    </Grid> 
    
    </Grid> 
    : 
    <Grid container>
    
    <Grid item className="gridItem" xs={12}>
    { ArrayUtil.isNonEmptyArray(currentUserIds) ? <Typography variant="body2">
            Please confirm you wish to share this binder. All {currentUserIds.length} users selected will have full edit access to the binder.
          </Typography> : <Typography variant="body2" color="error">
          Please confirm removing all users because it will delete this binder. 
  </Typography> }
          { ArrayUtil.isNonEmptyArray(currentUserIds) ? <Typography variant="body2" component="div" className="shareListWrapper"><List>
            { currentUserIds.map( (x, index) => <ListItem key={index}>
              <Hidden xsDown><ListItemIcon>
                <PersonIcon />
              </ListItemIcon></Hidden>
              <ListItemText primary={UsersUtil.getKeycloakNameFromStoreById(x)} secondary={UsersUtil.getKeycloakEmailFromStoreById(x)} />
            </ListItem>) }
          </List></Typography> : null }
    </Grid>
    
    <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12}>
        <Button size="small" color="default" onClick={()=>this.setState({showConfirmation : false})}>Cancel</Button> &nbsp; <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Confirm" aria-label="Confirm">{confirmButton}</Tooltip>
    </Grid> 
    
    </Grid>
  }
  </div>;

  return <div>
        { showConfirmation ? <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classnames(classes.customForm, className)}>
           {formBody}
          
        </form> : <div className={classnames(classes.customForm, className)}>{formBody}</div>}
    </div>
}
};


const selector = formValueSelector(formNames.BINDER_SHARE_FORM_NAME);

export default connect(state => ({
  currentUserIds: selector(state, 'userIds'),
}))(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.BINDER_SHARE_FORM_NAME, // a unique identifier for this form
  validate,
})(BinderShareForm)));
