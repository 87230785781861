import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import objectPath from 'object-path';
import { Grid, Divider, Typography, TableRow, TableCell, Table, TableBody, TableHead, Hidden } from '@material-ui/core';
import { formValueSelector } from 'redux-form'
import { Field } from "redux-form";
import { CheckboxInput, TextLabelColumnsInput } from '../inputs';
import PreviousComment from './PreviousComment';
import * as formNames from '../../constants/forms';


import { connect } from "react-redux";
import StoreUtil from '../../utils/StoreUtil';
import ProgressReportUtil from '../../utils/ProgressReportUtil';

class KpiSection extends React.Component {



  renderInputField = (i,index) => {
    var currentRowKey = "kpiOverview.kpiList."+index+".targetMet";
    return i.completeDate ? null : <Field name={currentRowKey} component={CheckboxInput} color="primary" />
  }


  render(){


  const { item, kpiOverviewKPIList } = this.props;
  const kpiSection = objectPath.get(item,'kpiOverview');

  const kpiList = objectPath.get(kpiSection,'kpiList', []);


  const previousProgressReportKPIComment = objectPath.get(item,'previousProgressReport.kpiOverview.kpiCommentary');
  const previousProgressReportKPICommentDate = objectPath.get(item,'previousProgressReport.kpiOverview.kpiCommentaryDts');

  const isContentNarrow = StoreUtil.getValueByKey('contentSubmenuLevel');
  
  return kpiSection ? <Grid container className="kpiSection">
  <Grid item className="gridItem" xs={12}>
    <Typography variant="h4">KEY PERFORMANCE INDICATORS</Typography>
    
  </Grid>
  
  <Hidden only={isContentNarrow ? ['lg','xl'] : ['md','lg', 'xl']}>
    {kpiList.map((i,index) => {
      
      return  <Grid item className="gridItem mobileMilestoneCard" xs={12} key={index}>
      <Grid container>
      <Grid item xs={12}>
      <Typography variant="caption" >{i.name}</Typography>
      </Grid>
      <Grid item xs={9} className="gridItem sectionVerticalPaddingItem">
      <Typography variant="caption"><strong>KPI Target</strong></Typography>
      </Grid>
      <Grid item xs={3} className="gridItem sectionVerticalPaddingItem">
      <Typography variant="caption"><strong>Met?</strong></Typography>
      </Grid>
      <Grid item xs={9}>
      <Typography variant="caption" >{i.target}</Typography>
      </Grid>
      <Grid item xs={3}>
        {this.renderInputField(i,index)}
      </Grid>
      </Grid>
      <Grid item className="gridItem" xs={12}>
  <Divider />
</Grid>
      </Grid>}) }
    
  </Hidden>

  <Hidden only={isContentNarrow ? ['xs','sm','md'] : ['xs','sm']}>
  <Grid item className="gridItem sectionSidePaddingItem" xs={12}>
  <Table>
      <TableHead>
          <TableRow>
          <TableCell className="expandCellWidth">
              &nbsp;
          </TableCell>
          <TableCell className="expandCellWidth">
              <Typography variant="caption"><strong>KPI Target</strong></Typography>
          </TableCell>

          <TableCell>
              <Typography variant="caption"><strong>Target Met?</strong></Typography>
          </TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
      {kpiList.map((i,index) => {
      return  <TableRow key={index}>
      <TableCell className="expandCellWidth"><Typography variant="caption" >{i.name}</Typography></TableCell>
      <TableCell className="expandCellWidth"><Typography variant="caption" >{i.target}</Typography></TableCell>
      <TableCell>
      {this.renderInputField(i,index)}
        </TableCell>
      
  </TableRow>
  }
 )}
      </TableBody>
  </Table>
  </Grid>
  </Hidden>

  {/* comment */}

  <Grid item className="gridItem" xs={12} sm={6} md={7}>
{ 
  ProgressReportUtil.showKPIExplanation(kpiOverviewKPIList) ? <Field name="kpiOverview.kpiCommentary" component={TextLabelColumnsInput} label="Reason why KPI(s) were not met" multiline={true} rows="4" /> : null 
}
  
    
    
</Grid>
<Grid item className="gridItem" xs={12} sm={6} md={5}>
{ previousProgressReportKPIComment && previousProgressReportKPIComment.length ?  <PreviousComment date={previousProgressReportKPICommentDate} text={previousProgressReportKPIComment} /> : null }
</Grid>   

  <Grid item className="gridItem" xs={12}>
  <Divider className="sectionDivider" />
</Grid>

</Grid> : null
}
}

KpiSection.propTypes = {
  classes: PropTypes.object.isRequired,
};



const selector = formValueSelector(formNames.PROGRESS_REPORT_FORM_NAME);

export default connect(state => ({
    kpiOverviewKPIList: selector(state, 'kpiOverview.kpiList'),
}))(withStyles(styles, { withTheme: true })(KpiSection));

