import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import * as reportingItemActions from '../../../actions/reportingItem';
import HeadingAddBtn from "../../../components/buttons/HeadingAddBtn";
import ReportingItemListingTable from "../../../components/reportingItem/ReportingItemListingTable"
import ComponentLoading from "../../../components/ComponentLoading";
import objectPath from 'object-path';
import ListingTableFooter from "../../../components/ListingTableFooter";
import queryString from 'query-string'
import * as constants from '../../../constants/constants';
import FormatterUtil from "../../../utils/FormatterUtil";
import AppDialog from "../../../components/AppDialog";
import { Button, DialogActions, Typography } from "@material-ui/core";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import { withSnackbar } from "notistack";
import UrlBuilderUtil from "../../../utils/UrlBuilderUtil";
import ReportingItemListFilter from "../../../components/reportingItem/ReportingItemListFilter";
import ArrayUtil from "../../../utils/ArrayUtil";

const entityName = "ReportingItem";

class ListReportingItem extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      listingDataObject: null,
      expandedRowItemId: null,
      expandAll: false,
      dataLoading: true,
      dialogProccessing: false,
      dialogItem: null,
      dialogActionApprovedByServer: false
    }
  }

  mountItems = () => {
    const _this = this;
    const { actions, location } = this.props;
    const querySearch = queryString.parse(location.search);

    const queryPage = objectPath.get(querySearch,constants.LISTING_PAGE_PARAM,constants.DEFAULT_LISTING_PAGE);
    const queryPageSize = objectPath.get(querySearch,constants.LISTING_PAGE_SIZE_PARAM,constants.DEFAULT_LISTING_PAGE_SIZE);
    
      this.setState({
        dataLoading: true
      })
      
      actions.getReportingItemSummaryList({
        pageSize: queryPageSize,
        page: queryPage
      },this.getFilter()).then( response => {
        return _this.setState({
          listingDataObject: objectPath.get(response, "data.data"),
          dataLoading: false
        })
      }); 
  }

  getFilter = () => {
    const { location } = this.props;
    const querySearch = queryString.parse(location.search);
    return {
      keyword : FormatterUtil.keywordDecodeURI(objectPath.get(querySearch,'keyword','')),
      archivedonly : objectPath.get(querySearch,'archivedonly',false)
    }
  }
  
  onToggleExpandAll = () => {
    const { expandAll } = this.state;
    this.setState({expandAll: !expandAll})
  }

  onExpandRow = (itemId) => {
    const { expandedRowItemId } = this.state;
    
    if(expandedRowItemId === itemId){//toggle row if expanded
      itemId = null;
    }

    this.setState({expandedRowItemId: itemId});
  }

  componentDidMount(){
    this.mountItems();
  }


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.mountItems();
    }
  }

  getActionName = (item, approve = true) => {
    return objectPath.get(item,'archived') ? 
      (approve ? 'canReinstateReportingItemById' : 'reinstateReportingItemById') : 
      (approve ? 'canArchiveReportingItemById' : 'archiveReportingItemById');
  }

  onArchiveToggleClicked = (item) => {
    const { actions } = this.props;
    this.setState({dialogProccessing : true, dialogItem: item, dialogActionApprovedByServer: false});
    actions[this.getActionName(item)](item.id).then( response => {
      var dialogActionApprovedByServer = false;
      if(
        objectPath.get(response, "data.data.result") === true
        ){
        dialogActionApprovedByServer = true;
      }
      this.setState({dialogProccessing : false, dialogActionApprovedByServer: dialogActionApprovedByServer});
    });
  }

  onArchiveToggleConfirmed = () => {
    const { actions } = this.props;
    const { dialogItem } = this.state;
    actions[this.getActionName(dialogItem, false)](dialogItem.id).then( response => this.handleResponse(response));
  }

  handleResponse = (response) => {
    const { dialogItem } = this.state;
    let snackBarSuccessMessage = dialogItem.name+(dialogItem.archived ? ' was reinstated' : ' was archived');
    const { enqueueSnackbar } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar, snackBarSuccessMessage);
      this.mountItems();
    }
    this.hideArchiveToggleDialog();
  }

  hideArchiveToggleDialog = () => {
    this.setState({
      dialogProccessing: false, dialogItem: null, dialogActionApprovedByServer: false
    })
  }

  renderArchiveToggleDialog = () => {
      const { dialogProccessing, dialogItem, dialogActionApprovedByServer } = this.state;
      
      let isArchiveAction = dialogItem.archived ? false : true;

      let archiveDenied = <span><strong>{dialogItem.name}</strong> reporting item requires a Project or Comment Update. You cannot archive at this time.</span>;
      let archiveApproved = <span>Please confirm you wish to archive reporting item: <strong>{dialogItem.name}</strong></span>;

      let reinstateDenied = <span>Items cannot be linked to an existing RI, and must be In Progress and Project Management set to Yes in order to be reinstated. You cannot reinstate reporting item <strong>{dialogItem.name}</strong></span>;
      let reinstateApproved = <span>Please confirm you wish to reinstate reporting item: <strong>{dialogItem.name}</strong></span>;
      
      let dialogMessage = !dialogProccessing ? (isArchiveAction ? (dialogActionApprovedByServer ? archiveApproved : archiveDenied) : (dialogActionApprovedByServer ? reinstateApproved : reinstateDenied)) : null;
  
      return (<AppDialog 
        maxWidth='xs'
        warningStyle={true}
        title={dialogActionApprovedByServer ? "Are you sure?" : null}
      message={dialogMessage}
      onClose={this.hideArchiveToggleDialog}
      actions={
        <DialogActions>
          <Button variant="text" onClick={this.hideArchiveToggleDialog}>Cancel</Button>
          { dialogActionApprovedByServer ? <Button variant="contained" color="default" className="errorBtn" onClick={this.onArchiveToggleConfirmed}>Confirm</Button> : null }
        </DialogActions>
      } >
      {dialogProccessing ? <ComponentLoading hideText={true} /> : null}
      </AppDialog>)
  }

  render () {
    const { dataLoading, listingDataObject, expandAll, expandedRowItemId, dialogItem  } = this.state;

    const itemsToList = objectPath.get(listingDataObject,"content",[]);

    return <div>
    <PageHeading heading={FormatterUtil.camelToNormalForm(entityName)+"s"} actions={<HeadingAddBtn redirectToUrl={UrlBuilderUtil.getReportingItemCreate()} />} />
    { dataLoading ? <ComponentLoading /> : 
      <div>
        <ReportingItemListFilter 
        expandAll={expandAll} 
        onToggleExpandAll={this.onToggleExpandAll} 
        archivedonly={objectPath.get(this.getFilter(),'archivedonly')} 
        keyword={objectPath.get(this.getFilter(),'keyword')} />
        { ArrayUtil.isNonEmptyArray(itemsToList) ? <ReportingItemListingTable 
          items={itemsToList} 
          onArchiveToggleClicked={this.onArchiveToggleClicked}
          expandedRowItemId={expandedRowItemId} 
          expandAll={expandAll} 
          onExpandRow={this.onExpandRow}
          rowClassName={entityName}
        /> : <div><br /><br /><Typography variant="body2" className="muted">No results</Typography></div> }
        { ArrayUtil.isNonEmptyArray(itemsToList) ? <ListingTableFooter listingDataObject={listingDataObject} filter={this.getFilter()} urlBuilderFunction={UrlBuilderUtil.getReportingItemList}  /> : null }
      </div> }
      {
        dialogItem ? this.renderArchiveToggleDialog() : null
      }
    
  </div>
  }
}


const mapStateToProps = (state) => ({})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...reportingItemActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(ListReportingItem)));
