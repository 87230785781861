
import * as actionsTypes from "../constants/actionTypes";
import objectPath from 'object-path';
import ServerErrorUtil from "./ServerErrorUtil";


export default class SnackBarUtil {

    static isResponseError(response, enqueueSnackbar, customMessage = null) {

        if(response.type === actionsTypes.AJAX_CALL_ERROR){       
            enqueueSnackbar(ServerErrorUtil.getErrorMessageFromResponse(response, customMessage), {
              variant: "error"
            });
            return true;
          }
          return false;
    }


    static showSuccessMessage(response, enqueueSnackbar, customMessage = null) {
      let actionType = objectPath.get(response, "type");
      let itemName = objectPath.get(response, "data.data.name");
      var successMessage = 'Ok';
      if(customMessage){
        successMessage = customMessage;
      } else {
        if(actionType.indexOf('DELETE_') !== -1){
          successMessage = 'Item has been removed';
        }
        else if(actionType.indexOf('PUT_') !== -1){
          successMessage = itemName+" has been updated";
        }else if(actionType.indexOf('POST_') !== -1){
          successMessage = itemName+" has been added";
        }
      }
      
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
  }
  }
  