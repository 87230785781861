import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function userInfo(state = initialState.userInfo, action) {
    if (action.type === types.SET_USER_INFO_SUCCESS) {
        return action.data;
    }
    if (action.type === types.SET_USER_INFO_ERROR) {
        return 'error';
    }
    return state;
}
