export default (theme, themeConfig, themeColors) => {
    return {
        snackbarBtn: {
            color: themeColors.primaryPalette.white,
            position: 'absolute',
            right: theme.spacing(1),
            top: 10,
            minWidth: 0,
        },
        snackbarWrap: {
            paddingRight: theme.spacing(1) * 4,
            position: 'relative',
        },
    };
};
