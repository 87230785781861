import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import classnames from 'classnames';
import styles from '../../../theme/styles';
import DrawerMenuItem from '../AppMenuItem';
import { MenuList, MenuItem } from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import HelpLinkIcon from '@material-ui/icons/HelpOutline';
import objectPath from 'object-path';
import { connect } from 'react-redux';

class MobileDrawer extends React.Component {
    renderMenuLinks = (items, showIcon) => {
        const { userInfo } = this.props;

        return items.map((m, index) => {
            return (
                <DrawerMenuItem
                    menuItem={m}
                    key={index}
                    showIcon={showIcon}
                    userInfo={userInfo}
                />
            );
        });
    };

    render() {
        const {
            classes,
            handleDrawerClose,
            handleHelp,
            handleLogout,
            userInfo,
            menu,
            drawerOpen,
        } = this.props;

        const loggedInAs = objectPath.get(userInfo, 'name', null);

        return (
            <Drawer
                anchor="top"
                variant="persistent"
                open={drawerOpen}
                onClose={handleDrawerClose}
                classes={{
                    root: classnames(classes.drawerRoot),
                    paper: classnames(
                        classes.drawerPaper,
                        classes.mobileDrawer,
                        'mobileDrawer'
                    ),
                }}
            >
                <div role="button">
                    <MenuList>
                        {this.renderMenuLinks(menu.primaryMenu, true)}
                        <Divider />
                        {loggedInAs ? (
                            <MenuItem
                                disabled
                                className={classnames(
                                    classes.menuItemText,
                                    classes.menuItemTextBtn
                                )}
                            >
                                <AccountIcon
                                    fontSize="small"
                                    className={classes.menuItemIcon}
                                />
                                <span>{loggedInAs}</span>
                            </MenuItem>
                        ) : null}
                        {handleHelp ? (
                            <MenuItem
                                onClick={handleHelp}
                                className={classnames(
                                    classes.menuItemText,
                                    classes.menuItemTextBtn
                                )}
                            >
                                <HelpLinkIcon
                                    fontSize="small"
                                    className={classes.menuItemIcon}
                                />
                                Help
                            </MenuItem>
                        ) : null}
                        <MenuItem
                            onClick={handleLogout}
                            className={classnames(
                                classes.menuItemText,
                                classes.menuItemTextBtn
                            )}
                        >
                            <LogoutIcon
                                fontSize="small"
                                className={classes.menuItemIcon}
                            />
                            Logout
                        </MenuItem>
                    </MenuList>
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => ({
    //force rerender on location and dashboard data change
    routerLocations: state.routerLocations,
    dashboardMergedData: state.dashboardMergedData,
});

export default connect(mapStateToProps)(
    withStyles(styles, { withTheme: true })(MobileDrawer)
);
