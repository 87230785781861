import React from 'react';
import { withRouter } from 'react-router-dom';
import * as constants from '../../constants/constants';
import ReportingItemQuickCommentForm from '../forms/reportingItem/quickComment/form';
import ReportingItemCommentaryForm from '../forms/reportingItem/commentary/form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import {
    Grid,
    Hidden,
    Divider,
    Typography,
    Button,
    Chip,
    DialogActions,
} from '@material-ui/core';
import classnames from 'classnames';
import ChatBubbleIcon from '@material-ui/icons/ChatBubbleOutline';
import GroupCommentCard from '../commentary/GroupCommentCard';
import objectPath from 'object-path';
import AppDialog from '../AppDialog';

class ReportingItemCommentaryFormWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showQCinNotDue: false,
            showNoUpdateConfirmDialog: false,
        };
    }

    hideConfirmation = () => {
        this.setState({
            showNoUpdateConfirmDialog: false,
        });
    };

    renderConfirmationDialog = () => {
        let message =
            'Please confirm that no further progress has been made on this item since the last update.';

        return (
            <AppDialog
                title="Are you sure?"
                message={message}
                onClose={this.hideConfirmation}
                actions={
                    <DialogActions>
                        <Button variant="text" onClick={this.hideConfirmation}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => this.noUpdateInQCDueState()}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                }
            />
        );
    };

    noUpdateInQCDueState = () => {
        const { reportingItemCommentary, handleReportingItemCommentarySubmit } =
            this.props;
        let noFutherUpdateQC = {
            reportingItemId: objectPath.get(
                reportingItemCommentary,
                'reportingItemId'
            ),
            noFurtherProgress: true,
        };
        this.hideConfirmation();
        handleReportingItemCommentarySubmit(noFutherUpdateQC);
    };

    render() {
        const {
            classes,
            reportingItemCommentary,
            handleReportingItemCommentarySubmit,
            includeOpenCommentary,
        } = this.props;
        const { showQCinNotDue, showNoUpdateConfirmDialog } = this.state;

        let lastAudienceComment = objectPath.get(
            reportingItemCommentary,
            'lastAudienceComment',
            null
        );
        let quickComments = objectPath.get(
            reportingItemCommentary,
            'quickComments',
            []
        );
        let riID = objectPath.get(reportingItemCommentary, 'reportingItemId');

        let dueCommentaryState = objectPath.get(
            reportingItemCommentary,
            'dueCommentaryState'
        );

        let isCommentaryDueState =
            (includeOpenCommentary &&
                objectPath.get(reportingItemCommentary, 'openCommentary')) ||
            dueCommentaryState ===
                constants.REPORTING_ITEM_COMMENTARY_STATE_COMMENTARYDUE;
        let isQCDueState =
            isCommentaryDueState === false &&
            dueCommentaryState ===
                constants.REPORTING_ITEM_COMMENTARY_STATE_QUICKCOMMENTDUE;
        let isNotDueState =
            isQCDueState === false && isCommentaryDueState === false;

        return (
            <div>
                <div className="maxWidthPart">
                    {lastAudienceComment ? (
                        <Grid container>
                            <Grid item className="gridItem" xs={12}>
                                <GroupCommentCard
                                    comment={lastAudienceComment}
                                    additionalClass="lastAudianceComment"
                                    heading="Here's What You said Last Time"
                                    showMoreText="+ See All Audiences"
                                    showLessText="- See less audiences"
                                    fields={[
                                        {
                                            label: 'Elected Members',
                                            name: 'electedMemberComment',
                                        },
                                        {
                                            label: 'Public',
                                            name: 'publicComment',
                                        },
                                        {
                                            label: 'Management',
                                            name: 'managementComment',
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item className="gridItem" xs={12}>
                                <Divider className="sectionDivider" />
                            </Grid>
                        </Grid>
                    ) : null}
                </div>

                {quickComments.length ? (
                    <div className={classes.quickAudienceCommentCardsOuterWrap}>
                        <Grid container>
                            <Grid item className="gridItem header" xs={12}>
                                <Typography variant="h5">
                                    Your recent comments
                                </Typography>
                            </Grid>
                            <Hidden xsDown>
                                <Grid item className="chatIconWrap" xs={12}>
                                    <div
                                        className={classnames(
                                            classes.textCenter,
                                            'chatIcon'
                                        )}
                                    >
                                        <ChatBubbleIcon />
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid
                                item
                                className={classnames(
                                    classes.quickAudienceCommentCardsWrap,
                                    'gridItem'
                                )}
                                xs={12}
                            >
                                {quickComments.map((x, index) => (
                                    <GroupCommentCard
                                        additionalClass={
                                            'quickAudienceCommentCard'
                                        }
                                        key={index}
                                        comment={x}
                                        showMoreText="+ See All Audiences"
                                        showLessText="- See less audiences"
                                        hasImage={true}
                                        fields={[
                                            {
                                                label: 'Elected Members',
                                                name: 'electedMemberComment',
                                            },
                                            {
                                                label: 'Public',
                                                name: 'publicComment',
                                            },
                                            {
                                                label: 'Management',
                                                name: 'managementComment',
                                            },
                                        ]}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </div>
                ) : null}

                {isCommentaryDueState ? (
                    <ReportingItemCommentaryForm
                        proceedSubmit={handleReportingItemCommentarySubmit}
                        initialValues={reportingItemCommentary}
                    />
                ) : (
                    <div>
                        <div
                            className={classnames(
                                'maxWidthPart narrowMaxMediumWidthPart',
                                classes.quickCommentActionsWrap
                            )}
                        >
                            <Grid container>
                                {isNotDueState ? (
                                    <Grid xs={6} item>
                                        <Chip
                                            label={
                                                constants.REPORTING_ITEM_COMMENTARY_STATE_NOTDUE_TEXT
                                            }
                                            className={classes.chip}
                                            clickable={false}
                                            component="button"
                                            variant="outlined"
                                        />
                                    </Grid>
                                ) : null}
                                <Grid
                                    xs={isNotDueState ? 6 : 12}
                                    item
                                    className={classes.textRightStrong}
                                >
                                    {isNotDueState ? (
                                        showQCinNotDue ? (
                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    this.setState({
                                                        showQCinNotDue: false,
                                                    })
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() =>
                                                    this.setState({
                                                        showQCinNotDue: true,
                                                    })
                                                }
                                            >
                                                Add Update
                                            </Button>
                                        )
                                    ) : null}
                                    {isQCDueState ? (
                                        <Button
                                            variant="outlined"
                                            color="default"
                                            onClick={() =>
                                                this.setState({
                                                    showNoUpdateConfirmDialog: true,
                                                })
                                            }
                                        >
                                            No Further Progress
                                        </Button>
                                    ) : null}
                                    {showNoUpdateConfirmDialog
                                        ? this.renderConfirmationDialog()
                                        : null}
                                </Grid>
                            </Grid>
                        </div>
                        {isQCDueState || showQCinNotDue ? (
                            <ReportingItemQuickCommentForm
                                heading="Quick Update"
                                expandable={false}
                                reportingItemId={riID}
                                proceedSubmit={
                                    handleReportingItemCommentarySubmit
                                }
                            />
                        ) : null}
                    </div>
                )}
            </div>
        );
    }
}

ReportingItemCommentaryFormWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(styles, { withTheme: true })(ReportingItemCommentaryFormWrapper)
);
