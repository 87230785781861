import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import classnames from 'classnames';
import * as formNames from "../../../constants/forms";
import * as constants from '../../../constants/constants';
import {
  TextInput, DateInput,
} from "../../inputs/";


import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import TickIcon from "@material-ui/icons/Check";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Tooltip, InputAdornment} from "@material-ui/core";
import warnAboutUnsavedForm from "../../../hoc/withWarnAboutUnsavedChanges";
import ValidationUtil from "../../../utils/ValidationUtil";

const ReportScheduleForm = props => {
  const {
    handleSubmit,
    //pristine,
    submitting,
    initialValues,
    classes,
    proceedSubmit,
  } = props;
  
  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><TickIcon /></Fab>;

  const isNameDisabled = (initialValues && initialValues.system) ? true : false;



  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container>
            
            
            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="name" component={TextInput} label="Name" disabled={isNameDisabled} inputProps={{maxLength: 50}} />
              </FormGroup>
            </Grid>
            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="description" component={TextInput} label="Description" multiline />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="dueDays" component={TextInput} label="Report Close days" placeholder="1-20" endAdornment={<InputAdornment position="end" className="daysSign noWrap">days</InputAdornment>} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="reminderDays1" component={TextInput} label="Reminder One days" placeholder="1-20" endAdornment={<InputAdornment position="end" className="daysSign noWrap">days</InputAdornment>} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="reminderDays2" component={TextInput} label="Reminder Two days" placeholder="1-20" endAdornment={<InputAdornment position="end" className="daysSign noWrap">days</InputAdornment>} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="meetingSchedules" component={DateInput} multiple={true} label="Meeting Schedule" />
              </FormGroup>
            </Grid>
            
            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12} lg={7}>
              { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Save">{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
};


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.REPORT_SCHEDULE_FORM_NAME, // a unique identifier for this form
  validate,
  shouldError: ValidationUtil.shouldValidateError,
  //asyncValidate
})(ReportScheduleForm)),formNames.REPORT_SCHEDULE_FORM_NAME);
