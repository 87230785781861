const primaryPalette = {
  primary: "#577624",
  secondary: "#B7BD00",
  white: "#ffffff",
  black: "rgba(0, 0, 0, 0.87)",
  textPrimary: "#4A4A4A",
  grey: "#83878D"
};
const primarySupportingPalette = {
  pms316c: "#004953",
  pms5473c: "#156570",
  pms7472c: "#5BBBB7",
  pms298c: "#3DB7E4",
  pms5256c: "#403B65",
  pms668c: "#614D7D",
  pms7669c: "#615E9B",
  pms7500c: "#E1D8B7",
  pms140c: "#755418",
  pms200c: "#CD4D49",
  pms110c: "#D7a900"
};

const secondarySupportingPalette = {
  pms288c: "#002C77",
  pms2612c: "#6B1F7C",
  pmsProCyanc: "#009FDA",
  pms186c: "#C60C30",
  pms1575c: "#FF8849",
  pms157c: "#E9994A"
};

const customPalette = {
  bodyBg: "#EDF1E7",//"rgba(87,118,37,0.1)",
  tableRowBorder: "rgba(0, 0, 0, 0.12)",
  transparentWhite: "rgba(255, 255, 255, 0.25)",
  successIndicator: '#8EC302',
  commentBorderFade: 'rgba(0, 73, 83, 0.25)',
}

export { primaryPalette, primarySupportingPalette, secondarySupportingPalette, customPalette };
