import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import * as reportScheduleActions from '../../../actions/reportSchedule';
import HeadingAddBtn from "../../../components/buttons/HeadingAddBtn";
import ListingTable from "../../../components/ListingTable"
import ComponentLoading from "../../../components/ComponentLoading";
import objectPath from 'object-path';
import FormatterUtil from "../../../utils/FormatterUtil";
import { withSnackbar } from "notistack";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import AppDialog from "../../../components/AppDialog";
import { DialogActions, Button } from "@material-ui/core";

const entityName = "ReportSchedule";
const entityListUrl = "/admin/report-schedules/";

class ListReportSchedule extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      items: [],
      dataLoading: true,
      itemToDelete: null,
    }
  }
  
  renderConfirmationDialog = () => {
    const { itemToDelete } = this.state;
    if(itemToDelete){
      let message = 'Please confirm you wish to remove '+FormatterUtil.camelToNormalForm(entityName)+' '+itemToDelete.name;

      return (<AppDialog 
        warningStyle={true}
        title="Are you sure?"
      message={message}
      onClose={() => this.onDeleteClicked(null)}
      actions={
        <DialogActions>
          <Button variant="text" onClick={() => this.onDeleteClicked(null)}>Cancel</Button>
          <Button variant="contained" color="default" className="errorBtn" onClick={() => this.handleDelete(itemToDelete.id)}>Confirm</Button>
        </DialogActions>
      } />)
    }
  }

  onDeleteClicked = (item) => {
    this.setState({
      itemToDelete: item
    });
  }

  handleDelete = (id) => {
    const { actions } = this.props;
    return actions.deleteReportSchedule(id).then(response => this.handleResponse(response));
  }

  handleResponse = response => {
    const { enqueueSnackbar } = this.props;
    this.onDeleteClicked(null);//hide confirm
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar);
      
      //reload
      this.mountItems(true);
    }
  }

  mountItems = (hardReload = false) => {
    const _this = this;
    const { actions } = this.props;
    const { items } = this.state;

    if(items.length < 1 || hardReload){
      
      actions.getReportSchedules().then( response => {
        return _this.setState({
          items: objectPath.get(response, "data.data", []),
          dataLoading: false
        })
      });
    }else{
      this.setState({
        dataLoading: false
      })
    }
     
  }
  


  componentDidMount(){
    
    this.mountItems()
  }


  render () {
    const { dataLoading, items, itemToDelete } = this.state;


    return <div>
    <PageHeading heading={FormatterUtil.camelToNormalForm(entityName)+"s"} actions={<HeadingAddBtn redirectToUrl={entityListUrl+'create/'} />} />
    { dataLoading ? <ComponentLoading /> : 
      <div>
      <ListingTable 
        items={items} 
        rowClassName={entityName}
        onDeleteClicked={this.onDeleteClicked}
         />
    </div> }
    { itemToDelete ? this.renderConfirmationDialog() : null}
    
  </div>
  }
}


function mapStateToProps(state) {
  return {
    reportSchedules: state.items,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...reportScheduleActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(ListReportSchedule)));
