import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, Hidden } from '@material-ui/core';
import DateUtil from '../../utils/DateUtil';
import classnames from 'classnames';
import UiHelperUtil from '../../utils/UiHelperUtil';
import RichTextRenderer from '../RichTextRenderer';


const truncateToLength = 100;

    class PreviousComment extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                showMore: false,
            }
          }

          getText = () => {
            const { text } = this.props;
            const { showMore } = this.state;
            return showMore ? text : UiHelperUtil.truncateIfNeeded(text,truncateToLength);
          }

       


        render() {
            const { date, text, classes } = this.props;
            const { showMore } = this.state;
            const adjustedText = this.getText();

            return text && text.length ? <div onClick={()=>this.setState({showMore:!showMore})} className={classnames(classes.previousComment,classes.previousValue, (this.state.showMore === false && 'compact'))}>
            {date ? <Typography variant="caption">{DateUtil.fullNameMonthDate(date)}</Typography> : null}
            <Typography variant="body2" component="div">
            {
              showMore || !UiHelperUtil.isTruncateNeeded(text,truncateToLength) ? 
              <RichTextRenderer text={text} />
              :  
              <Hidden smDown><RichTextRenderer text={adjustedText} /></Hidden>
            }
            </Typography>
            {(text && UiHelperUtil.isTruncateNeeded(text,truncateToLength)) ? <div className="btnWrap">
            <Hidden smDown><Typography variant="caption">{showMore ? "- Show less" : "+ Show more" }</Typography></Hidden>
            <Hidden mdUp><Typography variant="caption">{showMore ? "- Hide previous comment" : "+ See previous comment" }</Typography></Hidden>
            </div> : null }
        </div> : null
        }
    }


    PreviousComment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PreviousComment);