import React from "react";
import { FormControl, Grid, FormGroup, Button, Hidden, NativeSelect } from "@material-ui/core";

import { InputHelperText } from ".";
import LabelTooltip from "./LabelTooltip";

export default ({
  props,
  label,
  input,
  placeholder,
  helpText,
  meta: { touched, invalid, error, initial, form },
  range,
  previousValue,
  ...custom
}) => {
  


  return (
  <FormGroup className="rateInputWrap" >
    <FormControl error={touched && invalid}>
    <Grid container>
    <Grid item xs={12}>
    <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
    
    </Grid>
    <Grid item className="gridItem" xs={12}></Grid>
    
    <Hidden smUp>
    <Grid item className="gridItem" xs={12}>
   <NativeSelect {...input} className="nativeSelect">
          { !initial ? <option key="notSelected"> --- </option> : null }
          { range.map((i,key) => {
    return <option key={key} value={i}>{i}</option>})}
        </NativeSelect>
        </Grid>
        </Hidden>
    
    <Hidden only="xs">
    <Grid item xs={12}>
    { range.map((i,key) => {
    let btnVariant = i === previousValue ? 'outlined' : 'contained';
    let btnColor = 
      input.value === i //current value
      || 
      i === previousValue
      ? "primary" : "default";
    return <Button variant={btnVariant} className={btnColor === 'default' ? "notSelected":""} color={btnColor} key={key} onClick={() => input.onChange(i)}>{i}</Button>})}
    </Grid>
    </Hidden>
    </Grid>    
  </FormControl>
  {InputHelperText({ touched, error })}
  </FormGroup>
)};
