import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withRouter, Prompt } from "react-router"
import { connect } from "react-redux"
import { isDirty, isSubmitting, hasSubmitSucceeded } from "redux-form"

function warnAboutUnsavedForm(WrappedComponent, formName) {
  class WarnAboutUnsavedChanges extends React.Component {
    static propTypes = {
      isFormDirty: PropTypes.bool,
      isFormSubmitting: PropTypes.bool,
      hasFormSubmitSucceeded: PropTypes.bool,
      leaveMessage: PropTypes.string.isRequired
    }

    static defaultProps = {
      leaveMessage: "Leave with unsaved change?"
    }

    componentDidUpdate() {
      let { isFormDirty, leaveMessage } = this.props;

      this._promptUnsavedChange( isFormDirty,  leaveMessage)
    }

    componentWillUnmount() {
      window.onbeforeunload = null
    }

    _promptUnsavedChange(isUnsaved = false, leaveMessage) {
      window.onbeforeunload = isUnsaved && (() => leaveMessage)
    }

    render() {
      const { isFormDirty, isFormSubmitting, hasFormSubmitSucceeded, leaveMessage} = this.props;
      console.log('IsFormDirty:',isFormDirty);
      console.log('isFormSubmitting:',isFormSubmitting);
      console.log('hasFormSubmitSucceeded', hasFormSubmitSucceeded);
      return (
        <Fragment>
          <WrappedComponent {...this.props} />
          <Prompt
            when={(isFormDirty && !hasFormSubmitSucceeded  )}
            message={leaveMessage}
          />
        </Fragment>
      )
    }
  }

  const mapStateToProps = state => ({
    isFormDirty: isDirty(formName)(state),
    isFormSubmitting: isSubmitting(formName)(state),
    hasFormSubmitSucceeded: hasSubmitSucceeded(formName)(state)
  })

  return withRouter(connect(mapStateToProps, null)(WarnAboutUnsavedChanges))
}

export default warnAboutUnsavedForm