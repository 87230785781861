import React, { Component } from 'react';
import Select from 'react-select';
import objectPath from 'object-path';


export default class ReactSelect2Wrap extends Component {
  


  handleChange = selectedOption => {
    const { onChange, isMulti } = this.props;
    var newValue = null;
    if(isMulti){
      newValue = selectedOption.map(x => objectPath.get(x, 'value'));
    } else {
      newValue = objectPath.get(selectedOption, 'value', null);
    }
    
    onChange(newValue);
    
  };

  getValue = () => {
    const { isMulti, fieldOptions, value } = this.props;
    if(isMulti){
      return (Array.isArray(fieldOptions) && Array.isArray(value)) ? fieldOptions.filter(o => value.indexOf(o.value) !== -1) : [];
    }
    return Array.isArray(fieldOptions) ? fieldOptions.find(o => o.value === value) : null;
  };


  render () {
    const { fieldName, fieldOptions, classNamePrefix, isDisabled, isSearchable, isMulti } = this.props;

        return <Select 
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        isMulti={isMulti}
        name={fieldName}
        options={fieldOptions}
        classNamePrefix={classNamePrefix}
        value={this.getValue()}
        onChange={this.handleChange}
         />
    }
}