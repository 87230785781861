import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import objectPath from 'object-path';
import FinancialOverviewCard from './FinancialOverviewCard';
import { Grid, Divider, Typography, Hidden } from '@material-ui/core';
import PreviousComment from './PreviousComment';
import {
  TextLabelColumnsInput,
} from "../inputs";
import { Field } from "redux-form";
import ProgressReportUtil from '../../utils/ProgressReportUtil';

function FinanciallyOverviewSection(props) {

const { item } = props;
  let items = [];
  const previousReport = objectPath.get(item,'previousProgressReport');
  const previousExists = objectPath.get(item,'previousProgressReport.financialOverview');
  const previousVarianceExplanationText = objectPath.get(item,'previousProgressReport.financialOverview.varianceExplanation','');
  const previousVarianceExplanationDate = objectPath.get(item,'previousProgressReport.financialOverview.varianceExplanationDts');
  const currentExists = objectPath.get(item,'financialOverview');
    
    if(currentExists){
        items.push(item);
    }
    if(previousExists){
      items.push(item.previousProgressReport);
    }



  return items.length ? <Grid container className={props.classes.financialOverviewSectionWrap}><Grid item className="gridItem" xs={12}>
  <Typography variant="h4">FINANCIAL OVERVIEW</Typography>

</Grid>
{/* mobile labels */}
<Hidden smUp>
  <Grid item className="gridItem sectionOverviewItem mobileLabelColumn" xs={4}>
    <Grid container className={props.classes.financialOverviewCard}>
    <Grid item xs={12}>
        <Typography variant="h6"><br /></Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography className="cardCaption">Annual Budget (rev)</Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography className="cardCaption">Actual YTD</Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography className="cardCaption">Budget YTD (rev)</Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography className="cardCaption">YTD Variance</Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography className="cardCaption">System Assessment</Typography>
    </Grid>
    </Grid>
  </Grid>
</Hidden>
{/* mobile labels */}


{Array.isArray(items) ? items.map((i,index) => <Grid key={index} item className="gridItem sectionOverviewItem" xs={4} sm={6} lg={index === 0 ? 7 : 5}>
<FinancialOverviewCard item={i} /></Grid>) : null}
<Grid item className="gridItem" xs={12} sm={6} md={7}>
{ ProgressReportUtil.showFinancialOverviewVarianceExplanation(item) ? <Field name="financialOverview.varianceExplanation" component={TextLabelColumnsInput} label="Variance Explanation" multiline={true} rows="4" /> : null }
  
    
    
</Grid>
<Grid item className="gridItem" xs={12} sm={6} md={5}>
{ ProgressReportUtil.showFinancialOverviewVarianceExplanation(previousReport) && previousVarianceExplanationText.length ?  <PreviousComment date={previousVarianceExplanationDate} text={previousVarianceExplanationText} /> : null }
</Grid>  

<Grid item className="gridItem" xs={12}>
  <Divider className="sectionDivider" />
</Grid></Grid> : null;
}

FinanciallyOverviewSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FinanciallyOverviewSection);