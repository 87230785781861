import React from "react";
import { Input, FormControl } from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import LabelTooltip from "./LabelTooltip";

export default ({
  props,
  label,
  input,
  placeholder,
  type,
  helpText,
  meta: { touched, invalid, error, form },
  ...custom
}) => {

  

  return (
    <FormControl error={touched && error ? true : false} className="textFieldWrap" >
      <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
      <Input
      {...input}
      {...custom}
      placeholder={placeholder ? placeholder : label}
      error={touched && invalid}
      
    />
      {InputHelperText({ touched, error })}
    </FormControl>
    
  )
};

