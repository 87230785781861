import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as binderActions from '../../actions/binder';
import * as reportOutputActions from '../../actions/reportOutput';
import * as reportScheduleActions from '../../actions/reportSchedule';
import * as reportingItemActions from '../../actions/reportingItem';
import ComponentLoading from "../../components/ComponentLoading";
import objectPath from 'object-path';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../theme/styles";
import PageHeading from "../../components/PageHeading";
import AddIcon from "@material-ui/icons/Add";
import { Button, Fab, Typography, Hidden } from "@material-ui/core";
import SnackBarUtil from '../../utils/SnackBarUtil';
import BinderCategoryForm from '../../components/forms/binderCategory/form'
import { withSnackbar } from "notistack";
import BinderCategory from "../../components/binder/BinderCategory";
import StoreUtil from "../../utils/StoreUtil";
import ArrayUtil from "../../utils/ArrayUtil";
import UiHelperUtil from "../../utils/UiHelperUtil";
import {destroy} from 'redux-form';
import * as formNames from "../../constants/forms";
import LocalStorageUtil from "../../utils/LocalStorageUtil";


class Binders extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      dataLoading: true,
      
      bindersSummary: null,
      showBinderCategoryForm: false,//new category
      
      editedCategoryLoading: false,
      editedCategoryId: null,//edited category
      editedCategory: null,

      //binder forms
      editedBinderLoading: false,
      showBinderFormCatId: null,
      editedBinder: null,
      unexpandedCategoryIds: [],
      expandedCardIds: [] // taken to this level to force mansory layout rerender on change
    }
  }

  componentWillUnmount(){
    LocalStorageUtil.set('unexpandedCategoryIds', this.state.unexpandedCategoryIds, false);
  }

  mountItems = (reload = false) => {
    const _this = this;
    const { actions } = this.props;
    const { bindersSummary } = this.state;


    if(bindersSummary === null || reload){
      this.setState({
        dataLoading: true
      })
      
      actions.getBinderCategorySummary().then( response => {    
        return _this.setState({
          bindersSummary: objectPath.get(response, "data.data"),
          dataLoading: false
        })
      });
    }
  }

  handleToggleCategory = (id) => {
    let { unexpandedCategoryIds } = this.state;
    if(!unexpandedCategoryIds.includes(id)){
      unexpandedCategoryIds = ArrayUtil.addToArrayIfNotExist(unexpandedCategoryIds, id);
    } else {
      unexpandedCategoryIds = ArrayUtil.removeFromArrayIfExist(unexpandedCategoryIds, id);
    }
    this.setState({unexpandedCategoryIds});
  }

  handleSubmitBinderCategory = (values) => {
    const { actions } = this.props;
    
    if(values.id){
      return actions.putBinderCategory(values).then(response => this.handleResponse(response));
    } else {
      return actions.postBinderCategory(values).then(response => this.handleResponse(response));
    }
  }

  handleSubmitBinder = (values) => {
    const { actions } = this.props;

    if(values.id){
      return actions.putBinder(values).then(response => this.handleResponse(response));
    } else {
      return actions.postBinder(values).then(response => this.handleResponse(response));
    }
    
  }

  handleDeleteBinder = (id) => {
    const { actions } = this.props;
    return actions.deleteBinder(id).then(response => this.handleResponse(response));
  }

  handleDeleteBinderCategory = (id) => {
    const { actions } = this.props;
    return actions.deleteBinderCategory(id).then(response => this.handleResponse(response));
  }

  handleResponse = response => {
    const { enqueueSnackbar } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar);
      //reload summary data

      this.handleShowBinderCategoryForm(false);//hide forms
      
      this.mountItems(true);
    }
  }

  
  componentDidMount(){
    const { actions } = this.props;
    actions.getReportOutputs().then(() => {
      return Promise.all([
        actions.getReportOutputs(),
        actions.getReportSchedules(),
        actions.getAllReportingItems(),
      ]);
    }).then(() => {
      this.mountItems();

      //get unexpandedCategoryIds from local storage
      let unexpandedCategoryIds = LocalStorageUtil.get('unexpandedCategoryIds', [], false);
      this.setState({unexpandedCategoryIds});
    });
  }

  handleShowBinderCategoryForm = (show = true) => {
    this.setState({
      showBinderCategoryForm: show,

        editedBinderLoading: false,
       showBinderFormCatId: null,
       editedBinder: null,

       editedCategoryLoading: false,
      editedCategoryId: null,
      editedCategory: null,
      },()=> UiHelperUtil.scrollToFirstFormOnPage());
  }

  handleShowBinderCategoryFormById = (categoryId) => {
    const { actions } = this.props;
    this.setState({
      showBinderCategoryForm: false,

      editedBinderLoading: false,
       showBinderFormCatId: null,
       editedBinder: null,

       editedCategoryLoading: true,
      editedCategoryId: null,
      editedCategory: null,
    }, () => this.props.destroyCategoryForm());
    //get category and destroy form
    if(categoryId){
      
  
      actions.getBinderCategory(categoryId).then((response)=>{
        let category = objectPath.get(response, "data.data");
        this.setState({
          editedCategoryLoading: false,
          editedCategory: category,
          editedCategoryId: categoryId
        },()=> UiHelperUtil.scrollToFirstFormOnPage());
      })
    } 
  }


  handleShowBinderFormForCategory = (categoryId, binderId) => {
    const { actions } = this.props;
    if(binderId){
      //get binder and destroy form
      this.setState({
        editedBinderLoading: true,
      }, () => this.props.destroyBidnerForm());
      actions.getBinder(binderId).then((response)=>{
        let binder = objectPath.get(response, "data.data");
        this.setState({
          showBinderCategoryForm: false,
          editedBinderLoading: false,
          showBinderFormCatId: categoryId,
          editedCategoryId: null,
          editedBinder: binder,
        },()=> UiHelperUtil.scrollToFirstFormOnPage());
      })
    }else{
      this.setState({
        showBinderCategoryForm: false,
        editedBinderLoading: false,
        showBinderFormCatId: categoryId,
        editedCategoryId: null,
        editedBinder: null
      },()=> UiHelperUtil.scrollToFirstFormOnPage());
    }
    
  }

  toggleCardInfo = (binderId) => {
    let { expandedCardIds } = this.state;
    let newExpandedCardIds;
    if(expandedCardIds.includes(binderId)){
      newExpandedCardIds = ArrayUtil.removeFromArrayIfExist(expandedCardIds, binderId);
    }else{
      newExpandedCardIds = ArrayUtil.addToArrayIfNotExist(expandedCardIds, binderId);
    }
    this.setState({
      expandedCardIds: newExpandedCardIds
    })
  }

  
  render () {
    const { dataLoading, bindersSummary, showBinderCategoryForm, showBinderFormCatId, expandedCardIds, editedBinder, editedBinderLoading, unexpandedCategoryIds, editedCategory, editedCategoryId, editedCategoryLoading } = this.state;
    const { reportOutputs, reportSchedules, allReportingItems } = this.props;

    const userInfo = StoreUtil.getValueByKey('userInfo');

 
    return <div>
    { dataLoading ? <ComponentLoading /> : 
      <div>
        <PageHeading heading="Binders" actions={
          <div>
            { !showBinderCategoryForm ? <Fab color="secondary" size="small" variant="extended" onClick={this.handleShowBinderCategoryForm}><AddIcon fontSize="small" /> <Hidden smDown>New </Hidden>Category</Fab> : null }
        </div>
        }/>



        {
              showBinderCategoryForm ? <div>
                <PageHeading heading="New binder category" variantClass="secondary" actions={
                  <Button onClick={() => this.handleShowBinderCategoryForm(false)} color="default" className="muted">
                    Cancel
                  </Button>
                }/>
                <BinderCategoryForm 
                  className="secondaryForm fullWidthBgComponent"
                  proceedSubmit={this.handleSubmitBinderCategory}
                  reportOutputs={ArrayUtil.mapIdNameToValueLabel(reportOutputs)}
                />
              </div>
              : null
            }

            {
              //loop summaries
              (Array.isArray(bindersSummary) && bindersSummary.length) ? bindersSummary.map(( item, index )=> {
                return <BinderCategory key={index} 
                category={item} 
                //handlers
                handleSubmitBinder={this.handleSubmitBinder}
                handleSubmitBinderCategory={this.handleSubmitBinderCategory}
                handleShowBinderFormForCategory={this.handleShowBinderFormForCategory}
                handleShowBinderCategoryFormById={this.handleShowBinderCategoryFormById}
                handleDeleteBinder={this.handleDeleteBinder}
                handleDeleteBinderCategory={this.handleDeleteBinderCategory}
                handleToggleCategory={this.handleToggleCategory}

                //
                editedBinderLoading={editedBinderLoading}
                unexpandedCategoryIds={unexpandedCategoryIds}
                
                //binder form id and cat id
                editedBinder={editedBinder}
                showBinderFormCategoryId={showBinderFormCatId}
                
                //edit category id
                editedCategoryLoading={editedCategoryLoading}
                editedCategory={editedCategory}
                editedCategoryId={editedCategoryId}

                //dropdown data
                reportingItems={allReportingItems}
                reportOutputs={reportOutputs}
                reportSchedules={reportSchedules}
                binderCategorySummary={bindersSummary}
                userInfo={userInfo}

                //toggle cards
                expandedCardIds={expandedCardIds}
                toggleCardInfo={this.toggleCardInfo}

                />;
              }) : <Typography variant="body2" color="error">No categories found</Typography>
              
            }


     </div> }
            
  </div>
  }
}


function mapStateToProps(state) {
  return {
    bindersSummary: state.bindersSummary,
    reportOutputs: state.reportOutputs,
    reportSchedules: state.reportSchedules,
    allReportingItems: state.allReportingItems
  };
}

const mapDispatchToProps = dispatch =>
(
  {
  destroyBidnerForm: function() {
    dispatch(destroy(formNames.BINDER_FORM_NAME))
  },
  destroyCategoryForm: function() {
    dispatch(destroy(formNames.BINDER_CATEGORY_FORM_NAME))
  },
  actions: bindActionCreators(
    {
        ...reportOutputActions,
        ...reportScheduleActions,
        ...reportingItemActions,
        ...binderActions,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(Binders))));
