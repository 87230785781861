import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import { EditableProgressReportForm } from "../../../components/forms/progressReport/summary/form";
import * as progressReports from "../../../actions/progressReport";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import FullScreenDialog from "../../../components/FullScreenDialog";
import ViewProgressReport from "./ViewProgressReport";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import { Hidden, Tooltip, Button, Typography, IconButton, DialogTitle, DialogContent, Dialog } from "@material-ui/core";
import EditIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import classnames from 'classnames';
import UrlBuilderUtil from "../../../utils/UrlBuilderUtil";
import ProgressReportUtil from "../../../utils/ProgressReportUtil";
import * as constants from '../../../constants/constants';
import ProjectDocumentsForm from '../../../components/forms/projectDocument/form'

import * as externalDocumentActions from '../../../actions/externalDocuments';


class EditProgressReport extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true,
      dialogProjectId: null,
      showQCForm: false,
      showCommentaryModal: false,
      documents: []
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
    );
  }

  viewProgressReportById = (projectId) => {
    this.setState({
      dialogProjectId: projectId
    });
  }

  mountItem(){
    const { match, history, actions, location } = this.props;
    const _this = this;
    var projectId = objectPath.get(match, "params.projectId");

    if (projectId) {

      return actions.getProgressReport(projectId).then( response => {
        let progressReport = objectPath.get(response, "data.data");

        if (progressReport && progressReport.editable === true) {
          return _this.setState({
            item: progressReport,
            externalDocuments: objectPath.get(progressReport, 'externalDocuments', [])
          });

        } else {
          return history.push("/401?request="+location.pathname);
        }
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  handleResponse = response => {
    const { enqueueSnackbar, history, match } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      let itemName = objectPath.get(match, 'params.projectId') ? '#'+objectPath.get(match, 'params.projectId') : '';
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar,
        itemName+" item updated"
         );


       setImmediate(() => {
        history.push(UrlBuilderUtil.getDashboard())
       });
    }
  }

  onMainTabClick = (event,newTabId) => {
    const { history } = this.props;
    const { item } = this.state;
    if(newTabId === "commentary" && item &&  item.reportingItemId){
      history.push(UrlBuilderUtil.getReportingItemCommentary(item.reportingItemId));
    }
  }

  handleSubmit = values => {
    const { actions, match } = this.props;
    values.projectId = objectPath.get(match, 'params.projectId');
    
    //empty fields under conditions
    values = ProgressReportUtil.emptyConditionalFields(values);
    
    return actions.putProgressReport(values).then(response => this.handleResponse(response));
  };

  hideModal = () => {
    this.setState({
      showCommentaryModal: false
    }, () => { });
  }

  handleCreateDocumentCallback = (data) => {
    const documents = data.slice();

    this.setState({
      externalDocuments: documents
    });

    this.hideModal();
  }

  showModal = () => {
    this.setState({
      showCommentaryModal: true,
    }, () => { });
  }

  handleDeleteCallback = (id) => {
    const { externalDocuments } = this.state;

    const documents = externalDocuments.slice().filter((item) => item.id !== id);
    this.setState({
      externalDocuments: documents
    });
  };

  render() {
    const { match, classes, actions, enqueueSnackbar } = this.props;
    var projectId = objectPath.get(match, "params.projectId");
    const { item, dataLoading, dialogProjectId, showCommentaryModal, externalDocuments } = this.state;
    
    const progressReportExtarnalLinkUrl = objectPath.get(item,'hyperlink.url');

    const mapUrl = objectPath.get(item, 'projectMapUrl', '');
    const canEdit = objectPath.get(item, 'editable', false);

    return (
      <div>
        <PageHeading 
          mutedHeading={'#'+projectId} 
          heading={dataLoading ? "loading ..." : item ? item.projectName : null} 
          subHeading={dataLoading ? "" : item.reportingItemDescription}
          mapUrl={mapUrl}
          showPinpointMapIcon={mapUrl !== ''}
          externalDocuments={externalDocuments}
          canEdit={canEdit}
          onDeleteDocumentCallback={this.handleDeleteCallback}
          apiActions={actions}
          actions={
          <div>
            <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getDashboard()} />
          </div>
        } />

        { dataLoading ? <ComponentLoading /> : 
          <div>

            <Tabs classes={{root: classes.tabsWrapper}} indicatorColor="primary" value={"summary"} onChange={this.onMainTabClick}>
              <Tab label="Status" value="summary" classes={{root: classes.tabBtn}} />
              {item && item.reportingItemId ? 
                <Tab label="Commentary" value="commentary" classes={{root: classes.tabBtn}} /> : 
                null 
              }
            </Tabs>

            { progressReportExtarnalLinkUrl || canEdit ? 
              <Hidden mdDown>
                
                <div className={classnames("hideForPrint", classes.textRightStrong, classes.summaryActionsWrap)}>
                  { canEdit ?
                    <Tooltip title="Link Document(s)">
                      <Button color="primary" 
                        size="small" 
                        variant="outlined" 
                        target="_blank" 
                        onClick={this.showModal}><LinkIcon /> &nbsp; Link Document(s)</Button>
                    </Tooltip>
                    : null
                  }

                  {" "}

                  { progressReportExtarnalLinkUrl ? 
                    <Tooltip title="Open Project in Ozone">
                      <Button color="primary" size="small" variant="outlined" target="_blank" href={progressReportExtarnalLinkUrl}><EditIcon /> &nbsp; Edit</Button>
                    </Tooltip>
                    : null  
                  }
                </div>

              </Hidden> : null 
            }

            <EditableProgressReportForm 
                viewProgressReportById={this.viewProgressReportById}
                proceedSubmit={this.handleSubmit}               
                initialValues={item}
            />

          </div>
        }

        { dialogProjectId ? 
          <FullScreenDialog closeDialog={()=>this.viewProgressReportById(null)} showBar={true}>
            <ViewProgressReport 
              dialogProjectId={dialogProjectId} 
              isInDialog={true} 
              onBackBtn={()=>this.viewProgressReportById(null)} />
            </FullScreenDialog> : null 
        }

        <div>
          <Dialog fullWidth={true} maxWidth={"md"}
            onClose={this.hideCommentaryModal} open={showCommentaryModal}
            className={classnames("dialogWithArrows", classes.formDialogWrap)}
            classes={{ paper: "paper" }}>

            <DialogTitle className="formDialogTitle" onClose={this.hideModal} disableTypography={true}>
              <Typography key="mutedHeading" variant="h5" className="muted"><strong>#{projectId}</strong></Typography>
              <Typography variant="h5">
                <strong>{dataLoading ? "loading ..." : item ? item.projectName : null}</strong>
                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Close dialog">
                  <IconButton size="small" onClick={this.hideModal}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </DialogTitle>

            <DialogContent className={ "dialogFormContent" }>
              <ProjectDocumentsForm
                currentProject={item}
                onCancel={this.hideModal}
                actions={actions}
                snackbar={enqueueSnackbar}
                onCreateDocumentCallback={this.handleCreateDocumentCallback}
              />
            </DialogContent>

          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...progressReports,
    ...externalDocumentActions
  }, dispatch)
});

export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, {
      withTheme: true
    })(EditProgressReport)
  )
));