//import objectPath from 'object-path';
import ValidationUtil from '../../../../utils/ValidationUtil';

const validate = (values, {registeredFields} = {}) => {


  let errors = {};


  const requiredFields = [
    'activitySummaryReport.nonFinancialComment',
    'activitySummaryReport.financialComment',
    'activitySummaryReport.areaManagerNonFinancialComment',
    'activitySummaryReport.areaManagerFinancialComment'
  ];


  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);




  return errors;
};

export default validate;
