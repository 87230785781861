import objectPath from 'object-path';
import StoreUtil from './StoreUtil';
import * as constants from '../constants/constants';
import ArrayUtil from './ArrayUtil';

export default class ReportingItemsUtil {


  static getNameById(id) {
    var allReportingItems = StoreUtil.getValueByKey('allReportingItems.content', []);
    var itemFound = allReportingItems.find(x => x.id === id);
    return objectPath.get(itemFound, 'name', '');
  }

  static getFirstItemId(item){
    return objectPath.get(item,'reportingItems.0.id',null);
  }


  static isRICompletedByState(state,context = constants.DASHBOARD_CARD_TYPE_AS) {
    
    if(context === constants.DASHBOARD_CARD_TYPE_BINDER){
      return state === constants.REPORTING_ITEM_COMMENTARY_STATE_NOTDUE
    } else {
      return [constants.REPORTING_ITEM_STATUS_STATE_COMPLETE].includes(state)
    }
  }

  static getRIStateByContext(ri, context = constants.DASHBOARD_CARD_TYPE_AS) {
    var state = objectPath.get(ri,'dueState');
    if(context === constants.DASHBOARD_CARD_TYPE_BINDER){
      state = objectPath.get(ri,'dueCommentaryState');
    }
    return state;
  }

  static getRIcompleteCount(items,context = constants.DASHBOARD_CARD_TYPE_AS){
    return items.filter(x => x && ReportingItemsUtil.isRICompletedByState(
      ReportingItemsUtil.getRIStateByContext(x,context),
      context
      )).length;
  }

  static getNoneCompleteRIList(items,context = constants.DASHBOARD_CARD_TYPE_AS){
    return items.filter(x => x && !ReportingItemsUtil.isRICompletedByState(
      ReportingItemsUtil.getRIStateByContext(x,context),
      context
      ));
  }

  static getFirstNoneCompleteRI(items,context = constants.DASHBOARD_CARD_TYPE_AS){
    var nonCompleteList = ReportingItemsUtil.getNoneCompleteRIList(items, context);
    return ArrayUtil.isNonEmptyArray(nonCompleteList) ? nonCompleteList[0] : null;
  }
  

  
  static getRIstateClasses(item,context = constants.DASHBOARD_CARD_TYPE_RI) {
    let classNames = ['ri'];

    if (item.responsible) {
      classNames.push('responsible');
    }
    if (context) {
      classNames.push('ri-context-type-'+context);
    }
    if(item.dueCommentaryState){
      classNames.push('dueCommentaryState-'+item.dueCommentaryState);
    }
    if(item.dueProgressReportState){
      classNames.push('dueProgressReportState-'+item.dueProgressReportState);
    }
    if(item.dueState){
      classNames.push('dueState-'+item.dueState);
    }
    
    return classNames.join(' ');
  }

  static isProgressReportDue(item){
    var projectID = objectPath.get(item, 'projectId',null);
    var dueState = objectPath.get(item, 'dueState',null);
    var dueProgressReportState = objectPath.get(item, 'dueProgressReportState',null);
    return (projectID && constants.REPORTING_ITEM_DUE_STATES.includes(dueState) && dueProgressReportState === constants.REPORTING_ITEM_STATUS_STATE_NOTCOMPLETE) ? true : false;
  }






}
