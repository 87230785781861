import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import objectPath from 'object-path';
import { Grid, Typography, InputAdornment, Hidden } from '@material-ui/core';

import { Field } from "redux-form";
import { TextInput, RadioGroupInput, NativeSelectInput } from '../inputs';
import AssessmentUtil from '../../utils/AssessmentUtil';
import * as constants from '../../constants/constants';
import classnames from 'classnames'
import PreviousValue from './PreviousValue';


class OverallStatus extends React.Component {



  render(){


  const { item, classes, progressReportAssessmentType } = this.props;
  const overallStatus = objectPath.get(item,'status');
  const overallStatusConsolidationAssessment = objectPath.get(item,'status.consolidationAssessment');

  const previousOverallStatus = objectPath.get(item,'previousProgressReport.status');
  let previousOverallStatusPmAssesment = objectPath.get(previousOverallStatus,'pmAssessment');
  const previousOverallStatusPercentComplete = objectPath.get(previousOverallStatus,'percentComplete');
   

  const assessmentOptions = [
    { value:constants.ASSESSMENT_MET.toString(),label:constants.ASSESSMENT_MET_TEXT },
    { value:constants.ASSESSMENT_PARTIALMET.toString(),label:constants.ASSESSMENT_PARTIALMET_TEXT },
    { value:constants.ASSESSMENT_NOTMET.toString(),label:constants.ASSESSMENT_NOTMET_TEXT },
    { value:constants.ASSESSMENT_NOTCOMMENCED.toString(),label:constants.ASSESSMENT_NOTCOMMENCED_TEXT },
  ];

  const showSectionsBasedOnType = progressReportAssessmentType !== constants.PROGRESS_REPORT_KPI_TYPE;
  

  return overallStatus ? <Grid container className="OverallStatusSection">
  <Grid item className="gridItem" xs={12}>
    <Typography variant="h4">OVERALL PROJECT STATUS</Typography>
  </Grid>

  { showSectionsBasedOnType ? <Grid item className="gridItem sectionOverviewItem" xs={12}>
        <Typography variant="caption" className="muted inlineTypo" component="span">
        <strong>Consolidation Assessment</strong>
        </Typography> &nbsp; <Typography variant="body2" className="inlineTypo" component="span">
            {AssessmentUtil.getIndicatorText(overallStatusConsolidationAssessment)} &nbsp;
            <span className={classnames("indicator",AssessmentUtil.getIndicatorClassname(classes, overallStatusConsolidationAssessment))}></span>
        </Typography>
  </Grid> : null}

  { showSectionsBasedOnType ? <Grid item className="gridItem sectionOverviewItem" xs={9} sm={7}>
  <div className="percentageInputWrap">
            <Field name="status.percentComplete" component={TextInput} label="Percentage Complete"
            placeholder="" 
            helpText="help text test"
            endAdornment={<InputAdornment position="end" className="percentageSign">%</InputAdornment>}
            />
            </div>
  </Grid> : null}

  { showSectionsBasedOnType ? <Grid item  xs={3} sm={5} className={classnames(classes.textRightOnlyXs,"gridItem sectionVerticalPaddingItem")}>
  <Hidden smUp><br /></Hidden>
            <PreviousValue text={previousOverallStatusPercentComplete ? previousOverallStatusPercentComplete+"%" : ""} />
  </Grid> : null}

  <Grid item className="gridItem sectionOverviewItem" xs={12} sm={7}>
  <Hidden xsDown>
  <Field name="status.pmAssessment" className="inlineRadioGroup" component={RadioGroupInput} dataOptions={assessmentOptions} helpText="help text" label="Assessment" />  
  </Hidden>

  <Hidden smUp>
  <Field name="status.pmAssessment" component={NativeSelectInput} dataOptions={assessmentOptions} helpText="help text" label="Assessment" />  
  </Hidden>

  </Grid>

  <Grid item xs={12} sm={5} className={classnames(classes.textRightOnlyXs,"gridItem sectionVerticalPaddingItem")}>
            <PreviousValue text={AssessmentUtil.getIndicatorText(previousOverallStatusPmAssesment)} />
  </Grid>

  


</Grid> : null
}
}

OverallStatus.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(OverallStatus);