import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import * as formNames from "../../../constants/forms";
import * as constants from '../../../constants/constants';
import classnames from 'classnames';
import {
  TextInput, SelectWithSuggestions,
} from "../../inputs/";

import TickIcon from "@material-ui/icons/Check";
import validate from "./validate";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Fab, Tooltip} from "@material-ui/core";
import warnAboutUnsavedForm from "../../../hoc/withWarnAboutUnsavedChanges";
import PositionsUtil from "../../../utils/PositionsUtil";
import * as AuthUtil from '../../../utils/AuthUtil';


const BinderForm = props => {
  const {
    handleSubmit,
    classes,
    className,
    submitting,
    proceedSubmit,
    reportOutputs,
    reportSchedules,
    reportingItems,
    binderCategorySummary,
  } = props;
  




  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><TickIcon /></Fab>;

  const isReportingAdminRole = AuthUtil.hasReportingAdminRole();

  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classnames(classes.customForm, className)}>
           
          <div className="maxWidthPart narrowMaxWidthPart">
          <Grid container>
            
            
            <Grid item className="gridItem" xs={12}>
              <FormGroup>
                <Field name="name" component={TextInput} label="Name" inputProps={{maxLength: 50}} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12}>
                <Field name="binderCategoryId" component={SelectWithSuggestions} isSearchable={true} label="Binder Category" options={binderCategorySummary} placeholder="Select binder category" />
            </Grid>

            { isReportingAdminRole ? <Grid item className="gridItem" xs={12}>
              
              <Field name="reportScheduleId" component={SelectWithSuggestions} isSearchable={true} options={reportSchedules} label="Report Schedule"  />
            
          </Grid> : null }
          { isReportingAdminRole ? <Grid item className="gridItem" xs={12}>
              
                <Field name="reviewerPositionIds" closeMenuOnSelect={false} component={SelectWithSuggestions} isSearchable={true} isMulti={true} options={PositionsUtil.formatForUserSelector()} label="Report Reviewers"  />
              
            </Grid> : null }

            <Grid item className="gridItem" xs={12}>
              
                <Field name="reportOutputIds" component={SelectWithSuggestions} isSearchable={true} isMulti={true} options={reportOutputs} label="Report Outputs"  />
              
            </Grid>


            <Grid item className="gridItem" xs={12}>
              
                <Field name="reportingItemIds" component={SelectWithSuggestions} 
                closeMenuOnSelect={false}
                isSearchable={true} isMulti={true} options={reportingItems} label="Reporting Items"  />
              
            </Grid>

            

            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12}>
{ disableSubmit ? submitButton : <Tooltip  enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Save binder">{submitButton}</Tooltip> }
</Grid> 
            
            </Grid>
          </div>
        </form>
    </div>
  );
};


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.BINDER_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  validate,
  //asyncValidate
})(BinderForm)),formNames.BINDER_FORM_NAME);
