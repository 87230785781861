import React from 'react';
import objectPath from 'object-path';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import styles from '../../theme/styles';
import { CardContent, Typography, Grid, CardActions, Tooltip, Chip, Hidden, IconButton, ButtonBase } from '@material-ui/core';
import classnames from 'classnames';
import { withRouter } from "react-router-dom";
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import ReportingItemsUtil from '../../utils/ReportingItemsUtil';
import PositionsUtil from '../../utils/PositionsUtil';
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import { NavLink } from "react-router-dom";
import * as constants from '../../constants/constants';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import DateUtil from '../../utils/DateUtil';
import BinderUtil from '../../utils/BinderUtil';
import PrintIcon from "@material-ui/icons/PrintOutlined";
import BinderPrintDialog from './BinderPrintDialog';
import PositionIconName from '../PositionIconName';
import BinderIcon from "@material-ui/icons/FolderOpenOutlined";

class BinderSummaryCard extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isOpen: true,
            displayPrintDialogForItem: null
        }
      }



    isOpen = () => {
        const { item, toggleCardReportingItemsById, expandedCardIds, expandable } = this.props;
        const { isOpen } = this.state;
        let itemId = objectPath.get(item, 'id', null);
        if(toggleCardReportingItemsById){ // dashboard fnc
            return (expandable && expandedCardIds && expandedCardIds.includes(itemId));
        } else if (expandable) {
            return isOpen
        }
    }

    toggleOpen = (itemId) => {
        const { toggleCardReportingItemsById } = this.props;
        if(toggleCardReportingItemsById){ // dashboard fnc
            toggleCardReportingItemsById(itemId);
        } else { //standalone for mobile submenu 
            this.setState({
                isOpen: !this.isOpen()
            })
        }
    }



    render() {
        const { classes, item, history, expandable, showCardResponsibilityStates } = this.props;
        const { displayPrintDialogForItem } = this.state;
        let itemId = objectPath.get(item, 'id', null);
        
        const riCountChip = <ButtonBase
        className="chipBtnWrap" 
        onClick={() => BinderUtil.isDetailReady(item) ? history.push(UrlBuilderUtil.getBinderSummary(itemId, ReportingItemsUtil.getFirstItemId(item))) : this.toggleOpen(itemId)} 
        disabled={!expandable}><Chip
            label={BinderUtil.getRIcompleteVSCount(item)}
            className={classes.chip}
            variant="outlined"
        /></ButtonBase>;

        const closedDate = objectPath.get(item, 'reportCloseDate', null);
        const positionIds = objectPath.get(item, 'positionIds', []);

        return (
            <Card className={classnames('card',classes.dashboardCard, showCardResponsibilityStates ? BinderUtil.getBinderStateClasses(item) : '')}>
                <CardContent>
                <span className="typeIndicator"></span>
                    <span className="bar"></span>
                    <Grid container className={classnames('cardHeading',classes.dashboardCardHeader)}>
                        <Grid item xs={6} lg={4} className="dateColumnWrap">
                        { closedDate ? <Typography variant="caption" className="muted date">
                                <CalendarIcon className="calendarIcon" /> { DateUtil.basicDate(closedDate, true) }
                            </Typography> : null }
                        </Grid>
                        <Grid item xs={6} lg={8} className="positionColumnWrap">
                        <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={positionIds.length > 1 ? positionIds.map(x => PositionsUtil.getUsernameFromStoreById(x)).join(', ') : ''}>
                            <Typography variant="caption" className={classnames('muted', classes.textRightStrong)} >
                                <PositionIconName positionIds={positionIds} />
                            </Typography>
                        </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12}>
                            {expandable ? <div>
                            { /* mobile link to Binder menu*/ }
                            <Hidden mdUp><Typography variant="h5">
                            <NavLink className="cardMainActionElement" to={UrlBuilderUtil.getBinderSummaryMenu(itemId)}>
                                <strong>{BinderUtil.adjustBinderName(item.name)}</strong>
                                <span className="fullPseudoAfter"></span></NavLink>
                            </Typography>
                            </Hidden>

                            { /* desktop link to Binder */ }
                            <Hidden smDown><Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Go to binder summary view"><Typography variant="h5">
                            <NavLink className="cardMainActionElement" to={UrlBuilderUtil.getBinderSummary(itemId, ReportingItemsUtil.getFirstItemId(item))}>
                                <strong>{BinderUtil.adjustBinderName(item.name)}</strong>
                                <span className="fullPseudoAfter"></span></NavLink>
                            </Typography></Tooltip></Hidden>
                            </div>
                                :
                                <Typography variant="h5"><strong>{BinderUtil.adjustBinderName(item.name)}</strong></Typography>}
                        </Grid>
                        {this.isOpen() ? 
                        (item.reportingItems && item.reportingItems.length ? 
                            item.reportingItems.map((x, index) => <Grid item xs={12} key={index} className={classnames(classes.cardReportingItemLink, ReportingItemsUtil.getRIstateClasses(x, constants.DASHBOARD_CARD_TYPE_BINDER))}>
                            <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="View commentary in binder view">
                            <NavLink 
                            to={UrlBuilderUtil.getBinderSummaryReportingItem(itemId, x.id)}>
                            <Typography variant="body2">
                                <span className='cardRIstateIndicator'><CheckIcon /></span><span>{x.name}</span>
                                <PositionIconName positionIds={x.positionId} />
                            </Typography></NavLink></Tooltip>
                        </Grid>) : <Typography className="muted" variant="body2">No reporting items found</Typography>) : null}
                    </Grid>
                </CardContent>
                <CardActions className={classes.cardBottomActions}>
                <BinderIcon  fontSize="small" className="cardTypeIcon" />
                
                    <Typography className="leftTextWrap muted"> </Typography>
                    <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} key="printBtn"  title={"Print binder "+item.name} aria-label={"Print binder "+item.name}>
        <IconButton size="small" className="printBtn smallIconBtn" onClick={() => this.setState({displayPrintDialogForItem: item})}>
            <PrintIcon fontSize="small" />
        </IconButton>
        </Tooltip>
                    {riCountChip}
                    
                    { displayPrintDialogForItem ? <BinderPrintDialog item={displayPrintDialogForItem} onClose={() => this.setState({displayPrintDialogForItem : null})}/> : null}
                </CardActions>
            </Card>
        );
    }

}

export default withRouter(withStyles(styles, { withTheme: true })(BinderSummaryCard));