
import ValidationUtil from '../../../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "reportOutputId",
    "audience",
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  return errors;
};

export default validate;
