import React from "react";
import { withStyles } from "@material-ui/core/styles";

import styles from "../../theme/styles";
import { TableRow, TableCell, IconButton, Typography, Tooltip, Hidden } from "@material-ui/core";
import EditIcon from '@material-ui/icons/EditOutlined';
import { withRouter } from "react-router-dom"; 
import classnames from 'classnames';
import * as constants from '../../constants/constants';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import ReinstateIcon from '@material-ui/icons/UnarchiveOutlined';
import UrlBuilderUtil from "../../utils/UrlBuilderUtil";


function ReportingItemTableRow(props) {


  const { item, position, isExpanded, onExpandRow, history, classes, onArchiveToggleClicked } = props;



return <TableRow key={item.id} onClick={()=> onExpandRow(item.id) } className={isExpanded ? classes.expandedRow : null}>

<TableCell className={classnames("expandCellWidth")}>
  <Typography variant="body2"><strong>{item.name}</strong></Typography>
</TableCell>

<TableCell className={classes.tableCellProjectId}>
  <Typography variant="body2">{item.projectId ? <strong>{"#"+item.projectId}</strong> : null}</Typography>
</TableCell>

  <TableCell className="posNameCellWidth">
    <Typography variant="body2">{position ? position.positionId +" - "+ position.userFullname : null}</Typography>
  </TableCell>

<Hidden xsDown><TableCell className={classes.tableCellActionWrap}>
  <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Edit Reporting Item">
    <IconButton size="small" aria-label="Edit" onClick={() => history.push(UrlBuilderUtil.getReportingItemEdit(item.id))}>
      <EditIcon  fontSize="small"/>
    </IconButton>
  </Tooltip>
  { item.archived ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Reinstate Reporting Item">
                  <IconButton size="small" aria-label="Reinstate" onClick={() => onArchiveToggleClicked(item)}>
                    <ReinstateIcon  fontSize="small"/>
                  </IconButton>
                </Tooltip>
                :
                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Archive Reporting Item">
                  <IconButton size="small" aria-label="Archive" onClick={() => onArchiveToggleClicked(item)}>
                    <ArchiveIcon fontSize="small" />
                  </IconButton>
                </Tooltip> }
</TableCell></Hidden>

</TableRow>
}

export default withRouter(withStyles(styles, { withTheme: true })(ReportingItemTableRow));

