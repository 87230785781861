import React from 'react';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import objectPath from 'object-path';
import store from '../../../store';
import classnames from 'classnames';
import * as formNames from '../../../constants/forms';
import Fab from '@material-ui/core/Fab';
import TickIcon from '@material-ui/icons/Check';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Hidden,
    Button,
    Typography,
    Grid,
} from '@material-ui/core';
import warnAboutUnsavedForm from '../../../hoc/withWarnAboutUnsavedChanges';
import { TextInput } from '../../inputs/';
import { Field } from 'redux-form';
import FormGroup from '@material-ui/core/FormGroup';
import SnackBarUtil from '../../../utils/SnackBarUtil';

class ProjectDocumentsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectiveItemName: '',
            objectiveFolderName: '',
            submitDisabled: true,
            wasEnabledByLink: false,
            wasEnabledByObjective: false,
            hasUrlError: false,
            hasTitleError: false,
            hasTitleMissingError: false,
            hasUrlMissingError: false,
        };
    }

    handleSelectObjective = (e) => {
        const _this = this;
        const form = store.getState()['form'];
        const { actions, snackbar, currentProject } = this.props;
        const { wasEnabledByLink } = this.state;

        const objectiveId = objectPath.get(
            form,
            'ProjectDocumentsForm.values.objectiveId',
            ''
        );
        const projectId = objectPath.get(currentProject, 'projectId', '');

        const trimmedObjectiveId = objectiveId.trim();

        if (trimmedObjectiveId === '') return;

        actions
            .checkObjectiveById(trimmedObjectiveId, projectId)
            .then((response) => {
                let documentName = '';
                let documentFolder = '';
                let disabled = !wasEnabledByLink;
                let enabledByObjective = false;

                if (!SnackBarUtil.isResponseError(response, snackbar)) {
                    documentName = response.data.data.item1;
                    documentFolder = response.data.data.item2;
                    disabled = false;
                    enabledByObjective = true;
                }

                _this.setState({
                    objectiveItemName: documentName,
                    objectiveFolderName: documentFolder,
                    submitDisabled: disabled,
                    wasEnabledByObjective: enabledByObjective,
                });
            });
    };

    handleSubmit = (e) => {
        const form = store.getState()['form'];
        const {
            actions,
            snackbar,
            currentProject,
            onCancel,
            onCreateDocumentCallback,
        } = this.props;

        const { objectiveItemName, objectiveFolderName } = this.state;
        const projectId = objectPath.get(currentProject, 'projectId', '');
        const objectiveId = objectPath.get(
            form,
            'ProjectDocumentsForm.values.objectiveId',
            ''
        );
        const url = objectPath.get(form, 'ProjectDocumentsForm.values.url', '');
        const title = objectPath.get(
            form,
            'ProjectDocumentsForm.values.title',
            ''
        );

        let payload = [];

        if (objectiveItemName !== '') {
            payload.push({
                title: objectiveItemName,
                folderName: objectiveFolderName,
                objectiveId: objectiveId.trim(),
            });
        }

        if (url.trim().length > 0 && title.trim().length > 0) {
            payload.push({
                title: title.trim(),
                url: url.trim(),
            });
        } else if (url.trim().length > 0 || title.trim().length > 0) {
            const hasTitleMissingError = title.trim().length === 0;
            const hasUrlMissingError = url.trim().length === 0;

            this.setState({
                hasTitleMissingError: hasTitleMissingError,
                hasUrlMissingError: hasUrlMissingError,
            });

            return;
        }

        if (payload.length === 0) onCancel();

        actions.create(projectId, payload).then((response) => {
            if (!SnackBarUtil.isResponseError(response, snackbar)) {
                onCreateDocumentCallback(response.data.data);
            }
        });
    };

    handleOnBlur = (e) => {
        const form = store.getState()['form'];
        const url = objectPath.get(form, 'ProjectDocumentsForm.values.url', '');
        const title = objectPath.get(
            form,
            'ProjectDocumentsForm.values.title',
            ''
        );
        const { wasEnabledByObjective } = this.state;

        let disabled = !wasEnabledByObjective;
        let enabledByLink = false;
        let hasUrlError = false;
        let hasTitleError = false;

        if (url !== '') {
            var expression =
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            var regex = new RegExp(expression);

            hasUrlError = !url.match(regex);
        }

        if (title.trim() !== '' && title.trim().length < 3) {
            hasTitleError = true;
        }

        if (
            url !== '' &&
            title.trim() !== '' &&
            !hasUrlError &&
            !hasTitleError
        ) {
            disabled = false;
            enabledByLink = true;
        }

        this.setState({
            submitDisabled: disabled,
            wasEnabledByLink: enabledByLink,
            hasUrlError: hasUrlError,
            hasTitleError: hasTitleError,
        });
    };

    handleObjectiveChange = (e) => {
        const { objectiveItemName, wasEnabledByLink } = this.state;

        if (objectiveItemName !== '') {
            this.setState({
                wasEnabledByObjective: false,
                objectiveItemName: '',
                objectiveFolderName: '',
                submitDisabled: !wasEnabledByLink,
            });
        }
    };

    render() {
        const { classes, onCancel } = this.props;

        const {
            objectiveItemName,
            objectiveFolderName,
            submitDisabled,
            hasUrlError,
            hasTitleError,
            hasTitleMissingError,
            hasUrlMissingError,
        } = this.state;

        const fileName = objectiveItemName !== '';
        const folderName = objectiveFolderName !== '';

        return (
            <form className={classes.customForm}>
                <Grid container>
                    <Grid item xs={12}>
                        <Table
                            className={classnames(
                                classes.table,
                                classes.expandedAllTable
                            )}
                        >
                            <TableBody>
                                <TableRow className={classes.expandedRow}>
                                    <TableCell
                                        className={classnames(
                                            'expandCellWidth'
                                        )}
                                    >
                                        <Typography variant="body2">
                                            <strong>
                                                Link Objective Document
                                            </strong>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    className={classnames(
                                        'expandedAdditionalRow',
                                        classes.expandedAdditionalRow
                                    )}
                                >
                                    <TableCell className="expandCellWidth">
                                        <Grid container>
                                            <Grid item xs={12} md={8} lg={7}>
                                                <FormGroup>
                                                    <Field
                                                        name="objectiveId"
                                                        component={TextInput}
                                                        label="Linked document"
                                                        placeholder="Objective ID"
                                                        onChange={
                                                            this
                                                                .handleObjectiveChange
                                                        }
                                                    />
                                                </FormGroup>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={4}
                                                style={{ marginTop: '20pt' }}
                                            >
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={
                                                        this
                                                            .handleSelectObjective
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        {fileName ? (
                                            <Typography variant="body2">
                                                Document:{' '}
                                                <strong>
                                                    {objectiveItemName}
                                                </strong>
                                            </Typography>
                                        ) : null}
                                        {folderName ? (
                                            <Typography
                                                variant="body2"
                                                className="muted"
                                            >
                                                Folder:{' '}
                                                <strong>
                                                    {objectiveFolderName}
                                                </strong>
                                            </Typography>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Table
                            className={classnames(
                                classes.table,
                                classes.expandedAllTable
                            )}
                        >
                            <TableBody>
                                <TableRow className={classes.expandedRow}>
                                    <TableCell
                                        className={classnames(
                                            'expandCellWidth'
                                        )}
                                    >
                                        <Typography variant="body2">
                                            <strong>Link External URL</strong>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    className={classnames(
                                        'expandedAdditionalRow',
                                        classes.expandedAdditionalRow
                                    )}
                                >
                                    <Hidden xsDown>
                                        <TableCell className="expandCellWidth">
                                            <FormGroup>
                                                <Field
                                                    name="url"
                                                    component={TextInput}
                                                    type="url"
                                                    label="URL"
                                                    inputProps={{
                                                        maxLength: 300,
                                                    }}
                                                    onBlur={this.handleOnBlur}
                                                />
                                                {hasUrlError ? (
                                                    <Typography
                                                        variant="body2"
                                                        color="error"
                                                    >
                                                        Invalid URL
                                                    </Typography>
                                                ) : null}
                                                {hasUrlMissingError ? (
                                                    <Typography
                                                        variant="body2"
                                                        color="error"
                                                    >
                                                        Please provide the URL
                                                        Link
                                                    </Typography>
                                                ) : null}
                                            </FormGroup>

                                            <FormGroup>
                                                <Field
                                                    name="title"
                                                    component={TextInput}
                                                    label="Title"
                                                    inputProps={{
                                                        maxLength: 150,
                                                    }}
                                                    onBlur={this.handleOnBlur}
                                                />
                                                {hasTitleError ? (
                                                    <Typography
                                                        variant="body2"
                                                        color="error"
                                                    >
                                                        Title must be at least 3
                                                        characters long
                                                    </Typography>
                                                ) : null}
                                                {hasTitleMissingError ? (
                                                    <Typography
                                                        variant="body2"
                                                        color="error"
                                                    >
                                                        Please provide a URL
                                                        Title
                                                    </Typography>
                                                ) : null}
                                            </FormGroup>
                                        </TableCell>
                                    </Hidden>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <div>&nbsp;</div>
                <Grid
                    item
                    className={classnames('gridItem', classes.textRightStrong)}
                    xs={12}
                >
                    <Button variant="text" size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                    &nbsp;
                    <Fab
                        color="secondary"
                        type="button"
                        size="large"
                        onClick={this.handleSubmit}
                        disabled={submitDisabled}
                    >
                        <TickIcon />
                    </Fab>
                </Grid>
            </form>
        );
    }
}

export default warnAboutUnsavedForm(
    withStyles(styles, { withTheme: true })(
        reduxForm({
            form: formNames.PROJECT_DOCUMENTS_FORM_NAME,
        })(ProjectDocumentsForm)
    ),
    formNames.PROJECT_DOCUMENTS_FORM_NAME
);
