export default (theme, themeConfig, themeColors) => {
    return {
        activityListItem: {
            marginTop: themeConfig.commonPadding,
            marginBottom: themeConfig.commonPadding,
            maxWidth: themeConfig.customNarrowMaxWidthPart,
            position: 'relative',
            '& ul': {
                paddingTop: 0,
                paddingBottom: 0,
            },
            '& .fullPseudoAfter': {
                zIndex: 2,
            },
        },
        activityListItemHeader: {
            backgroundColor: themeColors.primarySupportingPalette.pms316c,
            '& a': {
                fontSize: 16,
                textDecoration: 'none',
                color: themeColors.primaryPalette.white,
            },
        },
        activityListItemMoneyWrap: {
            paddingTop: theme.spacing(1),
            '& svg': {
                fontSize: 14,
            },
            '& span': {
                color: themeColors.customPalette.bodyBg,
            },
        },
    };
};
