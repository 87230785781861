import objectPath from 'object-path';
import FormatterUtil from './FormatterUtil';

export default class ValidationUtil {

    static shouldValidateError(params){
      
      //default behaviour

      /* let { initialRender, structure, values, nextProps, lastFieldValidatorKeys, fieldValidatorKeys} = params;
      if (initialRender) {
        return true;
      }
      return !structure.deepEqual(values, nextProps && nextProps.values) || !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys); */

      //return true to revalidate Date input not being revalidated... not sure for what reason
      return true;
  }
 
    static checkRequiredFields(fields,values,errors){
        fields.forEach(field => {
            let fieldValue = objectPath.get(values,field);
            let isNullOrUndefined = (fieldValue === null || fieldValue === '' || typeof fieldValue === "undefined");
            let isArrayAndEmpty = (Array.isArray(fieldValue) && fieldValue.length < 1);
            if (
              (
                isNullOrUndefined
                ||
                isArrayAndEmpty
              )
            ) {
              objectPath.set(errors,field,"Required");
            }
        });
        return errors;
    }


    static checkImageField(fields,values,errors, maxSize){
      fields.forEach(field => {
          let fieldBase64String = objectPath.get(values,field+'.base64String');
          let fieldSize = objectPath.get(values,field+'.size');
          if (fieldBase64String && fieldBase64String.length && fieldSize > maxSize) {
            objectPath.set(errors,field,"Maximum file size is "+FormatterUtil.bytesToSize(maxSize)+" MB");
          }
      });
      return errors;
    }

    static checkIntegerFields(fields,values,errors){
        fields.forEach(field => {
            var hasError = objectPath.get(errors, field);
            let fieldValue = objectPath.get(values,field);
            if(!hasError){
              var isnum = /^\d+$/.test(fieldValue);
              if(!isnum){
                objectPath.set(errors,field,"Please format number correctly");
              }
            }
          });
          return errors;
    }

    static checkPercentageFields(fields,values,errors){
        fields.forEach(field => {
            var hasError = objectPath.get(errors, field);
            let fieldValue = objectPath.get(values,field,null);
            if(!hasError && fieldValue){ // empty is correct because we need to allow empty value (if do not add to required field)
              var n = Math.floor(Number(fieldValue));
              let isPercentageFormat = n >= 0 && n < 101;
              if(!isPercentageFormat){
                objectPath.set(errors,field,"Please format percentage correctly (0-100)");
              }
            }
          });
          return errors;
    }

    static checkIntegerMinMax(fields,values,errors, min, max){
      fields.forEach(field => {
          var hasError = objectPath.get(errors, field);
          let fieldValue = objectPath.get(values,field);
          if(!hasError){
            var n = Math.floor(Number(fieldValue));
            let isCorrectFormat = n >= min && n <= max;
            if(!isCorrectFormat){
              objectPath.set(errors,field,"Please format number correctly ("+min+"-"+max+")");
            }
          }
        });
        return errors;
  }


    static checkStringMaxLength(fields,values,errors, maxLength){
        fields.forEach(field => {
            var hasError = objectPath.get(errors, field);
            let fieldValue = objectPath.get(values,field,'').toString();
            if(!hasError){
              if(fieldValue.length > maxLength){
                objectPath.set(errors,field, "Max length is "+maxLength+" characters.");
              }
            }
          });
          return errors;
    }

}
