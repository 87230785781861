import objectPath from "object-path";
import * as constants from "../constants/constants"


export default class CommentUtil {

    static getCommentTypeById(object, commentId) {
        let commentSectionType = null;
        let commentTypeIndex = -1;
        const wHcomments = objectPath.get(object,constants.WHATS_HAPPEND_COMMENTS_KEY, []);
        commentTypeIndex = wHcomments.findIndex((o) => objectPath.get(o,'id') === commentId);
        if(commentTypeIndex !== -1){
            commentSectionType = constants.WHATS_HAPPEND_COMMENTS_KEY; 
        } else {
            const wNcomments = objectPath.get(object,constants.WHATS_NEXT_COMMENTS_KEY, []);
            commentTypeIndex = wNcomments.findIndex((o) => objectPath.get(o,'id') === commentId);
            if(commentTypeIndex !== -1){
                commentSectionType = constants.WHATS_NEXT_COMMENTS_KEY; 
            } else {
                const quickComments = objectPath.get(object,constants.QUICK_COMMENTS_KEY, []);
                commentTypeIndex = quickComments.findIndex((o) => objectPath.get(o,'id') === commentId);
                if(commentTypeIndex !== -1){
                    commentSectionType = constants.QUICK_COMMENTS_KEY; 
                }
            }
        }

        return commentSectionType;
    }


    static getCommentTypeIndexById(object, commentId) {
        let commentTypeIndex = -1;

        const wHcomments = objectPath.get(object,constants.WHATS_HAPPEND_COMMENTS_KEY, []);
        commentTypeIndex = wHcomments.findIndex((o) => objectPath.get(o,'id') === commentId);
        if(commentTypeIndex !== -1){
            return commentTypeIndex;
        }

        const wNcomments = objectPath.get(object,constants.WHATS_NEXT_COMMENTS_KEY, []);
        commentTypeIndex = wNcomments.findIndex((o) => objectPath.get(o,'id') === commentId);
        if(commentTypeIndex !== -1){
            return commentTypeIndex;
        }

        const quickComments = objectPath.get(object,constants.QUICK_COMMENTS_KEY, []);
        commentTypeIndex = quickComments.findIndex((o) => objectPath.get(o,'id') === commentId);
        return commentTypeIndex;
    }
}