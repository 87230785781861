export default (theme, themeConfig, themeColors) => {
    return {
        dashboardCardHeader: {
            paddingBottom: 10,
            '& .personIcon': {
                fontSize: 14,
            },
            '& .calendarIcon': {
                fontSize: 12,
            },
            '& svg': {
                marginTop: -3,
                color: themeColors.primarySupportingPalette.pms316c,
            },
        },
        cardBottomActions: {
            paddingTop: 0,
            paddingLeft: theme.spacing(1) * 1.5,
            paddingRight: theme.spacing(1) * 1.5,
            '& .leftTextWrap': {
                marginRight: 'auto',
            },
            '& > div': {
                width: '100%',
                display: 'flex',
            },
            '& button': {
                '& svg': {
                    color: themeColors.primarySupportingPalette.pms316c,
                },
            },
            '& .smallIconBtn, .chipBtnWrap > div[role="button"]': {
                padding: 6,
                borderWidth: 1,
                borderStyle: 'solid',
            },
        },
        dashboardCard: {
            position: 'relative',
            boxShadow: 'none',
            cursor: 'pointer',
            '&:hover': {
                boxShadow:
                    '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
            },
            '& a.cardMainActionElement': {
                textDecoration: 'none',
                color: themeColors.primaryPalette.black,
            },
            '&.binder': {
                '& .fullNames': {
                    display: 'none',
                },
                '& .cardTypeIcon': {
                    color: themeColors.primarySupportingPalette.pms7472c,
                },
            },
            '&.as': {
                '& .cardTypeIcon': {
                    color: themeColors.primarySupportingPalette.pms668c,
                },
            },
            '& .chipBtnWrap': {
                borderRadius: 16,
                fontWeight: 600,
            },
            '& .smallIconBtn.imageBtnInputWrap': {
                fontSize: 16,
            },
            '& .smallIconBtn, .chipBtnWrap > div[role="button"]': {
                borderColor: themeColors.customPalette.tableRowBorder,
            },
            '& .chipBtnWrap > div[role="button"]': {
                fontSize: 14,
                height: 34,
                '& > span': {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
            },

            '&.as.dueState-overdue,&.as.dueState-reminder2, &.binder.dueState-overdue,&.binder.dueState-reminder2, &.ri.dueState-overdue.dueProgressReportState-notcomplete.responsible,&.ri.dueState-reminder2.dueProgressReportState-notcomplete.responsible':
                {
                    backgroundColor:
                        themeColors.primarySupportingPalette.pms200c,
                    '& h5 strong, & span': {
                        color: themeColors.primaryPalette.white,
                    },
                    '& .personName': {
                        color: themeConfig.setImportant(
                            themeColors.primaryPalette.white
                        ),
                    },
                    '& .muted': {
                        color: themeColors.primaryPalette.white,
                    },
                    '& svg': {
                        color: themeConfig.setImportant(
                            themeColors.primaryPalette.white
                        ),
                    },
                    '&.as button, &.binder button:not(.printBtn)': {
                        '& span': {
                            color: themeColors.primarySupportingPalette.white,
                        },
                    },
                    '& .smallIconBtn, .chipBtnWrap > div[role="button"]': {
                        borderColor: themeColors.primaryPalette.white,
                    },
                    '& .ri-context-type-2:not(.dueState-complete), & .ri-context-type-3.dueCommentaryState-commentarydue, & .ri-context-type-3.dueCommentaryState-quickcommentdue':
                        {
                            '& .cardRIstateIndicator': {
                                backgroundColor:
                                    themeColors.primaryPalette.white,
                            },
                        },
                },
            '&.as.dueState-reminder1, &.binder.dueState-reminder1, &.ri.dueState-reminder1.dueProgressReportState-notcomplete.responsible':
                {
                    '& svg, & .date': {
                        color: themeColors.primarySupportingPalette.pms200c,
                    },
                    '& .smallIconBtn, .chipBtnWrap > div[role="button"]': {
                        borderColor:
                            themeColors.primarySupportingPalette.pms200c,
                        '& span': {
                            color: themeColors.primarySupportingPalette.pms200c,
                        },
                    },
                    '& .bar': {
                        display: 'block',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: 6,
                        backgroundColor:
                            themeColors.primarySupportingPalette.pms200c,
                    },
                },
        },

        timelineIntroWrap: {
            paddingTop: theme.spacing(1) * 4,
            paddingBottom: theme.spacing(1) * 3,
            '& strong': {
                paddingRight: theme.spacing(1) * 2.5,
                display: 'inline-block',
                minWidth: '145px',
            },
            '& .labelLike': {
                [theme.breakpoints.down('md')]: {
                    display: 'block',
                },
            },
        },

        cardReportingItemLink: {
            paddingLeft: theme.spacing(1) * 2.5,
            position: 'relative',
            marginTop: theme.spacing(1) * 1.5,
            '& a': {
                textDecoration: 'none',
                '& p, & span': {
                    textDecoration: 'none',
                },
                '& span': {
                    opacity: 0.5,
                },
            },
            '& .cardRIstateIndicator': {
                position: 'absolute',
                display: 'block',
                top: 0,
                left: -2,
                '& svg': {
                    fontSize: 16,
                },
            },
            '&.ri-context-type-2:not(.dueState-complete), &.ri-context-type-3.dueCommentaryState-commentarydue, &.ri-context-type-3.dueCommentaryState-quickcommentdue':
                {
                    '& a': {
                        '& span': {
                            opacity: 1,
                        },
                        '& .cardRIstateIndicator': {
                            backgroundColor: themeColors.primaryPalette.black,
                            borderRadius: '100%',
                            height: 4,
                            width: 4,
                            top: 8,
                            left: 4,
                            '& svg': {
                                display: 'none',
                            },
                        },
                    },
                },
        },
    };
};
