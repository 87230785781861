import objectPath from 'object-path';

export default class GoogleTagManagerUtil {

  static trackPageView(data) {
    GoogleTagManagerUtil.defineDataLayerIfNeeded();
    let locationObject = objectPath.get(data, 'location');
    if (locationObject) {

      let locationPathname = objectPath.get(data, 'location.pathname');
      let locationSearch = objectPath.get(data, 'location.search');

      let newDataLayerObject = {
        'event': 'Pageview',
        'pathname': locationPathname,
        'search': locationSearch
      };

      // ConsoleUtil.log('GoogleTagManagerUtil trackPageView', newDataLayerObject)
      window.dataLayer.push(newDataLayerObject);
    }
  }

  static trackDashboardCardCommentaryModal(reportingItem) {
    let newDataLayerObject = {
      'event': 'DashboardCardCommentaryModal',
      reportingItem: reportingItem
    };

    // ConsoleUtil.log('GoogleTagManagerUtil trackDashboardCardCommentaryModal', newDataLayerObject)
    window.dataLayer.push(newDataLayerObject);
  }

  static trackQuickComment(reportingItem) {
    let newDataLayerObject = {
      'event': 'QuickComment',
      reportingItem: reportingItem
    };

    // ConsoleUtil.log('GoogleTagManagerUtil trackQuickComment', newDataLayerObject)
    window.dataLayer.push(newDataLayerObject);
  }

  static trackPrintBinderModal(binder) {
    let newDataLayerObject = {
      'event': 'PrintBinderModal',
      binder: binder
    };

    // ConsoleUtil.log('GoogleTagManagerUtil trackPrintBinderModal', newDataLayerObject)
    window.dataLayer.push(newDataLayerObject);
  }

  static trackPrintedBinderDownloadLinkCreated(binder, settings = {}) {
    let newDataLayerObject = {
      'event': 'PrintedBinderDownloadLinkCreated',
      binder: binder,
      ...settings
    };

    // ConsoleUtil.log('GoogleTagManagerUtil trackPrintedBinderDownloadLinkCreated', newDataLayerObject)
    window.dataLayer.push(newDataLayerObject);
  }

  static defineDataLayerIfNeeded() {
    if (objectPath.get(window, 'dataLayer', null) === null) {
      window.dataLayer = window.dataLayer || [];
    }
  }

}
