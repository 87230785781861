import React from "react";
import { withStyles } from "@material-ui/core/styles";

import styles from "../../theme/styles";
import { Table, TableBody, Grid } from "@material-ui/core";
import ReportingItemTableRow from "./ReportingItemTableRow";
import ReportingItemExpandedTableRow from "./ReportingItemExpandedTableRow";
import ReportingItemTableRowSpacer from './ReportingItemTableRowSpacer';
import PositionsUtil from "../../utils/PositionsUtil";
import StrategicPlansUtil from "../../utils/StrategicPlansUtil";
import store from '../../store';
import classnames from 'classnames';

class ReportingItemListingTable extends React.Component {


    renderRows = () => {
        const { items, expandedRowItemId, expandAll, onExpandRow, rowClassName, onArchiveToggleClicked  } = this.props;
        
        let rows = [];
        let positions = store.getState()['positions'];
        let strategicPlans = store.getState()['strategicPlans'];

        items.forEach(row => {
            var isExpanded = expandedRowItemId === row.id;
            var rowComponent = null;
            var expandedRowComponent = null;
            var expandedRowSpacerComponent = null;
            switch (rowClassName) {
                case "ReportingItem":
                        rowComponent = <ReportingItemTableRow 
                            key={row.id} 
                            item={row} 
                            position={PositionsUtil.getPositionById(positions,row.positionId)}
                            isExpanded={(isExpanded || expandAll)} 
                            onExpandRow={onExpandRow}
                            onArchiveToggleClicked={onArchiveToggleClicked}
                            />
                        if(rowComponent && (isExpanded || expandAll)){
                            expandedRowComponent = <ReportingItemExpandedTableRow 
                                key={row.id+"_expanded"} 
                                item={row} 
                                onExpandRow={onExpandRow} 
                                onArchiveToggleClicked={onArchiveToggleClicked}
                                activity={StrategicPlansUtil.getActivityById(row.activityId,strategicPlans)}
                             />
                             expandedRowSpacerComponent = <ReportingItemTableRowSpacer key={row.id+"_expanded_spacer"} />
                        }
                    break;
                default:
                    break;
            }
            if(rowComponent){
                rows.push(rowComponent);
                if(expandedRowComponent){
                    rows.push(expandedRowComponent);
                    rows.push(expandedRowSpacerComponent);
                }
            }
        });
        return rows;
            
    }

  render() {
    const { classes, expandAll } = this.props;
    


    return (<Grid container>
        <Grid item xs={12}>
        <Table className={classnames(classes.table, expandAll && classes.expandedAllTable)}>
        <TableBody>
            { this.renderRows() }
        </TableBody>
      </Table>
      </Grid></Grid>
    );
  }
}


export default withStyles(styles, { withTheme: true })(ReportingItemListingTable);
