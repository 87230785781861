import * as log from "loglevel";

export default class LoggerUtil {
  static info(message, data, title) {
    log.info("Info: " + message, data);
  }

  static success(message, data, title) {
    log.info("Success: " + message, data);
  }

  static warning(message, data, title) {
    log.warn("Warning: " + message, data);
  }

  static error(message, data, title) {
    log.error("Error: " + message, data);
  }
}
