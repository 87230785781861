import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import objectPath from 'object-path';
import { Grid, Typography, TableHead, TableRow, TableCell, TableBody, Divider, Table, Tooltip, Fab, Hidden } from '@material-ui/core';
import AssessmentUtil from '../../utils/AssessmentUtil';
import classnames from 'classnames'
import PositionsUtil from '../../utils/PositionsUtil';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import * as constants from '../../constants/constants'
import StoreUtil from '../../utils/StoreUtil';
import NotDoneIcon from '@material-ui/icons/RemoveOutlined';
import DoneIcon from '@material-ui/icons/CheckOutlined';


class ChildrenProjects extends React.Component {

  renderTableHeadingCell = (text) => {
    return <Typography variant="caption" className="muted"><strong>{text}</strong></Typography>
  }

  renderManagerAssessmentIndicator = (project) => {
    const { classes } = this.props;
    return [constants.ASSESSMENT_PARTIALMET,constants.ASSESSMENT_NOTMET].includes(project.managerAssessment) ?
     <span className={classnames("indicator",AssessmentUtil.getIndicatorClassname(classes, project.managerAssessment))}></span>
     : null
  }

  renderForecastIndicator = (project) => {
    const { classes } = this.props;
    return !project.forecastAssessmentOk ?
    <span className={classnames("indicator",AssessmentUtil.getIndicatorClassname(classes, constants.ASSESSMENT_NOTMET))}></span>
     : null
  }

  renderCostVarianceIndicator = (project) => {
    const { classes } = this.props;
    return [constants.ASSESSMENT_PARTIALMET,constants.ASSESSMENT_NOTMET].includes(project.costVariance) ?
     <span className={classnames("indicator",AssessmentUtil.getIndicatorClassname(classes, project.costVariance))}></span> 
     : null
  }

  viewChildProject = (id) => {
    const { viewProgressReportById } = this.props;
    return viewProgressReportById ? viewProgressReportById(id) : {};
  }

  renderChildRow = (child,index) => {
    const { classes } = this.props;
    return <TableRow key={index} 
              onClick={() => {if(child.isProjectManaged){this.viewChildProject(child.id)}}}>
              <TableCell className="doneColumn">
                { child.reported ? <DoneIcon fontSize="small" /> : <NotDoneIcon fontSize="small" /> }
              </TableCell>
              <TableCell className="idColumn">
                {child.id ? "#"+child.id : ""}
              </TableCell>
              <TableCell className="expandCellWidth">
              {child.name}
              </TableCell>

              <Hidden only={this.getHideOnlyForColumn("manager")}><TableCell>
              {PositionsUtil.getUsernameFromStoreById(child.positionId)} 
              </TableCell></Hidden>

              <Hidden mdDown><TableCell>
                {this.renderManagerAssessmentIndicator(child)}
              </TableCell></Hidden>

              <Hidden mdDown><TableCell>
              {this.renderForecastIndicator(child)}
              
              </TableCell></Hidden>

              <Hidden mdDown><TableCell>
              {this.renderCostVarianceIndicator(child)}
              
              </TableCell></Hidden>

              <Hidden lgUp><TableCell className={classes.textCenter}>
              {this.renderManagerAssessmentIndicator(child)} {this.renderForecastIndicator(child)} {this.renderCostVarianceIndicator(child)}
              </TableCell></Hidden>

              <Hidden only={this.getHideOnlyForColumn("actions")}><TableCell className={classnames(classes.textRightStrong, "actionsColumn")}>
                { child.isProjectManaged ? <Fab size="small" color="default" onClick={() => this.viewChildProject(child.id)}>
                    <EyeIcon />
                  </Fab> : null }
              </TableCell></Hidden>
            </TableRow>
  }

  getHideOnlyForColumn = (columnName) => {
    const isContentNarrow = StoreUtil.getValueByKey('contentSubmenuLevel');
    if(columnName === "manager"){
      return isContentNarrow ? ['xs','sm','md'] : ['xs'];
    } else if(columnName === "actions"){
      return isContentNarrow ? ['xs','sm','md'] : ['xs'];
    }
  }


  render(){


  const { item, classes } = this.props;
  const childrenProjects = objectPath.get(item,'childrenProjects',[]);
  
  

  

  return childrenProjects.length ? <Grid container className="childProjectsSection">
  <Grid item className="gridItem" xs={12}>
    <Typography variant="h4">CHILD PROJECTS</Typography>
  </Grid>

  <Grid item className="gridItem sectionOverviewItem" xs={12}>
        <Table className={classnames(classes.table,'childProjectsTable')}>
          <TableHead>
            <TableRow>
              <TableCell className="doneColumn"><Hidden xsDown>{ this.renderTableHeadingCell("Report Status") }</Hidden><Hidden smUp>&nbsp;</Hidden></TableCell>
              <TableCell className="idColumn">
                { this.renderTableHeadingCell("Subjob ID") }
              </TableCell>
              <TableCell className="expandCellWidth">
              { this.renderTableHeadingCell("Name") }
                
              </TableCell>

              <Hidden only={this.getHideOnlyForColumn("manager")}><TableCell>
              { this.renderTableHeadingCell("Manager") }
              </TableCell></Hidden>

              <Hidden mdDown><TableCell>
              { this.renderTableHeadingCell("Manager Assessment") }
              </TableCell></Hidden>

              <Hidden mdDown><TableCell>
              { this.renderTableHeadingCell("Forecast Assessment") }
              </TableCell></Hidden>

              <Hidden mdDown><TableCell>
              { this.renderTableHeadingCell("Cost Variance") }
              </TableCell></Hidden>

              <Hidden lgUp><TableCell>
              &nbsp;
              </TableCell></Hidden>

              <Hidden only={this.getHideOnlyForColumn("actions")}><TableCell className="actionsColumn">
                &nbsp;
              </TableCell></Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
          { childrenProjects.map( (child,index) =>  
            (child.isProjectManaged) ? <Tooltip key={index} title={"view child project #"+child.id}>
            {this.renderChildRow(child,index)}
            </Tooltip> : this.renderChildRow(child,index)
            )}
          </TableBody>
        </Table>
  </Grid>

  <Grid item className="gridItem" xs={12}>
  <Divider className="sectionDivider"/>
</Grid>

  


</Grid> : null
}
}

ChildrenProjects.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(ChildrenProjects);