import * as types from '../constants/actionTypes';
import ConsoleUtil from '../utils/ConsoleUtil';

export function getReportOutputs(state = [], action) {
    if (action.type === types.GET_REPORT_OUTPUTS) {
        ConsoleUtil.log(types.GET_REPORT_OUTPUTS+' set in store');
        return action.data.data;
    }
    if (action.type === types.GET_REPORT_OUTPUTS_BY_BINDER_ID) {
        ConsoleUtil.log(types.GET_REPORT_OUTPUTS_BY_BINDER_ID+' set in store');
        return action.data.data;
    }
    return state;
}


export function getReportOutput(state = [], action) {
    if (action.type === types.GET_REPORT_OUTPUT) {
        ConsoleUtil.log(types.GET_REPORT_OUTPUT+' set in store');
        return action.data.data;
    }
    return state;
}

