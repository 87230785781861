import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as activitySummaryActions from "../../../actions/activitySummary";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import ComponentLoading from "../../../components/ComponentLoading";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import classnames from 'classnames';
import ActivitySummarySubmenu from "../../../components/menu/ActivitySummarySubmenu";


class ReviewActivitySummaryMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activitySummary: null,
      dataLoading: true,
    }
  }



  componentDidMount() {
    this.reloadData();
  }


  reloadData = () => {
    const { match } = this.props;
    var activitySummaryId = objectPath.get(match, "params.activitySummaryId", null);
    Promise.all([
      this.mountActivitySummary(activitySummaryId)
    ]).then(() =>
        this.setState({
          dataLoading: false
        })
    );
  }

  mountActivitySummary(id) { // for menu and page state purposes and based on condition load AS review
    const { actions, history } = this.props;


    const _this = this;
    if (id) {
      return actions.getActivitySummary(id).then(response => {
        let activitySummary = objectPath.get(response, "data.data");

        if (!activitySummary) {
          return history.push("/404");
        } else {
          _this.setState({
            activitySummary,
          });
        }
        
      });
    } else {
      return new Promise((resolve) => {
        _this.setState({
          activitySummary: null,
        }, resolve);
      });
    }
  }



  render() {
    const { classes } = this.props;
    const { dataLoading, activitySummary } = this.state;

    return (
      <div className={classnames("contentWithSubmenu", classes.contentWithSubmenu, classes['contentWithSubmenuLevel_1'])}>
        {dataLoading ? <ComponentLoading /> :<ActivitySummarySubmenu activitySummary={activitySummary} level={1} /> }
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...activitySummaryActions,
      },
      dispatch
    )
  });


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, { withTheme: true })(ReviewActivitySummaryMenu)
  )
));