import ReportingItemsUtil from "./ReportingItemsUtil";
import objectPath from 'object-path';
import * as constants from '../constants/constants';
import ArrayUtil from "./ArrayUtil";
import FilterUtil from "./FilterUtil";
import LocalStorageUtil from "./LocalStorageUtil";
import StoreUtil from "./StoreUtil";
import FormatterUtil from "./FormatterUtil";
import ActivitySummaryUtil from "./ActivitySummaryUtil";

export default class UrlBuilderUtil {

  static getRquestedHostUrl() {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
  }

  static getActivitySnapshot(asId, year = null, period = null) {
    return '/activity-summary-snapshot/' + asId + ((year && period) ? '/' + year + '/' + period : '');
  }

  static getActivitySummaryProgressReport(asId, projectId) {
    return '/activity-summary/' + asId + '/progress-report/edit/' + projectId;
  }

  static getActivitySummaryReportingItemCommentary(asId, riId) {
    return '/activity-summary/' + asId + '/reporting-item-commentary/' + riId;
  }

  static getActivitySummaryDashboardDesktop(as) {
    var asId = objectPath.get(as, 'id');

    //get first none complete URL if not ready
    if (!ActivitySummaryUtil.isDetailReady(as) && ArrayUtil.isNonEmptyArray(as.reportingItems)) {
      var firstNoneComplete = ReportingItemsUtil.getFirstNoneCompleteRI(as.reportingItems);
      if (firstNoneComplete) {
        return UrlBuilderUtil.getActivitySummaryDefaultReportingItem(asId, firstNoneComplete.id, firstNoneComplete.projectId);
      }
    } 
    return UrlBuilderUtil.getActivitySummary(asId);
  }

  static getActivitySummaryDefaultReportingItem(asId, riId, projectId) {

    return projectId ?
      UrlBuilderUtil.getActivitySummaryProgressReport(asId, projectId)
      :
      UrlBuilderUtil.getActivitySummaryReportingItemCommentary(asId, riId);
  }

  static getActivitySummary(asId) {
    return '/activity-summary/' + asId;
  }


  static getDashboard(filter = null, checkReferrer = false) {
    var query = LocalStorageUtil.get('dashboardLastSearch', '');
    if (checkReferrer) {
      var referrerPath = UrlBuilderUtil.getLastReferrer();
      if (referrerPath) {
        return referrerPath;
      }
    }
    if (filter) {
      query = FilterUtil.constructUrlParams(filter);
    }
    return '/dashboard/' + query;
  }

  static getScorecard(checkReferrer = false) {

    if (checkReferrer) {
      var referrerPath = UrlBuilderUtil.getLastReferrer('scorecard');
      if (referrerPath) {
        return referrerPath;
      }
    }
    return '/scorecard/';
  }

  static getLastReferrer(containStringCondition = null, notContainStringCondition = null) {
    var routerLocations = StoreUtil.getValueByKey('routerLocations');
    var referrer = null;
    if (ArrayUtil.isNonEmptyArray(routerLocations) && routerLocations.length > 1) {
      routerLocations.reverse();
      if (containStringCondition) {
        //get first last that contains the string
        var lastReferrerObject = routerLocations.find(x => objectPath.get(x, 'location.pathname', '').indexOf(containStringCondition) !== -1 
        && (notContainStringCondition === null || (notContainStringCondition && objectPath.get(x, 'location.pathname', '').indexOf(notContainStringCondition) === -1))
        );
        if (lastReferrerObject) {
          referrer = objectPath.get(lastReferrerObject, 'location.pathname', '') + objectPath.get(lastReferrerObject, 'location.search', '');
        }
      } else {
        referrer = objectPath.get(routerLocations, '1.location.pathname', null);
      }
    }
    return referrer;
  }

  static getBinderSummaryMenu(binderId) {
    return '/binder-summary/' + binderId + "/menu/";
  }

  static getBinderSummary(binderId, riId) {
    return riId ? UrlBuilderUtil.getBinderSummaryReportingItem(binderId, riId) : ('/binder-summary/' + binderId);
  }

  static getBinderSummaryReportingItem(binderId, riId) {
    return '/binder-summary/' + binderId + '/reporting-item-commentary/' + riId;
  }

  static getActivitySummaryMenu(asId) {
    //Mobile purpose
    return '/activity-summary/' + asId + "/menu/";
  }

  static getProgressReport(projectId) {
    return '/progress-report/edit/' + projectId;
  }

  static getProgressReportSnapshot(projectId, year, period) {
    return '/progress-report-snapshot/' + projectId + '/' + year + '/' + period;
  }

  static getReportingItemEdit(riId) {
    return '/reporting-items/edit/' + riId;
  }

  static getReportingItemCreate() {
    return '/reporting-items/create/';
  }

  static getReportingItemList(paging = null, filter = null, checkReferrer = false) {
    if (checkReferrer) {
      var referrerPath = UrlBuilderUtil.getLastReferrer('/reporting-items', '/reporting-items/');
      if (referrerPath) {
        return referrerPath;
      }
    }
    var querySearchPart = [];
    if (filter) {
      var keyword = objectPath.get(filter, 'keyword');
      if (keyword) {
        querySearchPart.push('keyword=' + FormatterUtil.keywordEncodeURI(keyword));
      }
      var archivedonly = objectPath.get(filter, 'archivedonly');
      if (archivedonly) {
        querySearchPart.push('archivedonly=' + archivedonly.toString());
      }
    }
    if (paging) {
      querySearchPart.push(constants.LISTING_PAGE_PARAM + '=' + objectPath.get(paging, 'page', constants.DEFAULT_LISTING_PAGE))
      querySearchPart.push(constants.LISTING_PAGE_SIZE_PARAM + '=' + objectPath.get(paging, 'pageSize', constants.DEFAULT_LISTING_PAGE_SIZE))
    }
    return '/reporting-items' + (querySearchPart.length ? '?' + querySearchPart.join('&') : '');
  }

  static getReportingItemTimeline(riId) {
    return '/reporting-item-timeline/' + riId;
  }

  static getReportingItemCommentary(riId) {
    return '/reporting-item-commentary/' + riId;
  }

  static getGoToURLForASAfterRICommentarySubmit(activitySummary, riId) {
    let asReportingItems = objectPath.get(activitySummary, 'reportingItems', []);
    let lastAsReportingItemID = objectPath.get(activitySummary, 'reportingItems.' + (asReportingItems.length - 1) + '.id', null);
    let asId = objectPath.get(activitySummary, 'id');
    if (
      lastAsReportingItemID === riId
    ) { //if last
      return UrlBuilderUtil.getActivitySummary(asId);
    } else { // if not go to next
      let nextRi = ArrayUtil.getNext(asReportingItems, riId);
      let projectId = objectPath.get(nextRi, 'projectId');
      let nextRiId = objectPath.get(nextRi, 'id');
      return UrlBuilderUtil.getActivitySummaryDefaultReportingItem(asId, nextRiId, projectId);
    }
  }

  static getGoToNoneCompleteURLForASAfterRICommentarySubmit(activitySummary, riId) {

    let asReportingItems = objectPath.get(activitySummary, 'reportingItems', []);
    let nonCompleteList = ReportingItemsUtil.getNoneCompleteRIList(asReportingItems, constants.DASHBOARD_CARD_TYPE_AS);
    let asId = objectPath.get(activitySummary, 'id');
    if (
      (nonCompleteList.length === 0) //all RI completed
      ||
      (nonCompleteList.length === 1 && nonCompleteList[0].id === riId) //submitting last none complete RI
    ) {
      return UrlBuilderUtil.getActivitySummary(asId);
    } else {
      let firstNoneComplete = nonCompleteList[0];
      let projectId = objectPath.get(firstNoneComplete, 'projectId');
      let nextRiId = objectPath.get(firstNoneComplete, 'id');
      return UrlBuilderUtil.getActivitySummaryDefaultReportingItem(asId, nextRiId, projectId);
    }
  }


  static getGoToURLForBinderAfterRICommentarySubmit(binderSummary, riId) {

    let binderReportingItems = objectPath.get(binderSummary, 'reportingItems', []);
    let lastBinderReportingItemID = objectPath.get(binderSummary, 'reportingItems.' + (binderReportingItems.length - 1) + '.id', null);
    let binderId = objectPath.get(binderSummary, 'id');
    if (
      lastBinderReportingItemID === riId
    ) { //if last
      return UrlBuilderUtil.getDashboard();
    } else { // if not go to next
      let nextRi = ArrayUtil.getNext(binderReportingItems, riId);
      let nextRiId = objectPath.get(nextRi, 'id');
      return UrlBuilderUtil.getBinderSummaryReportingItem(binderId, nextRiId);
    }
  }


  static getGoToNoneCompleteURLForBinderAfterRICommentarySubmit(binderSummary, riId) {

    let binderReportingItems = objectPath.get(binderSummary, 'reportingItems', []);
    let nonCompleteList = ReportingItemsUtil.getNoneCompleteRIList(binderReportingItems, constants.DASHBOARD_CARD_TYPE_BINDER);
    let binderId = objectPath.get(binderSummary, 'id');
    if (
      (nonCompleteList.length === 0) //all RI completed
      ||
      (nonCompleteList.length === 1 && nonCompleteList[0].id === riId) //submitting last none complete RI
    ) {
      return UrlBuilderUtil.getDashboard();
    } else {
      let firstNoneComplete = nonCompleteList[0];
      let nextRiId = objectPath.get(firstNoneComplete, 'id');
      return UrlBuilderUtil.getBinderSummaryReportingItem(binderId, nextRiId);
    }
  }




}
