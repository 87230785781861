
export default (theme, themeConfig, themeColors) => {
    return {

        summaryActionsWrap: {
            marginTop: -40,
            '& svg':{
                fontSize: 15
            }
        }
    }}