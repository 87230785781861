import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import * as reportingItemActions from "../../../actions/reportingItem";
import * as binderActions from "../../../actions/binder";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import * as constants from "../../../constants/constants";

import ComponentLoading from "../../../components/ComponentLoading";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import { Hidden, DialogActions, Button } from "@material-ui/core";
import classnames from 'classnames';
import BinderSummarySubmenu from "../../../components/menu/BinderSummarySubmenu";
import UrlBuilderUtil from "../../../utils/UrlBuilderUtil";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import BinderUtil from "../../../utils/BinderUtil";
import ReportingItemCommentaryFormWrapper from "../../../components/reportingItem/ReportingItemCommentaryFormWrapper";
import ReportingItemCommentaryReview from "../../../components/reportingItem/ReportingItemCommentaryReview";
import CommentUtil from "../../../utils/CommentUtil";
import GoogleTagManagerUtil from "../../../utils/GoogleTagManagerUtil";
import AppDialog from "../../../components/AppDialog";


class ReviewBinderSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      binderSummary: null,
      reportingItemCommentary: null,
      binderReviewReportingItemCommentary: null,
      dataLoading: true,
      showReviewConfirmation: false
    }
  }


  componentDidUpdate(prevProps) { // reload data on url change
    if (this.props.location !== prevProps.location) {
      this.setState({
        dataLoading: true
      });
      this.reloadData();
    }
  }

  componentDidMount() {
    this.reloadData();
  }


  reloadData = () => {
    const { match } = this.props;
    var reportingItemId = objectPath.get(match, "params.reportingItemId", null);
    var binderSummaryId = objectPath.get(match, "params.binderSummaryId", null);
    Promise.all([
      this.mountBinderSummary(binderSummaryId)
    ]).then(() =>
      Promise.all([
        this.mountBinderReviewReportingItemCommentary(binderSummaryId, reportingItemId),
        this.mountReportingItemCommentary(reportingItemId),
      ]).then(() =>
        this.setState({
          dataLoading: false
        })
      )
    );
  }

  mountBinderSummary(id) { // for menu and page state purposes and based on condition load AS review
    const { actions, history } = this.props;

    const _this = this;
    if (id) {
      return actions.getBinderSummary(id).then(response => {
        let binderSummary = objectPath.get(response, "data.data");
       
        if (binderSummary) {
            return _this.setState({
              binderSummary,
            })
        } else {
          return history.push("/404");
        }
      });
    } else {
      return new Promise((resolve) => {
        _this.setState({
          binderSummary: null,
        }, resolve);
      });
    }
  }


  mountBinderReviewReportingItemCommentary = (bId, rId) => {
    const { actions, history } = this.props;
    const { binderSummary } = this.state;
    let binderCurrentState = objectPath.get(binderSummary,'currentState');
    const _this = this;
    if (bId 
      && 
      rId 
      &&
      BinderUtil.isBinderInReviewMode(binderCurrentState) //binder is in review or complete state
      ) {
      return actions.getBinderReviewReportingItemCommentary(bId, rId).then(response => {
        let binderReviewReportingItemCommentary = objectPath.get(response, "data.data");
        if (binderReviewReportingItemCommentary) {
          return _this.setState({
            binderReviewReportingItemCommentary
          })
        } else {
          return history.push("/404");
        }
      });
    } else {
      return new Promise((resolve) => {
        _this.setState({
          binderReviewReportingItemCommentary: null,
        }, resolve);
      });
    }

  }


  mountReportingItemCommentary = (id) => {
    const { history, actions } = this.props;
    const { binderSummary } = this.state;
    let binderCurrentState = objectPath.get(binderSummary,'currentState');

    const _this = this;
    if (id
      &&
      !BinderUtil.isBinderInReviewMode(binderCurrentState)
      ) {
      return actions.getReportingItemCommentary(id).then(response => {
        let reportingItemCommentary = objectPath.get(response, "data.data");
        if (reportingItemCommentary) {
          return _this.setState({
            reportingItemCommentary
          })
        } else {
          return history.push("/404");
        }
      });
    } else {
      return new Promise((resolve) => {
        _this.setState({
          reportingItemCommentary: null,
        }, resolve);
      });
    }
  }



  getMutedHeadingText = () => {
    const { reportingItemCommentary, binderReviewReportingItemCommentary } = this.state;
    
    let riProjectId = objectPath.get(reportingItemCommentary, 'projectId', null);
    let binderRiProjectId = objectPath.get(binderReviewReportingItemCommentary, 'projectId', null);
    return riProjectId ? '#' + riProjectId : binderRiProjectId ? '#' + binderRiProjectId : '';
  }

  getHeadingText = () => {
    const { binderSummary, reportingItemCommentary, binderReviewReportingItemCommentary } = this.state;
    if (reportingItemCommentary) {
      if (reportingItemCommentary.projectName) {
        return reportingItemCommentary.projectName;
      } else {
        return reportingItemCommentary.name;
      }
    } else if (binderReviewReportingItemCommentary) {
      if (binderReviewReportingItemCommentary.projectName) {
        return binderReviewReportingItemCommentary.projectName;
      } else {
        return binderReviewReportingItemCommentary.name;
      }
    } else {
      return BinderUtil.adjustBinderName(objectPath.get(binderSummary, 'name', 'Binder'));
    }
  }

  handleResponse = (response, redirectToURLOnSuccess) => {
    let snackBarSuccessMessage = this.getMutedHeadingText()+' '+this.getHeadingText()+' updated';
    const { enqueueSnackbar, history } = this.props;
    this.hideReviewConfirmation();
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar, snackBarSuccessMessage);
      setImmediate(() => {
        history.push(redirectToURLOnSuccess);
      });
    }
  }

  handleReviewSubmit = () => {
    this.setState({ showReviewConfirmation: true});
  }

  hideReviewConfirmation = () => {
    this.setState({ showReviewConfirmation: false});
  }

  confirmReviewCompleted = () => {
    const { actions, match } = this.props;
    var binderSummaryId = objectPath.get(match, "params.binderSummaryId", null);
    return  actions.postBinderCommentaryReview(binderSummaryId).then(response => this.handleResponse(response, UrlBuilderUtil.getDashboard()));
  }


  handleCommentUpdateResponse = (response, commentPath, comment) => {
    //const { actions } = this.props;
    let snackBarSuccessMessage = 'Comment updated';
    const { binderReviewReportingItemCommentary } = this.state;
    const { enqueueSnackbar } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar, snackBarSuccessMessage);
      let _binderReviewReportingItemCommentary = binderReviewReportingItemCommentary;
      objectPath.set(_binderReviewReportingItemCommentary, commentPath, comment);
      
      this.setState({
        binderReviewReportingItemCommentary : _binderReviewReportingItemCommentary
      });
    }
    this.setState({dataLoading: false});

  }


  handleCommentEdit = (comment) => {
    const { actions } = this.props;
    const { binderReviewReportingItemCommentary } = this.state;
    let commentSectionType = CommentUtil.getCommentTypeById(binderReviewReportingItemCommentary, comment.id);
    let commentTypeIndex =  CommentUtil.getCommentTypeIndexById(binderReviewReportingItemCommentary, comment.id);
    let commentObjectPath = commentSectionType+'.'+commentTypeIndex;
    let actionName = 'putQuickComment';
    //diff endpoints for diff comment so have to loop thru each to find out what section and what comment
    
    if(commentSectionType === constants.WHATS_HAPPEND_COMMENTS_KEY){
      actionName = 'putWhatsHappenedComment';
    }
    if(commentSectionType === constants.WHATS_NEXT_COMMENTS_KEY){
      actionName = 'putWhatsNextComment';
    }
    if(commentSectionType && comment){
      this.setState({dataLoading: true});
      actions[actionName](comment).then(response => this.handleCommentUpdateResponse(response, commentObjectPath, comment));
    }
    


  }

  handleReportingItemCommentary = (values) => {
      const { actions, match } = this.props;
      const { binderSummary } = this.state;
      var reportingItemId = objectPath.get(match, "params.reportingItemId", null);
      let redirectToURLOnSuccess = UrlBuilderUtil.getGoToURLForBinderAfterRICommentarySubmit(binderSummary, reportingItemId);

      if(
        objectPath.get(values,'dueCommentaryState') === constants.REPORTING_ITEM_COMMENTARY_STATE_COMMENTARYDUE
        ||
        objectPath.get(values,'openCommentary') === true
        ){
        return  actions.postReportingItemCommentary(values).then(response => this.handleResponse(response, redirectToURLOnSuccess));
      } else {
        //submit QC
        return  actions.postReportingItemQuickCommentary(reportingItemId, values).then(response => {
          GoogleTagManagerUtil.trackQuickComment({id: reportingItemId, addedFrom: 'Review of binder summary', binderSummary: binderSummary.name});
          return this.handleResponse(response, redirectToURLOnSuccess);
        });
      }
  }



 

  render() {
    const { classes } = this.props;
    const { dataLoading, binderSummary, reportingItemCommentary, binderReviewReportingItemCommentary, showReviewConfirmation } = this.state;

    

    return (
      <div className={classnames("contentWithSubmenu", classes.contentWithSubmenu, classes['contentWithSubmenuLevel_1'])}>
        <Hidden smDown><BinderSummarySubmenu binderSummary={binderSummary} level={1} /></Hidden>
        <div>
          <PageHeading 
          mutedHeading={this.getMutedHeadingText()} 
          heading={dataLoading ? "loading ..." : this.getHeadingText()}
          actions={
            <Hidden mdUp>
              {binderSummary ? <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getBinderSummaryMenu(objectPath.get(binderSummary,'id'))} /> : null } 
            </Hidden>
          } />

          {dataLoading ? <ComponentLoading /> :

            reportingItemCommentary ? 
            <ReportingItemCommentaryFormWrapper
            includeOpenCommentary={true}
            handleReportingItemCommentarySubmit={this.handleReportingItemCommentary}
            reportingItemCommentary={reportingItemCommentary}
            />
                  :
                  binderReviewReportingItemCommentary ? 
                  <ReportingItemCommentaryReview 
                  handleCommentSubmit={this.handleCommentEdit}
                  handleReviewSubmit={this.handleReviewSubmit}
                  binderReviewReportingItemCommentary={binderReviewReportingItemCommentary}
                  binderSummary={binderSummary}
                  />
                  :
                  null

          }
          {
            showReviewConfirmation ? <AppDialog 
            title="Are you sure?"
          message={<p>Please confirm you have completed your review of all items within the <br /><strong>{binderSummary.name}</strong></p>}
          onClose={this.hideReviewConfirmation}
          actions={
            <DialogActions>
              <Button variant="text" onClick={this.hideReviewConfirmation}>Cancel</Button>
              <Button variant="contained" color="secondary" onClick={this.confirmReviewCompleted}>Complete Review</Button>
            </DialogActions>
          } /> 
          : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...reportingItemActions,
        ...binderActions
      },
      dispatch
    )
  });


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, { withTheme: true })(ReviewBinderSummary)
  )
));