//import objectPath from 'object-path';
import ValidationUtil from '../../../../utils/ValidationUtil';

const validate = (values, {registeredFields} = {}) => {


  let errors = {};


  const requiredFields = [
    'whatsHappenedComment.managementComment',
    'whatsHappenedComment.electedMemberComment',
    'whatsHappenedComment.publicComment',
    'whatsNextComment.managementComment',
    'whatsNextComment.electedMemberComment',
    'whatsNextComment.publicComment'
  ];


  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);




  return errors;
};

export default validate;
