import objectPath from 'object-path'

export default class ArrayUtil {

    static isNonEmptyArray(e) {
      return (Array.isArray(e) && e.length) ? true : false
    }

    static arraysHaveMatch(a1, a2) {
        var match = a1.filter(element => a2.includes(element));
        return ArrayUtil.isNonEmptyArray(match);
      }


      static addToArrayIfNotExist(array, item) {

        if(Array.isArray(array) && array.indexOf(item) === -1){
          array.push(item);
        }
        return array;
      }

      static mapIdNameToValueLabel(array){
        return Array.isArray(array) ? array.map((x) => {return {"label": x.name, "value": x.id}}) : array;
      }

      static mapRIToValueLabel(array){
        return Array.isArray(array) ? array.map((x) => {
          var label = x && x.projectId ? "#"+x.projectId+" "+x.name : x.name;
          return {"label": label, "value": x.id}
        }) : array;
      }


      static removeFromArrayIfExist(array, item) {
        if(Array.isArray(array)){
          var index = array.indexOf(item);
          if (index > -1) {
            array.splice(index, 1);
          }
        } 
        return array;
      }

      static getRandItemIfExist(array){
        return Array.isArray(array) && array.length ? array[Math.floor(Math.random() * array.length)] : null;
      }

      static getNext(array, value, key = 'id'){
        var next = null;
        if(Array.isArray(array)){
          for (var i = 0; i < array.length; i++) { 
            var iId = objectPath.get(array,i+'.'+key, null);
            var iNext = objectPath.get(array,(i+1), null);
            if(next === null && iId === value && iNext){
              next = iNext;
            }
          }
        }
        return next;
      }

      static getPrev(array, value, key = 'id'){
        var prev = null;
        if(Array.isArray(array)){
          for (var i = 0; i < array.length; i++) { 
            var iId = objectPath.get(array,i+'.'+key, null);
            var iPrev = objectPath.get(array,(i-1), null);
            if(prev === null && iId === value && iPrev){
              prev = iPrev;
            }
          }
        }
        return prev;
      }

      static getSimpleNext(array, index){
        return Array.isArray(array) && array[(index+1)] ? array[(index+1)] : null;
      }

      static getSimplePrev(array, index){
        return Array.isArray(array) && index > 0 && array[(index-1)] ? array[(index-1)] : null;
      }

      static isLast(array, value, key = 'id'){
        if(Array.isArray(array)){
          var lastItemValue = objectPath.get(array,(array.length-1)+'.'+key,null);
          return lastItemValue === value;
        }
        return false
      }


      static getItemIndex(array, value, key = 'id'){
        var index = null;
        if(Array.isArray(array)){
          array.forEach((o,i) => {
            if(index === null && objectPath.get(o,key) === value){
              index = i;
            }
          });
      }
      return index;
    }
  }
  