import * as types from '../constants/actionTypes';
import ConsoleUtil from '../utils/ConsoleUtil';

export function getReportSchedules(state = [], action) {
    if (action.type === types.GET_REPORT_SCHEDULES) {
        ConsoleUtil.log(types.GET_REPORT_SCHEDULES+' set in store');
        return action.data.data;
    }
    return state;
}


export function getReportSchedule(state = [], action) {
    if (action.type === types.GET_REPORT_SCHEDULE) {
        ConsoleUtil.log(types.GET_REPORT_SCHEDULE+' set in store');
        return action.data.data;
    }
    return state;
}

