import axios from 'axios';
import LoggerUtil from '../utils/LoggerUtil';
import * as constants from '../constants/constants';
import objectPath from 'object-path';

import keycloakManager from '../keycloakManager';

// Custom Axios Instance
const reportingClient = axios.create();

// Custom instance defaults
reportingClient.defaults.baseURL = window.env.API_HOST;
// reportingClient.defaults.headers.post['Content-Type'] = ('application/x-www-form-urlencoded');

// Response Interceptor
reportingClient.interceptors.response.use(responseSuccess, responseFailure);

function responseSuccess(config) {
    return config;
}

function responseFailure(error = {}) {
    LoggerUtil.error('Response failed', error.response.data.message);
    const errorStatus = objectPath.get(error, 'response.status');
    if (constants.REDIRECTED_ERROR_STATUSES.includes(errorStatus)) {
        //window.location.href = '/'+errorStatus;
    }
    return Promise.reject(error);
}

// Request Interceptor
reportingClient.interceptors.request.use(requestSuccess, requestFailure);

function requestSuccess(config) {
    return keycloakManager
        .updateToken()
        .then(addTokenToHeader)
        .catch(() => keycloakManager.logout());

    function addTokenToHeader(token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    }
}

function requestFailure(error = {}) {
    LoggerUtil.error(error);
    return Promise.reject(error);
}

export default reportingClient;
