export default (theme, themeConfig, themeColors) => {
    return {
        binderCategoryHeading: {
            position: 'relative',
            paddingTop: themeConfig.setImportant(theme.spacing(1) * 4 + 'px'),
            paddingBottom: themeConfig.setImportant(theme.spacing(1) + 'px'),
            paddingRight: themeConfig.setImportant('100px'),
            borderBottom: '1px solid ' + themeColors.primaryPalette.white,
            '& .actionsWrap': {
                position: 'absolute',
                top: theme.spacing(1) * 1.5,
                right: theme.spacing(1) * 1.5,
                [theme.breakpoints.up('lg')]: {
                    right: theme.spacing(1) * 4,
                    display: 'none',
                },
            },
            '& p.muted': {
                fontSize: 12,
            },
        },
        binderCardHeader: {
            paddingBottom: 10,
            '& .calendarIcon': {
                fontSize: 12,
                '& svg': {
                    marginTop: -3,
                    color: themeColors.primarySupportingPalette.pms316c,
                },
            },
        },
        binderCategory: {
            '&.open': {
                paddingBottom: theme.spacing(1) * 6,
            },
            '&:hover > div > .actionsWrap': {
                display: 'block !important',
            },
        },
        binderCard: {
            boxShadow: 'none',
            cursor: 'pointer',
            '& .cardReportingItemLink': {
                paddingLeft: 0,
                '& a span': {
                    color: '#000',
                    opacity: 1,
                },
            },
            '& .actionsWrap': {
                position: 'absolute',
                top: theme.spacing(1) * 1.5,
                right: theme.spacing(1) * 1.5,
                zIndex: 3,
                '& button': {
                    padding: 6,
                },
                [theme.breakpoints.up('lg')]: {
                    display: 'none',
                },
            },
            '&:hover': {
                boxShadow:
                    '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
                '& .actionsWrap': {
                    display: 'block !important',
                },
            },
            '& h5': {
                marginTop: theme.spacing(1),
                cursor: 'pointer',
            },
            '& .reportingItemsList': {
                marginTop: theme.spacing(1),
                '&  p': {
                    marginTop: theme.spacing(1),
                    fontSize: themeConfig.smallFontSize,
                },
            },
        },
    };
};
