import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import GroupIcon from '@material-ui/icons/GroupOutlined';
import UsersUtil from '../utils/UsersUtil';

function UserIconName(props) {

  const { userIds } = props;

  let icon = <PersonIcon className="personIcon" fontSize="small" key="icon" />;
  let names;

  if (Array.isArray(userIds)){
      if(userIds.length > 1){
        icon = <GroupIcon className="personIcon" fontSize="small" key="icon"/>;
      }
      names = <span className="personName" key="name">{userIds.map(x =>  UsersUtil.getNameFromStoreById(x)).join(', ')}</span>;
  } else {
    names = <span className="personName" key="name">{UsersUtil.getNameFromStoreById(userIds)}</span>;
  }

  return <span className="positionIconName user">{[icon, names]}</span>;
}


export default withStyles(styles, { withTheme: true })(UserIconName);