import * as types from '../constants/actionTypes';
import initialState from './initialState';
import ConsoleUtil from '../utils/ConsoleUtil';

export function getPositions(state = initialState.positions, action) {
    if (action.type === types.GET_POSITIONS) {
        ConsoleUtil.log(types.GET_POSITIONS+' set in store');
        return action.data.data;
    }
    return state;
}