export default (theme, themeConfig, themeColors, themeFonts) => {
    return {
        pageHeading: {
            backgroundColor: themeColors.primaryPalette.white,
            '& > div': {
                [theme.breakpoints.up('md')]: {
                    paddingTop: theme.spacing(1) * 2,
                    paddingBottom: theme.spacing(1) * 2,
                },
                alignItems: 'center',
            },
            '& h1': {
                display: 'inline-block',
                [theme.breakpoints.down('md')]: {
                    fontSize: 20,
                },
            },
            '& h1.muted': {
                paddingRight: theme.spacing(1) * 4,
            },
            '&.secondary': {
                backgroundColor: themeColors.primaryPalette.primary, //themeColors.primarySupportingPalette.pms7472c,
                '& h1, & button': {
                    color: themeColors.primaryPalette.white,
                },
                '& h1': {
                    fontFamily: themeFonts.defaultFont,
                    textTransform: 'none',
                    fontSize: 16,
                },
            },
            '& .smallRoundedDialogIcon': {
                width: 28,
                height: 28,
                marginLeft: theme.spacing(1),
                border: '0px',
                verticalAlign: 'text-bottom',
                '&:not(:hover)': {
                    backgroundColor: 'transparent',
                },
                '& svg': {
                    color: themeColors.primarySupportingPalette.pms316c,
                },
            },
        },
        pageHeadingActions: {
            textAlign: 'right',
        },
    };
};
