import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../theme/styles";
import classnames from "classnames";
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded';
import TickIcon from "@material-ui/icons/Check";
import objectPath from 'object-path';
import ReportingItemCommentaryReviewForm from "../forms/reportingItem/commentaryReview/form"
import { Fab, Button, Typography, Grid, Divider, Tooltip } from "@material-ui/core";
import ArrayUtil from "../../utils/ArrayUtil";
import UrlBuilderUtil from "../../utils/UrlBuilderUtil";
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelIcon from '@material-ui/icons/CloseOutlined';
import * as constants from '../../constants/constants';
import GroupCommentCard from "../commentary/GroupCommentCard";
import MailtoAuthorButton from "../commentary/MailtoAuthorButton";


class ReportingItemCommentaryReview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editedCommentId: null,
    }
  }


  renderForm = (comment,index,section) => {
    const { handleCommentSubmit } = this.props;
    return  <ReportingItemCommentaryReviewForm
    proceedSubmit={ (comment) => {
      this.setState({editedCommentId: null});
      handleCommentSubmit(comment);
    }}
    initialValues={comment}
    heading={this.getHeadingForSection(section)}
    section={section}
    toggleEditableComment={this.toggleEditableComment}
    />
  }

  isEdited = (cId) => {
    return cId === this.state.editedCommentId;
  }

  toggleEditableComment = (cId) => {
    let { editedCommentId } = this.state;
    this.setState({
      editedCommentId: editedCommentId !== cId ? cId : null
    });
  }

  renderCommentActions = (comment, sectionKey) => {
    let { classes } = this.props;
    var commentId = objectPath.get(comment, 'id');
    return objectPath.get(comment, 'noFurtherProgress') !== true ? <div className={classnames("commentActions maxWidthPart narrowMaxMediumWidthPart",classes.textRightStrong)}>
          <MailtoAuthorButton comment={comment} 
          reportingItem={this.getCurrentReportingItem()} 
          sectionTitle={this.getHeadingForSection(sectionKey)} 
          />
          <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={this.isEdited(commentId) ? "Cancel" : "Edit"}><Button variant="outlined" size="small" className="toggleBtn"
          color={this.isEdited(commentId) ? "default" : "primary"} 
          onClick={() => this.toggleEditableComment(commentId)}>{ this.isEdited(commentId) ? <CancelIcon fontSize="small" /> : <EditIcon fontSize="small" /> }</Button>
         </Tooltip>
    </div> : null;
  }

  getPlaceholderForSection = (sectionKey) => {
    if(sectionKey === constants.WHATS_HAPPEND_COMMENTS_KEY){
      return "Enter what has happend...";
    } else if(sectionKey === constants.WHATS_NEXT_COMMENTS_KEY){
      return "Enter what's next...";
    }
    return "Enter comment...";
  }

  getAdditionalClassForSection = (sectionKey) => {
    if(sectionKey === constants.QUICK_COMMENTS_KEY){
      return "quickAudienceCommentCard"
    }
    return "";
  }

  getHeadingForSection = (sectionKey) => {
    if(sectionKey === constants.WHATS_HAPPEND_COMMENTS_KEY){
      return "What's Happened?";
    } else if(sectionKey === constants.WHATS_NEXT_COMMENTS_KEY){
      return "What's Next?";
    }
    return "Quick Update";
  }

  renderCommentPreview = (comment, index, sectionKey) => {
    return <div className="commentOuterWrapperWithActions narrowMaxMediumWidthPart">
      
      <GroupCommentCard 
    
    heading={this.getHeadingForSection(sectionKey)}
    comment={comment}
    additionalClass={this.getAdditionalClassForSection(sectionKey)}
    objectPathUserId="commentedUserId"
    objectPathCreatedDate="createdDate"
    showAll={false}
    hasImage={sectionKey === constants.QUICK_COMMENTS_KEY}
    fields={
      [
        
        {
          label: "Elected Members",
          name: "electedMemberComment"
        },
        {
          label: "Public",
          name: "publicComment"
        },
        {
          label: "Management",
          name: "managementComment"
        }
      ]
    }
  /> 
  { this.renderCommentActions(comment, sectionKey) }
  </div>

  }

  getCurrentReportingItem = () => {
    const { 
      binderSummary,
      binderReviewReportingItemCommentary } = this.props;
    var reportingItemId = objectPath.get(binderReviewReportingItemCommentary, 'reportingItemId',null);
    var binderReportingItems = objectPath.get(binderSummary, 'reportingItems',[]);
    return binderReportingItems.find(x => x.id === reportingItemId);
  }


  render() {
    const { 
      binderSummary, 
      history,
      classes,
      handleReviewSubmit,
      binderReviewReportingItemCommentary } = this.props;

    var binderReportingItems = objectPath.get(binderSummary, 'reportingItems',[]);
    var binderId = objectPath.get(binderSummary, 'id',null);
    var reportingItemId = objectPath.get(binderReviewReportingItemCommentary, 'reportingItemId',null);
    var nextRi = ArrayUtil.getNext(binderReportingItems,reportingItemId);
    var prevRi = ArrayUtil.getPrev(binderReportingItems,reportingItemId);
    var currentItemIndex = ArrayUtil.getItemIndex(binderReportingItems,reportingItemId);


    const wHcomments = objectPath.get(binderReviewReportingItemCommentary,constants.WHATS_HAPPEND_COMMENTS_KEY, []);
    const wNcomments = objectPath.get(binderReviewReportingItemCommentary,constants.WHATS_NEXT_COMMENTS_KEY, []);
    const quickComments = objectPath.get(binderReviewReportingItemCommentary,constants.QUICK_COMMENTS_KEY, []);

    
    return <div className="maxWidthPart riCommentaryReview">



<div className="maxWidthPart">
    <Grid container>
    

{
  wHcomments.map( (x,i) =>  x ? <Grid item className="gridItem" xs={12} key={i}>
      { this.isEdited(x.id) ? 
      this.renderForm(x,i, constants.WHATS_HAPPEND_COMMENTS_KEY) : 
      this.renderCommentPreview(x,i, constants.WHATS_HAPPEND_COMMENTS_KEY)
      }
      
    </Grid> : null)
}

<Grid item className="gridItem" xs={12}>
  <Divider className="sectionDivider"/>
</Grid>



{
  wNcomments.map( (x,i) =>  x ? <Grid item className="gridItem" xs={12} key={i}>
        { this.isEdited(x.id) ? 
      this.renderForm(x,i, constants.WHATS_NEXT_COMMENTS_KEY) : 
      this.renderCommentPreview(x,i, constants.WHATS_NEXT_COMMENTS_KEY)
      }</Grid> : null
      )
}






<Grid item className="gridItem" xs={12}>
<br /><br />
</Grid>



{
  quickComments.map( (x,i) => x ? <Grid item className="gridItem" xs={12} key={i}>
  { this.isEdited(x.id) ? 
      this.renderForm(x,i, constants.QUICK_COMMENTS_KEY) : 
      this.renderCommentPreview(x,i, constants.QUICK_COMMENTS_KEY)
      }
  </Grid> : null)
}



    
    </Grid>
    </div>


   

    
    
    
    <Grid container>
    <Grid item xs={12} className="gridItem">
    <div className={classnames("narrowMaxMediumWidthPart reportingItemsStepper",classes.textRightStrong)}>
    <Typography component="p" variant="body2" color="primary"><strong>{currentItemIndex+1} of {binderReportingItems && binderReportingItems.length}</strong></Typography>
      {
        prevRi ? 
        <Button color="primary" variant="outlined" type="submit"  className="navButton navPrev" onClick={()=> history.push(UrlBuilderUtil.getBinderSummaryReportingItem(binderId,prevRi.id)) }><ChevronLeftIcon /></Button>  : null
      }
      {
        nextRi ? 
        <Button color="primary" variant="outlined" type="submit"  className="navButton navNext" onClick={()=> history.push(UrlBuilderUtil.getBinderSummaryReportingItem(binderId,nextRi.id)) }><ChevronRightIcon /></Button> 
        : 
        ArrayUtil.isLast(binderReportingItems,reportingItemId) ? 
        <Fab color="secondary" className="navButton" onClick={handleReviewSubmit}><TickIcon /></Fab> : null
      }

    </div>
    </Grid>
    </Grid>
    
    
    
    </div>;
  }
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ReportingItemCommentaryReview)
);