import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";


import styles from "../../../theme/styles";
import Logo from "../../Logo";

class MobileNavBar extends React.Component {
  render() {
    const { classes, handleDrawerOpen, handleDrawerClose, drawerOpen } = this.props;


    return (
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar >
      <Logo inNavbar={true} />
      <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
            className={classes.menuButton}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton> 
          
        </Toolbar>
      </AppBar>
    );
  }
}

MobileNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MobileNavBar);
