import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from './containers/App';
import ProgressLoaderUtil from './utils/ProgressLoaderUtil';
import 'sanitize.css/sanitize.css';
import './theme/css/main.css';

window.__STORE__ = store;

const rootNode = document.getElementById('root');
const root = ReactDOMClient.createRoot(rootNode);

root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <App />
            </div>
        </ConnectedRouter>
    </Provider>
);

ProgressLoaderUtil();
