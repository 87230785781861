import React from 'react';
import objectPath from 'object-path';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import ExportButton from '../buttons/ExportButton';
import FilterIcon from '../icons/FilterIcon';
import {
    Grid,
    InputAdornment,
    Input,
    FormControlLabel,
    Switch,
    MenuItem,
    Menu,
    Button,
    Tooltip,
    Hidden,
} from '@material-ui/core';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import Fab from '@material-ui/core/Fab';
import TickIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/CloseOutlined';
import FilterUtil from '../../utils/FilterUtil';
import PositionsUtil from '../../utils/PositionsUtil';
import UrlBuilderUtil from '../../utils/UrlBuilderUtil';
import StrategicPlansUtil from '../../utils/StrategicPlansUtil';
import ReactSelect2Wrap from '../inputs/ReactSelect2Wrap';
import LabelTooltip from '../inputs/LabelTooltip';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import ArrayUtil from '../../utils/ArrayUtil';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import * as AuthUtil from '../../utils/AuthUtil';

class DashboardFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: objectPath.get(props, 'open', false),
            recentSearchOpen: false,
            recentSearchMenuButton: null,
            ...FilterUtil.getRequestedFilterFromUrl(),
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({
                ...FilterUtil.getRequestedFilterFromUrl(),
            });
        }
    }

    getFilterState = () => {
        const { due, myitems, keyword, activityids, positionids } = this.state;

        return {
            keyword,
            myitems,
            due,
            activityids,
            positionids,
        };
    };

    hasFilterChanged = () => {
        var stateFilter = this.getFilterState();
        var lastRequestedFilter = objectPath.get(
            this,
            'props.dashboardMergedData.filter',
            null
        );
        return FilterUtil.areEqual(stateFilter, lastRequestedFilter)
            ? false
            : true;
    };

    hasActivityPmFilterChanged = () => {
        var stateFilter = this.getFilterState();
        var lastRequestedFilter = objectPath.get(
            this,
            'props.dashboardMergedData.filter',
            null
        );
        //remove ignored filters and compare
        delete stateFilter.keyword;
        delete stateFilter.myitems;
        delete stateFilter.due;

        delete lastRequestedFilter.keyword;
        delete lastRequestedFilter.myitems;
        delete lastRequestedFilter.due;

        return FilterUtil.areEqual(stateFilter, lastRequestedFilter)
            ? false
            : true;
    };

    redirectToCurrentFilter = (filter = null) => {
        if (filter === null) {
            filter = this.getFilterState();
        }
        const { history } = this.props;
        history.push(UrlBuilderUtil.getDashboard(filter));
    };

    keyPress = (e) => {
        if (e.keyCode === 13) {
            //enter pressed
            this.redirectToCurrentFilter();
        }
    };

    goToRecentSearch = (selected) => {
        this.recentFiltersShowButtonClicked(null); //close menu
        //ConsoleUtil.log('goToRecentSearch',selected);
        this.props.history.push(selected);
    };

    recentFiltersShowButtonClicked = (event) => {
        this.setState({
            recentSearchMenuButton: objectPath.get(
                event,
                'currentTarget',
                null
            ),
        });
    };

    changeMyItemsCheckbox = () => {
        const { myitems } = this.state;
        var stateFilter = this.getFilterState();
        stateFilter.myitems = !myitems;
        this.redirectToCurrentFilter(stateFilter);
    };

    changeDueCheckbox = () => {
        const { due } = this.state;
        var stateFilter = this.getFilterState();
        stateFilter.due = !due;
        this.redirectToCurrentFilter(stateFilter);
    };

    activityChanged = (valId) => {
        this.setState({
            activityids: valId,
        });
    };

    positionChanged = (valId) => {
        this.setState({
            positionids: valId,
        });
    };

    isFilterOpen = () => {
        const { open, activityids, positionids } = this.state;
        return (
            open ||
            activityids.length ||
            positionids.length ||
            this.hasActivityPmFilterChanged()
        );
    };

    hasKeyword = () => {
        var lastRequestedKeyword = FilterUtil.getRequestedKeywordFromUrl();
        return lastRequestedKeyword && lastRequestedKeyword.length;
    };

    render() {
        const { classes } = this.props;
        const {
            open,
            myitems,
            due,
            keyword,
            activityids,
            positionids,
            recentSearchMenuButton,
        } = this.state;

        let recentFilters = LocalStorageUtil.get('dashboardFilters', [], false);

        const recentSearchLabel = (
            <div className={classes.textRightStrong}>
                <LabelTooltip
                    label={
                        ArrayUtil.isNonEmptyArray(recentFilters)
                            ? 'Recent filters (' + recentFilters.length + ')'
                            : 'No recent filters'
                    }
                    inputName="recentFilters"
                    formName="searchFilter"
                />
            </div>
        );

        return (
            <div
                className={classnames(
                    classes.headerFilterWrap,
                    classes.DashboardFilterWrap,
                    this.isFilterOpen() ? 'open' : ''
                )}
            >
                <Grid container className="gridContainer">
                    <Grid item xs={12} sm={5}>
                        <Input
                            className="keywordSearch"
                            placeholder="Search"
                            onKeyDown={this.keyPress}
                            value={keyword}
                            onChange={(e) =>
                                this.setState({ keyword: e.target.value })
                            }
                            startAdornment={
                                <Tooltip title="Search by Title">
                                    <InputAdornment
                                        onClick={() =>
                                            this.redirectToCurrentFilter()
                                        }
                                        position="start"
                                    >
                                        <SearchIcon
                                            color="primary"
                                            fontSize="small"
                                        />
                                    </InputAdornment>
                                </Tooltip>
                            }
                            endAdornment={
                                this.hasKeyword() ? (
                                    <Tooltip title="Clear search">
                                        <InputAdornment
                                            onClick={() =>
                                                this.setState(
                                                    { keyword: '' },
                                                    () =>
                                                        this.redirectToCurrentFilter()
                                                )
                                            }
                                            position="end"
                                        >
                                            <CancelIcon
                                                color="inherit"
                                                className="muted"
                                                fontSize="small"
                                            />
                                        </InputAdornment>
                                    </Tooltip>
                                ) : null
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        className={classes.textRightStrong}
                    >
                        <FormControlLabel
                            classes={{
                                label: 'controlLabel',
                            }}
                            control={
                                <Switch
                                    className={classes.customSwitch}
                                    checked={
                                        myitems === 1 ||
                                        myitems === '1' ||
                                        myitems === true
                                            ? true
                                            : false
                                    }
                                    onChange={this.changeMyItemsCheckbox}
                                    color="primary"
                                />
                            }
                            label="My Items"
                        />

                        <FormControlLabel
                            classes={{
                                label: 'controlLabel',
                            }}
                            control={
                                <Switch
                                    className={classes.customSwitch}
                                    checked={
                                        due === 1 || due === '1' || due === true
                                            ? true
                                            : false
                                    }
                                    onChange={this.changeDueCheckbox}
                                    color="primary"
                                />
                            }
                            label="Due"
                        />

                        {AuthUtil.hasAdminRole() && <ExportButton />}

                        <Fab
                            size="small"
                            className="filterBtn"
                            onClick={() => {
                                this.setState({ open: !open });
                            }}
                        >
                            <FilterIcon fontSize="small" color="primary" />
                        </Fab>
                    </Grid>
                </Grid>
                {this.isFilterOpen() ? (
                    <Grid container className="gridContainer">
                        <Grid
                            item
                            className="gridItem"
                            xs={12}
                            sm={6}
                            md={4}
                            xl={5}
                        >
                            <LabelTooltip
                                label="Filter by: Activity"
                                inputName="activityIds"
                                formName="searchFilter"
                            />
                            <ReactSelect2Wrap
                                isMulti={true}
                                isSearchable={true}
                                isClearable={true}
                                fieldName="activityIds"
                                value={activityids}
                                fieldOptions={StrategicPlansUtil.formatForActivitySelector()}
                                onChange={this.activityChanged}
                                classNamePrefix="filter-select"
                            />
                        </Grid>
                        <Grid
                            item
                            className="gridItem"
                            xs={12}
                            sm={6}
                            md={4}
                            xl={5}
                        >
                            <LabelTooltip
                                label="Project/Activity Summary Manager"
                                inputName="positionIds"
                                formName="searchFilter"
                            />
                            <ReactSelect2Wrap
                                isMulti={true}
                                isSearchable={true}
                                isClearable={true}
                                fieldName="positionIds"
                                value={positionids}
                                fieldOptions={PositionsUtil.formatForUserSelector()}
                                onChange={this.positionChanged}
                                classNamePrefix="filter-select"
                            />
                        </Grid>
                        <Grid item className="gridItem" xs={12} md={4} xl={2}>
                            <Hidden smDown>{recentSearchLabel}</Hidden>
                            <Fab
                                color="secondary"
                                className="filterConfirmBtn"
                                size="small"
                                disabled={
                                    this.hasActivityPmFilterChanged()
                                        ? false
                                        : true
                                }
                                onClick={() => this.redirectToCurrentFilter()}
                            >
                                <TickIcon />
                            </Fab>

                            {ArrayUtil.isNonEmptyArray(recentFilters) ? (
                                <div
                                    className={classnames(
                                        classes.textRightStrong,
                                        'recentFilters'
                                    )}
                                >
                                    <Hidden mdUp>{recentSearchLabel}</Hidden>
                                    <Button
                                        aria-owns={
                                            recentSearchMenuButton
                                                ? 'recent-menu'
                                                : undefined
                                        }
                                        aria-haspopup="true"
                                        variant="text"
                                        color="default"
                                        onClick={
                                            this.recentFiltersShowButtonClicked
                                        }
                                    >
                                        <HistoryIcon fontSize="small" /> Show
                                        all
                                    </Button>
                                    <Menu
                                        id="recent-menu"
                                        className="recent-menu"
                                        open={Boolean(recentSearchMenuButton)}
                                        anchorEl={recentSearchMenuButton}
                                        onClose={() =>
                                            this.recentFiltersShowButtonClicked(
                                                null
                                            )
                                        }
                                        PaperProps={{
                                            style: {
                                                maxHeight: 48 * 4.5,
                                                width: 300,
                                            },
                                        }}
                                    >
                                        {recentFilters.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={() =>
                                                    this.goToRecentSearch(
                                                        option
                                                    )
                                                }
                                            >
                                                {FilterUtil.getLinkTitleByUrl(
                                                    option
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        dashboardMergedData: state.dashboardMergedData,
    };
}

export default withRouter(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(DashboardFilter)
    )
);
