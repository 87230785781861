export default (theme, themeConfig, themeColors, themeFonts) => {
    return {
        root: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                minHeight: `calc(100vh - ${themeConfig.toolbarMinHeight}px)`,
            },
        },

        hide: {
            display: 'none',
        },

        success: {
            backgroundColor: themeColors.primaryPalette.primary,
            color: themeColors.primaryPalette.white,
        },

        error: {
            backgroundColor: themeConfig.setImportant(
                themeColors.primarySupportingPalette.pms200c
            ),
            color: themeConfig.setImportant(themeColors.primaryPalette.white),
        },

        warning: {
            backgroundColor: themeColors.secondarySupportingPalette.pms1575c,
            color: themeColors.primaryPalette.black,
        },

        successText: {
            color: themeColors.primaryPalette.primary,
        },

        errorText: {
            color: themeColors.primarySupportingPalette.pms200c,
        },

        warningText: {
            color: themeColors.secondarySupportingPalette.pms1575c,
        },

        textCenter: {
            textAlign: 'center',
        },

        textRight: {
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right',
            },
        },

        textRightStrong: {
            textAlign: 'right',
        },

        tabsWrapper: {
            borderBottom: '1px solid ' + themeColors.primaryPalette.secondary,
            '& button': {
                textTransform: 'none',
                minWidth: 'auto',
                '&[aria-selected="true"]': {
                    color: themeColors.primaryPalette.primary,
                },
            },
        },

        textRightOnlyXs: {
            [theme.breakpoints.only('xs')]: {
                textAlign: 'right',
            },
        },

        hiddenXs: {
            [theme.breakpoints.only('xs')]: {
                display: 'none !important',
            },
        },

        inputLabelColumnsComponentControlWrap: {
            backgroundColor: themeColors.primaryPalette.primary,
            padding: theme.spacing(1) * 2,
            borderRadius: theme.spacing(1) / 2,
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1) * 1.5,
            },
            '& .gridItem': {
                position: 'relative',
            },
            '& .labelWrap': {
                color: themeConfig.setImportant(
                    themeColors.primaryPalette.white
                ),
            },

            '& .inputLabelColumnsInputWrap': {
                display: 'flex',
                padding: 0,
            },
            '& input[type="text"], & textarea': {
                backgroundColor: themeColors.primaryPalette.white,
                borderRadius: theme.spacing(1) / 2,
                width: '100%',
                padding: theme.spacing(1),
            },
        },

        previousValue: {
            display: 'inline-block',
            backgroundColor: themeColors.primarySupportingPalette.pms316c,
            padding: theme.spacing(1) * 2,
            borderRadius: theme.spacing(1) / 2,
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1) * 1.5,
            },
            '& span, & div': {
                color: themeColors.primaryPalette.white,
                fontStyle: 'italic',
                textTransform: 'none',
            },
            '& div': {
                fontWeight: 600,
            },
        },

        previousComment: {
            cursor: 'pointer',
            display: 'block',

            '& .btnWrap span': {
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '80%',
            },
            '&.compact': {},
        },

        formDialogWrap: {
            '& .roundedDialogIcon': {
                width: 48,
                height: 48,
                marginLeft: theme.spacing(1),
                border: '1px solid ' + themeColors.customPalette.tableRowBorder,
                '&:not(:hover)': {
                    backgroundColor: 'transparent',
                },
                '& svg': {
                    color: themeColors.primarySupportingPalette.pms316c,
                },
            },
            '&.dialogWithArrows .paper': {
                '& .dialogFormContent': {
                    backgroundColor: 'transparent',
                },
                [theme.breakpoints.up('sm')]: {
                    position: 'relative',
                    paddingTop: theme.spacing(1) * 2,
                    paddingLeft: theme.spacing(1) * 2.5,
                    paddingRight: theme.spacing(1) * 2.5,
                    '& button.arrow': {
                        position: 'absolute',
                        top: '45%',
                        left: 4,
                        '&.arrowRight': {
                            left: 'auto',
                            right: 4,
                        },
                    },
                },
                '& .dialogHistoryContent': {
                    [theme.breakpoints.down('md')]: {
                        minHeight: '60vh',
                    },
                },
            },
            '& h5': {
                position: 'relative',
                paddingRight: theme.spacing(1) * 6,
                '& button': {
                    position: 'absolute',
                    right: theme.spacing(1),
                    top: -theme.spacing(1) / 2,
                    padding: theme.spacing(1) / 2,
                },
            },
            '&.fullScreen': {
                '& > div > div': {
                    backgroundColor: themeColors.customPalette.bodyBg,
                    overflow: themeConfig.setImportant('visible'),
                },
                '& .formDialogTitle': {
                    backgroundColor: themeColors.primaryPalette.white,
                    '& h6': {
                        fontWeight: 900,
                        fontFamily: themeFonts.headingFont,
                    },
                },
            },
        },

        ImageList: {
            '& .avatarWrap, .primaryListText, .secondaryListText': {
                cursor: 'pointer',
            },
            '& .primaryListText': {
                fontSize: themeConfig.smallFontSize,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            '& .secondaryListText': {
                fontSize: themeConfig.smallFontSize - 1,
            },
        },
    };
};
