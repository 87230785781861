import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Table, TableRow, TableBody, TableCell, Paper } from '@material-ui/core';
import StoreUtil from '../../utils/StoreUtil';
import objectPath from 'object-path'
import classnames from 'classnames'

function SystemThresholdsTable(props) {

  const naValue = 'N/A';

  const { classes } = props;
  const systemTresholds = StoreUtil.getValueByKey('reportingTargetParameters');

  const nonF_partialMet_Percent_Lower = objectPath.get(systemTresholds,'nonFinancialTarget.partialMet_Percent_Lower');
  const nonF_met_Percent_Lower = objectPath.get(systemTresholds,'nonFinancialTarget.met_Percent_Lower');

  const f_met_Percent_Over = objectPath.get(systemTresholds,'financialTarget.met_Percent_Over', );
  const f_met_Percent_Under = objectPath.get(systemTresholds,'financialTarget.met_Percent_Under');
  
  const f_partialMet_Percent_Over = objectPath.get(systemTresholds,'financialTarget.partialMet_Percent_Over');
  const f_partialMet_Percent_Under = objectPath.get(systemTresholds,'financialTarget.partialMet_Percent_Under');

  return systemTresholds ? <div className={classnames(classes.systemTresholdsWrap,"maxWidthPart narrowMaxMediumWidthPart")}><Paper className="paper"><Table className="table">
        <TableBody>
        <TableRow>
            <TableCell>Legend</TableCell>
            <TableCell><strong>MET</strong></TableCell>
            <TableCell><strong>PARTIAL MET</strong></TableCell>
            <TableCell><strong>NOT MET</strong></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><strong>Non Financial - CPS</strong></TableCell>
            <TableCell className="met">{nonF_met_Percent_Lower ? parseInt(nonF_met_Percent_Lower) : naValue} - 100% MET</TableCell>
            <TableCell className="partialMet">{nonF_partialMet_Percent_Lower ? parseInt(nonF_partialMet_Percent_Lower) : naValue} - {nonF_met_Percent_Lower ? parseInt(nonF_met_Percent_Lower)-1 : naValue}% MET</TableCell>
            <TableCell className="notMet">0 - {nonF_partialMet_Percent_Lower ? parseInt(nonF_partialMet_Percent_Lower)-1 : naValue}% MET</TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell><strong>Financial - GL</strong></TableCell>
            <TableCell className="met">within {f_met_Percent_Under}% to {f_met_Percent_Over}%</TableCell>
            <TableCell className="partialMet">within {f_partialMet_Percent_Under}% to {f_partialMet_Percent_Over}%</TableCell>
            <TableCell className="notMet">&lt; {f_partialMet_Percent_Under}% or &gt; {f_partialMet_Percent_Over}%</TableCell>
          </TableRow>
        </TableBody>
  </Table></Paper></div> : null
}


export default withStyles(styles, { withTheme: true })(SystemThresholdsTable);