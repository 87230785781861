import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";  
import styles from '../../theme/styles';
import BinderPrintSetupForm from '../forms/binder/printSetup/form';
import { DialogTitle, Typography, DialogContent, Dialog, Button } from '@material-ui/core';
import ComponentLoading from '../ComponentLoading';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector, clearFields } from 'redux-form'
import objectPath from "object-path";
import * as audiencesActions from '../../actions/audiences';
import * as reportOutputActions from '../../actions/reportOutput';
import * as binderActions from '../../actions/binder';
import ArrayUtil from '../../utils/ArrayUtil';
import * as formNames from "../../constants/forms";
import ReportOutputUtil from '../../utils/ReportOutputUtil';
import ErrorIcon from '@material-ui/icons/Warning'
import GoogleTagManagerUtil from '../../utils/GoogleTagManagerUtil';


    class BinderPrintDialog extends React.Component {


      constructor(props){
        super(props);
        this.state={
          audiences: [],
          reportingOutputs: [],
          dataLoading: true,
          documentPrintError: false,
          documentUrl: null
        }
      }


      componentDidMount(){
          const { actions, item } = this.props;
          Promise.all([
            actions.getAudiences(),
            actions.getReportOutputs(item.id)
          ]).then(() => {
            this.setState({
              dataLoading: false
            });
            GoogleTagManagerUtil.trackPrintBinderModal(item);
          }
            );
      }

      getInitialValues = () => {
        const { item, reportOutputs } = this.props;
        var initialValues = {
          binderId : item.id
        };
        if(reportOutputs.length === 1){
          initialValues.reportOutputId = objectPath.get(reportOutputs,'0.id', null);
          var reportOutputAudiences = objectPath.get(reportOutputs,'0.audiences', []);
          if(reportOutputAudiences.length === 1){
            initialValues.audience = objectPath.get(reportOutputAudiences,'0', null);
          }
        }
        return initialValues;
      }


      componentDidUpdate(prevProps){
        const {selectedAudience, clearFieldsValue}  = this.props;
        let filteredAudiences = this.getFilteredAudienceOptions();
        if(selectedAudience && filteredAudiences && ArrayUtil.getItemIndex(filteredAudiences, selectedAudience, 'value') === null){//reset if not in filtered options
          clearFieldsValue(formNames.BINDER_PRINT_SETUP_FORM_NAME, ['audience']);
        }
      }


      getFilteredAudienceOptions = () => {
        let { audiences } = this.props;
        return ReportOutputUtil.getFilteredAudienceOptions(this.getSelectedOutputOption(), audiences.map(x => {return {label: x.name, value: x.id}}))
      }

      getReportOutputOptions = () => {
        let { reportOutputs } = this.props;
        return reportOutputs.map(x => {return {label: x.name, value: x.id, audiences: x.audiences}})
      }

      getSelectedOutputOption = () => {
        let { selectedReportOutputId } = this.props;
        return selectedReportOutputId ? this.getReportOutputOptions().find(x => x.value === selectedReportOutputId) : null;
      }

      handlePrintSubmit = (values) => {
        let { actions, item } = this.props;
        this.setState({dataLoading: true});
        return actions.postBinderCommentaryDocs(values).then( (response) => {
          var documentUrl = objectPath.get(response,'data.data.url',null);
          if(documentUrl){
            this.setState({
              dataLoading: false,
              documentUrl,
              documentPrintError: false
            });
            GoogleTagManagerUtil.trackPrintedBinderDownloadLinkCreated(item, {
              printSetting: values,
              printResponse: objectPath.get(response,'data.data',null)
            });
          } else {
            this.setState({
              dataLoading: false,
              documentPrintError: true
            });
            GoogleTagManagerUtil.trackPrintedBinderDownloadLinkCreated(item, {
              printSetting: values,
              printResponse: 'error'
            });
          }
          
        });
      }

      


      
        render() {
            const { item, onClose } = this.props;
            const { dataLoading, documentUrl, documentPrintError } = this.state;

            
            return <Dialog onClose={onClose} open={true} 
            classes={{
              paper: "dialogPaperWithForm"
            }}
            ><DialogTitle
            className="formDialogTitle"
           onClose={() => onClose()}
          disableTypography={true}
             
           ><Typography variant="h5">
           <strong>{item.name}</strong>
         </Typography>
         <Typography variant="body2" className="muted">print setup</Typography>
        </DialogTitle>
        <DialogContent className="dialogFormContent">
          
  
          { dataLoading ? <div><ComponentLoading /></div> : 
            documentUrl ? <div><br /><br /><Button target="_blank" href={documentUrl} variant="contained" color="secondary">Download document</Button> <Button color="default" variant="outlined" onClick={() => onClose()}>Close</Button><br /><br /></div> :
            documentPrintError ? <div><br /><br /><Typography variant="body2" color="error"><ErrorIcon fontSize="small"/> There was an error processing selected document. Please try again later.</Typography>
            <br />
            <Button color="default" variant="outlined" onClick={() => onClose()}>Cancel</Button>
            </div>
            
            :

            <BinderPrintSetupForm 
            binder={item}
            initialValues={this.getInitialValues()}
            onCancel={onClose}
            audiences={this.getFilteredAudienceOptions()}
            reportOutputs={this.getReportOutputOptions()}
            proceedSubmit={this.handlePrintSubmit}
            /> 
          }
          
          
        </DialogContent></Dialog>
        }
}


const selector = formValueSelector(formNames.BINDER_PRINT_SETUP_FORM_NAME);


function mapStateToProps(state) {
  return {
    audiences: state.audiences,
    reportOutputs: state.reportOutputsByBinderId,
    selectedReportOutputId: selector(state, 'reportOutputId'),
    selectedAudience: selector(state, 'audience')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFieldsValue: function(formName, fields) {
      dispatch(clearFields(formName, true, false, fields))
    },
    actions: bindActionCreators(
      {
        ...audiencesActions,
        ...reportOutputActions,
        ...binderActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BinderPrintDialog)));

