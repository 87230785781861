import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageHeading from '../../../components/PageHeading';

import * as reportingItemActions from '../../../actions/reportingItem';
import * as progressReportActions from '../../../actions/progressReport';
import * as activitySummaryActions from '../../../actions/activitySummary';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import * as constants from '../../../constants/constants';
import { EditableProgressReportForm } from '../../../components/forms/progressReport/summary/form';
import ActivitySummaryReviewForm from '../../../components/forms/activitySummary/review/form';
import ReportingItemCommentaryFormWrapper from '../../../components/reportingItem/ReportingItemCommentaryFormWrapper';
import ComponentLoading from '../../../components/ComponentLoading';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import {
    Typography,
    Tab,
    Tabs,
    Tooltip,
    Hidden,
    Button,
    Grid,
} from '@material-ui/core';
import classnames from 'classnames';
import EditIcon from '@material-ui/icons/EditOutlined';
import ActivitySummarySubmenu from '../../../components/menu/ActivitySummarySubmenu';
import UrlBuilderUtil from '../../../utils/UrlBuilderUtil';
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ActivitySummaryResultsSection from '../../../components/activitySummary/ActivitySummaryResultsSection';
import ListingTable from '../../../components/ListingTable';
import FullScreenDialog from '../../../components/FullScreenDialog';
import ViewProgressReport from '../progressReport/ViewProgressReport';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import HeadingBackBtn from '../../../components/buttons/HeadingBackBtn';
import ActivitySummaryUtil from '../../../utils/ActivitySummaryUtil';
import SystemThresholdsTable from '../../../components/activitySummary/SystemThresholdsTable';
import ProgressReportUtil from '../../../utils/ProgressReportUtil';
import StoreUtil from '../../../utils/StoreUtil';
import GoogleTagManagerUtil from '../../../utils/GoogleTagManagerUtil';

class ReviewActivitySummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activitySummary: null,
            activitySummaryReview: null,
            progressReportSummary: null,
            reportingItemCommentary: null,
            dataLoading: true,
            dialogProjectId: null,
        };
    }

    viewProgressReportById = (projectId) => {
        this.setState({
            dialogProjectId: projectId,
        });
    };

    componentDidUpdate(prevProps) {
        // reload data on url change
        if (this.props.location !== prevProps.location) {
            this.setState({
                dataLoading: true,
            });
            this.reloadData();
        }
    }

    componentDidMount() {
        this.reloadData();
    }

    isASReady = () => {
        const {
            activitySummaryReview,
            reportingItemCommentary,
            progressReportSummary,
        } = this.state;
        return activitySummaryReview &&
            reportingItemCommentary === null &&
            progressReportSummary === null
            ? true
            : false;
    };

    reloadData = () => {
        const { match } = this.props;
        var { year, period } = this.getSelectedPeriod();
        var reportingItemId = objectPath.get(
            match,
            'params.reportingItemId',
            null
        );
        var projectId = objectPath.get(match, 'params.projectId', null);
        var activitySummaryId = objectPath.get(
            match,
            'params.activitySummaryId',
            null
        );
        Promise.all([
            this.mountActivitySummary(activitySummaryId, year, period),
        ]).then(() =>
            Promise.all([
                this.mountProgressReport(projectId),
                this.mountReportingItemCommentary(reportingItemId),
            ]).then(() =>
                this.setState({
                    dataLoading: false,
                })
            )
        );
    };

    mountActivitySummary(id, year, period) {
        // for menu and page state purposes and based on condition load AS review
        const { match, actions, history } = this.props;

        var projectId = objectPath.get(match, 'params.projectId', null);
        var reportingItemId = objectPath.get(
            match,
            'params.reportingItemId',
            null
        );

        const _this = this;
        if (id) {
            return actions.getActivitySummary(id).then((response) => {
                let activitySummary = objectPath.get(response, 'data.data');

                if (activitySummary) {
                    if (
                        projectId === null &&
                        reportingItemId === null &&
                        ActivitySummaryUtil.isASreviewAccessible(
                            activitySummary
                        )
                    ) {
                        return actions
                            .getActivitySummaryReview(id, year, period)
                            .then((response) => {
                                let activitySummaryReview = objectPath.get(
                                    response,
                                    'data.data'
                                );

                                return _this.setState({
                                    activitySummary,
                                    activitySummaryReview,
                                });
                            });
                    } else {
                        return _this.setState({
                            activitySummary,
                            activitySummaryReview: null,
                        });
                    }
                } else {
                    return history.push('/404');
                }
            });
        } else {
            return new Promise((resolve) => {
                _this.setState(
                    {
                        activitySummary: null,
                    },
                    resolve
                );
            });
        }
    }

    mountProgressReport(id) {
        const { history, actions } = this.props;

        const _this = this;
        if (id) {
            return actions.getProgressReport(id).then((response) => {
                let progressReportSummary = objectPath.get(
                    response,
                    'data.data'
                );
                if (progressReportSummary) {
                    return _this.setState({
                        progressReportSummary,
                    });
                } else {
                    return history.push('/404');
                }
            });
        } else {
            return new Promise((resolve) => {
                _this.setState(
                    {
                        progressReportSummary: null,
                    },
                    resolve
                );
            });
        }
    }

    mountReportingItemCommentary(id) {
        const { history, actions } = this.props;

        const _this = this;
        if (id) {
            return actions.getReportingItemCommentary(id).then((response) => {
                let reportingItemCommentary = objectPath.get(
                    response,
                    'data.data'
                );
                if (reportingItemCommentary) {
                    return _this.setState({
                        reportingItemCommentary,
                    });
                } else {
                    return history.push('/404');
                }
            });
        } else {
            return new Promise((resolve) => {
                _this.setState(
                    {
                        reportingItemCommentary: null,
                    },
                    resolve
                );
            });
        }
    }

    onMainTabClick = (event, newTabId) => {
        const { history, match } = this.props;
        const { progressReportSummary, reportingItemCommentary } = this.state;
        var activitySummaryId = objectPath.get(
            match,
            'params.activitySummaryId',
            null
        );

        if (
            newTabId === 'commentary' &&
            progressReportSummary &&
            progressReportSummary.reportingItemId
        ) {
            history.push(
                UrlBuilderUtil.getActivitySummaryReportingItemCommentary(
                    activitySummaryId,
                    progressReportSummary.reportingItemId
                )
            );
        }
        if (
            newTabId === 'summary' &&
            reportingItemCommentary &&
            reportingItemCommentary.projectId
        ) {
            history.push(
                UrlBuilderUtil.getActivitySummaryProgressReport(
                    activitySummaryId,
                    reportingItemCommentary.projectId
                )
            );
        }
    };

    getProjectId = () => {
        const { match } = this.props;
        const { reportingItemCommentary } = this.state;
        const reportingItemProjectId = objectPath.get(
            reportingItemCommentary,
            'projectId',
            ''
        );
        const projectId = objectPath.get(match, 'params.projectId', '');

        return projectId || reportingItemProjectId;
    };

    getMutedHeadingText = () => {
        return this.getProjectId() ? '#' + this.getProjectId() : '';
    };

    getHeadingText = () => {
        const {
            activitySummary,
            progressReportSummary,
            reportingItemCommentary,
        } = this.state;
        if (reportingItemCommentary) {
            if (reportingItemCommentary.projectName) {
                return reportingItemCommentary.projectName;
            } else {
                return reportingItemCommentary.name;
            }
        } else if (progressReportSummary) {
            return progressReportSummary.projectName;
        } else {
            return ActivitySummaryUtil.adjustASname(
                objectPath.get(activitySummary, 'name', 'Activity Summary')
            );
        }
    };

    getSelectedPeriod() {
        const { match } = this.props;

        var year = objectPath.get(match, 'params.year', null);
        var period = objectPath.get(match, 'params.period', null);
        if (year && period) {
            return { year, period };
        }
        return ActivitySummaryUtil.getActivityReportPeriod(
            StoreUtil.getValueByKey('reportingParameters.currentReportingYear'),
            StoreUtil.getValueByKey(
                'reportingParameters.currentReportingPeriod'
            )
        );
    }

    handleResponse = (response, redirectToURLOnSuccess) => {
        let snackBarSuccessMessage =
            this.getMutedHeadingText() +
            ' ' +
            this.getHeadingText() +
            ' updated';
        const { enqueueSnackbar, history } = this.props;
        if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
            SnackBarUtil.showSuccessMessage(
                response,
                enqueueSnackbar,
                snackBarSuccessMessage
            );
            setImmediate(() => {
                history.push(redirectToURLOnSuccess);
            });
        }
    };

    handleActivitySummaryReviewSubmit = (values) => {
        const { actions } = this.props;
        let redirectToURLOnSuccess = UrlBuilderUtil.getDashboard();
        return actions
            .postActivitySummaryReview(values)
            .then((response) =>
                this.handleResponse(response, redirectToURLOnSuccess)
            );
    };

    handleActivitySummaryReviewAsDraft = (values) => {
        const { actions } = this.props;
        let redirectToURLOnSuccess = UrlBuilderUtil.getDashboard();
        return actions
            .postActivitySummaryReviewAsDraft(values)
            .then((response) =>
                this.handleResponse(response, redirectToURLOnSuccess)
            );
    };

    handleProgressReportSummary = (values) => {
        const { actions, match } = this.props;
        var activitySummaryId = objectPath.get(
            match,
            'params.activitySummaryId'
        );
        let redirectToURLOnSuccess =
            UrlBuilderUtil.getActivitySummaryReportingItemCommentary(
                activitySummaryId,
                values.reportingItemId
            );

        //empty fields under conditions
        values = ProgressReportUtil.emptyConditionalFields(values);

        return actions
            .putProgressReport(values)
            .then((response) =>
                this.handleResponse(response, redirectToURLOnSuccess)
            );
    };

    handleReportingItemCommentary = (values) => {
        const { actions, match } = this.props;
        const { activitySummary } = this.state;
        var reportingItemId = objectPath.get(
            match,
            'params.reportingItemId',
            null
        );
        let redirectToURLOnSuccess =
            UrlBuilderUtil.getGoToURLForASAfterRICommentarySubmit(
                activitySummary,
                reportingItemId
            );
        if (
            objectPath.get(values, 'dueCommentaryState') ===
                constants.REPORTING_ITEM_COMMENTARY_STATE_COMMENTARYDUE ||
            objectPath.get(values, 'openCommentary') === true
        ) {
            return actions
                .postReportingItemCommentary(values)
                .then((response) =>
                    this.handleResponse(response, redirectToURLOnSuccess)
                );
        } else {
            //submit QC
            return actions
                .postReportingItemQuickCommentary(reportingItemId, values)
                .then((response) => {
                    GoogleTagManagerUtil.trackQuickComment({
                        id: reportingItemId,
                        addedFrom: 'Review of activity summary',
                        activitySummaryName: activitySummary.name,
                    });
                    return this.handleResponse(
                        response,
                        redirectToURLOnSuccess
                    );
                });
        }
    };

    renderActivitySummaryReview = () => {
        const { activitySummaryReview, activitySummary } = this.state;

        const nonFinancialResults = objectPath.get(
            activitySummaryReview,
            'nonFinancialResults',
            []
        );
        const financialResults = objectPath.get(
            activitySummaryReview,
            'financialResults',
            []
        );

        return (
            <div>
                <div className="maxWidthPart">
                    <ActivitySummaryReviewForm
                        initialValues={activitySummaryReview}
                        activitySummary={activitySummary}
                        readOnly={!this.isASReady()}
                        proceedAsDraft={
                            !this.isASReady()
                                ? () => {}
                                : this.handleActivitySummaryReviewAsDraft
                        }
                        proceedSubmit={
                            !this.isASReady()
                                ? () => {}
                                : this.handleActivitySummaryReviewSubmit
                        }
                    />
                    <ActivitySummaryResultsSection
                        isEmpty={nonFinancialResults.length < 1}
                        heading="Non Financial Results"
                        emptyText="No results"
                    >
                        <ListingTable
                            items={nonFinancialResults}
                            viewProgressReportById={this.viewProgressReportById}
                            rowClassName="ActivitySummaryNonFinancialResult"
                            additionalClasses="activitySummaryResultsSection"
                        />
                    </ActivitySummaryResultsSection>

                    <ActivitySummaryResultsSection
                        isEmpty={financialResults.length < 1}
                        heading="Financial Results"
                        emptyText="No results"
                    >
                        <ListingTable
                            items={financialResults}
                            rowClassName="ActivitySummaryFinancialResult"
                            additionalClasses="activitySummaryResultsSection"
                        />
                    </ActivitySummaryResultsSection>

                    <Hidden smDown>
                        <SystemThresholdsTable />
                    </Hidden>
                </div>
            </div>
        );
    };

    renderProgressReportSummary = () => {
        const { classes } = this.props;
        const { progressReportSummary } = this.state;
        const progressReportExtarnalLinkUrl = objectPath.get(
            progressReportSummary,
            'hyperlink.url'
        );
        return (
            <div>
                <Tabs
                    classes={{ root: classes.tabsWrapper }}
                    indicatorColor="primary"
                    value={'summary'}
                    onChange={this.onMainTabClick}
                >
                    <Tab
                        label="Status"
                        value="summary"
                        classes={{ root: classes.tabBtn }}
                    />
                    {progressReportSummary &&
                    progressReportSummary.reportingItemId ? (
                        <Tab
                            label="Commentary"
                            value="commentary"
                            classes={{ root: classes.tabBtn }}
                        />
                    ) : null}
                </Tabs>

                {progressReportExtarnalLinkUrl ? (
                    <Hidden mdDown>
                        <div
                            className={classnames(
                                'hideForPrint',
                                classes.textRightStrong,
                                classes.summaryActionsWrap
                            )}
                        >
                            <Tooltip title="Open Project in Ozone">
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    target="_blank"
                                    href={progressReportExtarnalLinkUrl}
                                >
                                    <EditIcon /> &nbsp; Edit
                                </Button>
                            </Tooltip>
                        </div>
                    </Hidden>
                ) : null}

                <EditableProgressReportForm
                    viewProgressReportById={this.viewProgressReportById}
                    proceedSubmit={this.handleProgressReportSummary}
                    initialValues={progressReportSummary}
                />
            </div>
        );
    };

    renderReportingItemCommentary = () => {
        const { classes } = this.props;
        const { reportingItemCommentary } = this.state;
        const projectId = objectPath.get(
            reportingItemCommentary,
            'projectId',
            null
        );
        return (
            <div>
                <Tabs
                    classes={{ root: classes.tabsWrapper }}
                    indicatorColor="primary"
                    value={'commentary'}
                    onChange={this.onMainTabClick}
                >
                    {projectId ? (
                        <Tab
                            label="Status"
                            value="summary"
                            classes={{ root: classes.tabBtn }}
                        />
                    ) : null}
                    <Tab
                        label="Commentary"
                        value="commentary"
                        classes={{ root: classes.tabBtn }}
                    />
                </Tabs>

                <ReportingItemCommentaryFormWrapper
                    handleReportingItemCommentarySubmit={
                        this.handleReportingItemCommentary
                    }
                    reportingItemCommentary={reportingItemCommentary}
                />
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        const {
            dataLoading,
            activitySummary,
            reportingItemCommentary,
            progressReportSummary,
            dialogProjectId,
        } = this.state;

        return (
            <div
                className={classnames(
                    'contentWithSubmenu',
                    classes.contentWithSubmenu,
                    classes['contentWithSubmenuLevel_1']
                )}
            >
                <Hidden smDown>
                    <ActivitySummarySubmenu
                        activitySummary={activitySummary}
                        level={1}
                    />
                </Hidden>
                <div>
                    <PageHeading
                        mutedHeading={this.getMutedHeadingText()}
                        heading={
                            dataLoading ? 'loading ...' : this.getHeadingText()
                        }
                        actions={
                            <Hidden mdUp>
                                {activitySummary ? (
                                    <HeadingBackBtn
                                        redirectToUrl={UrlBuilderUtil.getActivitySummaryMenu(
                                            objectPath.get(
                                                activitySummary,
                                                'id'
                                            )
                                        )}
                                    />
                                ) : null}
                            </Hidden>
                        }
                    />

                    {dataLoading ? (
                        <ComponentLoading />
                    ) : this.isASReady() ? (
                        this.renderActivitySummaryReview()
                    ) : progressReportSummary ? (
                        this.renderProgressReportSummary()
                    ) : reportingItemCommentary ? (
                        this.renderReportingItemCommentary()
                    ) : (
                        <div>
                            <div className="maxWidthPart">
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className="gridItem sectionSidePaddingItem"
                                    >
                                        <Typography variant="body2">
                                            <br />
                                            <br />
                                            <strong>
                                                <WarningIcon /> &nbsp; All
                                                Progress Summaries must be
                                                complete before you can provide
                                                your summary
                                            </strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {this.renderActivitySummaryReview()}
                            </div>
                        </div>
                    )}

                    {dialogProjectId ? (
                        <FullScreenDialog
                            closeDialog={() =>
                                this.viewProgressReportById(null)
                            }
                            showBar={true}
                        >
                            <ViewProgressReport
                                dialogProjectId={dialogProjectId}
                                isInDialog={true}
                                onBackBtn={() =>
                                    this.viewProgressReportById(null)
                                }
                            />
                        </FullScreenDialog>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            ...reportingItemActions,
            ...activitySummaryActions,
            ...progressReportActions,
        },
        dispatch
    ),
});

export default withRouter(
    withSnackbar(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(withStyles(styles, { withTheme: true })(ReviewActivitySummary))
    )
);
