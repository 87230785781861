import React from "react";
import SubMenu from "../../components/menu/desktop/SubMenu";
import store from '../../store';
import objectPath from 'object-path';
import StrategicPlansUtil from '../../utils/StrategicPlansUtil';
import AppCard from "../../components/AppCard";
import ActivityListItem from "../../components/activitySummary/ActivityListItem";
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../theme/styles";
import StoreUtil from "../../utils/StoreUtil";
import PageHeading from "../../components/PageHeading";


class Scorecard extends React.Component {



  render() {
    let strategicPlans = store.getState()['strategicPlans'];
    let zeroLevelId = objectPath.get(this, "props.match.params.zeroLevelId");
    let firstLevelId = objectPath.get(this, "props.match.params.firstLevelId");
    let secondLevelId = objectPath.get(this, "props.match.params.secondLevelId");
    //let thirdLevelId = objectPath.get(this, "props.match.params.thirdLevelId");//selected activity
    let subMenu = StrategicPlansUtil.formatMenu(strategicPlans, zeroLevelId, firstLevelId, secondLevelId);
    const showFirstLevel = objectPath.get(subMenu, "firstLevel.length");
    const showSecondLevel = objectPath.get(subMenu, "secondLevel.length");
    const activities = secondLevelId && showSecondLevel && subMenu.activeNode ? 
                        StrategicPlansUtil.getCurrentActivities(strategicPlans, subMenu.activeNode) 
                        : 
                        null;

    const { classes } = this.props;

    const currentYear = StoreUtil.getValueByKey('reportingParameters.currentReportingYear');
    const currentPeriod = StoreUtil.getValueByKey('reportingParameters.currentReportingPeriod');
    const headingText = currentPeriod && currentYear ? currentYear+" Period "+currentPeriod : "";

    return <div className={classnames("contentWithSubmenu", classes.contentWithSubmenu, classes['contentWithSubmenuLevel_'+(showSecondLevel ? '2' : '1')])}>
      { showFirstLevel ? 
        <SubMenu items={subMenu.firstLevel} groups={true} level={1} /> 
        : 
        null
      }
      { showSecondLevel ?
        <SubMenu items={subMenu.secondLevel} groups={false} level={2} previousLevelBackBtnUrl="/scorecard/" /> 
        : 
        null
      }
      <PageHeading heading={headingText}  />
      { Array.isArray(activities) ?
        (activities.length ? activities.map((a, index) => <ActivityListItem key={index} item={a} />) : <AppCard type="warning"></AppCard>)
        :
        null
      }
    </div>
  }
}

export default withStyles(styles, { withTheme: true })(Scorecard);