import * as types from '../constants/actionTypes';
import initialState from './initialState';
import ConsoleUtil from '../utils/ConsoleUtil';

export function getUsers(state = initialState.users, action) {
    if (action.type === types.GET_USERS) {
        ConsoleUtil.log(types.GET_USERS+' set in store');
        return action.data.data;
    }
    return state;
}

export function getKeycloakUsers(state = initialState.keycloakUsers, action) {
    if (action.type === types.GET_KEYCLOAK_USERS) {
        ConsoleUtil.log(types.GET_KEYCLOAK_USERS+' set in store');
        return action.data.data;
    }
    return state;
}