import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from '../../theme/styles';
//import classnames from 'classnames';
import {
    CardHeader,
    Grid,
    Button,
    CardActionArea,
    CardActions,
} from '@material-ui/core';
import { WysiwygInput, ImageInput } from '../inputs';
import { Field } from 'redux-form';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import StoreUtil from '../../utils/StoreUtil';
import ArrayUtil from '../../utils/ArrayUtil';

class CommentPerGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFieldIndexes: [0], //by default just first if expandable
        };
    }

    copyTextFromTo = (fromFieldName, toFieldName) => {
        const { changeFieldValue, formName } = this.props;
        let fromFieldText = StoreUtil.getValueByKey(
            'form.' + formName + '.values.' + fromFieldName
        );

        changeFieldValue(formName, toFieldName, fromFieldText);
    };

    render() {
        const {
            heading,
            fields,
            classes,
            expandable,
            formName,
            copyable,
            disabled,
            hasImage,
        } = this.props;
        const { showFieldIndexes } = this.state;

        let firstErrorFieldName = null;

        return (
            <Card className={classes.commentPerGroup}>
                {heading ? (
                    <CardHeader
                        title={heading}
                        className={classes.commentPerGroupHeader}
                    ></CardHeader>
                ) : null}
                <CardContent className={classes.commentPerGroupContent}>
                    <Grid container>
                        {fields.map((x, index) => {
                            //display conditions
                            let hasFieldError = StoreUtil.hasFormFieldError(
                                formName,
                                x.name
                            );
                            if (firstErrorFieldName === null && hasFieldError) {
                                firstErrorFieldName = x.name;
                            }
                            let isFieldExpandedShown =
                                !expandable ||
                                (expandable &&
                                    (firstErrorFieldName === x.name || //first error
                                        showFieldIndexes.includes(index) || // show field clicked
                                        StoreUtil.hasFormFieldValue(
                                            formName,
                                            x.name
                                        ))) //has a value
                                    ? true
                                    : false;

                            let hasFieldCopyPrevious =
                                index > 0 && isFieldExpandedShown && copyable;

                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={index}
                                    className={'gridItem' + index}
                                >
                                    <div
                                        className={
                                            !isFieldExpandedShown
                                                ? 'hidden'
                                                : ''
                                        }
                                    >
                                        <Field
                                            name={x.name}
                                            component={WysiwygInput}
                                            hasCopyPrevious={
                                                hasFieldCopyPrevious
                                            }
                                            disabled={disabled}
                                            label={x.label}
                                            placeholder={x.placeholder}
                                            multiline={true}
                                            rows="4"
                                        />
                                    </div>

                                    {!isFieldExpandedShown && (
                                        <Card className="clickToAddBtn">
                                            <CardActionArea
                                                onClick={() =>
                                                    this.setState({
                                                        showFieldIndexes:
                                                            ArrayUtil.addToArrayIfNotExist(
                                                                showFieldIndexes,
                                                                index
                                                            ),
                                                    })
                                                }
                                            >
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                Click to add...
                                                            </span>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className={
                                                                classes.textRightStrong
                                                            }
                                                        >
                                                            <strong>
                                                                {x.label}
                                                            </strong>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    )}

                                    {hasFieldCopyPrevious ? (
                                        <Button
                                            variant="text"
                                            className="copyTxtBtn"
                                            size="small"
                                            color="default"
                                            onClick={() =>
                                                this.copyTextFromTo(
                                                    fields[index - 1].name,
                                                    x.name
                                                )
                                            }
                                        >
                                            Copy {fields[index - 1].label}
                                        </Button>
                                    ) : null}
                                </Grid>
                            );
                        })}
                    </Grid>
                </CardContent>
                {hasImage ? (
                    <CardActions>
                        <Field
                            name={'document'}
                            component={ImageInput}
                            disabled={disabled}
                        />
                    </CardActions>
                ) : null}
            </Card>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    changeFieldValue: function (formName, field, value) {
        dispatch(change(formName, field, value));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CommentPerGroup));
