export default (theme, themeConfig, themeColors) => {
    return {
        logo: {
            display: 'block',

            height: themeConfig.toolbarMinHeight,
            position: 'absolute',
            zIndex: 100,
            paddingTop: 10,
            paddingLeft: themeConfig.commonPadding,
            paddingRight: themeConfig.commonPadding,
            [theme.breakpoints.up('md')]: {
                backgroundColor: themeColors.primaryPalette.white,
                width: themeConfig.drawerWidthLevel_1,
            },
            '& img': {
                width: '100px',
                marginRight: 'auto',
                display: 'inline-block',
            },
        },
        navbarLogo: {
            position: 'relative',
            left: -themeConfig.commonPadding,
            [theme.breakpoints.up('md')]: {
                left: -(3 * theme.spacing(1)),
            },
        },
    };
};
