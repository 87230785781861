import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import HelpLinkIcon from '@material-ui/icons/HelpOutline';
import objectPath from 'object-path';
import { connect } from 'react-redux';

import styles from '../../../theme/styles';

//config
import { Divider, Grid, MenuList } from '@material-ui/core';
import DrawerMenuItem from '../AppMenuItem';
import Logo from '../../Logo';

class DesktopDrawer extends React.Component {
    renderMenuLinks = (items, showIcon) => {
        const { userInfo } = this.props;

        return items.map((m, index) => {
            return (
                <DrawerMenuItem
                    menuItem={m}
                    key={index}
                    showIcon={showIcon}
                    userInfo={userInfo}
                />
            );
        });
    };

    render() {
        const {
            menu,
            classes,
            userInfo,
            //theme,
            handleHelp,
            handleLogout,
        } = this.props;

        const loggedInAs = objectPath.get(userInfo, 'name', null);

        return (
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={true}
                classes={{
                    root: classnames(classes.drawerRoot, 'hideForPrint'),
                    paper: classnames(
                        classes.drawerPaper,
                        classes.drawerPaperLevel_1
                    ),
                }}
            >
                <Logo />
                <Grid container className={classes.drawerGridContainer}>
                    <Grid item xs={12}>
                        <MenuList>
                            <Divider />
                            {this.renderMenuLinks(menu.primaryMenu, true)}
                            <Divider />
                            {this.renderMenuLinks(menu.secondaryMenu, true)}
                            <Divider />
                            {loggedInAs ? (
                                <MenuItem
                                    disabled
                                    className={classnames(
                                        classes.menuItemText,
                                        classes.menuItemTextBtn
                                    )}
                                >
                                    <AccountIcon
                                        fontSize="small"
                                        className={classes.menuItemIcon}
                                    />
                                    <span>{loggedInAs}</span>
                                </MenuItem>
                            ) : null}
                            {handleHelp ? (
                                <MenuItem
                                    onClick={handleHelp}
                                    className={classnames(
                                        classes.menuItemText,
                                        classes.menuItemTextBtn
                                    )}
                                >
                                    <HelpLinkIcon
                                        fontSize="small"
                                        className={classes.menuItemIcon}
                                    />
                                    <span>Help</span>
                                </MenuItem>
                            ) : null}
                            <MenuItem
                                onClick={handleLogout}
                                className={classnames(
                                    classes.menuItemText,
                                    classes.menuItemTextBtn
                                )}
                            >
                                <LogoutIcon
                                    fontSize="small"
                                    className={classes.menuItemIcon}
                                />
                                <span>Logout</span>
                            </MenuItem>
                        </MenuList>
                    </Grid>
                </Grid>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => ({
    //force rerender on location and dashboard data change
    routerLocations: state.routerLocations,
    dashboardMergedData: state.dashboardMergedData,
});

export default connect(mapStateToProps)(
    withStyles(styles, { withTheme: true })(DesktopDrawer)
);
