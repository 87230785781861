import React, { Component } from "react";
import PropTypes from "prop-types";
import LoggerUtil from "../utils/LoggerUtil";

import keycloakManager from "../keycloakManager";

const withAuth = WrappedComponent => {
  const propTypes = {
    isAuthenticated: PropTypes.bool
  };

  class AuthedComponent extends Component {
    state = {
      isAuthenticated: false
    };

    componentDidMount() {
      this.authenticate();
    }

    authenticate() {
      keycloakManager
        .authenticate().then(isAuthenticated => this.authenticateSuccess(isAuthenticated))
        .catch(error => this.authenticateFailure(error));
    }

    authenticateSuccess(isAuthenticated) {
      if (!isAuthenticated) return false;
      this.setState({
        isAuthenticated,
        userName: keycloakManager.getUserName() || 'User Name',
      });
    }

    authenticateFailure(error) {
      LoggerUtil.error("Keycloak failed to initialize: ", error);
      keycloakManager.logout();
    }

    render() {
      let { isAuthenticated, userName } = this.state;

      if (!isAuthenticated) return null;

      return <WrappedComponent userName={userName} />;
    }
  }

  AuthedComponent.propTypes = propTypes;

  return AuthedComponent;
};

export default withAuth;
