import objectPath from 'object-path';
import classnames from 'classnames';
import * as constants from '../constants/constants';

export default class AssessmentUtil {

    
    
      static getAssessmentStatusCount(items, status, assessmentField) {
        var fileteredByStatus = items.filter(i => objectPath.get(i,'assessments.'+assessmentField) === status );
        return fileteredByStatus ? fileteredByStatus.length : 0;
      }
    
    
    
      static getIndicator(assessment) {
        assessment = parseInt(assessment);//double check if int
        switch(assessment) {
          case constants.ASSESSMENT_MET:
            return 'successIndicator';
          case constants.ASSESSMENT_PARTIALMET:
            return 'warningIndicator';
          case constants.ASSESSMENT_NOTMET:
            return 'errorIndicator';
          default:
            return '';
        }
      }

      static getIndicatorText(assessment) {
        assessment = parseInt(assessment);//double check if int
        switch(assessment) {
          case constants.ASSESSMENT_MET:
            return constants.ASSESSMENT_MET_TEXT;
          case constants.ASSESSMENT_PARTIALMET:
            return constants.ASSESSMENT_PARTIALMET_TEXT;
          case constants.ASSESSMENT_NOTMET:
            return constants.ASSESSMENT_NOTMET_TEXT;
          case constants.ASSESSMENT_NOTCOMMENCED:
            return constants.ASSESSMENT_NOTCOMMENCED_TEXT;
          default:
            return 'N/A';
        }
      }
    
      static getIndicatorClassname(classes, assessment) {
          return classnames(classes.statusIndicator,classes[AssessmentUtil.getIndicator(assessment)]);
      }

}