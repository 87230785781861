import { createTheme } from '@material-ui/core/styles';
import {
    primaryPalette,
    primarySupportingPalette,
    //secondarySupportingPalette,
    customPalette,
} from './constants/colors';
import { defaultFont, headingFont } from './constants/fonts';

//see https://material-ui.com/customization/default-theme/ for list of all variables

const CustomTheme = createTheme({
    palette: {
        primary: { main: primaryPalette.primary }, // Purple and green play nicely together.
        secondary: {
            main: primaryPalette.secondary,
            contrastText: primaryPalette.white,
        }, // This is just green.A700 as hex.
        error: {
            main: primarySupportingPalette.pms200c,
        },
        background: {
            paper: primaryPalette.white,
            default: customPalette.bodyBg,
        },
    },
    typography: {
        fontFamily: defaultFont, // '"Open Sans", sans-serif'
        useNextVariants: true,
        h1: {
            fontFamily: headingFont,
            textTransform: 'uppercase',
            fontSize: 24,
            fontWeight: 900,
            lineHeight: 'normal',
        },
        h2: {
            fontFamily: headingFont,
        },
        h3: {
            fontFamily: headingFont,
        },
        h4: {
            fontFamily: headingFont,
            fontWeight: 600,
            fontSize: '1.2rem',
            textTransform: 'uppercase',
        },
        h5: {
            fontSize: '1.1rem',
        },
        button: {
            fontWeight: 900,
            textTransform: 'none',
        },
        caption: {
            fontSize: '0.85rem',
        },
    },
    spacing: 8,
});

export default CustomTheme;
