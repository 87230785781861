import React from 'react';
import { Button } from '@material-ui/core';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeftRounded";
import { withRouter } from "react-router-dom";  

function HeadingBackBtn(props) {

  const { redirectToUrl, history, onBackBtn } = props;

  return <Button variant="text" size="small" onClick={() => onBackBtn ? onBackBtn() : history.push(redirectToUrl)}>
        <ChevronLeftIcon />
        Back
      </Button>;
}

export default withRouter(HeadingBackBtn);