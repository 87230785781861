import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from '../theme/styles';
import { TablePagination, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import * as constants from '../constants/constants';

class ListingTableFooter extends React.Component {
    handleChangePage = (event, newPage) => {
        const { history, listingDataObject, filter, urlBuilderFunction } =
            this.props;
        history.push(
            urlBuilderFunction(
                {
                    page: newPage,
                    pageSize: listingDataObject.size,
                },
                filter
            )
        );
    };

    handleChangeRowsPerPage = (event) => {
        const { history, filter, urlBuilderFunction } = this.props;
        history.push(
            urlBuilderFunction(
                {
                    page: constants.DEFAULT_LISTING_PAGE,
                    pageSize: event.target.value,
                },
                filter
            )
        );
    };

    render() {
        const { classes, listingDataObject } = this.props;

        return listingDataObject ? (
            <div className={classes.tablePaginationWrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <table>
                            <tbody>
                                <tr>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        colSpan={3}
                                        count={listingDataObject.totalElements}
                                        rowsPerPage={listingDataObject.size}
                                        page={listingDataObject.number}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={
                                            this.handleChangeRowsPerPage
                                        }
                                        //ActionsComponent={TablePaginationActions}
                                        classes={{ input: 'paginationInput' }}
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </div>
        ) : null;
    }
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ListingTableFooter)
);
