import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Button } from '@material-ui/core';
import StoreUtil from '../../utils/StoreUtil';
import objectPath from 'object-path';
import GroupCommentCard from './GroupCommentCard';
import classnames from 'classnames';
import CommentPerGroup from './CommentPerGroup';
import { change } from 'redux-form';
import { connect } from "react-redux";

class PreviewEditCommentPerGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editMode: objectPath.get(props,'editMode',false)
    }
  }


  isEditMode = () => {
    const { editMode } = this.state;
    return this.isEditable() && (!this.hasInitValue() || editMode);
  }

  hasInitValue = () => {
    const { fields, formName, comment } = this.props;
    if(comment){
      return true;
    }else{
      let firstFieldName = objectPath.get(fields,'0.name');
      return StoreUtil.getValueByKey('form.' + formName + '.initial.' + firstFieldName, false) ? true : false;
    }  
  }

  toggleEditable = () => {
    const { editMode } = this.state;
    const { fields, changeFieldValue, formName } = this.props;

    if(editMode && this.hasInitValue()){//Cancel reset to init values
      fields.forEach(f => {
        var initialValue = StoreUtil.getValueByKey('form.' + formName + '.initial.' + f.name);
        changeFieldValue(formName, f.name, initialValue);
      });
    }
    this.setState({editMode: !editMode})
  }

  isEditable = () => {
    const { disabled } = this.props;
    
    //(not disabled and has a value) render button to edit comment
    return !disabled;
  }

  getComment = () => {
    const {comment, formName} = this.props;
    return comment ? comment : StoreUtil.getValueByKey('form.' + formName + '.values');
  }


  render() {
    const { heading, fields, classes, expandable, formName, copyable, disabled, objectPathUserId, objectPathCreatedDate, additionalClass, showAllInReadOnly, hasImage } = this.props;
    const { editMode } = this.state;


    return <div>
      { this.isEditMode() ? <CommentPerGroup 
      heading={heading}
      fields={fields}
      expandable={expandable}
      formName={formName}
      copyable={copyable}
      disabled={disabled}
      hasImage={hasImage}
      />
      :
      <GroupCommentCard 
      comment={this.getComment()}
      additionalClass={additionalClass}
      objectPathUserId={objectPathUserId}
      objectPathCreatedDate={objectPathCreatedDate}
      heading={heading}
      nonExpandable={!expandable}
      showAll={showAllInReadOnly}
      fields={fields}
      hasImage={hasImage}
      /> }
      {
        this.isEditable() && this.hasInitValue() ? <div className={classnames(classes.textRightStrong,'narrowMaxMediumWidthPart')}>
          <Button variant="outlined" color={editMode ? "default" : "primary"} onClick={this.toggleEditable}>
            { editMode ? "Cancel" : "Edit" }
          </Button>
        </div> : null
      }
    </div>;
  }
}


const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  ({
    changeFieldValue: function(formName, field, value) {
      dispatch(change(formName, field, value))
    },
  });


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles, { withTheme: true })(PreviewEditCommentPerGroup)
);
