import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import * as formNames from "../../../constants/forms";
import * as constants from '../../../constants/constants';
import classnames from 'classnames';
import {
  TextInput, SelectWithSuggestions,
} from "../../inputs/";
import TickIcon from "@material-ui/icons/Check";
import validate from "./validate";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Fab, Tooltip} from "@material-ui/core";
import warnAboutUnsavedForm from "../../../hoc/withWarnAboutUnsavedChanges";

const BinderCategoryForm = props => {
  const {
    handleSubmit,
    classes,
    className,
    submitting,
    proceedSubmit,
    reportOutputs
  } = props;
  

  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><TickIcon /></Fab>;

  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classnames(classes.customForm, className)}>
           
          <div className="maxWidthPart narrowMaxWidthPart">
          <Grid container>
            
            
            <Grid item className="gridItem" xs={12}>
              <FormGroup>
                <Field name="name" component={TextInput} label="Name" inputProps={{maxLength: 50}} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12}>
              
                <Field name="reportOutputIds" component={SelectWithSuggestions} isSearchable={true} isMulti={true} options={reportOutputs} label="Report Outputs"  />
              
            </Grid>

            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12}>
{ disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title="Save binder category">{submitButton}</Tooltip> }
</Grid> 
            
          </Grid>
          </div>
        </form>
    </div>
  );
};


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.BINDER_CATEGORY_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  validate,
  //asyncValidate
})(BinderCategoryForm)),formNames.BINDER_CATEGORY_FORM_NAME);
