import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import * as constants from '../constants/constants';
import ConsoleUtil from "../utils/ConsoleUtil";
import FormatterUtil from "../utils/FormatterUtil";

export function getReportingItems() {
  return function(dispatch) {
    ConsoleUtil.log('getReportingItems called');
    return reportingClient
      .get(`ReportingItems`)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_ITEMS)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}


export function getAllReportingItems() {
  return function(dispatch) {
    ConsoleUtil.log('getReportingItemSummaryList called');
    return reportingClient
      .get('ReportingItemSummary?'+
        constants.LISTING_PAGE_PARAM+'=0&'+constants.LISTING_PAGE_SIZE_PARAM+'=1000'
        )
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_ALL_REPORTING_ITEMS)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}

  export function getReportingItemSummaryList(paginationObject = { pageSize: constants.DEFAULT_LISTING_PAGE_SIZE, page: constants.DEFAULT_LISTING_PAGE}, filter = null) {
    return function(dispatch) {
      ConsoleUtil.log('getReportingItemSummaryList called');
      var searchQuery = [];
      searchQuery.push(constants.LISTING_PAGE_PARAM+'='+paginationObject.page);
      searchQuery.push(constants.LISTING_PAGE_SIZE_PARAM+'='+paginationObject.pageSize);
      if(filter){
        if(filter.keyword){
          searchQuery.push('keyword='+FormatterUtil.keywordEncodeURI(filter.keyword));
        }
        if(filter.archivedonly){
          searchQuery.push('archivedonly=1');
        }
      }
      return reportingClient
        .get('ReportingItemSummary?'+searchQuery.join('&'))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_ITEM_SUMMARY_LIST)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  export function getReportingItemSummary(id) {
    return function(dispatch) {
      ConsoleUtil.log('getReportingItemSummary '+id+' called');
      return reportingClient
        .get(`ReportingItemSummary/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_ITEM_SUMMARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function getReportingItemTimeline(id, nextDate = null) {
    return function(dispatch) {
      ConsoleUtil.log('getReportingItemTimeline '+id+' called for date '+nextDate);
      var queryPart = nextDate ? '?date='+nextDate : '';
      return reportingClient
        .get(`ReportingItemRecords/${id}${queryPart}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_ITEM_TIMELINE)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

export function getReportingItem(id) {
    return function(dispatch) {
      ConsoleUtil.log('getReportingItems '+id+' called');
      return reportingClient
        .get(`ReportingItems/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_ITEM)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function postReportingItem(data) {
    return function(dispatch) {
      ConsoleUtil.log('postReportingItem');
      return reportingClient
        .post(`ReportingItems`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_REPORTING_ITEM)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function putReportingItem(data) {
    return function(dispatch) {
      ConsoleUtil.log('putReportingItem');
      return reportingClient
        .put(`ReportingItems/${data.id}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_REPORTING_ITEM)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function getReportingItemCommentary(id) {
    return function(dispatch) {
      ConsoleUtil.log('getReportingItemCommentary '+id+' called');
      return reportingClient
        .get(`ProgressCommentary/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_ITEM_COMMENTARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  export function getReportingItemHistoryCommentary(id) {
    return function(dispatch) {
      ConsoleUtil.log('getReportingItemHistoryCommentary '+id+' called');
      return reportingClient
        .get(`ReportingItemCommentaries/?reportingItemId=${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_REPORTING_ITEM_HISTORY_COMMENTARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  export function postReportingItemCommentary(data) {
    return function(dispatch) {
      ConsoleUtil.log('postReportingItemCommentary called');
      return reportingClient
        .post(`ProgressCommentary/${data.reportingItemId}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_REPORTING_ITEM_COMMENTARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function postReportingItemQuickCommentary(reportingItemId, data) {
    return function(dispatch) {
      ConsoleUtil.log('postReportingItemQuickCommentary called');
      return reportingClient
        .post(`QuickComments/${reportingItemId}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_REPORTING_ITEM_QUICK_COMMENTARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }



  export function putQuickComment(comment) {
    return function(dispatch) {
      ConsoleUtil.log('putQuickComment called');
      return reportingClient
        .put(`QuickComments/${comment.id}`,comment)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_QUICK_COMMENT)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }

  
  export function putWhatsHappenedComment(comment) {
    return function(dispatch) {
      ConsoleUtil.log('putWhatsHappenedComment called');
      return reportingClient
        .put(`WhatsHappenedComments/${comment.id}`,comment)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_WHAT_HAPPENED_COMMENT)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function putWhatsNextComment(comment) {
    return function(dispatch) {
      ConsoleUtil.log('putWhatsNextComment called');
      return reportingClient
        .put(`WhatsNextComments/${comment.id}`,comment)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_WHAT_NEXT_COMMENT)),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }
  


  export function canArchiveReportingItemById(id) {
    return function(dispatch) {
      ConsoleUtil.log('get ReportingItems archive called');
      return reportingClient
        .get(`ReportingItems/${id}/archive`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, '')),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function canReinstateReportingItemById(id) {
    return function(dispatch) {
      ConsoleUtil.log('get ReportingItems reinstate called');
      return reportingClient
        .get(`ReportingItems/${id}/reinstate`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, '')),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function archiveReportingItemById(id) {
    return function(dispatch) {
      ConsoleUtil.log('patch ReportingItems archive called');
      return reportingClient
        .patch(`ReportingItems/${id}`, [{
          "op": "replace",
          "path": "/archived",
          "value": true
        }])
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, '')),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }


  export function reinstateReportingItemById(id) {
    return function(dispatch) {
      ConsoleUtil.log('patch ReportingItems reinstate called');
      return reportingClient
        .patch(`ReportingItems/${id}`,[{
          "op": "replace",
          "path": "/archived",
          "value": false
        }])
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, '')),
          error => dispatch(ajaxCalls.ajaxCallError(error))
        );
    };
  }
  
