import * as types from "../constants/actionTypes";
import reportingClient from "../api/reportingClient";
import * as ajaxCalls from "./ajaxCalls.js";
import ConsoleUtil from "../utils/ConsoleUtil";
import * as constants from '../constants/constants';



export function getActivitySummaries(paginationObject = { pageSize: constants.DEFAULT_LISTING_PAGE_SIZE, page: constants.DEFAULT_LISTING_PAGE}) {
  return function(dispatch) {
    ConsoleUtil.log('get activity summaries action');
    return reportingClient
      .get('ActivitySummary?'+
        constants.LISTING_PAGE_PARAM+'='+paginationObject.page+
        '&'+
        constants.LISTING_PAGE_SIZE_PARAM+'='+paginationObject.pageSize
        )
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_ACTIVITY_SUMMARIES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_ACTIVITY_SUMMARIES))
      );
  };
}


export function getActivitySummary(id) {
    return function(dispatch) {
      ConsoleUtil.log('get activity summary by id action');
      return reportingClient.get(`ActivitySummary/${id}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_ACTIVITY_SUMMARY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_ACTIVITY_SUMMARY))
        );
    };
  }

  export function getActivitySummaryReview(id, year = null, period = null) {
    return function(dispatch) {
      ConsoleUtil.log('get activity summary review by id action');
      var query = (year && period) ? '?year='+year+'&period='+period : '';
      return reportingClient.get(`ActivityReviews/${id}${query}`)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_ACTIVITY_SUMMARY_REVIEW)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_ACTIVITY_SUMMARY_REVIEW))
        );
    };
  }

  export function postActivitySummaryReview(data) {
    return function(dispatch) {
      ConsoleUtil.log('post activity summary review by id action');
      return reportingClient
      .post(`ActivityReviews/${data.id}`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_ACTIVITY_SUMMARY_REVIEW)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_ACTIVITY_SUMMARY_REVIEW))
        );
    };
  }

  export function postActivitySummaryReviewAsDraft(data) {
    return function(dispatch) {
      ConsoleUtil.log('post activity summary review by id action as draft');
      return reportingClient
      .post(`ActivityReviews/${data.id}/true`,data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_ACTIVITY_SUMMARY_REVIEW_DRAFT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_ACTIVITY_SUMMARY_REVIEW_DRAFT))
        );
    };
  }
